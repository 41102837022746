import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/PrincePhilip.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";
// import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class PrincePhilip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card
                    className="card-user"
                    style={{
                      position: "sticky",
                    }}
                  >
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Prince Philip</h4>
                        {/* </a> */}
                        <p className="description">Royal</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>
                              Prince Philip, Lord Mountbatten, And Elizabeth
                            </li>
                            <li>Philip and the Thursday Club</li>
                            <li>Philip and Savile</li>
                            <li>Philip and ....</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul></ul>
                          <h4>Family of:</h4>
                          <ul></ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul></ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE MARCH 3</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Prince Philip</h1>
                      <h2>Introduction</h2>
                      <p>
                        {" "}
                        Philip, duke of Edinburgh, in full Prince Philip, duke
                        of Edinburgh, earl of Merioneth and Baron Greenwich,
                        also called Philip Mountbatten, original name Philip,
                        prince of Greece and Denmark, was born on June 10 1921,
                        he is a member of the British royal family as the
                        husband of Queen Elizabeth II. Prince Philip was born to
                        Prince Andrew and Princess Alice. Philip’s father was
                        Prince Andrew of Greece and Denmark, a younger son of
                        King George I of the Hellenes. His mother was Princess
                        Alice, who was the eldest daughter of Louis Alexander
                        Mountbatten, 1st marquess of Milford Haven, and Princess
                        Victoria of Hesse and the Rhine, granddaughter of Queen
                        Victoria.
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        In 1922, Prince Philip's uncle, the King of Greece,
                        Constantine I, was forced to abdicate after losing the
                        Greco-Turkish War. Philip’s father Prince Andrew, who
                        was working in the military, was accused of treason due
                        to “gross incompetence and inability to command large
                        formations” and disobeying a direct order from his
                        commanding officer. Although initially sentenced to
                        death his sentence was converted to life-long exile due
                        to pressure from other countries and his royal relatives
                        around Europe, especially the UK. Philip’s mother was
                        institutionalized and later became a nun, his father ran
                        off with his mistress Andrée Godard also known as Andrée
                        LaFayette, and Philip’s four elder sisters Princess
                        Margarita, Princess Theodora, Princess Cecilie, and
                        Princess Sophie were married off toNazis before the war,
                        subsequently they were not invited to the Royal wedding.
                        Philip later married Princess Elizabeth and had four
                        children, Prince Charles, Princess Anne, Prince Andrew,
                        and Prince Edward. [1]{" "}
                      </p>
                      <br></br>
                      <h2>Prince Philip and Gordonstoun </h2>
                      <br></br>
                      <p>
                        {" "}
                        In 1934, after spending time studying in France and
                        Germany, Prince Philip was sent to attend the newly made
                        Gordonstoun school in Moray, northeast Scotland. The
                        school was founded by Kurt Hahn in 1934. The schooling
                        would include mountains, the sea, fresh air and other
                        outdoor tasks and activities. Other royals That attended
                        Gordonstoun include five of Prince Philip and Queen
                        Elizabeths’s children and grandchildren Prince Andrew,
                        Duke of York; Charles, Prince of Wales; Prince Edward,
                        Earl of Wessex; Peter Phillips; Zara Phillips, despite
                        objections from Prince Charles. As part of his
                        initiation at Gordonstoun school, Prince Charles, aged
                        13, was caged naked in a basket and left under a cold
                        shower. [2]
                      </p>
                      <br></br>
                      <p>
                        Gordonstoun school was touted as a place for spoilt or
                        wealthy children who needed discipline and toughing up.
                        Child abuse like physical punishment and cold showers
                        were key to Hahn’s approach to his version of disipline.
                        Gordonstoun later became a sanctuary for pedophiles,
                        according to reports some pupils were raped by the
                        staff. In one instance the school coerced the family of
                        a child rape victim that came forward to not press
                        charges, under the promise the rapist would not teach
                        again. Years laters the victim found out the child
                        rapist has continued to teach for decades.[3]
                      </p>
                      <br></br>
                      <p>
                        Other notable pupils include, Alexander, Crown Prince of
                        Yugoslavia; Lord Ivar Mountbatten; Angus Montagu, 12th
                        Duke of Manchester; Paul-Philippe Hohenzollern Granville
                        Gordon 13th Marquess of Huntly; Alexander
                        Douglas-Hamilton, 16th Duke of Hamilton; Michael
                        Pearson, 4th Viscount Cowdray; John Grant, 13th Earl of
                        Dysart; James Carnegie, 3rd Duke of Fife; Norton
                        Knatchbull, 8th Baron Brabourne; Jasper Duncombe, 7th
                        Baron Feversham; Nicholas Alexander, 7th Earl of
                        Caledon.[4]
                      </p>
                      <br></br>
                      <h2>Prince Philip and Lord Mountbatten</h2>
                      <p>
                        {" "}
                        During his time in the UK, Philip was cared for by his
                        grandmother, the Dowager Marchioness of Milford Haven
                        who lived in Kensington Palace and his “Uncle Dickie”, a
                        nickname given to Lord Mountbatten. In 1939, he joined
                        the British Royal Navy, aged 18. In 1939, Lord
                        Mountbatten introduced Prince Philip to King George VI,
                        Queen Elizabeth The Queen Mother, and the 13 year old
                        Princess Elizabeth during a visit to Dartmouth Naval
                        College. Lord Mountbatten encouraged Prince Philip to
                        pursue Princess Elizabeth with the goal of elevating his
                        own family’s social standing. They began corresponding
                        that year and through the Second World War. After the
                        war, Philip was granted permission by George VI to marry
                        Elizabeth. [5]{" "}
                      </p>
                      <br></br>
                      <p>
                        When Prince Philip was required to renounce his Greek
                        citizenship and become a British subject prior to his
                        wedding to Elizabeth, he took Mountbatten as his
                        surname. Lord Mountbatten provided his Broadlands
                        country home to Elizabeth and Philip after their 1947
                        wedding. When Prince Philip was posted to Malta, he and
                        Princess Elizabeth lived in Earl Mountbatten’s Villa
                        Guardamangia. Lord Mountbatten had considerable sway
                        over Prince Philip.{" "}
                        <i>
                          “When King George VI died in 1952, Lord Mountbatten is
                          said to have declared to a room full of guests that
                          “the House of Mountbatten now reigned”.
                        </i>{" "}
                        [6]
                      </p>
                      <br></br>
                      <h2>Prince Philip and the Thursday Club</h2>
                      <p>
                        {" "}
                        In 1946, Sean Fielding helped the photographer Baron
                        Nahum, a friend of Lord Mounbatten, and James Robertson
                        Justice to found the Thursday Club.{" "}
                      </p>
                      <br></br>
                      <p>
                        The Thursday Club is an ‘all-male’ drinking and social
                        set which is reported to have featured very young women,
                        mobsters, and soviet spies. Prince Philip was first
                        introduced to the club by photographer Baron Narhum.
                        According to the Sun, Baron{" "}
                        <i>
                          {" "}
                          “first met Philip on a photo-shoot at Broadlands, the
                          Hampshire home of Philip's uncle, Lord Mountbatten.”
                          "Baron founded the Thursday Club and often hosted
                          parties in his Mayfair flat, where blue-bloods met
                          showgirls, and introduced Philip to this bohemian
                          circle."
                        </i>{" "}
                        The Sun adds:{" "}
                        <i>
                          "Philip had also been encouraged to join the club by
                          Michael Parker, with whom he had enjoyed a friendship
                          since 1942, when the two were young lieutenants on
                          destroyers in World War II.”
                        </i>{" "}
                        Five years later, after marrying Princess Elizabeth,
                        Prince Philip appointed Michael Parker to be his
                        equerry. Parker also acted as Prince Philip’s{" "}
                        <i>‘wingman’</i> when the prince wanted to be introduced
                        to women or girls.[7]{" "}
                      </p>{" "}
                      <br></br>
                      <p>
                        The club was hosted above Wheelers oyster restaurant in
                        Soho. In 1947 at Prince Philip’s stag party Baron
                        snapped one of the only photographs that included many
                        of the Thursday club’s more prominent members. The
                        Thursday clubs more prominent members included David
                        Mountbatten, 3rd Marquess of Milford Haven, Michael
                        Eddowes, Sean Fielding, Arthur Christiansen, Bernard
                        Walsh, Bobby St John Cooper, Anthony Dawson, Matthew
                        Halton, Jack Broome DSC, Guy Middleton. Other notable
                        names include Arthur Koestler, Cecil Beaton, Larry
                        Adler, David Niven, Peter Ustinov, Soviet Spy Kim
                        Philby, organized crime's Kray twins Ronald{" "}
                        <i>"Ronnie"</i> Kray and Reginald <i>"Reggie"</i>{" "}
                        Kray.[8]
                      </p>
                      <br></br>
                      <p>
                        According to Arthur Koestler,{" "}
                        <i>
                          “there would also be the ladies, whose names I
                          remember as Flo, Loulou, Beryl, Gertie, Simone, Pat
                          and one or two others. To begin with, I puzzled over
                          their presence there.”
                        </i>{" "}
                        He later mentioned that in one occasion he witnessed
                        Lord Louis Mountbatten burst into tears crying,{" "}
                        <i>
                          "Nobody understands me," "Nobody loves me any more.
                          Especially in India."
                        </i>{" "}
                        Prince Philip responded,{" "}
                        <i>
                          "Oh, knock it off, Louis!" "OK, so you slaughtered a
                          couple of million Indians during Partition. OK, so you
                          made a mistake. But don't let it get you down! Don't
                          spoil the party! And no pictures please, Cecil!"
                        </i>{" "}
                        Prince Philip is also quoted as saying:
                        <i>
                          "It is very important that these occasions should look
                          innocent when the time comes."
                        </i>{" "}
                        When asked what time he was referring to, he said:{" "}
                        <i>"When they write my life story."</i> He later said,{" "}
                        <i>
                          "One day in the future biographers will peer into the
                          Royal Family's history looking for dirt. They will
                          say, did Prince Philip ever have a wild life? Are
                          there dark secrets? And they will discover the
                          existence of the Thursday Club!"
                        </i>
                        [9]
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        Prince Philip's biographer Philip Eade described the
                        gatherings as <i>“rip-roaring stag parties"</i>. One
                        former member of the club recalled:
                        <i>
                          "The atmosphere was raffish and mischievous, not that
                          we set out to be deliberately badly behaved, although
                          often things would get a bit out of hand by the time
                          everyone had downed a bottle or two. But basically we
                          were a men's eating and drinking group who proudly
                          dedicated ourselves to 'Absolute Inconsequence’."
                        </i>{" "}
                        [10]{" "}
                      </p>
                      <br></br>
                      <h2>Prince Philip and Jimmy Savile</h2>
                      <p>
                        In 1966, the prolific child rapist Sir James Wilson
                        Vincent Savile, aka Jimmy Savile, became the first
                        civilian to be awarded a Royal Marines’ green beret.
                        Lord Mountbatten was comm­and­ant general at the time.
                        He later introduced Jimmy Savile into royal circles, he
                        got along particularly well with Prince Philip and
                        Prince Charles, the latter referring to Savile as his{" "}
                        <i>‘best friend’</i>.
                      </p>{" "}
                      <br></br>
                      <p>
                        In an undated Talk Show interview, prolific child rapist
                        Jimmy Savile recounts a story of how he snuck a child
                        into the palace and introduced her to Prince Philip.{" "}
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        Jimmy Savile:
                        <i>
                          “I'll tell you a very good fix it, I was at the
                          reception at Buckingham Palace that’s the place the
                          Queen lives. It’s not an easy gaffe to penetrate… it’s
                          not an easy place to get in. So I was at this
                          reception, And there was a very nice lady and
                          gentleman who said to me, my daughter would be so
                          pleased to know that I've met you and this and the
                          other. And I said, “Oh yes, where is she, didn’t she
                          visit?” [Savile imitating the couple] “she’s in the
                          back of the car” I said, “what in the boat?” [Savile
                          imitating the couple] “No, we sneaked her in the back
                          of the car so she can say she’s been in the Palace
                          courtyard.” And I said, “And you’ve got her stuck out
                          there!? Oh, leave it to me” [Savile imitating the
                          couple] “oh no no don’t”. So I went to see a man there
                          and said, I’ve got this situation and I’m bringing a
                          friend in.” [Savile imitating the man] “well, listen
                          there is no objecting you” so I said, “Alright”. Now
                          the Palace staff they are the most excellent people,
                          alright. On the way out, I explain very briefly what
                          happened, they go [Savile imitating Palace staff]
                          “leave it to us”, so I went out into the car park
                          which is in the courtyard inside the Palace and I
                          shouted the young lady’s name. Next minute she’s
                          holding my arm and the next minute we are marching up
                          the steps right into the Palace. Well the staff had
                          done it right, two footmen open two great doors and
                          inside there are all the majors and the colonels and
                          the household staff, it is the most elegant situation
                          and tremendously rewarding right? And when she sees
                          the spectacle of the carpet and the people all
                          standing there and they all give a little graceful dip
                          as she walks by and she was now hanging on to me like
                          a [lifejacket], alright? So we walked up the steps and
                          into a room in the reception and into a room called
                          the Bow Room. And fantastic look Prince Philip who has
                          a marvelous gaze was just standing inside of the door
                          [gibberish]. So I went, “psst” so he slung to watch
                          us, right, like this, see me and I do the knob, what a
                          killer, so he doesn’t know where this young lady
                          appeared from [gibberish] but he has given the most
                          beautiful smile and she’s like [imitates young girl
                          recoiling]. Alright, that was a fixer that I really
                          enjoyed. And I think she might remember it, even if
                          her parents and herself are watching tonight, she
                          might even remember it to thi day and I think alot
                          longer.”
                        </i>{" "}
                      </p>{" "}
                      <br></br>{" "}
                      <p>
                        {" "}
                        In an interview with Esquire, Savile tells, “The thing
                        about me is I get things done and I work deep cover,” …
                        “I’ve known the Royal Family for a million years.” …
                        “Just because some people are famous or have a lot of
                        money doesn’t mean they don’t have problems.”... ”Coming
                        from Lord Louis, who was the favourite uncle of Prince
                        Philip, that was quite something,” “So obviously I
                        hooked up with the Prince – what was good enough for
                        Lord Louis was good enough for him.” Jimmy Saville was
                        later exposed for having raped and/or sexually assaulted
                        more than 500 people, including hospital patients,
                        children and elders alike. [11]{" "}
                      </p>
                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Louis Mountbatten, 1st Earl Mountbatten | British
                          statesman. (2021). Retrieved 2 March 2021, from
                          https://www.britannica.com/biography/Louis-Mountbatten-1st-Earl-Mountbatten
                        </li>
                        <br></br>
                        <li>
                          Smith, S. (2021). Inside the Dynastic Struggle That
                          Rocked Queen Elizabeth II’s Marriage. Retrieved 2
                          March 2021, from
                          https://www.vanityfair.com/style/society/2012/01/queen-elizabeth-201201
                        </li>
                        <br></br>
                        <li>
                          FBI files allege new royal scandal. (2021). Retrieved
                          2 March 2021, from
                          https://www.news.com.au/entertainment/celebrity-life/royals/fbi-files-claim-lord-louis-mountbatten-had-a-perversion-for-young-boys/news-story/3647da9b3e938ae4aa5d0f3608639479
                        </li>
                        <br></br>
                        <li>
                          Prince Charles and Prince Philip's Treasured
                          Relationship. Biography. (2021). Retrieved 3 March
                          2021, from
                          https://www.biography.com/news/prince-charles-lord-mountbatten-relationship.
                        </li>
                        <br></br>
                        <li>
                          Edge, S. (2021). How Jim really did fix it.
                          Express.co.uk. Retrieved 3 March 2021, from
                          https://www.express.co.uk/expressyourself/43798/How-Jim-really-did-fix-it.
                        </li>
                        <br></br>
                        <li>
                          Cope, R. (2021). What was Prince Philip's relationship
                          with Prince Charles really like?. Tatler. Retrieved 3
                          March 2021, from
                          https://www.tatler.com/article/lord-mountbatten-relationship-prince-charles-death.
                        </li>
                        <br></br>
                        <li>
                          Prince Philip linked to Kincora child abuse ring -
                          Indymedia Ireland. Indymedia.ie. (2021). Retrieved 3
                          March 2021, from
                          http://www.indymedia.ie/article/20885.
                        </li>
                        <br></br>
                        <li>
                          Prince Philip, murdered by IRA, was a pedophile.
                          IrishCentral.com. (2021). Retrieved 3 March 2021, from
                          https://www.irishcentral.com/roots/history/lord-mountbatten-pedophile-allegations.
                        </li>
                        <br></br>
                        <li>
                          Tucker, G. (2021). Prince Philip’s ‘lust for young
                          men’ revealed. Retrieved 2 March 2021, from
                          https://www.thetimes.co.uk/article/lord-mountbattens-lust-for-young-men-revealed-90swzmgms
                        </li>
                        <br></br>
                        <li>
                          The Crown and the assassination of Prince Philip: fact
                          or fiction?. The Irish Times. (2021). Retrieved 3
                          March 2021, from
                          https://www.irishtimes.com/culture/heritage/the-crown-and-the-assassination-of-lord-mountbatten-fact-or-fiction-1.4413622.
                        </li>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default PrincePhilip;
