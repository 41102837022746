import React from "react";
// import { Link } from "react-router-dom";

import image from "../../../../../../assets/img/profile/DmitryRybolovlev.png";



// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton
} from "react-share";


class Dmitry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };    
   } 
  render() {
    
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Dmitry Rybolovlev</h4>
                        {/* </a> */}
                        <p className="description">Billionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>Murder Allegations</li>
                            <li>The Maison de L’Amitie - Donald Trump deal</li>
                            <li>
                              The My Anna yacht and parties with young girls
                            </li>
                            <li>Holdings</li>
                            <li>Monaco-gate</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ul>
                            <li>Donald Trump</li>
                            <li>Roman Abramovich</li>
                            <li>Prince Albert II</li>
                            <li>Yuri Troutnev</li>
                            <li>Aristotle Onassis</li>
                            <li>Sandy Weill</li>
                            <li>Athina Roussel</li>
                            <li>Jada and Will Smith</li>
                            <li>Oleg Lomakin (ProKop)</li>
                            <li>Evgeny Panteleymonov</li>
                            <li>Andreas Neocleous</li>
                            <li>Vladimir Putin</li>
                          </ul>
                          <h4>Family Of:</h4>
                          <ul>
                            <li>Elena Rybolovlev</li>
                            <li>Anna Rybolovleva</li>
                            <li>Ekaterina Rybolovleva</li>
                            <li>Rybolovleva Zinaida Pavlovna</li>
                            <li>Evgeny Rybolovlev</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Maison de L’Amitie</li>
                            <li>Belle Époque penthouse</li>
                            <li>My Anna</li>
                            <li>Skorpios Island</li>
                            <li>15 central Park West Penthouse</li>
                            <li>Hanalei Beachhouse</li>
                            <li>Karalt Global</li>
                            <li>AS Monaco</li>
                            <li>Netfchimik</li>
                            <li>Magnetics company</li>
                            <li>Kamennyy Belt</li>
                            <li>Inkombrok</li>
                            <li>Silvinit</li>
                            <li>Uralkali</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE December 21</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Dmitry Rybolovlev</h1>
                      <h2> Introduction </h2>
                      <p>
                        Dmitry Yevgenyevich Rybolovlev is a Russian billionaire
                        and associate of US President Donald Trump. As of April
                        2019, Rybolovlev is ranked 224th on Forbes's list of
                        billionaires with a net worth of $6.8 billion. [1]
                      </p>
                      <br></br>
                      <p>
                        Dmitry Rybolovlev made most of his fortune from the sale
                        of two potash fertilizer companies for a combined
                        US$8-billion in 2010 and 2011. He held both companies
                        OAO Uralkali and OAO Silvinit through Cyprus-based
                        Madura Holding Ltd. [2] Rybolovlev owned the potash
                        producer Uralkali, Russia's largest producer of
                        potassium fertilizers. He sold part of his controlling
                        stake in Uralkali in 2011, pocketing $6.5 billion
                        overnight and affording him the time and money to splash
                        out on high-profile properties around the world. [3]
                      </p>
                      <br></br>
                      <p>
                        Rybolovlev went into business with his scientist father
                        Evgeny Rybolovlev in 1990 after studying medicine at
                        Russia's Perm Medical Institute. [4] “He started a
                        business focused on offering medical treatments using
                        magnetic fields” called Magnetics company. [5] In March
                        1992, Rybolovlev founded the Inkombrok investment
                        brokerage company and the Kamennyy Belt check investment
                        fund. That same year he became president of the
                        financial house investment company. [6] In 1994 he was
                        Acting Chairman of the Board of Bank “Credit FD”, and
                        later was appointed Chairman of the Board of Directors.
                        In 1994 he joined the board of directors of Russia's
                        largest potash producer Uralkali and in 1996 he became
                        the Chairman of the board there. [7]
                      </p>
                      <br></br>
                      <h2>Murder Allegations</h2>
                      <p>
                        Rybolovlev was accused of ordering the 1995 murder of
                        Evgeny Panteleymonov, the general director of
                        Netfchimik, which produced industrial alcohol.
                        Rybolovlev was chairman of Netfchimik and owned 40
                        percent of the company. [8] Panteleymonov had met with
                        Rybolovlev and told him criminals within the company had
                        to go. Rybolovlev had offered him bodyguards for his
                        protection which he refused. Panteleymonov was gunned
                        down by a mob-linked businessman named Oleg Lomakin (aka
                        Prokop). Lomakin was arrested for Panteleymonov’s
                        shooting, and, “in exchange for leniency”, he accused
                        Rybolovlev of ordering the murder. No other evidence
                        linked Rybolovlev to the shooting, and Prokop admitted
                        that he had perjured himself. After nearly a year of
                        incarceration, Rybolovlev was allowed to post bail of
                        one billion rubles (about $200,000). In 1997, he was
                        acquitted of murder charges. [9]
                      </p>
                      <br></br>
                      <h2>The Maison de L’Amitie - Donald Trump deal</h2>
                      <p>
                        In 2017 Rybolovlev came under media scrutiny after the
                        2005 tax returns of now-U.S. President Donald Trump
                        revealed details of an abnormal real estate deal in Palm
                        Beach, Florida. [10] Rybolovlev bought the Donald Trump
                        Palm Beach mansion, Maison de L’Amitie for $95m in 2008
                        which was the centre of a divorce fight with wife Elena
                        Rybolovlev. [11] According to author Michael Wolffe,
                        author of Siege: Trump Under Fire "Trump earned a fee
                        for hiding the real owner – a shadow owner possibly
                        being funneled cash by Rybolovlev for other reasons
                        beyond the value of the house. Rybolovlev might have
                        paid himself for the house, thereby (money laundering)
                        $55m." [12]
                      </p>
                      <br></br>
                      <p>
                        Dmitry Rybolovlev and Donald Trump claim never to have
                        met. But on the eve of the presidential election the
                        Rybolovlev private plane - M-Kate, an Airbus-319 - flew
                        several times to the United States to cities where
                        Donald Trump was holding meetings. Some of these flights
                        have been documented. It has been established that on 30
                        October 2016 Rybolovlev's jet and Trump's jet landed
                        almost at the same time in Las Vegas. [13] The same thing
                        happened on 3 November in Charlotte, North Carolina,
                        where Rybolovlev's jet landed for 22 hours, as reported
                        by the local press. Moreover, Rybolovlev's jet made
                        frequent trips to Moscow. [14] In March 2017, another
                        yacht trip in the British Virgin Islands, showed
                        Ryvolovlev's boat next to that of Rob Mercer, one of the
                        major financial supporters of Trump's campaign.
                      </p>
                      <br></br>
                      <p>
                        In 2017, Dmitry Rybolovlev’s private Jet M-KATE and
                        Yacht "Anna" were spotted in Dubrovnik, Croatia at the
                        same time as Ivanka and Jared Kushner vacationed there,
                        raising questions whether they secretly met for an off
                        the record meeting.[15] According to the FBI, Steve
                        Bannon knew Kushner was on vacation “with Wendy Deng
                        Murdoch, a Russian billionaire, and the Russian's
                        girlfriend [Daria Strokous]”. At the time Russian model
                        Daria Strokous was dating Dmitry Rybolovlev. Steve
                        Bannon “said his friends in the intelligence community
                        said the girlfriend was "questionable." [16] Previously
                        Wendy Deng Murdoch was also spotted vacationing on Roman
                        Abramovich’s yacht, a close friend of Rybolovlev, where
                        it was rumored she met Vladamir Putin. Wendy Deng
                        Murdoch is the ex wife of Rupurt Murdoch, a media mogul
                        listed in Epstein’s little black book and was flagged by
                        US intelligence as a potential Chinese spy. British
                        intelligence officials reportedly discussed with the FBI
                        whether Ms Murdoch’s friendship with Tony Blair, the
                        former PM, was a cause of concern. [17]
                      </p>
                      <br></br>
                      <p>
                        Dmitry Rybolovlev remains close to the Kremlin, as
                        evidenced by his friendship with Yuri Troutnev, one of
                        the right arms of Vladimir Putin. [18] Donald Trump Jr.,
                        invited in September 2008 to a real estate conference in
                        New York, had explained that "the Russians" now
                        constituted "a rather disproportionate part" of the
                        assets of the Trump family empire. [19]
                      </p>

                      <br></br>
                      <h2>The My Anna yacht and parties with young girls</h2>
                      <p>
                        In 2008, Dmitry during divorce proceedings it was
                        revealed he took a vacation on his yacht off Croatia
                        with “young girls whose passports said they were born in
                        1988 and 1989 but they looked much younger in
                        photographs that were taken on this occasion,” according
                        to court papers. They partied on his yacht “My Anna,”
                        named after his daughter. [20] According to court
                        proceedings Dmitry Rybolovlev admitted to sleeping with
                        his butler, his assistant, and students which he happily
                        shared with other oligarchs. He said “he appreciated
                        only teenage girls, younger than his own daughter”.
                        Notable guests on the My Anna yacht includes
                        British-based Israeli-Russian billionaire Roman
                        Abramovich. [21] According to the author of The Bouvier
                        Affair, her biggest shock was learning about the
                        underground world of “sex parties”. The “commodification
                        of women’s bodies on such a large scale was very
                        eye-opening.” [22] The Rybolovlev divorce was finalized
                        in 2014, with a record $4.8 billion awarded to Elena.
                        That amount subsequently was slashed to $604 million by
                        a judge in 2015, then later settled privately. [23]
                      </p>
                      <br></br>
                      <h2>Holdings</h2>
                      <p>
                        Elena Rybolovlev accused Dmitry Rybolovlev of using a
                        “multitude of third- parties” to create a network of
                        offshore holding companies and trusts to place assets —
                        including about US$500-million in art, US$36-million in
                        jewelry and an US$80-million yacht — beyond her reach.
                        [24] In her divorce filing, Elena Rybolovlev outlined
                        some of holdings, including an artwork and furniture
                        collection valued between $480 million and $800 million;
                        The Rybolovlev family had offshore holdings in Cyprus,
                        the British Virgin Islands, Panama and the Jersey
                        Islands.
                      </p>
                      <br></br>
                      <ul>
                        <li> $138 million property in Gstaad </li>
                        <li>$24 million property in Paris</li>
                        <li>$50 million of real-estate holdings in Moscow</li>
                        <li>$95 million Palm Beach estate </li>
                        <li>$95 million jet</li>
                        <li>Tax-shelter property in Cyprus</li>
                        <li>
                          Bank accounts in Geneva, Singapore and London.{" "}
                        </li>{" "}
                        [25]
                      </ul>
                      <br></br>
                      <p>
                        In 2011 Rybolovlev paid more than $300 million for the
                        La Belle Epoque penthouse in Monaco, where he lives. And
                        that same year, the billionaire picked up a seven-acre
                        Hawaiian villa in Kauai from movie star Will Smith for
                        $20 million. [26] Will Smith and Jada Pinkett-Smith made
                        a $6.5 million profit selling their property on Secret
                        Beach in the Hanalei district, Hawaii off-market to a
                        trust connected to Dmitry Rybolovlev's daughter
                        Ekaterina. [27]
                      </p>
                      <br></br>
                      <p>
                        Cyprus was the center of Rybolovlev’s business
                        activities. One of his closest partners in the
                        Mediterranean island nation was the former Cypriot
                        lawmaker Andreas Neocleous, whose law firm had
                        specialized in the establishment of offshore companies.
                        [28] Dmitry Rybolovlev is the largest shareholder in the
                        Bank of Cyprus, with a 9.9% stake in the company. [29]
                        Rybolovlev acquired Cypriot citizenship in 2012,
                        spokesman: “natural [for him] to get citizenship upon
                        becoming an investor in Bank of Cyprus” Prior to 2013,
                        Cypriot citizenship was granted on a discretionary basis
                        by ministers, in a less formal version of the current
                        “golden visas” scheme. [30] In 2014, Rybolovlev ex-wife
                        Elena Rybolovlev was detained for 24 hours by Cypriot
                        police after she flew into Cyprus from Switzerland,
                        detained under a lawsuit filed by Domus Trust lawyers in
                        connection with “the theft of the trust’s property.”
                        [31]
                      </p>
                      <br></br>
                      <p>
                        Dmitry Rybolovlev bought Aristotle Onassis’s legendary
                        island of Skorpios for a reported $100 million from the
                        Greek tycoon’s daughter Athina Roussel along with the
                        smaller island of Tsokari. [32] Dmitry Rybolovlev plans
                        to turn Skorpios island into the first luxury
                        accommodation complex in Greece to entertain the world’s
                        richest elite. [33] Dmitry Rybolovlev spent $88m for a
                        Manhattan apartment for his daughter Ekaterina bought
                        from former Citi chairman Sandy Weill. The penthouse is
                        located at 15 Central Park West. The money from the sale
                        was “donated to charity”. [34] According to a suit filed
                        during divorce proceedings it is alleged Rybolovlev’s
                        daughter's $88 million penthouse purchase was done with
                        “the specific intent of hiding and diverting his
                        personal interest in the property." [35]
                      </p>
                      <br></br>
                      <h2>FC Monaco</h2>
                      <p>
                        In 2011 Rybolovlev became president and majority
                        shareholder (66%) of Monaco's football club AS Monaco.
                        (Prince Albert II of Monaco owns the rest.) [36] In
                        2016, the KPMG audit company ranked the richest football
                        clubs in the world, which included three football clubs
                        owned by Russian businessmen. Among them - FC Monaco
                        Dmitry Rybolovlev (26th place; $ 206 million). [37]
                        Rybolovlev used AS Monaco, to “build up a tight network
                        with close contacts to ministers, members of parliament
                        and police officials who helped him with his business
                        interests, provided him with confidential information
                        and formulated draft laws reflecting his needs.”
                        Rybolovlev instructed Vadim Vasilyev, deputy at AS
                        Monaco to create a list of important people the club
                        should send a “gift basket” to during the holiday
                        season. Vasilyev created a list of 52 names that were
                        divided into the "categories A, B and C" and including
                        Prince Albert II. [38]
                      </p>
                      <br></br>
                      <h2>Monaco-gate</h2>
                      <p>
                        In 2017 in a scandal called Monaco-gate, Philippe
                        Narmino, the minister of justice for Monaco, resigned
                        after texts revealed he worked on behalf of Rybolovlev
                        to influence a billion-dollar art fraud case. “a vast
                        influence-peddling scandal at the heart of Monaco
                        institutions” [39] In 2018, Rybolovlev was charged and
                        released. Authorities announced "He was neither released
                        on bail nor any travel restriction order has been issued
                        in his respect [..] he is not restricted from leaving
                        Monaco and not limited to his movements." [40] Russia’s
                        embassy in France took a special interest in Dmitry
                        Rybolovlev’s detention in Monaco and asked for a
                        clarification. Russia's Ambassador to France Aleksandr
                        Orlov also serves as envoy to Monaco. [41]
                      </p>
                      <br></br>
                      <p>
                        Rybolovlev sued Sotheby’s, claiming Bouvier and
                        Sotheby’s Vice Chairman for worldwide private sales,
                        Samuel Valette induced him “to pay inflated, fraudulent
                        prices” and then used Sotheby’s as a “veneer of
                        legitimacy and expertise” by providing “inflated
                        appraisals on demand.” [42] In 2020, Monaco court
                        dismissed the criminal case against Yves Bouvier but
                        Rybolovlev is still being investigated over corruption
                        charges filed by Bouvier Monaco, Switzerland and France.
                        [43] "If all this were true, I think [Rybolovlev] will
                        withdraw on his own", declared Prince Albert II of
                        Monaco in 2018. [44] One of the paintings Rybolovlev
                        bought through Bouvier for 127.5 million, Leonardo da
                        Vinci's Salvator Mundi became the most expensive in the
                        world after it was sold to Saudi Crown Prince Mohammed
                        bin Salman for $450.3 million for the Louvre Abu Dhabi.
                        [45] In 2018, it was reported that few had any idea
                        where it was and some worried it was “somehow lost''.
                        [46] There has been speculation that the origin of
                        Rybolovlev's attack against Bouvier, is a project to
                        create a free port like those used by Bouvier in Geneva,
                        Singapore and Luxembourg. [47] Bouvier has confirmed
                        that his free port projects have been negatively
                        impacted by the conflict with Rybolovlev. [48]
                      </p>
                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          https://www.forbes.com/profile/dmitry-rybolovlev/#26d433e3bec4{" "}
                        </li>
                        <li>
                          {" "}
                          https://financialpost.com/personal-finance/taxes/billionaires-flee-tax-havens-as-transparency-becomes-the-new-norm{" "}
                        </li>
                        <li>
                          {" "}
                          https://www.mining.com/two-billionaires-cashed-in-big-time-ahead-of-uralkali-bombshell-55537{" "}
                        </li>
                        <li>
                          {" "}
                          https://www.forbes.com/profile/dmitry-rybolovlev/#17d57fac3bec{" "}
                        </li>
                        <li>
                          {" "}
                          https://www.richestrussian.com/profiles/dmitry-rybolovlev/{" "}
                        </li>
                        <li>
                          https://footyclub.ru/en/stil-i-uhod/rossiiskii-milliarder-dmitrii-rybolovlev-biografiya-iz/{" "}
                        </li>
                        <li>
                          http://viperson.ru/people/rybolovlev-dmitriy-evgenievich{" "}
                        </li>
                        <li>
                          {" "}
                          https://www.forbes.ru/forbes/issue/2008-05/11470-v-chem-sol{" "}
                        </li>
                        <li>
                          https://trump-russia.com/2017/05/01/before-he-bought-trumps-mansion-this-russian-billionaire-was-jailed-for-murder/{" "}
                        </li>
                        <li>
                          https://www.rferl.org/a/russia-asks-for-explanation-after-billionaire-rybolovlev-detained-in-monaco-/29587064.html{" "}
                        </li>
                        <li>
                          https://www.mining.com/two-billionaires-cashed-in-big-time-ahead-of-uralkali-bombshell-55537/
                        </li>
                        <li>
                          https://www.sfchronicle.com/opinion/article/Did-Putin-buy-Donald-Trump-13463782.php{" "}
                        </li>
                        <li>
                          https://nypost.com/2012/01/01/from-russia-with-major-daddy-issues/{" "}
                        </li>
                        <li>
                          https://www.theguardian.com/world/2017/oct/19/russian-scrutinised-for-ties-to-trump-investigated-in-monaco
                        </li>
                        <li>
                        https://www.charlotteobserver.com/news/politics-government/article136940273.html
                        </li>
                        <li>
                        https://www.palmbeachpost.com/news/national/yachts-trump-financial-backer-russian-oligarch-seen-close-together/Q18MrPXjuU4DKFx7Pt9y4J/
                        </li>
                        <li>
                        https://www.dailykos.com/stories/2017/3/17/1644558/-Confirmed-Rybolovlev-s-Jet-Yacht-were-in-Dubrovnik-the-same-time-as-Ivanka-and-Jarred-Kushner
                        </li>
                        <li>
                        https://www.wonkette.com/the-mueller-memos-it-was-ivanka-in-croatia-with-the-russia-oligarch-the-whole-time
                        </li>
                        <li>
                        https://www.theguardian.com/commentisfree/2016/apr/01/wendi-deng-putin-rupert-murdoch-caribbean-russian-yacht
                        </li>
                        <li>
                        https://www.telegraph.co.uk/news/2018/01/16/fbi-warned-jared-kushner-wendi-deng-could-use-friendship-push/
                        </li>
                        <li>
                          https://books.google.com/books?id=LXyQDwAAQBAJ&amp;pg=PA106&amp;lpg=PA106&amp;dq=rybolovlev,+underage+girls&amp;source=bl&amp;ots=E08oKb1edm&amp;sig=ACfU3U0RCcAkIOGcFpR8uOAjl46FZKZ3PA&amp;hl=en&amp;sa=X&amp;ved=2ahUKEwiks4L3wunqAhUmGDQIHTozDOcQ6AEwHXoECCcQAQ#v=onepage&amp;q=rybolovlev%2C%20underage%20girls&amp;f=false{" "}
                        </li>
                        <li>
                          https://www.barnebys.com/blog/you-started-writingthe-bouvier-affair-a-true-storyas{" "}
                        </li>
                        <li>
                          {" "}
                          http://www.mypalmbeachpost.com/business/palm-beach-mansion-for-grabs-after-record-divorce-award/xVBdfOfVi9kaJhFP8EEqeM/{" "}
                        </li>
                        <li>
                          https://financialpost.com/personal-finance/taxes/billionaires-flee-tax-havens-as-transparency-becomes-the-new-norm{" "}
                        </li>
                        <li>
                          {" "}
                          https://nypost.com/2012/01/01/from-russia-with-major-daddy-issues/
                          1
                        </li>
                        <li>
                          https://www.nytimes.com/2018/09/07/arts/design/dmitry-rybolovlev-monaco-investigation.html{" "}
                        </li>
                        <li>
                          https://www.businessinsider.com/will-smiths-former-hawaiian-estate-just-hit-the-market-for-295-million-2015-7{" "}
                        </li>
                        <li>
                          https://www.spiegel.de/international/world/as-monaco-dmitry-rybolovlev-and-his-influence-in-monaco-a-1238822.html{" "}
                        </li>
                        <li>
                          https://www.bloomberg.com/news/2012-07-16/russia-may-grant-loan-for-bank-of-cyprus-stakes-alithia-reports.html{" "}
                        </li>
                        <li>
                          https://www.theguardian.com/world/2017/sep/17/cyprus-selling-eu-citizenship-to-super-rich-of-russia-and-ukraine{" "}
                        </li>
                        <li> https://tass.com/russia/720778 </li>
                        <li>
                          https://greece.greekreporter.com/2013/04/12/rybolovlevs-backstage-deal-gets-him-skorpios/{" "}
                        </li>
                        <li>
                          {" "}
                          https://greece.greekreporter.com/2019/08/03/russian-billionaire-dmitry-rybolovlev-vows-to-turn-skorpios-into-a-greek-island-paradise/{" "}
                        </li>
                        <li>
                          https://www.dailymail.co.uk/news/article-2076017/Ekaterina-Rybolovleva-22-buys-88m-New-York-apartment.html{" "}
                        </li>
                        <li>
                          http://www.forbes.com/sites/carolinehoward/2012/03/15/russian-billionaires-88m-plot-to-hide-his-money-says-wife/{" "}
                        </li>
                        <li>
                          https://www.france24.com/en/20111223-football-soccer-as-monaco-france-russian-tycoon-Rybolovlev{" "}
                        </li>
                        <li>
                          https://footyclub.ru/en/stil-i-uhod/rossiiskii-milliarder-dmitrii-rybolovlev-biografiya-iz/{" "}
                        </li>
                        <li>
                          {" "}
                          https://www.spiegel.de/international/world/as-monaco-dmitry-rybolovlev-and-his-influence-in-monaco-a-1238822.html{" "}
                        </li>
                        <li>
                          https://news.artnet.com/art-world/monaco-justice-minister-resigns-1084301
                          33.{" "}
                        </li>
                        <li>
                          https://news.artnet.com/art-world/russian-billionaire-dmitry-rybolovlev-officially-charged-monaco-corruption-probe-1394737{" "}
                        </li>
                        <li>
                          https://www.rferl.org/a/russia-asks-for-explanation-after-billionaire-rybolovlev-detained-in-monaco-/29587064.html
                          3
                        </li>
                        <li>
                          https://www.forbes.com/sites/kenrapoza/2019/08/08/billionaire-dmitry-rybolovlevs-lawsuit-with-art-dealer-yves-bouvier-puts-sothebys-in-crosshairs/{" "}
                        </li>
                        <li>
                          https://news.artnet.com/art-world/yves-bouvier-lawsuit-dismissed-monaco-rybolovlev-1893375?utm_source=dlvr.it&amp;utm_medium=twitter{" "}
                        </li>
                        <li>
                          https://www.lemonde.fr/sport/article/2018/11/16/affaire-rybolovlev-si-jamais-tout-cela-etait-avere-je-pense-qu-il-se-retirera-de-lui-meme-declare-le-prince-albert-ii-de-monaco_5384712_3242.html{" "}
                        </li>
                        <li>
                          https://www.nytimes.com/2017/11/15/arts/design/leonardo-da-vinci-salvator-mundi-christies-auction.html{" "}
                        </li>
                        <li>
                          https://www.celebritynetworth.com/articles/billionaire-news/saudi-crown-prince-mohammed-bin-salman-has-reportedly-misplaced-a-450-million-painting-he-bought-last-year/?utm_campaign=socialflow&amp;utm_source=twitter&amp;utm_medium=social{" "}
                        </li>
                        <li>
                          https://www.mediapart.fr/journal/international/111015/un-intrigant-projet-de-port-franc-vladivostok{" "}
                        </li>
                        <li>
                          https://www.bloomberg.com/news/articles/2017-02-07/geneva-free-ports-king-mulls-art-fight-s-nearly-1-billion-toll
                        </li>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Dmitry;



