import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/PrinceAndrew.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";
// import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class PrinceAndrew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card
                    className="card-user"
                    style={{
                      position: "sticky",
                    }}
                  >
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Prince Andrew</h4>
                        {/* </a> */}
                        <p className="description">Royal</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>Prince Andrew and Jeffrey Epstein</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul></ul>
                          <h4>Family of:</h4>
                          <ul></ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul></ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE MARCH 8</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Prince Andrew</h1>
                      <h2>Introduction</h2>
                      <p>
                        Prince Andrew, Duke of York, full name Andrew Albert
                        Christian Edward, was born February 19, 1960. He is the
                        third child and second son of Queen Elizabeth II and
                        Prince Philip, Duke of Edinburgh. He is eighth in the
                        line of succession to the British throne.
                      </p>
                      <br></br>
                      <p>
                        Prince Andrew served in the Royal Navy as a helicopter
                        pilot and as the captain of a warship. During the
                        Falklands War, the English occupation of the Malvinas in
                        Argentina, he flew on multiple missions which he used to
                        gain public favor, with the media dubbing him a ‘hero’.
                        In 1986, Prince Andrew married Sarah Ferguson and was
                        created Duke of York and they had two daughters,
                        Princesses Beatrice and Eugenie. Their tumultuous
                        marriage was often subject to media attention and
                        culminated in a separation in 1992, and a divorce in
                        1996.
                      </p>
                      <br></br>
                      <h2>Prince Andrew and Epstein</h2>
                      <br></br>
                      <p>
                        {" "}
                        In 1999, Prince Andrew was introduced to child
                        trafficker Jeffrey Epstein by British socialite
                        Ghislaine Maxwell, who was Epstein's girlfriend and
                        accomplice at the time. The extent of Epstein's
                        relationship with Andrew or other members of the royal
                        family is mostly hidden but images of the relationship
                        go back as far as the year 2000.{" "}
                      </p>
                      <br></br>

                      <h3>Prince Andrew and Trump</h3>

                      <p>
                        In February 2000, Donald Trump, then dating Melania
                        Knauss (now Melania Trump), hosted a pro-am tennis
                        tournament at Mar-A-Lago, which Prince Andrew, Jeffrey
                        Epstein, and Ghislaine Maxwell attended. According to
                        reports, “Even when Trump announced England’s Prince
                        Andrew, many in the crowd doubted his presence. For one,
                        he stayed in the background, introducing himself as
                        Andrew York, and for two, he’s recently shed 20 pounds,
                        making him a possible double for actor Rupert Everett.”
                        Donald Trump was pictured with Melania and Prince Andrew
                        while Jeffrey Epstein and Ghislaine Maxwell were
                        photographed with Michael Bolton at the same event.
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        In October 2000, Donald Trump, Jeffrey Epstein, Melania
                        Knauss (now Melania Trump), Ghislaine Maxwell, and
                        Prince Andrew 'The Duke Of York' attended a 'Hookers and
                        Pimps' themed Halloween party hosted by former Elite
                        model Heidi Klum at Hudson Bar, New York. Witnesses
                        confirm “Prince Andrew was with Ghislaine and they were
                        greeted by Heidi Klum,"..." While Andrew was chatting
                        with Heidi, Ghislaine went over to speak with Donald
                        Trump."..." Donald and Ghislaine were chatting for about
                        15 or 20 minutes."..."It seemed clear they already knew
                        each other. " Donald Trump made the comments to People
                        magazine after the party where he claimed Prince Andrew
                        'The Duke of York' was "a lot of fun to be with" and
                        that "He's not pretentious,".
                      </p>
                      <br></br>
                      <p>
                        In 2015, Donald Trump was questioned by U.S. media about
                        his links to the Jeffrey Epstein rape network and the
                        crimes that took place on Little St James, Epstein’s
                        private Caribbean island. According to the Telegraph,
                        Trump replied: ‘That island was really a cesspool,
                        there’s no question about it, just ask Prince Andrew –
                        he’ll tell you about it,’ adding, ‘The island was an
                        absolute cesspool.’ In 2018, then U.S. President Donald
                        Trump and Prince Andrew 'The Duke of York attended
                        multiple events together, including a breakfast meeting
                        and a service at Westminster Abbey where they were
                        photographed greeting each other effusively. Others at
                        the event included Melania Trump, Jared Kushner, Ivanka
                        Trump, and various members of the UK Royal family. In
                        2019, Donald Trump claimed he did not know Prince
                        Andrew, “I don’t know Prince Andrew, but it’s a tough
                        story, it’s a very tough story.”
                      </p>

                      <h3>Prince Andrew Blackmailed By Epstein</h3>

                      <br></br>
                      <p>
                        In 2001, Prince Andrew holidayed with Jeffrey Epstein in
                        Thailand and was photographed surrounded by topless
                        females (age unknown) on a yacht. Juan Alessi who spent
                        11 years working for Jeffrey Epstein claimed Prince
                        Andrew often had massages when he was a guest at
                        Epstein's Palm Beach mansion. Alessi said Prince Andrew
                        enjoyed daily massages from young women and often
                        emerged “smiling” after rub-downs. The massages were
                        carried out in a private part of the mansion only
                        Epstein and “selected” guests were allowed to access.
                        Alessi also witnessed parties at the mansion where
                        Prince Andrew was present while very young girls
                        frolicked nude. Court papers filed against Jeffrey
                        Epstein revealed that he recorded sordid orgies he threw
                        for VIPs at his luxury homes using cameras hidden in the
                        walls of guest bedrooms. According to Alessi, 'Andrew
                        was with security. He had his two bodyguards from
                        Scotland Yard. I can remember their names.'The
                        bodyguards were off duty and they slept in one of the
                        guest bedrooms.' Royal protection officers are very well
                        paid, some get up to £80,000 a year which is double
                        their basic pay – because of overtime and special
                        allowances. Challenging a royal about their conduct, or
                        the people they are mixing with, can result in removal
                        from a very lucrative job.
                      </p>
                      <br></br>
                      <p>
                        UK Security sources suggest that Prince Andrew’s travels
                        with Jeffrey Epstein cost taxpayers well over £500,000
                        of public money. Prince Andrew spent several weeks at
                        Epstein's Florida mansion and also visited his property
                        in New York and shared holidays with him in Thailand,
                        New Mexico, and the Caribbean. In 2015, Scotland Yard
                        declined to comment when asked whether royalty
                        protection officers had raised concerns about Prince
                        Andrew's conduct with Epstein or about his visits to
                        Jeffrey Epstein's mansion. Scotland Yard said: 'We do
                        not discuss matters of security and protection.' A
                        prominent child abuse charity urged the Scotland Yard to
                        investigate Prince Andrew and his Royal protection
                        bodyguards over their involvement with the Jeffrey
                        Epstein network. The police response was: “Our current
                        activity does not constitute an investigation.”{" "}
                      </p>
                      <br></br>

                      <h3>Prince Andrew asks the U.S. to go easy on Epstein</h3>

                      <br></br>
                      <p>
                        In 2008, Jeffrey Epstein was arrested for child rape
                        trafficking for the first time, Epstein was accused of
                        paying children hundreds of dollars in cash for massages
                        and then raping them at various locations, including
                        homes in Palm Beach, Florida, and New York from 2002
                        through 2005. Instead of possible life sentences over
                        human trafficking charges, which included claims he
                        shared child rape slaves with his wealthy and powerful
                        friends, Epstein was allowed to plead guilty to a far
                        lesser state charge that sent him to “home arrest” for
                        just 13 months. While under “home arrest” he was allowed
                        to travel extensively and “work”. In 2015, Four women
                        who were Jeffrey Epstein’s rape slaves sued the U.S.
                        federal prosecutors for not conferring with them before
                        reaching “a secret non-prosecution agreement” with
                        Epstein. The lawsuit stated that Jeffrey Epstein “used
                        his significant social and political connections to
                        lobby the Justice Department to obtain a more favorable
                        plea deal.” One of those “political connections'' who
                        lobbied the U.S. government was Prince Andrew, the Duke
                        of York, according to a document filed in federal court
                        in Miami in 2015. The complaint “seeks documents
                        regarding Epstein’s lobbying efforts to persuade the
                        Government to give him a more favorable plea arrangement
                        and/or non-prosecution agreement, including efforts on
                        his behalf by Prince Andrew and former Harvard Law
                        Professor Alan Dershowitz.”
                      </p>

                      <h3>Prince Andrew evades justice</h3>

                      <br></br>
                      <p>
                        Court documents filed in the U.S. state of Florida
                        showed the extent to which the FBI and victims’ lawyers
                        were obstructed in their attempts to investigate the
                        extent of Epstein’s crimes and the claims against Prince
                        Andrew. Nadia Marcinkova, one of Epstein’s top rape
                        slaves and alleged recruiter, invoked her right not to
                        incriminate herself, protected by the US constitution’s
                        Fifth Amendment, when she was asked about Prince Andrew.
                        When asked by a lawyer: “Have you ever been made to
                        perform sexually on Prince Andrew?” she replied:
                        “Fifth.” As well as having a lawyer provided by Jeffrey
                        Epstein, she was “harassed and pressured” by not to
                        cooperate. Sarah Kellen, Epstein’s executive assistant
                        and alleged recruiter, whose legal fees were also said
                        to have been paid by Jeffrey Epstein, was asked: “Would
                        you agree with me that Prince Andrew and Jeffrey Epstein
                        used to share under-age girls for sexual relations?” She
                        replied: “On the instruction of my lawyer, I must invoke
                        my Fifth Amendment privilege.” Sarah Kellen was also
                        “threatened” by Jeffrey Epstein to prevent her from
                        co-operating. Adriana Ross, a former model who worked as
                        Epstein’s diary organizer, also refused to answer the
                        question: “Has Prince Andrew ever been involved with
                        under-age minor females to your knowledge?” Jeffrey
                        Epstein himself, questioned under oath, refused to
                        answer the questions regarding Prince Andrew: “Did you
                        provide any under-aged girls for sex to Prince Andrew?”
                        and: “Did you fly with Prince Andrew on your plane, or
                        planes, with any under-aged girls, girls under the age
                        of 18?
                      </p>
                      <br></br>
                      <p>
                        A Jeffrey Epstein rape network victim stated that she
                        was rape trafficked to Prince Andrew on at least three
                        occasions between 2001 and 2002, in London, New York,
                        and the US Virgin Islands. She said she first met Prince
                        Andrew when the financier took her to Europe and North
                        Africa in 2001. She was forced to tell Jeffrey Epstein
                        all about her sexual encounters so he could use the
                        information to “blackmail” Prince Andrew. In court
                        documents from a civil suit that were released from a
                        seal in 2019, she named several others that she claims
                        Epstein and Maxwell trafficked her to, including hedge
                        fund manager Glenn Dubin, attorney Alan Dershowitz,
                        politician Bill Richardson, MIT scientist Marvin Minsky,
                        lawyer George J. Mitchell, and MC2 modeling agent
                        Jean-Luc Brunel. One of the victim's first encounters
                        with Prince Andrew is alleged to have taken place at an
                        orgy ­involving other underage girls on Epstein’s
                        private island. According to the victim, she "couldn't
                        comprehend how in the highest level of the government
                        powerful people were allowing this to happen. Not just
                        allowing but participating in it."
                      </p>
                      <br></br>
                      <p>
                        Prince Andrew and Jeffrey Epstein were both also
                        photographed walking together in Central Park in 2010,
                        several years after Epstein was convicted for child rape
                        trafficking. Shortly Sarah Ferguson, Prince Andrew’s
                        ex-wife who was also close to Epstein, accepted an
                        estimated £20,000 from Jeffrey Epstein to clear her
                        debts. In 2005, U.S. police discovered communications
                        between the Duchess of York Sarah Ferguson and child
                        trafficker Jeffrey Epstein. At the time Sarah Ferguson
                        and Prince Andrew were divorced and living separately.
                        In a statement released in 2015, Prince Andrew shared
                        that he had stayed at several of Epstein's properties,
                        but that he "saw him infrequently and probably no more
                        than only once or twice a year." Buckingham Palace also
                        stifled UK Media in an attempt to cover up the story. It
                        wrote to editors reminding them of their
                        responsibilities under the Independent Press Standards
                        Organisation code and the law. Palace lawyers were also
                        said to be closely reviewing coverage and “assessing the
                        legal position”.{" "}
                      </p>
                      <br></br>
                      <p>
                        The lawyers for five women who have accused Epstein of
                        abusing them have called upon Prince Andrew to give
                        evidence about what he knows, they argue he could have
                        important information about the Epstein trafficking
                        network. Prince Andrew has repeatedly denied the
                        allegations, which Buckingham Palace said were “false
                        and without foundation” and described as “lurid and
                        deeply personal”. Even though the UK Royal family claims
                        the allegations are baseless, Prince Andrew is being
                        socially shunned amid ongoing calls for him to speak to
                        the FBI. French investigators also want to quiz Prince
                        Andrew as part of their probe into the Jeffrey Epstein
                        child rape network after the arrest of Jean-Luc Brunel.
                        And Met Police has offered to assist US authorities
                        investigating Prince Andrew's Epstein links in the
                        United States. In 2020, the U.S. attorney Audrey Strauss
                        for the SDNY said: “I'm not going to comment on anyone's
                        status in this investigation but I will say that we
                        would welcome Prince Andrew coming in to talk with us,
                        we would like to have the benefit of his statement.” The
                        FBI claims that Prince Andrew has refused to approach
                        them or co-operate.{" "}
                      </p>
                      <br></br>
                      <p>
                        In 2011, Prince Andrew stepped down as an official
                        government trade envoy after his relationship with
                        Jeffrey Epstein was exposed.
                      </p>
                      <br></br>
                      <h2>Prince Andrew and Peter Nygard</h2>
                      <br></br>
                      <p>
                        In 2000, Prince Andrew and his ex-wife the Duchess of
                        York Sarah Ferguson reportedly visited child trafficker
                        Peter Nygard at Nygard Cay, with their two daughters,
                        Princesses Eugenie and Beatrice. In 2003 Queen Elizabeth
                        II awarded her Golden Jubilee medal, to Peter Nygard as
                        someone who, during her reign “helped create the Canada
                        of today, and to recognize Mr. Nygard for outstanding
                        and exemplary achievement to Canada as a whole”. In
                        2013, Queen Elizabeth II awarded Peter Nygard for a
                        second time with her Diamond Jubilee Medal “in
                        recognition for his philanthropic work raising money and
                        awareness for cancer research and his outstanding
                        contributions to Canada and the Commonwealth.” According
                        to the U.S. attorney’s office, Peter Nygard specifically
                        targeted women and girls who came from disadvantaged
                        backgrounds and who had a history of abuse. “He
                        controlled his victims through threats, false promises
                        of modelling opportunities and other career
                        advancements, financial support, and by other coercive
                        means, including constant surveillance, restrictions of
                        movement, and physical isolation,” According to a former
                        Peter Nygard employee Victoria Sarne, originally from
                        London, during her time working as his former PA in the
                        1990s she saw "countless" girls arriving at his offices
                        in Toronto, LA, and the Bahamas before they were taken
                        to his private quarters. “He’d attach his homes to his
                        offices, often with a glass elevator. His private spaces
                        were all fitted with mirrors. There were no door
                        handles. They seemed ­impossible to get out of." “Peter
                        monitored everything with close circuit TV. Everyone
                        could be watched. It didn’t matter who you were... a
                        Hollywood star, a sporting icon or from the Royal
                        Family, Peter could see and record their every move."
                        “He was sleazy. Like Epstein, he courted anyone in power
                        or who had great fame. They may as well have been
                        brothers.”{" "}
                      </p>
                      <br></br>

                      <h2>Prince Andrew Pay For Access</h2>

                      <p>
                        Prince Andrew was married to Sarah Ferguson, who was one
                        of the biggest pay-for-access dealers in recent history.
                        In 2010, The Duchess of York Sarah Ferguson was caught
                        on tape running a pay-for-access racket in which she
                        charged thousands of dollars for interviews with Prince
                        Andrew. Sarah Ferguson was caught during a stint by an
                        undercover journalist posing as a businessman who filmed
                        the negotiation. Sarah Ferguson confirmed Prince Andrew
                        knew about the arrangement and was okay with it. After
                        the pay-for-access story went public the prince's office
                        also released a statement in which he "categorically
                        denied” any knowledge of the duchess' meeting.{" "}
                      </p>
                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>currently unavailable</ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default PrinceAndrew;
