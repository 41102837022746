
import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";



// core components

import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../components/FixedContact/FixedContact.js";
import ContactBox from "../../components/Contact/ContactBox.jsx";

// react components
import {Container, Card, CardBody} from "reactstrap";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }

  render() {
    return (
      <>
        <div className="wrapper">
        <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
              
            <Container className="content">
              <Card>
                  <CardBody>

                  <ContactBox />
                 
                  </CardBody>
              </Card>
            
            </Container>   
            </div>

        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
      </>
    );
  }
}

export default Contact;





// import React, { Component } from 'react';

// import Contact from "../../components/Contact/ContactBox.jsx";
// import {Jumbotron,  Container, Row} from 'reactstrap';


// export class Content extends Component {
//     render() {
//         return (
//             <>
//             <div className="content"
//             style={{
//                 height:'100vh !important',
//                 borderRadius: '0',
//                 paddingBottom:'1vh',
//                 paddingTop:'3vh'}}>
//             <Jumbotron style={{
//                 height:'100%',
//                 borderRadius: '0',
//                 background:'#263148',}}>
//             <Container style={{
//                     height:'100%',
//                 }}>
//                     <Row style={{ marginTop: '3rem', margin:'1rem'}}>
//                     <Contact/>
//                     </Row>
//                     </Container>
//             </Jumbotron>
//             </div>
//             </>
//         )
//     }
// }

// export default Content;
