import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/IvanDuque.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";
import Iframe from "react-iframe";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Duque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Ivan Duque</h4>
                        {/* </a> */}
                        <p className="description">Millionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Alvaro Uribe</li>
                            <li>Pablo Escobar</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul></ul>
                          <h4>Family of:</h4>
                          <ul>
                            <li></li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE May 19</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <Iframe
                        url="http://www.youtube.com/embed/VzL-xXl0rtQ"
                        width="100%"
                        height="450px"
                        id="myId"
                        className="myClassname"
                        frameBorder="0"
                      />
                      <br></br>
                      <br></br>
                      <h2>Introduction</h2>

                      <p>
                        Iván Duque Márquez, born on August 1st in 1976, is a
                        politician and lawyer who is the current president of
                        Colombia. He was elected as the candidate from the
                        Democratic Centre Party in the 2018 presidential
                        election. Iván Duque's professional career began in 1999
                        as a consultant in the Andean Development Corporation
                        (CAF) and later served as an advisor at the Colombian
                        Ministry of Finance and Public Credit during the
                        government of Andrés Pastrana (1998–2002).
                      </p>
                      <br></br>
                      <p>
                        Duque was born in Bogota to a wealthy political family
                        in the Colombian town of Gómez Plata, Antioquia. He is
                        the son of Juliana Márquez Tono a political scientist
                        and Iván Duque Escobar of Medellin Colombia. Ivan
                        Escobar was the former Governor of Antioquia, auditor to
                        the United Nations, Minister of Mines and Energy, and
                        head of the National Registry of Civil Status in the
                        Government of Andrés Pastrana.{" "}
                      </p>
                      <br></br>
                      <h2>Duque and the Medellin Cartel</h2>
                      <p>
                        In 1981, Governor Ivan Duque Escobar reportedly called
                        the country’s aviation chief, Alvaro Uribe, to warn him
                        about a license granted to Jaime Cardona a known member
                        of the Medellin Cartel. Duque called Uribe and told him
                        that “in case you didn’t know, this is a businessman who
                        is linked to the mafia.” Alvaro Uribe told Ivan Duque
                        Escobar that the drug trafficker Cardona was “a good
                        man.” In 1977, Cardona was caught with 530 kilos of
                        cocaine and was one of Pablo Escobar‘s first money
                        launderers. Despite Cardona’s known ties to Pablo
                        Escobar, Uribe granted him the license to fly a route
                        from Medellin to Turbo, a town on the Caribbean coast.
                        The Uribe family was also close to Pablo Escobar
                        according to declassified US cable links. Uribe was a
                        cousin of the Ochoa crime family that partnered with
                        Escobar and had financed his political campaigns. A
                        declassified U.S. DIA report listed Uribe as one of 100
                        suspected Colombian “narcopols,” calling him a “close
                        personal friend” of Escobar who was “dedicated to
                        collaboration with the Medellin cartel at high
                        government levels.” Uribe’s father was close friends
                        with Fabio Ochoa, the patriarch of the Ochoa family that
                        would help form the Medellin cartel in 1982. Uribe and
                        the Ochoa brothers grew up together according to Alvaro
                        Uribe's admission. In 1980, Jaime Uribe, Alvaro's
                        brother, had his first child with Medellin cartel
                        associate Dolly Cifuentes. The license was revoked after
                        Duque personally reported the incident to President
                        Julio Cesar Turbay, but Alvaro Uribe remained in his
                        post despite his links to the Medellin cartel. Alvaro
                        Uribe was first given the job as chief in civil aviation
                        after his predecessor, Fernando Uribe, was assassinated
                        by the Medellin cartel. According to newspaper reports,
                        the assassinated aviation chief revoked licenses of
                        prominent narcos and was about to file a report on the
                        Medellin Cartel's use of aircraft to traffic cocaine to
                        the US. Alvaro Uribe never released the report prepared
                        by Fernando Uribe about Escobar’s extensive fleet of
                        aircraft and cocaine transportation to the US. Instead,
                        he enjoyed his time as an aviation chief granting
                        licenses to the Medellin cartel. A few years later after
                        Uribe left his post Justice Minister Rodrigo Lara
                        ordered the aviation agency to revoke all the Medellin
                        Cartel’s licenses. Lara was killed by the cartel soon
                        after. Alvaro Uribe, former president of Colombia,
                        became Iván Duque Márquez's political patron, financier,
                        and mentor, less than two years after former governer
                        Ivan Duque Escobar died in 2016.
                      </p>
                      <h2> Duque and Uribe </h2>
                      <p>
                        Iván Duque Márquez is heavily backed former president
                        Alvaro Uribe. Experts claim Duque is a puppet for Uribe
                        to evade a constitutional ban on presidential 3rd
                        limits. s During his presidential campaign, he openly
                        stood against the Havana Accords that ended decades of
                        war in Colombia with the guerilla group FARC. A policy
                        pushed by Alvaro Uribe who wants to resume the war. Ivan
                        Duque entered office on a pro-business platform that
                        included promises to reform Colombia’s tax structure,
                        modernize its economy and attract foreign capital. Duque
                        failed to enact the policy agenda in the first year of
                        his presidency, and the covid-19 pandemic and subsequent
                        economic crisis have made his failure much more clear.
                        Ivan Duque ran on an anti-corruption kick, promising to
                        clean up the system. His presidency kicked off embroiled
                        in a massive voter fraud scandal that involved some of
                        Colombia's more influential families and organized
                        crime.
                      </p>
                      <br></br>
                      <h2>Duque's Narco Election And Murder Coverup</h2>
                      <p>
                        President Ivan Duque was involved in a massive voter
                        fraud conspiracy by organized crime figures led by
                        Alvaro Uribe that aimed at putting him in power. Ivan
                        Duque's electoral fraud involved coordination between
                        Alvaro Uribe, Jose Guillermo Hernandez "Ñeñe", Marcos
                        "Marquitos" Figueroa, the Gerlain family, the Char
                        family, and other influential members linked to
                        organized crime. A murder investigation recorded drug
                        trafficker and mafia boss Jose Guillermo Hernandez,
                        alias Ñeñe admitting to being key in a vote-buying
                        operation for President Ivan Duque, at the order of
                        former president Alvaro Uribe. The police wiretaps
                        revealed an extensive relationship between Ivan Duque
                        and Ñeñe, where Duque's personal assistant Maria Claudia
                        Daza acted as an intermediary between organized crime
                        and his campaign. When news about the Ñeñe wiretaps and
                        investigation were made public, Duque's then-personal
                        assistant Maria Claudia Daza fled the country. The
                        Wiretaps also revealed that Ñeñe sought favors from the
                        Duque appointed finance minister Alberto Carrasquilla
                        after he took office in 2018. Ñeñe was recorded talking
                        about using his ties to the finance minister to recover
                        assets seized from narcos. He maintained ties with the
                        Uribe’s Democratic Center (CD) party and the Duque
                        administration long after the president had taken
                        office. The homicide case which revealed evidence of
                        vote buying for Duque appeared during an investigation
                        into the murder of Oscar Rodriguez, a man who was
                        mistaken for his father, money launderer Carlos
                        Rodriguez. Carlos Rodriguez had just returned to
                        Colombia after paying a penalty for money laundering in
                        the United States. He wanted Ñeñe to pay an old debt of
                        COP1 billion ($290,000). Instead of paying his debt Jose
                        Guillermo Hernandez, alias Ñeñe decided to kill Carlos
                        Rodriquez with hitmen working for his partner, Marcos
                        "Marquitos" Figueroa. Unfortunately, the hitmen
                        accidentally killed Carlos' son in a case of mistaken
                        identity. Criminal lawyer Miguel Angel del Rio,
                        representing the dead man reported that the case was
                        been obstructed due to high-level criminal and political
                        interests around ‘Ñeñe’ Hernández. Ñeñe, according to
                        Colombian and US authorities, was a partner of drug
                        trafficking kingpin and murderer Marcos "Marquitos"
                        Figueroa linked to the criminal organization the
                        Urabeños. Marcos "Marquitos" Figueroa was a major drug
                        trafficker, the main source of Marquitos’ wealth and
                        power was the contraband gasoline trade, which is worth
                        as much as $3 billion and smuggled out of Venezuela.
                        Marcos "Marquitos" Figueroa and his partner former
                        governor of La Guajira department, Francisco “Kiko”
                        Gomez, garnered enormous political power. Figueroa and
                        Kiko are also implicated in a string of political
                        murders, for which Francisco “Kiko” Gomez was sentenced
                        in 2017 to 55 years in prison. Figueroa helped place
                        allies not only in the governor’s office but also in
                        mayor’s offices, congressional seats, and even the
                        Colombian Presidency. When the death of Ñeñe became
                        known, former president Uribe was the first in Colombia
                        to express grief for the dead kingpin. Ñeñe's assets
                        were valued at $580 million. Jose Guillermo Hernandez,
                        alias Ñeñe was one of Duque’s main guests at his
                        inauguration ceremony on August 7, 2018. Ñeñe and Duque
                        were very close friends. In recordings “Ñeñe”, admits
                        that stole money that was meant for buying votes in the
                        Caribbean region for German Vargas Lleras in the first
                        round and used this to buy them from Duque in the second
                        round. During the conversation Jose Guillermo Hernandez,
                        alias Ñeñe warned: “Be careful, we took advantage of the
                        money that in the last one was stolen from Vargas Lleras
                        for transport and stuff in the other one we are not
                        going to have. Can you imagine where those hundreds of
                        millions of pesos and some would have taken us, what
                        would have happened in Valle?” Jose Guillermo Hernandez,
                        alias Ñeñe revealed there were more than a billion pesos
                        involved and he was in charge of getting entrepreneurs
                        to commit to buying votes for Duque.
                      </p>
                      <h2>Ivan Duque electoral fraud coverup </h2>

                      <p>
                        A prosecutor named Ricardo Bejarano was working with
                        ‘Neñe’, warning him of confidential proceedings at the
                        Prosecutor General’s Office and alerted him when his
                        communications were being wiretapped by authorities.
                        Prosecutor General Francisco Barbosa ordered the
                        prosecution and arrest the police investigators who
                        discovered Duque and Uribe's narco ties instead of the
                        elections fraud scandal. Ivan Duque and Francisco
                        Barbosa have been close friends since they went to law
                        school together more than 25 years ago. Prosecutor
                        Barbosa was later accused of withholding evidence in the
                        Duque electoral fraud investigation. Prosecutor Barbosa
                        was heavily involved in Duque's campaign and his social
                        media displayed a clear bias and political support for
                        Duque, he deleted his social media accounts to save
                        face. A judge ordered the release of the police
                        investigators since there was no basis for their arrest
                        and Barbosa couldn't provide a reason for arresting
                        them. The journalists that covered the Duque election
                        fraud story have since been attacked and sent death
                        threats in a coordinated effort to silence them.
                        According to a Supreme Court draft, Duque’s 2018
                        campaign chief & patron, Luis Alfredo Ramos, was linked
                        to warlords and cartels between 1998 and 2011, which
                        “facilitated some of the worst crimes in the region.”
                        Luis Alfredo Ramos is reportedly facing a 19-year prison
                        sentence over his ties to organized crime linked to the
                        Uribe family and the Castaño family.
                      </p>
                      <br></br>
                      <h2>Duque and the Gerlain and Char crime families</h2>
                      <p>
                        The pro-Duque fraud was financed by the Gerlain and Char
                        families who bought thousands of votes for about 10,000
                        pesos each in Colombia's Atlantic department. The
                        Gerlein and Char families are among the most powerful
                        economic groups in Colombia and their tentacles reach
                        all aspects of politics, business, and organized crime.
                        Julio Gerlein invested 6,000 million pesos (more than
                        1,500,000 USD) in Duque's presidential campaign, the
                        Chars also held a "fundraising" between contractors.
                        Julio Gerlein is the brother of senator Roberto Gerlein,
                        the owner of construction company Valorcon, and the
                        former lover of fugitive congresswoman Aída Marleno. The
                        Char Clan, founded by patriarch Fuad Char, owns large
                        swaths of the northern Atlantico province which it
                        obtained through its associations with death squads and
                        organized crime. The Char family is linked to the
                        Medellin cartel.
                      </p>
                      <br></br>
                      <h2>Ivan Duque vs Aida Marleno</h2>
                      <p>
                        In 2018, the lower house of the Colombian Congress
                        opened a preliminary corruption investigation against
                        Ivan Duque. The investigation against Duque rose after
                        accusations of corruption, electoral fraud and vote
                        trafficking were made by former congresswoman Aída
                        Merlano. Aída Marleno was sentenced to 15 years in
                        prison in Colombia for corruption and illegal possession
                        of weapons, after having participated in the practice of
                        electoral fraud. Aída Marleno escaped Colombia and was
                        arrested in Venezuela where she is still being held and
                        from where she made her accusations against Duque.
                        Marleno turned on Ivan Duque after he performatively
                        asked the Venezuelan opposition leader Juan Guaido to
                        extradite her, knowing fully Guaido has no power to do
                        so. Merlano commented that, “They do not want me to be
                        extradited, because if they wanted to, they would ask
                        the legitimate president, Nicolás Maduro, and not
                        Guaido. It is a political and diplomatic absurdity to
                        request extradition to Guaidó.” In addition to Duque,
                        Marleno accused several current and former congress
                        members and police officers of participating or
                        benefiting from the same corruption scheme and organized
                        crime. In a statement before the justice of Venezuela,
                        Merlano accused Duque of wanting to assassinate her to
                        silence her as she can reveal the direct ties between
                        Uribe, Duque's government, and organized crime. In her
                        40-minute testimony, Merlano claimed she became the
                        victim of a criminal conspiracy after trying to break
                        away from her long-time lover Julio Gerlein. In early
                        2020, the authoritarian president of Colombia Nicolas
                        Maduro publicly blackmailed Ivan Duque in change for
                        amending diplomatic relations between Colombia and
                        Venezuela. According to Venezuelan authorities, its
                        intelligence agency SEBIN has been interrogating Merlano
                        “and she has begun telling everything,” about Colombia's
                        ruling class.
                      </p>
                      <br></br>
                      <h2>Ivan Duque and Juan Guaido</h2>

                      <p>
                        In 2019, the Colombia - U.S. backed opposition leader
                        Juan Guaido was photographed by Venezuelan intelligence
                        with members of Colombia's organized crime. Guaido posed
                        with two leaders of Los Rastrojos which controls
                        trafficking routes to Venezuela. These routes are used
                        to smuggle illicit gasoline, traffick drugs, and
                        traffick rape slaves. Members of Los Rastrojos organized
                        crime group drove Juan Guaido from the border of San
                        Cristobal to Agua Clara where Ivan Duque's helicopter
                        was waiting for him. Juan Guaido was transported to
                        President Ivan Duque to attend a concert organized by UK
                        billionaire Richard Branson. Branson is linked to human
                        traffickers Peter Nygard, Keith Raniere, Clare Bronfman
                        Jeffrey Epstein. After Juan Guaido's trip with Colombian
                        narcos to meet Ivan Duque was revealed, two massacres
                        were committed in Agua Clara. One of the massacres
                        killed the parents of one of the Narcos involved in the
                        Guaido operation. A pro-Duque far right group called the
                        "Aguilas Negras" later terrorized residents of Agua
                        Clara and they who told them, “we are carrying out a
                        cleansing.” Colombia’s police confirmed the ties between
                        Juan Guaido and the organized crime group Los Rastrojos.
                        The operation allowed Guaido to meet with US
                        intelligence and coordinate an operation to attempt to
                        weaken Nicolas Maduro. Former National Security Adviser
                        John Bolton, former US ambassador Kevin Whitaker, and
                        Colombian Foreign Minister Carlos Holmes Trujillo
                        coordinated with Guaido and Duque. The failed operation
                        injured hundreds of people and led to Venezuela closing
                        the border and breaking all diplomatic ties with the
                        U.S. and Colombia.
                      </p>
                      <br></br>
                      <h2>Duque and the protests 2021</h2>
                      <p>
                        In April 2021, protests broke out throughout Colombia
                        against tax hikes that would have directly impacted the
                        countries most vulnerable people. The proposal of the
                        Tax hike came amid the deadly COVID19 pandemic that
                        President Duque completely mishandled and whose response
                        he and his government are solely responsible for
                        sabotaging. The 2021 demonstrations in Colombia are the
                        culmination of a series of issues surrounding the
                        Presidency of Ivan Duque such as organized crime,
                        impunity, incompetence, and a public policy prioritizing
                        the interests of the wealthy over those of the general
                        public. Under Duque Colombia's poverty rate increased
                        more than 42 percent as he spent US$9.2 billion on the
                        military in 2020 amid the Covid-19 pandemic. While Duque
                        lined the pockets of contractors and organized crime
                        close to his government Colombia's hospitals struggled,
                        often lacking basic necessities and PPE in key areas.
                        Military expenditure represents 3.4 percent of GDP –
                        higher than the world average of 2.4 percent. The
                        Covid-19 pandemic in Colombia has so far claimed more
                        than 75,000 lives, with daily deaths increasing. On top
                        of that police and military violence against the
                        civilian population has risen, as have state massacres.
                        The number of people living in extreme poverty grew by
                        2.8 million people in 2020 amid coronavirus lockdowns
                        that exacerbated the country’s deeply entrenched
                        inequalities. In response to growing protests, Ivan
                        Duque has chosen to declare war on unarmed civilians,
                        referring to them as "terrorists" and "vandals", to
                        justify human rights abuses under his command. Calling
                        protestors "vandals" or "terrorists" is an
                        internationally known propaganda terminology used by
                        authoritarian governments preparing or in the process of
                        committing crimes against humanity. The Duque government
                        has participated in excessive and unnecessary use of
                        force against an unarmed civilian population. Colombian
                        troops and police under his authority murdered,
                        tortured, raped, and injured dozens. They subjected
                        hundreds of others to arbitrary detentions, while others
                        have been disappeared.
                      </p>
                      <br></br>
                      <h1>
                        To Be Continued.... follow us on social media or
                        subscribe for the latest updates.
                      </h1>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>Coming soon</li>
                        <li>Coming soon</li>
                        <li>Coming soon</li>
                        <li>Coming soon</li>
                        <li>Coming soon</li>
                        <li>Coming soon</li>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Duque;
