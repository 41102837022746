import React, { Component } from 'react';
import Navbar from "../../../../../../components/Navbar/Navbar.js";
import Content from "../../../../../views/NorthAmerica/US/Trump-Crime-Family/Fredrich-Trump/Content.js";
import Footer from "../../../../../../components/Footer/Footer.js";
//SEO
import { Helmet } from "react-helmet";

export class FredrichTrump extends Component {
    render() {
        return (
            <div>
             <Helmet>
                <title>Spooky Connections - Fredrich Trump and transnational organized crime.</title>
                <meta name="description" content="Spooky Connections - Fredrich Trump and transnational organized crime"/>

                <meta itemprop="name" content="Spooky Connections - Fredrich Trump"/>
                <meta itemprop="description" content="Spooky Connections - Fredrich Trump and transnational organized crime"/>
                <meta itemprop="image" content="http://localhost:3000/static/media/FredrichTrump.11c9d47b.png"/>

                <meta property="og:url" content="https://www.spookyconnections.com/"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Spooky Connections - Fredrich Trump"/>
                <meta property="og:description" content="Spooky Connections - Fredrich Trump and transnational organized crime"/>
                <meta property="og:image" content="http://localhost:3000/static/media/FredrichTrump.11c9d47b.png"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Spooky Connections - Fredrich Trump"/>
                <meta name="twitter:description" content="Spooky Connections - Fredrich Trump and transnational organized crime"/>
                <meta name="twitter:image" content="http://localhost:3000/static/media/FredrichTrump.11c9d47b.png"/>
                </Helmet>
            <Navbar />
            <Content />
            <Footer />
            </div>
        )
    }
}

export default FredrichTrump;
