import React from "react";
// import Iframe from "react-iframe";

// views
import Persons from "../views/Persons/Persons.jsx";

// core components
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../components/FixedContact/FixedContact.js";

// react components
import { Container, CardBody, Button } from "reactstrap";

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }

  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
             <Container className="content">
              {/*<Card className="card-user"> */}
                <CardBody className="text-center">
                  <h2>
                    Spooky Connections
                  </h2>
                  <h5> Independent, Open Source, Investigation</h5>

                  {/* <div style={{ height: "35em" }}>
                    <Iframe
                      src="https://embed.kumu.io/d0de3d633dadbc941a8067cac2becc8d"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      top="0"
                      left="0"
                      bottom="0"
                      right="0"
                    ></Iframe>
                  </div> */}
                  <br></br>
                  {/* <hr color="white"></hr> */}

                  <h4>Follow Us:</h4>
                  {/* // eslint-disable-next-line */}
                  <a
                    target="_blank"
                    href="https://twitter.com/spookygraphs"
                    rel="noreferrer"
                  >
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                  </a>
                  {/* // eslint-disable-next-line */}
                  <a
                    target="_blank"
                    href="https://www.facebook.com/SpookyConnections"
                    rel="noreferrer"
                  >
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fab fa-facebook" />
                    </Button>
                  </a>
                  {/* // eslint-disable-next-line */}
                  <a
                    target="_blank"
                    href="https://www.instagram.com/spookyconnections/"
                    rel="noreferrer"
                  >
                    <Button className="btn-icon btn-round" color="Instagram">
                      <i className="fab fa-Instagram" />
                    </Button>
                  </a>
                </CardBody>

                <Persons />
              {/* </Card>*/}
            </Container> 
          </div>
        </div>

        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Content;

// import React from "react";

// // views
// import Persons from "../views/Persons/Persons.jsx";

// // core components
// import Navbar from "../components/Navbar/Navbar.js";
// import Footer from "../components/Footer/Footer.js";
// import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
// import FixedGlobe from "../components/FixedGlobe/FixedGlobe.js";
// import FixedContact from "../components/FixedContact/FixedContact.js";
// // react components
// import {Container, Card, CardBody, Button} from "reactstrap";

// // SEO
// import { Helmet } from "react-helmet";

// class Main extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       backgroundColor: "default",
//     };
//   }

//   render() {
//     return (
//       <>
//         <Helmet>
//             <title>Spooky Connections - End Impunity</title>
//             <meta name="description" content="An independent international open source investigation to probe transnational
//             organized crime"/>
//             <meta itemprop="name" content="Spooky Connections - Ending Impunity"/>
//             <meta itemprop="description" content="An independent international open source investigation to probe transnational
//             organized crime"/>
//             <meta itemprop="image" content="https://pbs.twimg.com/profile_images/1305761155785220096/UDSU72Z0_400x400.jpg"/>

//             <meta property="og:url" content="https://www.spookyconnections.com/"/>
//             <meta property="og:type" content="website"/>
//             <meta property="og:title" content="Spooky Connections - Ending Impunity"/>
//             <meta property="og:description" content="An independent international open source investigation to probe transnational
//             organized crime"/>
//             <meta property="og:image" content="https://pbs.twimg.com/profile_images/1305761155785220096/UDSU72Z0_400x400.jpg"/>
//             <meta property="og:image:width" content="1200"/>
//             <meta property="og:image:height" content="630"/>

//             <meta name="twitter:card" content="summary_large_image"/>
//             <meta name="twitter:title" content="Spooky Connections - Ending Impunity"/>
//             <meta name="twitter:description" content="An independent international open source investigation to probe transnational
//             organized crime"/>
//             <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1305761155785220096/UDSU72Z0_400x400.jpg"/>
//        </Helmet>
//         <div className="wrapper" >
//         <div
//             className="main-panel"
//             ref="mainPanel"
//             data={this.state.backgroundColor}
//           >
//               <Navbar
//               {...this.props}
//               // brandText={this.getBrandText(this.props.location.pathname)}

//             />
//             <Container className="content">
//               <Card
//               className=""
//               style={{
//                 background:"transparent",
//                 }}>
//                   <CardBody className="text-center">
//                   <h4>Welcome To Spooky Connections | Let's End Impunity!</h4>
//                   <h5>Follow Us:</h5>
//                      {/* // eslint-disable-next-line */}
//                 <a target="_blank" href="https://twitter.com/spookygraphs" rel="noreferrer">

//                   <Button className="btn-icon btn-round" color="twitter">
//                   <i className="fab fa-twitter" />
//                   </Button>
//                 </a>
//                    {/* // eslint-disable-next-line */}
//                 <a target="_blank" href="https://www.facebook.com/SpookyConnections" rel="noreferrer">
//                   <Button className="btn-icon btn-round" color="facebook">
//                   <i className="fab fa-facebook" />
//                   </Button>
//                 </a>
//                    {/* // eslint-disable-next-line */}
//                 <a target="_blank" href="https://www.instagram.com/spookyconnections/" rel="noreferrer">
//                   <Button className="btn-icon btn-round" color="Instagram">
//                   <i className="fab fa-Instagram" />
//                   </Button>
//                 </a>
//                   </CardBody>

//               </Card>

//             <Persons />
//             </Container>

//             </div>

//         </div>
//         <Footer fluid />
//         <FixedPlugin
//           bgColor={this.state.backgroundColor}
//           handleBgClick={this.handleBgClick}
//         />
//         <FixedGlobe
//           bgColor={this.state.backgroundColor}
//           handleBgClick={this.handleBgClick}
//         />
//         <FixedContact
//           bgColor={this.state.backgroundColor}
//           handleBgClick={this.handleBgClick}
//         />

//       </>
//     );
//   }
// }

// export default Main;
