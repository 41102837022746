import React from "react";
import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/DonaldTrumpJr.png";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Aras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Donald Trump Jr</h4>
                        {/* </a> */}
                        <p className="description">Millionaire</p>
                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ol>
                            <li>Introduction</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ul>
                            <Link to="Emin-Agalarov">
                              <li>Emin Agalarov</li>
                            </Link>
                            <li>Jared Kushner</li>
                            <li>Paul Manfort</li>
                            <li>Natalia Veselnitskaya</li>
                          </ul>
                          <h4>Family Of:</h4>
                          <ul>
                            <li>Maryanne Trump Barry</li>
                            <li>Elizabeth Trump Grau</li>
                            <li>Mary Anne MacLeod</li>
                            <Link to="Donald-Trump">
                              <li>Donald Trump</li>
                            </Link>
                            <li>Elizabeth Trump</li>
                            <Link to="Fred-Trump">
                              <li>Fred Trump</li>
                            </Link>
                            <li>John G. Trump</li>
                            <li>Robert Trump</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Trump & Son (Trump Organization)</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">
                    LATEST UPDATE NOVEMBER 25 Introduction
                  </Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Donald Trump Jr</h1>

                      <h2> Introduction </h2>

                      <p>
                        Donald Trump Jr. is the eldest child of United States
                        president Donald Trump and Ivana Trump. He currently
                        serves as a trustee and executive vice president of the
                        Trump Organization, running the company alongside his
                        younger brother Eric Trump. He also served as a
                        boardroom judge on his father's TV show The Apprentice.
                      </p>

                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Aras;
