import React from "react";
// import { Link } from "react-router-dom";

import image from "../../../../../../assets/img/profile/AlexeiNavalny.png";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

import Iframe from 'react-iframe';

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Dmitry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Alexei Navalny</h4>
                        {/* </a> */}
                        <p className="description">Middle Class</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>Investigations</li>
                            <li>Fallout</li>
                            
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ul>

                          <li>Roman Abramovich</li>
                            <li>Alexander Bastrykin</li>
                            <li>Ramzan Kadyrov</li>
                            <li>Sergi Kolesnikov</li>
                            <li>Dmitry Medvedev</li>
                            <li>Vladimir Putin</li>
                            <li>Nikolai Shamalov</li>
                            <li>Alisher Usmanov</li>
                            <li>Viktor Vekselberg</li>
                            <li>Viktor Zolotov</li>
                            <li>Corus Group</li>
                            <li>Rosneft</li>
                            <li>Gazprom</li>
                            <li>Gazprom Neft</li>
                            <li>Lukoil</li>
                            <li>Surgutneftegas</li>

                          </ul>
                          <h4>Family Of:</h4>
                          <ul><li>Lyudmila Navalnaya</li>
                            <li>Anatoly Navalny </li>
                            <li>Yulia Navalnaya</li>
                            <li>Zahar Navalny </li>
                            <li>Daria Navalnaya</li></ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                          <li>RosPil</li>
                            <li>RosYama</li>
                            <li>Anti-Corruption Foundation (FBK)</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="8">
                  <Card>
                    
                    <CardBody>
                      
                    <h1 class="typography-line">Alexei Navalny</h1>           
                    <Iframe url="https://www.youtube.com/embed/SbY7S9V0PIY"
                
                width="100%"
                height="450px"
                frameBorder="0"
                id="myId"
                className="myClassname"/>
                <br></br>
                      <h2> Introduction </h2>
                    
                      <p>
                        Alexei Navalny is Russia's main opposition leader,
                        anti-corruption investigative journalist, and Putin's
                        number one critic. He was born in Butyn, Russia on June
                        4, 1976. Navalny was born to Anatoly Navalny and
                        Lyudmila Navalnaya, who own a basket-weaving factory in
                        the village of Kobyakovo, Vologda Oblast, which they
                        have run since 1994. His estimated net worth is reported
                        to be around $200,000 as of 2020.  Navalny graduated from the Peoples' Friendship
                        University of Russia in 1998 with a law degree. He then
                        studied securities and exchanges at the Financial
                        University under the Government of the Russian
                        Federation. Navalny received a scholarship to the Yale
                        World Fellows program at Yale University in 2010. </p>
                      <p>
                        Navalny has released some of Russia's most damning
                        reports exposing corruption and organized crime links in
                        Putin’s inner circle. He has also been a prominent
                        figure in sparking mass protests, including the 2011 -
                        2012, sparked by reports of widespread electoral fraud
                        during the parliamentary election.Navalny has been arrested multiple times and has served
                        stints in jail for charges relating to leading protests.
                        He has been legally barred from running for Russia's
                        presidency by previous convictions. </p>
                      <p>
                        <br/>
                        <h2>Investigations</h2>
                        </p>
                      <p>
                        In 2008, Navalny invested in stocks of
                        five oil and gas companies: Rosneft, Gazprom, Gazprom
                        Neft, Lukoil, and Surgutneftegas to become an activist
                        shareholder. Navalny was investigating high-level
                        directors of these companies and attempting to prove
                        theft and gain transparency as a shareholder.
                        </p>
                      <p>
                        In 2010n, Navalny published
                        confidential documents about Transneft's auditing.
                        Navalny exposed that Transneft's leaders had stolen at
                        least $4 billion during the construction of the Eastern
                        Siberia–Pacific Ocean oil pipeline. </p>
                      <p>
                        In December of that year, Navalny announced the launch
                        of the RosPil project, which sought to shed light on
                        corruption in the government procurement process. RosPil
                        project took advantage of regulation that requires all
                        government requests for tender to be posted online.
                        RosPil now publishes information about winning bids
                        online as well, allowing comprehensive transparency. In
                        May two thousand eleven, Navalny launched RosYama, a
                        project that allowed individuals to report potholes and
                        track government responses to complaints. This project
                        was aimed at exposing the Kremlin's incompetence and
                        neglect of infrastructure across Russia.
                        </p>
                      <p>
                        In August 2011, Navalny published papers
                        related to an infamous real estate deal between the
                        Hungarian and Russian governments. The papers revealed
                        Hungary sold a former embassy building in Moscow for $21
                        million to an offshore company owned by Viktor
                        Vekselberg, who immediately resold it to the Russian
                        government for $116 million. Viktor Vekselberg is a
                        Russian metals tycoon and multi-billionaire currently
                        sanctioned by the United States. Three Hungarian
                        officials responsible for the deal were detained and no
                        official investigation was conducted on the Russian
                        side.</p>
                      <p>
                        In February 2012, Navalny concluded that
                        Russian federal money going to Ramzan Kadyrov's Chechen
                        Interior Ministry was being spent on luxuries. The money
                        was being stolen by Kadyrov's inner circle for their
                        personal use.</p>
                      <p>
                        In May 2012, Navalny accused Deputy Prime
                        Minister Igor Shuvalov of participating in organized
                        crime and theft. He exposed that companies owned by
                        Roman Abramovich and Alisher Usmanov transferred tens of
                        millions of dollars to Shuvalov's company. The transfer
                        was to share in the profit from Usmanov's purchase of
                        the British steel company Corus.
                        </p>
                      <p>
                        In July 2012, Navalny exposed that
                        Alexander Bastrykin, head of the Investigative Committee
                        of Russia, owned an undeclared business in the Czech
                        Republic. The investigation on Bastrykin came out on the
                        back of a espionage operation targeting Navalny whose
                        emails leaked prior to his arrest.
                        </p>
                      <p>
                        In March 2017, Navalny published the
                        investigation "He Is Not Dimon To You", exposing Prime
                        Minister Dmitry Medvedev of participating in organized
                        crime. Russian authorities refused to look into the
                        matter or conduct a further investigation, instead, they
                        dismissed it altogether. On March 26, two thousand
                        seventeen, Navalny coordinated a series of
                        anti-organized crime rallies in cities across Russia. On
                        March 27, he was fined twenty thousand rubles minimum
                        for organizing an illegal protest and jailed for 15 days
                        for resisting arrest. At least one thousand people were
                        also arrested for participating in the rallies which the
                        Kremlin deemed unlawful.
                        </p>
                      <p>
                        In August two thousand eighteen, Navalny exposed Viktor
                        Zolotov for the theft of at least US$29 million from
                        procurement contracts for the National Guard of Russia.
                        Shortly after his allegations against Zolotov, Navalny
                        was imprisoned for staging protests. On September two
                        thousand eighteen a desperate Viktor Zolotov published a
                        video message challenging Navalny to a duel.
                        </p>
                      <p>
                        On January 19, 2021, Navalny exposed
                        President Vladimir Putin of using illegal funds to build
                        a massive estate for himself near the town of Gelendzhik
                        in Krasnodar Krai. Alexy Navalny dubbed Putin's fraud
                        "the world's biggest bribe". Putin's estate featured
                        many golden toilet brushes that cost around $824 each.
                        The toilet brushes were the price of the average Russian
                        pensioner's monthly income. This spurred protestors to
                        start carrying toilet brushes to protests in defiance of
                        the Kremlin. The toilet brushes symbolized Putin's
                        out-of-control decadence. It made clear just how out of
                        touch with the average Russian's reality Putin and his
                        inner circle truly were.
                        </p>
                      <p>
                        The estate was first reported on in two thousand ten by
                        Sergei Kolesnikov the co-owner of Petromed who was
                        involved in the project. Kolesnikov has since fled
                        Russia and has spoken out about Putin's inner circle
                        which he calls a corrupt club. He says they have
                        enriched its leader to the point where "money doesn't
                        have any meaning anymore." Petromed performed legitimate
                        functions manufacturing medical equipment and
                        refurbishing operating rooms in a number of regional
                        hospitals. Kolesnikov and his partner Nikolai Shamalov,
                        now Bank Rossia's second-largest shareholder and on the
                        sanctions blacklist, were called on to help divert
                        public funds from hospitals. The funds were diverted
                        through a complex network of companies and offshore
                        accounts on behalf of "Mikhail Ivanovich" as Putin was
                        known among his business partners.
                        </p>
                      <p>
                        According to Navalny, Putin's estate is 39 times the
                        size of Monaco, with the Federal Security Service (FSB)
                        owning 70 square kilometers of land around the palace,
                        and the estate cost over $1.35 billion) to construct.
                        The estate was built during a period of great financial
                        strife for the average Russian citizen and public funds
                        were used to fuel Putin's massive theft.
                        </p>
                      <br/>
                       <h2> Fallout</h2>
                      <p>
                        Navalny has increasingly come under attack by Putin's
                        sycophants and the state apparatus through espionage,
                        harassment, arrests, acid attacks, and even attempted
                        assassinations through poisoning. In 2017, an attacker
                        threw a green antiseptic liquid in his face, damaging
                        his sight. He also was hospitalized in 2019 after a
                        suspected poisoning while in jail. In 2021 the Russian
                        government poisoned Navalny with Novichok nerve agent.
                        </p>
                      <p>
                        Navalny has a YouTube channel with 6.5 million
                        subscribers which he uses to spread information ranging
                        from the government linked organized crime activities to
                        protest organizations. Through his Youtube Channel and
                        network of activists, Navalny has taught the Russian
                        public a new voting strategy called smart voting. Smart
                        Voting is a tactical voting strategy to deprive the
                        United Russia party of votes in regional and federal
                        elections. The goal of "Smart Voting" is to consolidate
                        the votes of those who oppose the United Russian party.
                        People from different parties and political backgrounds
                        vote as a single block for any candidate that comes up
                        against Putin's allies.
                        </p>
                      <br/>
                       <h2>Controversy</h2> 
                        
                      <p>
                        Navalny first entered politics as part of a broader
                        ethnic Russian nationalist movement. Which has earned
                        him criticism for his endorsement of nationalist causes
                        in the late 2000s. In 2012, Navalny pivoted to become
                        part of a wider coalition of opposition groups that
                        sought to lead protests against Mr. Putin’s decision to
                        return as president after serving for four years as
                        prime minister. Navalny has repeatedly stated in
                        interviews that he doesn't regret his past comments or
                        and suggested that an ability to engage both liberals
                        and nationalists is necessary for politicians.
                        </p>
                      <p>
                        Navalny said, "The basis of my approach is that you have
                        to communicate with nationalists and educate them... I
                        think it's very important to explain to them that the
                        problem of illegal immigration is not solved by beating
                        up migrants but by other, democratic means: a return to
                        competitive elections that would help us to get rid of
                        the crooks and thieves getting rich off of illegal
                        immigration." 
                        </p>
                      <p> 
                        Kremlin-linked media and online propagandists use
                        Navalny's nationalist past to push a coordinated
                        state-sponsored propaganda campaign to discredit Navalny
                        to bury his investigations. Russian State TV has sought
                        to discredit him as a neo-Nazi, and comparisons with
                        Hitler have been shown to university students during
                        lectures and broadcast on government channels. The views
                        the Russian state apparatus is attempting to discredit
                        Navalny for are relatively light in comparison to those
                        held by Putin or his support base. Russia is a largely
                        conservative country where the Russian orthodox church
                        still exercises extreme influence over social and
                        political life. While western audiences may find Navalny
                        past statements concerning, western notions of
                        pluralism, liberalism and left-wing rhetoric fall short
                        in Russia when it comes to public approval, and any
                        politician displaying these sentiments is unlikely to
                        win.
                        </p>
                      
                        <br/>
                        <h2>Repression</h2>
                       
                      <p>A Russian court’s decision to imprison Navalny in
                        early February two thousand twenty-one and the release
                        of the Putin estate investigation sparked massive
                        protests that stretched to every city in Russia. The
                        protests involved a wide cross-section of Russian
                        society, including middle-aged and middle-class Russians
                        and hundreds of thousands of young people. Street
                        demonstrations were largely called off in the face of
                        extreme police violence and the detention of thousands
                        countrywide. Protestors continue to demonstrate against
                        the Kremlin but in smaller numbers to avoid mass
                        arrests. </p>
                      <p>
                        The Kremlin has suppressed opposition activities,
                        stopped the freedom to protest on the street or online,
                        and has legally barred Kremlin critics from
                        participating in the political process. The recent
                        financial strain from oil prices collapsing in two
                        thousand twenty and the Covid-19 lockdown measures have
                        worsened the economic prospects of Russians, many of
                        whom are now demanding more political freedoms as Putin
                        secures the opportunity to run for the presidency once
                        more.
                        </p>
                      <p>
                        The Russian government under Putin is moving towards
                        labeling all support of Alexei Navalny or his
                        organizations an act of extremism. Navalny is currently
                        serving a two-and-a-half-year prison sentence in a penal
                        colony. His health is quickly deteriorating and his
                        allies fear he will die in custody. In May two thousand
                        twenty-one during a court appearance, Navalny called
                        President Putin a "king with no clothes", who was
                        "robbing the people" and depriving Russians of a future.
                        </p>
                      <p>
                        "Russians are being turned into slaves" said Navalny.
                        
                      </p>
                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Coming Soon
                        </li>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Dmitry;
