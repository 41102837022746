import React, { Component } from 'react';
import Navbar from "../components/Navbar/Navbar.js";
import Content from "../views/About/Content.js";
import Footer from "../components/Footer/Footer.js";

export class About extends Component {
    render() {
        return (
            <div>
            <Navbar fluid />
            <Content />
            <Footer fluid />
            </div>
        )
    }
}

export default About;
