import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/PrinceCharles.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";
// import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class PrinceCharles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card
                    className="card-user"
                    style={{
                      position: "sticky",
                    }}
                  >
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Prince Charles</h4>
                        {/* </a> */}
                        <p className="description">Royal</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>Prince Charles and Gortenstoun</li>
                            <li>Prince Charles and Lord Mountbatten</li>
                            <li>Charles and Savile</li>
                            <li>Prince Charles and Jimmy Savile</li>
                            <li>Prince Charles and Peter Ball</li>
                            <li>Prince Charles and Princess Diana</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul></ul>
                          <h4>Family of:</h4>
                          <ul></ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul></ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE MARCH 3</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Prince Charles</h1>
                      <h2>Introduction</h2>
                      <p>
                        Charles, Prince of Wales, in full Charles Philip Arthur
                        George; born 14 November 1948 is the heir apparent to
                        the British throne as the eldest son of Queen Elizabeth
                        II and Prince Philip. He has been heir apparent as well
                        as Duke of Cornwall and Duke of Rothesay since 1952, and
                        the longest-serving Prince of Wales, holding the title
                        since July 1958.
                      </p>
                      <br></br>
                      <p>
                        Prince Charles attended Cheam school in England,
                        Gordonstoun school in Scotland, and Timbertop campus of
                        Geelong Grammar School in Victoria, Australia. He
                        graduated from the University of Cambridge and from 1971
                        to 1976 he served in the Royal Air Force and Royal Navy.
                        In 1981, he married Lady Diana Spencer, and they had two
                        sons: Prince William and Prince Harry. By 1996, the
                        couple divorced after years of an extramarital affair
                        between Prince Charles and Camilla Parker Bowles. The
                        following year, Princess Diana was later killed during a
                        car crash in Paris. In 2005, Charles married long-time
                        partner Camilla Parker Bowles.
                      </p>
                      <br></br>
                      <h2>Prince Charles and Gortenstoun</h2>
                      <br></br>
                      <p>
                        {" "}
                        Prince Charles was a lonely child with a tendency for
                        self-pity; with a mother that neglected him and a father
                        who bullied him according to witnesses. At Cheam school
                        Prince Charles became a target of bullying as his
                        classmates ridiculed his protruding ears and called the
                        pudgy prince “fatty.” Lord Mountbatten even suggested
                        that Charles’ large ears should be pinned back
                        surgically. Charles was physically uncoordinated and
                        slow as well as overweight, Charles had no talent for
                        Rugby, cricket, or soccer which made him an even bigger
                        outcast. The Queen thought Charles was a “slow
                        developer” and Prince Philip believed he was too
                        sensitive and he worried that he was weak and
                        vulnerable, so he set about toughening him up. When
                        asked in an interview when he was 20 years old whether
                        Prince Philip had been a “tough disciplinarian” and
                        whether he had been told “to sit down and shut up,”
                        Charles answered without hesitation: “The whole time,
                        yes.”
                      </p>
                      <br></br>
                      <p>
                        In 1962, with the intention of toughing up Prince
                        Charles, his father Prince Philip, arranged for Charles
                        to be sent to Gordonstoun school in Scotland. As part of
                        his initiation into Gordonstoun he was locked in a cage
                        naked and showered in cold water. Afterwards Prince
                        Charles stood firm that his younger siblings should not
                        be sent to Gordonstoun, a plea that went unnoticed by
                        his parents.
                      </p>
                      <br></br>
                      <h2>Prince Charles and Lord Mountbatten</h2>
                      <br></br>
                      <p>
                        {" "}
                        Prince Charles was known to have a close relationship
                        with Lord Louis Mountbatten who became a life-long
                        mentor offering him guidance throughout his life. Lord
                        Mountbatten was the great-uncle to Prince Charles and he
                        advised him in ‘matters of the heart’. Mountbatten is
                        reported to have encouraged Charles to 'sow his wild
                        oats' and embrace his bachelorhood before settling down.
                        They first met when Prince Charles was age 5. Charles
                        viewed Lord Mountbatten as an ‘honorary grandfather’ and
                        visited him frequently at his estate. Mountbatten was
                        the only family member to attend the prince's 1971
                        graduation from the Royal Naval College Dartmouth, and
                        the following year, they escorted Wallis, Duchess of
                        Windsor, to see her husband Edward VIII’s body lying in
                        state in St George's Chapel at Windsor.{" "}
                      </p>
                      <br></br>
                      <p>
                        Lord Mountbatten was later revealed to be a child rapist
                        by documents recorded by the FBI. Mountbatten was also
                        named by multiple witnesses and victims during the
                        Kincora Boys Home child rape network investigation. In
                        1979 Lord Mountbatten was killed after a bomb was
                        planted on his boat, Shadow V, during a fishing Trip in
                        Ireland. The blast also killed three other people: his
                        14-year-old grandson Nicholas Knatchbull, Lady Doreen
                        Brabourne, Nicholas’s 83-year-old paternal grandmother,
                        and Paul Maxwell, a 15-year-old boat boy. It is
                        suggested the IRA may have killed Mountbatten because of
                        the allegations that he was a child rapist rather than
                        because of his position. “There were a lot of IRA people
                        in that area. I am pretty sure they knew [the rumours].
                        They could have killed him any time in the last 30
                        years” of his life. Attempts to learn more about
                        Mountbatten’s death have been thwarted by the failure of
                        the UK National Archives to release its files about
                        Kincora Boys’ Home.
                      </p>
                      <br></br>
                      <p>
                        In 2015, Prince Charles visited the site of
                        Mountbatten's death, he said 'I could not imagine how we
                        would come to terms with the anguish of such a deep loss
                        since, for me, Lord Mountbatten represented the
                        grandfather I never had.' Prince Charles's eldest son,
                        Prince William, reportedly named his own son Prince
                        Louis after Lord Mountbatten.[6]{" "}
                      </p>
                      <br></br>
                      <h2>Prince Charles and Jimmy Savile</h2>
                      <p>
                        In 1966, the prolific child rapist Sir James Wilson
                        Vincent Savile, aka Jimmy Savile, became the first
                        civilian to be awarded a Royal Marines’ green beret.
                        Lord Mountbatten was comm­and­ant general at the time.
                        Jimmy was later introduced by Lord Mountbatten into
                        royal circles and to Prince Charles. Savile visited
                        Prince Charles's official London residence several times
                        in the late 1980s when he was acting as a kind of
                        marriage counsellor between Prince Charles and Princess
                        Diana. Royal staff were confused and struggled to
                        understand why Savile was granted extensive access to
                        the royal family. In 1999 Prince Charles accepted an
                        invitation to a private meal at Savile's Glencoe home
                        and Savile asked three local women to dress up in
                        pinafores emblazoned with the letters HRH. Prince
                        Charles would also send Jimmy Savile Christmas cards, in
                        one particular card he wrote a note that read: "Jimmy,
                        with affectionate greetings from Charles. Give my love
                        to your ladies in Scotland." Charles reportedly sent him
                        a box of cigars and a pair of gold cufflinks on his 80th
                        birthday with a note that read: "Nobody will ever know
                        what you have done for this country Jimmy. This is to go
                        some way in thanking you for that."{" "}
                      </p>
                      <br></br>
                      <p>
                        In an interview with Esquire, Savile tells, “The thing
                        about me is I get things done and I work deep cover,” …
                        “I’ve known the Royal Family for a million years.” …
                        “Just because some people are famous or have a lot of
                        money doesn’t mean they don’t have problems.”... ”Coming
                        from Lord Louis, who was the favourite uncle of Prince
                        Philip, that was quite something,” “So obviously I
                        hooked up with the Prince – what was good enough for
                        Lord Louis was good enough for him.” Jimmy Saville was
                        later exposed for having raped and/or sexually assaulted
                        more than 500 people, including hospital patients,
                        children and elders alike.
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        Prince Charles is reported to have identified Jimmy
                        Savile as his ‘best friend’ and even included Savile in
                        a list of potential godparents after his second son
                        Harry was born in 1984. After Jimmy Saviles death Prince
                        Charles led tributes despite growing allegations of
                        Savile’s mass child rape.{" "}
                      </p>
                      <br></br>
                      <h2>Prince Charles and Peter Ball </h2>
                      <p>
                        Jimmy Savile introduced Prince Charles to child rapist
                        Bishop Peter Ball. Prince Charles became very close to
                        Peter Ball and he was a long-time admirer. When the
                        prince’s marriage to Diana was unravelling, Prince
                        Charles sought advice from Ball who encouraged his
                        relationship with Camilla Parker Bowles. Years later,
                        Peter Ball was a guest of honour at the wedding when
                        Prince Charles finally married Camilla after years of
                        extramarital affair.
                      </p>
                      <p>
                        In 1992 when Peter Ball was first arrested and issued a
                        caution for child rape and other crimes, Prince Charles
                        used his influence to protect him. Prince Charles sent
                        letters of support to him while he was under
                        investigation. In 1993, the bishop was forced to resign
                        after being let off with a caution over allegations he
                        assaulted and raped a teen monk. Charles immediately
                        offered the disgraced Peter Ball sanctuary and a place
                        to live in a Duchy of Cornwall house. As well as gaining
                        influence within the church, Bishop Ball had a powerful
                        Establishment network, which included disgraced PM
                        Margaret Thatcher who covered up for multiple child
                        rapists during her tenure.
                      </p>
                      <br></br>
                      <p>
                        Finally in 2015 the bishop came to trial and the jury
                        heard how he abused, tortured, and raped young priests
                        and monks. At the second trial the court heard from 18
                        victims but it is believed there were more than 100. As
                        part of Peter Ball’s 2015 trial, the court heard that,
                        backed by Prince Charles, Cabinet ministers, the Royal
                        Family, MPs, JPs and a lord, he escaped with a caution
                        over the 1992 allegations and continued to abuse for 23
                        years. Peter Ball was released from prison in February
                        2017 after serving half of his 32-month sentence. The
                        former bishop of both Lewes and Gloucester, “ensured
                        that Lambeth Palace and Archbishop Carey were aware of
                        his friendship with His Royal Highness the Prince of
                        Wales,” “He did so in the hope this would influence
                        their treatment of him and, ultimately, ease his return
                        to ministry.” He also let it be known that Prince
                        Charles also purchased a property specifically to rent
                        to Ball and his brother, and his “charm, charisma and
                        reputation” enabled him to avoid criminal conviction for
                        decades.{" "}
                      </p>{" "}
                      <br></br>
                      <p>
                        Investigators later found that Prince Charles’s
                        correspondence with George Carey, the Archbishop of
                        Canterbury at the time, “could have been interpreted as
                        expressions of support” for Ball and an attempt to
                        influence Lambeth Palace in his favour. As a result,
                        “the actions of the Prince of Wales were misguided. His
                        actions, and those of his staff, could have been
                        interpreted as expressions of support for Peter Ball
                        and, given the Prince of Wales’ future role within the
                        Church of England, had the potential to influence the
                        actions of the Church.” The report mentions Prince
                        Charles has stated that he “took no position on Peter
                        Ball’s return to ministry, he and his private secretary
                        enquired about Peter Ball within Lambeth Palace. He
                        should have recognised the potential effect that his
                        apparent support for Peter Ball could have had upon
                        decision-making within Lambeth Palace.”
                      </p>
                      <br></br>
                      <p>
                        However one of Peter Ball’s victims had this to say,
                        “Throughout all of this Prince Charles has tried to
                        distance himself from Ball and play down their close
                        friendship, the nature of which can be seen in letters
                        disclosed to the inquiry. “To say that he was simply
                        misguided in continuing that friendship, even after he
                        was made aware of Ball’s police caution, seems to be
                        letting him off rather lightly. He must have been fully
                        aware of the power and influence that his support would
                        bring. “I welcome the work of the inquiry but I can’t
                        help but feel that we will never know the full truth and
                        so those who did contribute to the cover-up may never be
                        properly held to account.” Richard Scorer, a specialist
                        abuse lawyer at Slater and Gordon, said, “We may never
                        know the true harm caused by Charles’ intervention and
                        support for Ball, but welcome the fact that the inquiry
                        did not shy away from highlighting his role in this
                        scandal.”
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        The Prince maintained a correspondence with Ball for
                        more than two decades after his police caution and told
                        the inquiry that he regretted being "deceived" by him
                        over a long period of time “about the true nature” of
                        his activities. He also denied seeking to influence the
                        outcome of police investigations and said he was unsure
                        whether he was told about Ball’s caution until 2009. In
                        one letter, sent from Prince Charles to Peter Ball in
                        February 1995, he said: “I wish I could do more. I feel
                        so desperately strongly about the monstrous wrongs that
                        have been done to you and the way you have been
                        treated.”
                      </p>
                      <br></br>
                      <h2>Prince Charles and Princess Diana</h2>
                      <p>
                        In 1981, Prince Charles married Lady Diana Spencer, and
                        they had two sons: Prince William and Prince Harry. By
                        1996 they were divorced after a series of extramarital
                        affairs by Prince Charles. In 1997, Princess Diana was
                        killed in a car crash in Paris which has been the
                        subject of various investigations and speculation. She
                        died alongside Dodi Fayed, who was an Egyptian film
                        producer and the son of billionaire Mohamed Al Fayed.
                        Dodi’s father Mohamed called Prince Philip a Nazi and
                        went on to blame the Royal family for the murder of his
                        son and Princess Diana.
                      </p>
                      <br></br>
                      <p>
                        In the mid-1990s, prior to the Royal divorce and Diana’s
                        death, Diana visited a hospitalized Royal servant who
                        said he was raped twice by a man who works closely with
                        Prince Charles. One of the incidents was said to have
                        taken place in Egypt, the other at a royal palace. The
                        victim was being paid less than £10,000 a year, and is
                        understood to have left the royal household with a
                        £30,000 payoff. During her hospital visit Princess Diana
                        secretly recorded the conversation with the victim,
                        however the tape went missing shortly after her death in
                        1997.{" "}
                      </p>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Louis Mountbatten, 1st Earl Mountbatten | British
                          statesman. (2021). Retrieved 2 March 2021, from
                          https://www.britannica.com/biography/Louis-Mountbatten-1st-Earl-Mountbatten
                        </li>
                        <br></br>
                        <li>
                          Smith, S. (2021). Inside the Dynastic Struggle That
                          Rocked Queen Elizabeth II’s Marriage. Retrieved 2
                          March 2021, from
                          https://www.vanityfair.com/style/society/2012/01/queen-elizabeth-201201
                        </li>
                        <br></br>
                        <li>
                          FBI files allege new royal scandal. (2021). Retrieved
                          2 March 2021, from
                          https://www.news.com.au/entertainment/celebrity-life/royals/fbi-files-claim-lord-louis-mountbatten-had-a-perversion-for-young-boys/news-story/3647da9b3e938ae4aa5d0f3608639479
                        </li>
                        <br></br>
                        <li>
                          Prince Charles and Prince Charles's Treasured
                          Relationship. Biography. (2021). Retrieved 3 March
                          2021, from
                          https://www.biography.com/news/prince-charles-lord-mountbatten-relationship.
                        </li>
                        <br></br>
                        <li>
                          Edge, S. (2021). How Jim really did fix it.
                          Express.co.uk. Retrieved 3 March 2021, from
                          https://www.express.co.uk/expressyourself/43798/How-Jim-really-did-fix-it.
                        </li>
                        <br></br>
                        <li>
                          Cope, R. (2021). What was Prince Charles's
                          relationship with Prince Charles really like?. Tatler.
                          Retrieved 3 March 2021, from
                          https://www.tatler.com/article/lord-mountbatten-relationship-prince-charles-death.
                        </li>
                        <br></br>
                        <li>
                          Prince Charles linked to Kincora child abuse ring -
                          Indymedia Ireland. Indymedia.ie. (2021). Retrieved 3
                          March 2021, from
                          http://www.indymedia.ie/article/20885.
                        </li>
                        <br></br>
                        <li>
                          Prince Charles, murdered by IRA, was a pedophile.
                          IrishCentral.com. (2021). Retrieved 3 March 2021, from
                          https://www.irishcentral.com/roots/history/lord-mountbatten-pedophile-allegations.
                        </li>
                        <br></br>
                        <li>
                          Tucker, G. (2021). Prince Charles’s ‘lust for young
                          men’ revealed. Retrieved 2 March 2021, from
                          https://www.thetimes.co.uk/article/lord-mountbattens-lust-for-young-men-revealed-90swzmgms
                        </li>
                        <br></br>
                        <li>
                          The Crown and the assassination of Prince Charles:
                          fact or fiction?. The Irish Times. (2021). Retrieved 3
                          March 2021, from
                          https://www.irishtimes.com/culture/heritage/the-crown-and-the-assassination-of-lord-mountbatten-fact-or-fiction-1.4413622.
                        </li>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default PrinceCharles;
