import React from "react";
import { Link } from "react-router-dom";

import image from "../../assets/img/logo.png";

// nodejs library that concatenates classes
import classNames from "classnames";


// reactstrap components
import {
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Modal,
  Button
} from "reactstrap";

class MainNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSearch: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-dark"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };

  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };
  render() {
    const { bgColor } = this.props;  
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <NavbarBrand 
              to="/" 
              tag={Link}            
              >
                <img
                data={bgColor}
                className="logo-img"
                        alt="..."
                        style={{
                          height:'50px', 
                          borderRadius:'50%', 
                          background:'rgba(52, 70, 117, 0.3)', 
                          padding:'1px'}}
                        src={image}
                      />
              </NavbarBrand>
              
            </div>
              <Nav className="ml-auto" navbar>
              
                  
                
                <InputGroup className="search-bar">
                  
                  
                {/* // eslint-disable-next-line */}
                <a target="_blank" href="https://donorbox.org/donate-to-spooky-connections" rel="noreferrer">
              <Button
                color="danger"
                className="btn-round"
              >
                 Support Us
              </Button>
              </a> 
                </InputGroup>
                
                              
              
                <li className="separator d-lg-none" />
              </Nav>
            {/* </Collapse> */}
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input
             id="inlineFormInputGroup" 
             placeholder="SEARCH" 
             type="text"
              />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal> 
      </>
    );
  }
}

export default MainNavbar;
