import React, {useEffect} from "react";
import ReactGA from 'react-ga';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Main from "./layouts/Main.js";
import Graph from "./pages/Graph.js";

import About from "./pages/About.js";
import Legal from "./pages/Legal.js";
import Contact from "./pages/Contact.js";
// import Donation from "./pages/Donation.js";

//investigation profiles

//Profiles
//Canada
import PeterNygard from "./profiles/pages/NorthAmerica/Canada/Nygard-Crime-Family/Peter-Nygard/Content.js";

//Colombia
import IvanDuque from "./profiles/pages/SouthAmerica/Colombia/Duque-Crime-Family/Ivan-Duque/Content.js";




//Brazil
import RicardoBellino from "./profiles/pages/SouthAmerica/Brazil/Bellino-Crime-Family/Ricardo-Bellino/Content.js";
import RicardoGoldstein from "./profiles/pages/SouthAmerica/Brazil/Goldstein-Crime-Family/Ricardo-Goldstein/Content.js";

//France
import JeanLucBrunel from "./profiles/pages/Europe/France/Brunel-Crime-Family/Jean-Luc-Brunel/Content.js";
import GeraldMarie from "./profiles/pages/Europe/France/Marie-Crime-Family/Gerald-Marie/Content.js";

//Russia
import ArasAgalarov from "./profiles/pages/Europe/Russia/Agalarov-Crime-Family/Aras-Agalarov/Content.js";
import EminAgalarov from "./profiles/pages/Europe/Russia/Agalarov-Crime-Family/Emin-Agalarov/Content.js";

import Dmitry from "./profiles/pages/Europe/Russia/Rybolovlev-Crime-Family/Dmitry-Rybolovlev/Content.js";
import AlexeiNavalny from "./profiles/pages/Europe/Russia/Navalny-Hero-Family/Alexei-Navalny/Content.js";


//Georgia
import IraklyKaveladze from "./profiles/pages/Europe/Georgia/Kaveladze-Crime-Family/Irakli-Kaveladze/Content.js";

//Turkey
import TevfikArif from "./profiles/pages/Europe/Turkey/Arif-Crime-Family/Tevfik-Arif/Content.js";

//UK
import RobertMaxwell from "./profiles/pages/Europe/UK/Maxwell-Crime-Family/Robert-Maxwell/Content.js";
import LordMountbatten from "./profiles/pages/Europe/UK/Royal-Crime-Family/Lord-Mountbatten/Content.js";
import KingEdwardVIII from "./profiles/pages/Europe/UK/Royal-Crime-Family/King-EdwardVIII/Content.js";
import PrincePhilip from "./profiles/pages/Europe/UK/Royal-Crime-Family/Prince-Philip/Content.js";
import PrinceCharles from "./profiles/pages/Europe/UK/Royal-Crime-Family/Prince-Charles/Content.js";
import PrinceAndrew from "./profiles/pages/Europe/UK/Royal-Crime-Family/Prince-Andrew/Content.js";
//USA
import RoyCohn from "./profiles/pages/NorthAmerica/US/Cohn-Crime-Family/Roy-Cohn/Content.js";
import JohnCasablancas from "./profiles/pages/NorthAmerica/US/Casablancas-Crime-Family/John-Casablancas/Content.js";
import JeffreyEpstein from "./profiles/pages/NorthAmerica/US/Epstein-Crime-Family/Jeffrey-Epstein/Content.js";
import RudyGiuliani from "./profiles/pages/NorthAmerica/US/Giuliani-Crime-Family/Rudy-Giuliani/Content.js";

import CharlesKushner from "./profiles/pages/NorthAmerica/US/Kushner-Crime-Family/Charles-Kushner/Content.js";
import JaredKushner from "./profiles/pages/NorthAmerica/US/Kushner-Crime-Family/Jared-Kushner/Content.js"; 

import DonaldTrump from "./profiles/pages/NorthAmerica/US/Trump-Crime-Family/Donald-Trump/Content.js";
import DonaldTrumpJr from "./profiles/pages/NorthAmerica/US/Trump-Crime-Family/Donald-Trump-Jr/Content.js";
import FredTrump from "./profiles/pages/NorthAmerica/US/Trump-Crime-Family/Fred-Trump/Content.js";
import FredrichTrump from "./profiles/pages/NorthAmerica/US/Trump-Crime-Family/Fredrich-Trump/Content.js";
 
//USA Mob
import TheCommission from "./profiles/pages/NorthAmerica/US/Cosa-Nostra/The-Commission/Content.js";

//Gambino Crime Family
import PaulCastellano from "./profiles/pages/NorthAmerica/US/Cosa-Nostra/Gambino-Crime-Family/Paul-Castellano/Content.js";
import SalvatoreGravano from "./profiles/pages/NorthAmerica/US/Cosa-Nostra/Gambino-Crime-Family/Salvatore-Gravano/Content.js";

//Philadephia Crime Family
import PhilLeonetti from "./profiles/pages/NorthAmerica/US/Cosa-Nostra/Philadelphia-Crime-Family/Phil-Leonetti/Content.js";
import Soon from "./profiles/pages/ComingSoon.js";

//styling
import "./assets/scss/black-dashboard-react.scss";
// import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";

function App() {
  useEffect(() => {
      ReactGA.initialize('UA-177504818-1');
    //  To Report Page View 
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    useEffect(() => {
     console.log(window.location.pathname)
    })
    const hist = createBrowserHistory();
  return (

<Router history={hist}>
    <Switch>
    <Route
      path="/"
     exact
    render={props => <Main {...props} />}
    />
      <Route
        path="/about"
        exact
        render={props => <About {...props} />}
      />
       <Route
        path="/Graph"
        exact
        render={props => <Graph {...props} />}
      />
      <Route
        path="/legal"
        exact
        render={props => <Legal {...props} />}
      />
      <Route
        path="/contact"
        exact
        render={props => <Contact {...props} />}
      />
      <Route
        path="/coming-soon"
        exact
        render={props => <Soon {...props} />}
      />
      <Route
        path="/Dmitry-Yevgenyevich-Rybolovlev"
        exact
        render={props => <Dmitry {...props} />}
      />
      <Route
        path="/Aras-Agalarov"
        exact
        render={props => <ArasAgalarov {...props} />}
      />
      <Route
        path="/Emin-Agalarov"
        exact
        render={props => <EminAgalarov {...props} />}
      />
      <Route
        path="/Tevfik-Arif"
        exact
        render={props => <TevfikArif {...props} />}
      />
      <Route
        path="/Ricardo-Bellino"
        exact
        render={props => <RicardoBellino {...props} />}
      />
      <Route
        path="/Jean-Luc-Brunel"
        exact
        render={props => <JeanLucBrunel {...props} />}
      />
      <Route
        path="/John-Casablancas"
        exact
        render={props => <JohnCasablancas {...props} />}
      />
      <Route
        path="/Ivan-Duque"
        exact
        render={props => <IvanDuque {...props} />}
      />
      <Route
        path="/King-EdwardVIII"
        exact
        render={props => <KingEdwardVIII {...props} />}
      />
      <Route
        path="/Lord-Mountbatten"
        exact
        render={props => <LordMountbatten {...props} />}
      />
       <Route
      path="/Prince-Philip"
      exact
      render={props => <PrincePhilip {...props} />}
      /> 
      <Route
      path="/Prince-Andrew"
      exact
      render={props => <PrinceAndrew {...props} />}
      /> 
       <Route
      path="/Prince-Charles"
      exact
      render={props => <PrinceCharles {...props} />}
      /> 
      <Route
        path="/Paul-Castellano"
        exact
        render={props => <PaulCastellano {...props} />}
      />
      <Route
        path="/Roy-Cohn"
        exact
        render={props => <RoyCohn {...props} />}
      />
      <Route
        path="/The-Commission"
        exact
        render={props => <TheCommission {...props} />}
      />
      <Route
        path="/Jeffrey-Epstein"
        exact
        render={props => <JeffreyEpstein {...props} />}
      />     
      <Route
        path="/Ricardo-Goldstein"
        exact
        render={props => <RicardoGoldstein {...props} />}
      />
      <Route
        path="/Ricardo-Goldstein"
        exact
        render={props => <RicardoGoldstein {...props} />}
      />
      <Route
        path="/Salvatore-Gravano"
        exact
        render={props => <SalvatoreGravano {...props} />}
      />
      <Route
        path="/Rudy-Giuliani"
        exact
        render={props => <RudyGiuliani {...props} />}
      />
      <Route
        path="/Irakly-Kaveladze"
        exact
        render={props => <IraklyKaveladze {...props} />}
      />
      <Route
        path="/Charles-Kushner"
        exact
        render={props => <CharlesKushner {...props} />}
      />
      <Route
        path="/Jared-Kushner"
        exact
        render={props => <JaredKushner {...props} />}
      />
      <Route
        path="/Phil-Leonetti"
        exact
        render={props => <PhilLeonetti {...props} />}
      /> 
      <Route
      path="/Gerald-Marie"
      exact
      render={props => <GeraldMarie {...props} />}
      />
      <Route
      path="/Robert-Maxwell"
      exact
      render={props => <RobertMaxwell {...props} />}
      />
      <Route
      path="/Alexei-Navalny"
      exact
      render={props => <AlexeiNavalny {...props} />}
      />
      <Route
      path="/Peter-Nygard"
      exact
      render={props => <PeterNygard {...props} />}
      />
     
      <Route
        path="/Dmitry-Rybolovlev"
        exact
        render={props => <Dmitry {...props} />}
      />
       <Route
        path="/Donald-Trump"
        exact
        render={props => <DonaldTrump {...props} />}
      />
      <Route
        path="/Donald-Trump-jr"
        exact
        render={props => <DonaldTrumpJr {...props} />}
      />
      <Route
        path="/Fred-Trump"
        exact
        render={props => <FredTrump {...props} />}
      />
       <Route
        path="/Fredrich-Trump"
        exact
        render={props => <FredrichTrump {...props} />}
      />
    </Switch>
</Router>
  
  );
}

export default App;