import React, { Component } from 'react';
import Navbar from "../../../../../../components/Navbar/Navbar.js";
import Content from "../../../../../views/SouthAmerica/Brazil/Bellino-Crime-Family/Ricardo-Bellino/Content.js";
import Footer from "../../../../../../components/Footer/Footer.js";

//SEO
import { Helmet } from "react-helmet";

export class RicardoBellino extends Component {
    render() {
        return (
            <div>
             <Helmet>
                <title>Spooky Connections - Ricardo Bellino and transnational organized crime.</title>
                <meta name="description" content="Profile of Ricardo Bellino's connections to organized crime networks, child trafficking, and foreign espionage operations"/>

                <meta itemprop="name" content="Spooky Connections - Ricardo Bellino"/>
                <meta itemprop="description" content="Profile of Ricardo Bellino's connections to organized crime networks, child trafficking, and foreign espionage operations"/>
                <meta itemprop="image" content="https://www.spookyconnections.com/static/media/RicardoBellino.43b2d154.png"/>

                <meta property="og:url" content="https://www.spookyconnections.com/"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Spooky Connections - Ricardo Bellino"/>
                <meta property="og:description" content="Profile of Ricardo Bellino's connections to organized crime networks, child trafficking, and foreign espionage operations"/>
                <meta property="og:image" content="https://www.spookyconnections.com/static/media/RicardoBellino.43b2d154.png"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Spooky Connections - Ricardo Bellino"/>
                <meta name="twitter:description" content="Profile of Ricardo Bellino's connections to organized crime networks, child trafficking, and foreign espionage operations"/>
                <meta name="twitter:image" content="https://www.spookyconnections.com/static/media/RicardoBellino.43b2d154.png"/>
                </Helmet>
            <Navbar />
            <Content />
            <Footer />
            </div>
        )
    }
}

export default RicardoBellino;
