/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";


import { Link } from "react-router-dom";

// reactstrap components
import { Container, Row, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid>
          <Nav>
            <NavItem>
            <Link to="/about" 
             >
              <NavLink style={{
              color:'white'
            }} >About Us</NavLink>
            </Link>
            </NavItem>
            <NavItem>
              <NavLink>Shop</NavLink>
            </NavItem>
            <NavItem>
              <NavLink>Blog</NavLink>
            </NavItem>
          </Nav>
          <NavItem>
            <Link to="/legal" 
             >
              <NavLink style={{
              color:'white'
            }} >Legal</NavLink>
            </Link>
            </NavItem>
            <NavItem>
            <Link to="/contact" 
             >
              <NavLink style={{
              color:'white'
            }} >Contact</NavLink>
            </Link>
            </NavItem>
          <div className="copyright">
            © {new Date().getFullYear()} {" "}
           
              Spooky Connections
           
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
