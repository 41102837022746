import React from "react";
import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/FredTrump.png";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Aras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Fred Trump</h4>
                        {/* </a> */}
                        <p className="description">Billionaire</p>
                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ol>
                            <li>Introduction</li>
                            <li>Fred Trump Scams</li>
                            <ul>
                              <li>1954 Beach Haven Investigation</li>
                            </ul>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ul>
                            <li>Clyde Powell</li>
                          </ul>
                          <h4>Family Of:</h4>
                          <ul>
                            <li>Maryanne Trump Barry</li>
                            <li>Elizabeth Trump Grau</li>
                            <li>Mary Anne MacLeod</li>
                            <Link to="Donald-Trump">
                              <li>Donald Trump</li>
                            </Link>
                            <li>Elizabeth Trump</li>
                            <Link to="Donald-Trump-Jr">
                              <li>Donald Trump Jr</li>
                            </Link>
                            <li>Frederick Trump</li>
                            <li>John G. Trump</li>
                            <li>Robert Trump</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>E. Trump & Son (Trump Organization)</li>
                            <li>Beach Haven Aparments</li>
                            <li>Beach Shore Apartments</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">
                    LATEST UPDATE NOVEMBER 26 Introduction
                  </Alert>
                </Col>
                <Col md="8">
                <Alert
                    color="danger"
                  >
                    <CardBody>
                    
                      <h3>Like our work
                      and want us to grow? please support us and donate. Your donation
                      to Spooky Connections 👻 is a direct contribution to our
                      research, developers, and servers. With your support, we
                      will continue to publish new profiles, investigations, and
                      uncover organized crime networks.</h3>
                    <br></br>
                    <a target="_blank" rel="noreferrer" 
                    href="https://donorbox.org/donate-to-spooky-connections">
                    <img src="https://d1iczxrky3cnb2.cloudfront.net/button-medium-blue.png" alt="Donate Here"/></a>
                    </CardBody>
                  </Alert>
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Fred Trump</h1>

                      <h2> Introduction </h2>

                      <p>Fred Trump was a real-estate developer in New York City who was the son for Fredrick Trump, a bar & brothel owner, and the father of Donald Trump, the 45th president of the United States, and Maryanne Trump Barry, a former United States Court of Appeals judge. As a partner with his mother Elizabeth Christ Trump, he began a career in home construction and sales. The Trump Organization, originally called E. Trump & Son, was founded in 1923 and grew with the construction and management of single-family houses in Queens, barracks and garden apartments for U.S. Navy personnel near major shipyards along the East Coast, and more than 27,000 apartments in New York City. [1]</p>
                      <br></br>
                      <h1>Old Man Trump and the KKK </h1>

<p>In 1927, Fred Trump was arrested while participating in a KKK riot. The KKK riot broke out during the May 30, Memorial Day parade in Jamaica, Queens, N.Y. [2] A week prior, police commissioner Joseph Warren was warned that the Ku Klux Klan intended to parade in hoods and gowns and he ordered that they should not be issued a permit to have a parade. The KKK got permission from the Grand Army of the Republic, a veterans’ organization that had charge of the parade arrangements and at least 1,000 Klansmen clashed with 100 police officers. [3] Fred Trump, then 21, was arrested at the parade along with six others but was the only arrestee discharged. [4] Donald Trump decades later denied that his father, Fred Trump, had been arrested at a KKK riot but official records proved the arrest. [5]</p> 
<br></br>
<h1>Trump’s child slumlords & tax evasion</h1>

<p>In the late 1940s, Fred Trump obtained at least $26 million in federal loans to build two of his largest developments, Beach Haven Apartments, and Shore Haven Apartments and made his children his landlords. According to records obtained by The Times he built or bought apartment buildings in Brooklyn and Queens and then gradually, without public trace, transferred ownership to his children through a web of partnerships and corporations. In all, Fred Trump put up nearly $13 million in cash and mortgage debt to create a mini-empire within his empire — eight buildings with 1,032 apartments — that he would transfer to his children. By the early 1970s, Fred Trump would execute similar transfers of the other seven buildings. [6] </p><br></br>


<p>Fred Trump was investigated by a U.S. Senate committee in 1954, for profiteering off of public contracts and for overcharging his Beach Haven building up to $3.7m. Fred Trump exploited funds from the Federal Housing Administration intended to help build affordable housing for WW2 military vets and then overcharged veterans in rent. The investigation was headed by lawyer William McKenna, an expert in housing, finance, and organized crime. McKenna discovered criminal activity in the housing program, with builders bribing officials tasked with determining who got access to government loans and contracts.</p><br></br>

<p>The individual in charge of the FHA’s New York office was Clyde L. Powell and he controlled the flow of money for Beach Haven, an apartment complex built by Fred Trump with FHA loans. He allowed Fred Trump to start building before Beach Haven was approved and start renting to vets and others six months before he was due to start repaying his loan. Fred Trump profited $1.7 million in overpriced rent payments and was allowed to pocket most of a 5% fee from the Beach Haven development’s cost that was earmarked for architectural work. Trump was also permitted to borrow more in federally subsidized funds, at least $3.7 million more than he actually needed. To develop Beach Haven, Fred Trump devised a scheme to get the U.S. government to approve extra high construction costs, which would give him more borrowing power and make it easy to charge higher rents. The final tally on the project showed that Beach Haven had been built for $4 million less than the estimate that was given. The high rents set when the project was approved remained in place and the $4 million leftover from the FHA building loan stayed in a Trump bank account. Clyde L. Powell's savings accounts noted frequent deposits above his annual salary and several individuals later admitted to bribing him in exchange for contracts. [7]</p><br></br>

                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Fred C. Trump, Postwar Master Builder of Housing for
                          Middle Class, Dies at 93 (Published 1999).
                          Nytimes.com. (2020). Retrieved 27 November 2020, from
                          https://www.nytimes.com/1999/06/26/nyregion/fred-c-trump-postwar-master-builder-of-housing-for-middle-class-dies-at-93.html.
                        </li>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Aras;
