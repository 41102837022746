import React from "react";
import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/FredrichTrump.png";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Aras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Fredrich Trump</h4>
                        {/* </a> */}
                        <p className="description">Millionaire</p>
                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ol>
                            <li>Introduction</li>
                            <li>Fredrich Trump Brothel</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <br></br>
                          <ul>
                            <li>Ernest Levin</li>
                          </ul>
                          <h4>Family Of:</h4>
                          <ul>
                            <li>Maryanne Trump Barry</li>
                            <li>Elizabeth Trump Grau</li>
                            <li>Mary Anne MacLeod</li>
                            <Link to="Donald-Trump">
                              <li>Donald Trump</li>
                            </Link>
                            <li>Elizabeth Trump</li>
                            <Link to="Donald-Trump-Jr">
                              <li>Donald Trump Jr</li>
                            </Link>
                            <Link to="Fred-Trump">
                              <li>Fred Trump</li>
                            </Link>
                            <li>John G. Trump</li>
                            <li>Robert Trump</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Dairy Restaurant</li>
                            <li>The Arctic Restaurant</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">
                    LATEST UPDATE NOVEMBER 26 Introduction
                  </Alert>
                </Col>
                <Col md="8">
                <Alert
                    color="danger"
                  >
                    <CardBody>
                    
                      <h3>Like our work
                      and want us to grow? please support us and donate. Your donation
                      to Spooky Connections 👻 is a direct contribution to our
                      research, developers, and servers. With your support, we
                      will continue to publish new profiles, investigations, and
                      uncover organized crime networks.</h3>
                    <br></br>
                    <a target="_blank" rel="noreferrer" 
                    href="https://donorbox.org/donate-to-spooky-connections">
                    <img src="https://d1iczxrky3cnb2.cloudfront.net/button-medium-blue.png" alt="Donate Here"/></a>
                    </CardBody>
                  </Alert>
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Fredrich Trump</h1>
                      <h2> Introduction </h2>
                      <p>Frederick Trump, born Friedrich Trump, was a German-USian businessman, brothel operator, and the patriarch of the Trump family. Born in Kallstadt, in the Kingdom of Bavaria (now in Rhineland-Palatinate, Germany).</p>
                      <br></br>
                      <h1>Trump Brothels</h1>
                      <p>In 1891, Trump moved to Seattle, Washington where he bought the Poodle Dog, which he renamed the Dairy Restaurant, Located at 208 Washington Street, the Dairy Restaurant was in the middle of Seattle's Pioneer Square. Washington Street was nicknamed "the Line" and included an assortment of saloons, casinos, and brothels. The Dairy Restaurant served food and liquor and was advertised to include "Rooms for Ladies", a common euphemism for prostitution.[1]</p> 
                      <br></br>
                      <p>In 1894, Trump sold the Dairy Restaurant and moved to the emerging mining town of Monte Cristo, Washington in Snohomish County north of Seattle. Before leaving Seattle, Trump bought 40 acres in the Pine Lake Plateau, for $200, which was the first major real estate purchase of the Trump family. In Monte Cristo, Trump chose a plot of land near the later train station that he wanted to build a hotel on, but could not afford the $1,000-per-acre fee to purchase it. Instead, he filed a Gold placer claim on the land, which allowed him to claim exclusive mineral rights to the land without having to pay for it, even though the land had already been claimed by Everett resident Nicholas Rudebeck. At that time, the U.S. Land Office was known to be corrupt and frequently allowed such multiple claims. Despite the placer's claim providing Trump no right to build any structure on the land, he quickly bought lumber to build a new “boarding house” and operate it similarly to the Dairy Restaurant. He never tried to mine gold on the land. Blair described Trump as "mining the miners" since they needed a place to sleep at night while they were mining.In July 1894, Rudebeck filed to incorporate the land and sent an agent to collect rent; this was unsuccessful since the people of Monte Cristo did not pay attention to legal titles.Trump finally bought the land in December 1894. While in Monte Cristo, Trump was elected in 1896 as justice of the peace by a 32-to-5 margin. [2]</p>
                      <br></br>
                      <p>Friedrich Trump made his fortune by operating The Arctic Restaurant and brothel in Bennett, B.C., Canada in 1899. [3] It was open around the clock with “private boxes for ladies and parties,” according to an advertisement in the Dec. 9, 1899 edition of the Bennett Sun newspaper. [4] The boxes typically included a bed and scale for weighing gold dust used to pay for “services,”. Bennett was a key hub for prospectors, who traveled from Alaska to Dawson City in search of gold. After a railway link from Skagway, Alaska to Whitehorse, allowing miners to bypass Bennett was built the local economy sank. Frederick Trump left as soon as the Gold Rush boom ended and before laws regarding prostitution, gambling, and liquor, hotels and restaurants could be enforced which would make his business less profitable. Trump dismantled the brothel and rebuilt it in Whitehorse. [5]  A photo in Gwenda Blair’s 2000 book The Trumps: Three Generations of Builders and a Presidential Candidate, shows Fred Trump in a white apron. He’s standing at the bar near a wall of drapes behind which women, known as “sporting ladies,” were prostituted to miners. </p>
                      <br></br>
                      <p>Trump was wealthy when he left Whitehorse in 1901 and returned to his native Kallstadt, Germany, where he later deposited savings of 80,000 marks in the village treasury. Unable to regain German citizenship, he returned to New York with the money he made through his brothel, funding the Trump family’s first residential real estate investments in the New York area, later carried on by his son Fred Trump and grandson Donald Trump. [6]</p>
                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                      <li>Stewart, A. (2016). Donald Trump can trace his family's wealth back to the 'Poodle Dog' in Seattle's Pioneer Square. Bizjournals.com. Retrieved 15 December 2020, from https://www.bizjournals.com/seattle/news/2016/03/23/donald-trump-can-trace-his-familys-wealth-back-to.html.  </li><br></br><li>
Blair, G. (2015). The Trumps: Three Generations of Builders and a Presidential Candidate. Simon & Schuster; Illustrated edition. </li><br></br><li>
Panetta, A. (2015). How a Canadian adventure helped create the Donald Trump family story | CBC News. CBC. Retrieved 15 December 2020, from https://www.cbc.ca/news/canada/trump-canada-yukon-1.3235254. </li><br></br><li>
The Bennett Sun. Open.library.ubc.ca. (1899). Retrieved 15 December 2020, from https://open.library.ubc.ca/collections/bcnewspapers/bensun/items/1.0314940#p4z-3r0f </li><br></br><li>
Obiko Pearson, N. (2016). Donald Trump’s grandfather Friedrich Trump ran a restaurant, bar, and brothel in British Columbia.. Bloomberg.com. Retrieved 15 December 2020, from https://www.bloomberg.com/features/2016-trump-family-fortune/. </li><br></br><li>
Campbell, M. (2017). Donald Trump's ancestral brothel gets a new lease on life - Macleans.ca. Macleans.ca. Retrieved 15 December 2020, from https://www.macleans.ca/news/donald-trumps-ancestral-brothel-gets-a-new-lease-on-life/.</li>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Aras;
