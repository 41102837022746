import React from "react";
import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/TevfikArif.png";


// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton
} from "react-share";


class Tevfik extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };    
   } 
  render() {
    
    return (
      <>
       
        <div className="wrapper">
          
        <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
              
            <Container className="content">
            <Row>
          <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                      <img
                        alt="..."
                        className="avatar"
                        src={image}
                      />
                      <h4 className="title">Tevfik Arif</h4>
                    {/* </a> */}
                    <p className="description">Human Trafficker</p>
                  
                  <Button  color="primary"id="contents" size="sm">
                    Contents {''}
                    <i className="tim-icons icon-bullet-list-67" />
                  </Button>                 
                  <UncontrolledCollapse toggler="#contents" style={{textAlign:'left', marginBottom: '1rem'}}>
                      <ol>
                        <li>Introduction</li>
                         
                      </ol> 
                </UncontrolledCollapse>
                  <Button  color="primary" id="connections" size="sm" >
                    Connections {''}
                    <i className="tim-icons icon-vector" />
                  </Button>
                  <UncontrolledCollapse toggler="#connections" style={{textAlign:'left', marginBottom: '1rem'}}>            
                 <br></br>
                  <ul>
                    <li>Patokh Chodiev</li>
                    <li>Alijan Ibragimov</li>
                    <li>Alexander Mashkevitch</li>
                    <li>David Reuben</li>
                    <li>Simon Reuben</li>
                    <Link to='Donald-Trump'><li>Donald Trump</li></Link>
                    
                    
                     
                  </ul>
                <h4>Family Of:</h4>
                <ul>
                <li>Refik Arif</li>
                </ul>
                  <h4>Owner / Founder Of:</h4>
                  <ul>
                         <li>Bayrock Group</li> 
                         <li>ACCP</li>      
                  </ul>
                </UncontrolledCollapse>
                  <div>
                </div>
                </div>
                
                </CardBody>
                <CardFooter>
                <div className="button-container">
                <h5>Share:</h5>        
                <FacebookShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="facebook">
                  <i className="fab fa-facebook" />
                </Button>
                </FacebookShareButton>
                <TwitterShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="twitter">
                  <i className="fab fa-twitter" />
                </Button>
                </TwitterShareButton>
                <RedditShareButton
                  text={'hello world'}
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="reddit">
                  <i className="fab fa-reddit-alien" />
                </Button>
                </RedditShareButton>                
                </div>  

                </CardFooter>
              </Card> 
              
                <Alert color="danger">
               LATEST UPDATE NOVEMBER 26 Introduction
                </Alert>
                
            
            </Col>
            <Col md="8">
            

              <Card>
                <CardBody>
                  <h1 class="typography-line">Tevfik Arif</h1>
                  
                  <h2> Introduction </h2>
                  <p>Tevfik Arif, a Soviet-born Turkish real estate developer and the founder of the Bayrock Group, a real estate development company best known for its collaboration with Donald Trump. Bayrock and Trump were partners on Trump SoHo and the Trump International Hotel and Tower in Fort Lauderdale. [1] Arif worked for the Soviet Union's Ministry of Trade and Commerce for 17 years before the dissolution of the Soviet Union. He served as the deputy head of its Department of Hotel Management. [2] In 1991, Arif resigned from the ministry and founded Specialty Chemicals Trading Company, an export-import business trading in rare metals, chrome, and raw materials.[3] He then worked on the ground as an 'agent' for Trans World Group, a natural resources company run by UK billionaires David and Simon Reuben.[4]</p>
                    <br></br>
                    <p>In 1991, following the independence of Kazakhstan, Arif and his brother Refik Arif secured ownership of ACCP, a chromium plant in Aktobe that had its headquarters in the British Virgin Islands.  The brothers developed ties to the "Kazakh Trio" — Alexander Mashkevitch, Patokh Chodiev, and Alijan Ibragimov. They later established offshore real estate companies to fund high-end developments in Kazakhstan, Azerbaijan, and Turkey.  [5]  In 1993, Arif moved to Turkey where he had operated the jewellery business Alset Dis Ticaret since 1979. In 1999, he partnered with the Rixos hotel chain to open the Labada luxury hotel in Antalya, Turkey. [6] </p>
                  <br></br>     
              </CardBody>
              <CardFooter>
                <h4>Citations</h4>
              <ol>
              <li>Here’s A Closer Look At Donald Trump’s Disturbingly Deep Ties To Russia. Fortune. (2020). Retrieved 26 November 2020, from https://fortune.com/2017/05/17/donald-trump-russia-2/.</li>
              <br></br>
              <li>Tevfik Arif: From Bayrock Group to his Business Deals with Donald Trump | News Break. News Break Manhattan, NY. (2020). Retrieved 26 November 2020, from https://www.newsbreak.com/news/featured/1614437253255/tevfik-arif-from-bayrock-group-to-his-business-deals-with-donald-trump.</li>
              <br></br>
              <li>Luis, N. (2020). All About Tevfik Arif and Bayrock Group – Beyond Donald Trump and Trump SoHo. Baltimore Post Examine. Retrieved 26 November 2020, from https://baltimorepostexaminer.com/all-about-tevfik-arif-and-bayrock-group-beyond-donald-trump-and-trump-soho/2019/07/15.</li>
              <br></br>
              <li>World leaders, mobsters, smog and mirrors. Theblacksea.eu. (2020). Retrieved 26 November 2020, from https://theblacksea.eu/stories/football-leaks/the-football-leaks-family-world-leaders-mobsters-smoke-and-mirrors/.</li>
              <br></br>
              <li>Donald Trump's disastrous relationship with the dodgy Kazakh business world. Theblacksea.eu. (2020). Retrieved 27 November 2020, from https://theblacksea.eu/stories/football-leaks/donald-trumps-disastrous-relationship-with-the-dodgy-kazakh-business-world/.</li>
<br></br>
<li>World leaders, mobsters, smog and mirrors. Theblacksea.eu. (2020). Retrieved 26 November 2020, from https://theblacksea.eu/stories/football-leaks/the-football-leaks-family-world-leaders-mobsters-smoke-and-mirrors/. </li>   
              </ol>
              </CardFooter>
                     
              </Card>
            </Col>           
          </Row>
            
            </Container>       
          
            </div>

        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
      </>
    );
  }
}

export default Tevfik;



