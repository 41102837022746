import React from "react";

import {
Col,
Button,
CardBody,
FormGroup,
Input,
Label,
Row
} from "reactstrap";

export default class ContactBox extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <CardBody className="px-lg-5 py-lg-5 text-muted">
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/meqryrkk"
        method="POST"
      >
        {/* <!-- add your custom form HTML here --> */}
        <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="email" name="email" id="email" placeholder=""/>  
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input type="name" name="name" id="name" placeholder=""/>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="service">Choose a subject</Label>
            <Input type="select" name="service" id="service" placeholder="I am interested in">
            <option value="" selected disabled></option>
            <option>Submission</option>
            <option>Volunteer</option>
            <option>Report A Bug</option>
            <option>Other</option>
          </Input>  
          </FormGroup>
        </Col>
      </Row>
      <FormGroup >
        <Label for="message">Message</Label>
        <Input  type="textarea"  name="message" placeholder="" />
      </FormGroup>
      <small>By clicking submit I consent to SpookyConnections collecting my email address so that I can receive a response to my request.</small>

        <div className="text-center">
        {status === "SUCCESS" ? <p 
        style={{
          color:"#2dce89",
        }}>Thanks, we'll get back to you within 24 hours!</p> : <Button className="my-4" color="success">Submit</Button>}
        {status === "ERROR" && <p 
        style={{
          color:"red",
        }}>Ooops! There was an error.</p>}
        </div>
      </form>
      </CardBody>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}