import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/PeterNygard.png";
// import image1 from "../../../../../../assets/img/nygard/image1.png";


// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Nygard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Peter Nygard</h4>
                        {/* </a> */}
                        <p className="description">Millionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>
                              Peter Nygard, Nygard International, Nygard Cay
                            </li>
                            <li>Peter Nygard vs Louis Bacon</li>
                            <li>
                              Peter Nygard partying with celebrities,
                              politicians, and royals
                            </li>
                            <li>Peter Nygard rape network</li>
                            <li>Peter Nygard Arrest</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul>
                            <li>Gloria Allred</li>
                            <li>Pamela Anderson</li>
                            <li>Prince Andrew</li>
                            <li>Criss Angel</li>
                            <li>Susan Anton</li>
                            <li>Louis Bacon</li>
                            <li>Jonathan Baram</li>
                            <li>Princess Beatrice</li>
                            <li>Tomaczek Bednarek</li>
                            <li>Milton Berle</li>
                            <li>Pat boon</li>
                            <li>Richard Branson</li>
                            <li>Livingston Bullard</li>
                            <li>Steffanie Busey</li>
                            <li>Gary Busey</li>
                            <li>George H.W. Bush</li>
                            <li>Jean Chretien</li>
                            <li>Professor Tom Clifford</li>
                            <li>Sean Connery</li>
                            <li>Rita Cosby</li>
                            <li>Cathy Lee Crosby</li>
                            <li>Wisler Davilma Robert DeNiro</li>
                            <li>Bo Derek Queen Elizabeth</li>
                            <li>Princess Eugenie</li>
                            <li>Johnny Fay</li>
                            <li>Dutchess Sarah Ferguson</li>
                            <li>Lou Ferrigno</li>
                            <li>David Foster</li>
                            <li>Bill Gates</li>
                            <li>Mel Gibson</li>
                            <li>Tanya Golesic</li>
                            <li>Lee Iococca</li>
                            <li>Michael Jackson</li>
                            <li>Beverly Johnson</li>
                            <li>Jean Kasem</li>
                            <li>Chad Kroeger</li>
                            <li>Robin Leach</li>
                            <li>Warren & Baram Management LLC</li>
                            <li>Ed Lozzi</li>
                            <li>Dan McVicor</li>
                            <li>Suelyn Medeiros</li>
                            <li>Phil Miller</li>
                            <li>Princess Michael of Kent</li>
                            <li>Dudley Moore</li>
                            <li>Brian Mulroney</li>
                            <li>Sir Sidney Poitier</li>
                            <li>Lionel Richie</li>
                            <li>Diane Richie</li>
                            <li>Ted Rogers</li>
                            <li>Anna Nicole Smith Frederick Smith</li>
                            <li>Tanya Tucker</li>
                            <li>Dick van Patten</li>
                            <li>Norby Walters</li>
                            <li>Serena Williams</li>
                            <li>Oprah Winfrey</li>
                          </ul>
                          <h4>Family of:</h4>
                          <ul>
                            <li>Eeli Nygard</li>
                            <li>Hilkka Nygard</li>
                            <li>Liisa Nygard</li>
                            <li>Bianca Nygard</li>
                            <li>Kai Nygard </li>
                            <li>Jessar Nygard</li>
                            <li>Åliå Nygard</li>
                            <li>Scarlet Nygard</li>
                            <li>Xar Nygard</li>
                            <li>Mika Nygard</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Nygard International</li>
                            <li>Nygard Slims</li>
                            <li>Bianca Nygard</li>
                            <li>ADX</li>
                            <li>TanJay</li>
                            <li>Alia</li>
                            <li>Allison Daley</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE DECEMBER 27</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Peter Nygard</h1>
                      <h2> Introduction </h2>
                      <p>
                        {" "}
                        Peter J. Nygard is a Finnish-Canadian former fashion
                        executive. He was rated the 70th richest Canadian by
                        Canadian Business Magazine in 2009 with a net worth of
                        $817 million and in 2020 his net worth is estimated at
                        around $900 million. Nygard was born in Helsinki,
                        Finland in 1943. His parents were Eeli and Hilkka Nygard
                        were bakers who immigrated to Canada in 1952, settling
                        at first in Deloraine, Manitoba (where Nygard Park would
                        later be named in Peter Nygard’s honour in 2002). [1]
                        Peter Nygard’s mother Hilkka wanted to stay involved in
                        business and remained an active director of many of her
                        son's companies and was highly involved in the financial
                        day-to-day control of the Bahamas operations. [2]{" "}
                      </p>{" "}
                      
                      <br></br>{" "}
                      <p>
                        {" "}
                        Nygard attended the University of North Dakota where he
                        graduated in 1964 with a business degree. In interviews
                        given by Nygard, he praises professor Tom Clifford as
                        his mentor, “You are always looking for mentors
                        everywhere,” ... “He became one of my mentors and I
                        highly respected him.” Clifford was then the dean of the
                        College of Business and would later become a longtime
                        president of the University. When Peter Nygard
                        graduated, he made sure his sister Pirjo-Liisa “Liisa”
                        Nygard Johnson was able to pursue a degree from UND as
                        well. Liisa, who started her own fashion business
                        Pirjo-Liisa Fashions with her then-husband Russell
                        Nichol in 1975. [3]{" "}
                      </p>{" "}
                      <br></br>{" "}
                      <p>
                        {" "}
                        One of Peter Nygard’s first jobs out of college was as a
                        sales manager for a new line of women's denim for a
                        company called Jacobs Fashions.In 1967 Peter used his
                        life savings, roughly $8,000, and a loan to purchase a
                        20% stake in the company from its founder Nathan Jacob.
                        Within a few years, Peter bought Nathan out entirely and
                        renamed the business, Tan Jay. [4] He founded Nygard
                        International in 1967 and expanded into the United
                        States in 1978, the company is worth more than $1
                        billion and "the largest producer of women’s apparel" in
                        Canada. [5] While the company was active it produced
                        clothing under brand names such as Peter Nygard, Nygard
                        Slims, Bianca Nygard, ADX, TanJay, Alia, and Allison
                        Daley. In 1978 the company expanded into the United
                        States and in 1987. The company’s corporate facilities
                        are in New York City, US (World Headquarters, Research,
                        and Design); Winnipeg, Manitoba, Canada
                        (IT/Retail/Services/Design & Merchandising, Production &
                        Distribution); Toronto, Ontario, Canada (International
                        Sales & Marketing Team). Nygard also has manufacturing
                        facilities in Shanghai, Tianjin, Sri Lanka, Indonesia,
                        and Mexico. [6]{" "}
                      </p>{" "}
                      <br></br>{" "}
                      <p>
                        {" "}
                        Peter Nygard resigned as chairman in February 2020 after
                        the FBI raided company headquarters in its investigation
                        of rape trafficking accusations against him by more than
                        57 women. The company filed for Chapter 15 Bankruptcy in
                        New York on March 18, 2020, and a Manitoba judge has
                        ordered a group of Nygard companies into receivership on
                        March 19, 2020. On April 30, 2020, a Canadian judge gave
                        the green light to an accounting firm to sell and
                        liquidate part of it. Nygard was arrested on rape
                        trafficking and racketeering charges in December 2020.
                        [7]{" "}
                      </p>{" "}
                      <br></br>{" "}
                      <h1> Peter Nygard, Nygard International, Nygard Cay </h1>{" "}
                      <p>
                        {" "}
                        In 1987, Peter Nygard built a luxury resort named Nygard
                        Cay in Lyford Cay in the Bahamas on the island of
                        Nassau, New Providencia. In 2009 it was partly destroyed
                        by a fire. Peter Nygard accused his neighbor, hedge fund
                        billionaire Louis Bacon, of setting the fire. On April
                        14, 2010, Nygard announced he was planning a $50 million
                        renovation of Nygard Cay, which would take two years to
                        complete and repair the damage and employ 200
                        construction workers.The Bahamanian Prime Minister's
                        Office rejected the plans. In 2018, Nygård Cay was
                        seized by the Supreme Court of the Bahamas as part of a
                        legal battle surrounding Nygård's efforts to dredge the
                        sea floor around the estate. [9]{" "}
                      </p>{" "}
                      <br></br> <h2>Peter Nygard vs Louis Bacon</h2>
                      <p>
                        {" "}
                        The fire and stunted renovation plans have been the
                        source of a feud between Louis Bacon and Peter Nygard
                        for over a decade. Louis Bacon and Peter Nygard have
                        spent tens of millions of dollars fighting each other
                        with more than 25 lawsuits in multiple international
                        jurisdictions. Both sides are claiming damages in the
                        tens of millions of dollars and launching allegations of
                        activities that include vandalism, bribery, insider
                        trading, arson, murder, destruction of the fragile
                        seabed, and having a close association with the Ku Klux
                        Klan. Louis Bacon hasn’t set foot in the Bahamas in more
                        than a year fearing for his personal safety. According
                        to court documents Nygard met covertly Livingston
                        Bullard and Wisler Davilma, two convicted criminals, to
                        discuss the assassination of perceived opponents and
                        adversaries of Peter Nygard, including Frederick Smith
                        and Louis Bacon. allegedly Peter Nygard promised to pay
                        substantial sums for the killings. assignations. [10]{" "}
                      </p>{" "}
                      <br></br>{" "}
                      <p>
                        {" "}
                        In January 2015, he leveled a $100 million defamation
                        lawsuit against Peter Nygard in New York. The suit
                        alleges Nygard is behind a smear campaign that involved
                        TV and radio ads purchased, Websites created, videos
                        doctored, T-shirts printed, and even “hate rallies”
                        staged with parades through Nassau aiming to label Louis
                        Bacon a racist, a thief, and a “terrorist.” Louis Bacon
                        alleges that Nygard planted stories accusing him of
                        arson and murder, citing a 2014 Daily Mail article that
                        said Bacon told his groundskeeper, Dan Tuckfield, now
                        dead, to “find a way to burn Mr. Nygard’s f- -king house
                        down.” according to Peter Nygard “The burn was complete
                        and instantaneous and of suspicious origin,". Nygard
                        also claims Bacon hired Dan Tuckfield to take Bacon’s
                        cigarette boat and plant 7 kilos of cocaine worth
                        $67,000 on Nygard’s beachfront — a plot allegedly foiled
                        when the coke washed back up to Bacon’s residence. It is
                        unknown what happened to the cocaine or if authorities
                        followed up, Nygard promoted the story heavily. Nygard
                        also claims Louis Bacon murder Dan Tuckfield, who was
                        found dead in Louis Bacons pool, according to Nygard it
                        was “suspicious given that he was an expert swimmer who
                        had previously survived a plane crash in the ocean,
                        miles offshore.” The local coroner claims the cause of
                        death was it was a heart attack. [11]
                      </p>{" "}
                      <br></br>
                      <p>
                        In court documents, Louis Bacon cited a Canadian
                        documentary that claimed Nygard turned his beachfront
                        estate into a “mini-brothel” where he lures women and
                        children to rape them. Allegations about his treatment
                        of women and his business practices were also covered in
                        that documentary and a devastating 2010 Forbes profile
                        titled “Peter Nygard Answers to No One.” In it, Nygard
                        is portrayed as a draconian boss who fined low-level
                        house staff $25 for being late or leaving dirty glasses
                        outside. A stewardess on his private plane claimed that
                        Peter Nygard once threw a fit midair, his robe hanging
                        open exposing himself. “You are nothing! … You are
                        garbage! I am God! Do you not understand?”[12]{" "}
                      </p>{" "}
                      <br></br>{" "}
                      <h2>
                        Peter Nygard partying with celebrities, politicians, and
                        royals{" "}
                      </h2>
                      <p>
                        {" "}
                        Several celebrities, politicians, and royals have been
                        reported to have visited and partied at Nygard Cay over
                        the decades including Alan Thicke, Anna Nicole Smith,
                        Beanie Man, Beverly Johnson, Beverly Peel, Duchess of
                        York Sarah Ferguson, Jessica Alba, John Singleton, Lenny
                        Kravitz, Lesie Vanderpo, Michael Jackson, Oprah Winfrey,
                        Prince Albert II of Monaco, Prince Andrew, Princess
                        Beatrice, Princess Eugenie, Princess Marie Chantal of
                        Greece, Princess Michael of Kent, Robert DeNiro, Sean
                        Connery, Zoe Zaldena, And former President George H.W.
                        Bush. [13] Prince Andrew and his ex-wife the Duchess of
                        York Sarah Ferguson reportedly visited Nygard Cay in
                        June 2000, with their two daughters, Princesses Eugenie
                        and Beatrice. [14] In 2003 Queen Elizabeth II awarded
                        her Golden Jubilee medal, to Peter Nygard as someone
                        who, during her reign “helped create the Canada of
                        today, and to recognize Mr. Nygard for outstanding and
                        exemplary achievement to Canada as a whole”. In 2013,
                        Queen Elizabeth II awarded Peter Nygard for a second
                        time with her Diamond Jubilee Medal “in recognition for
                        his philanthropic work raising money and awareness for
                        cancer research and for his outstanding contributions to
                        Canada and the Commonwealth.” [15] In 2004 while filming
                        the movie "Into the Blue", Jessica Alba attended a party
                        at Nygard Cay which she reportedly found "gross". On a
                        subsequent press tour Alba, who was 23 years old at the
                        time, recalled her time at Nygard's house: "These girls
                        are like 14 years old in the Jacuzzi, taking off their
                        clothes…" [16]{" "}
                      </p>{" "}
                      <br></br>
                      <p>
                        "We do a lot of entertaining, I have a lot of wonderful
                        people here, wonderful women here, who make the place,"
                        "Then we have the rap guys come over here and the
                        basketball players … We have a great church here, we
                        have all the church leaders over here..." Peter Nygard
                        said in a 2008 video interview with Bahamas Weekly. [17]{" "}
                        According to a former Peter Nygard employee Victoria
                        Sarne, originally from London, during her time working
                        as his former PA in the 1990s she saw "countless" girls
                        arriving at his offices in Toronto, LA and the Bahamas
                        before they were take to his private quarters. “He’d
                        attach his homes to his offices, often with a glass
                        elevator. His private spaces were all fitted with
                        mirrors. There were no door handles. They seemed
                        ­impossible to get out of." “Peter monitored everything
                        with close circuit TV. Everyone could be watched. It
                        didn’t matter who you were... a Hollywood star, a
                        sporting icon or from the Royal Family, Peter could see
                        and record their every move." “He was sleazy. Like
                        Epstein, he courted anyone in power or who had great
                        fame. They may as well have been brothers.” [18] 
                      </p>
                      <br></br> <h2>Peter Nygard rape network</h2>
                      <p>
                        {" "}
                        In February 2020, 10 women filed a civil class-action
                        lawsuit against Peter accusing him of rapes, assaults,
                        and druggings that occurred at Nygard Cay. [19] At least
                        57 women and girls from five countries and three
                        continents have come forward accusing Nygard of rape,
                        assault, and druggings. [20] Peter Nygard hosted ‘pamper
                        parties’ used to rape and abuse aspiring models and
                        children from around the world, according to a Manhattan
                        lawsuit. He travelled with an entourage of women and
                        girls that described themselves as "paid girlfriends"
                        but were in fact ‘sex workers’ or rape trafficking
                        victims. a 2020 indictment revealed Nygard's
                        “girlfriends” were also trafficked to “swingers” clubs
                        in New York, Miami, Los Angeles and Winnipeg. “While at
                        the clubs, Nygard directed and pressured ‘girlfriends’
                        through manipulation, intimidation, degradation, threats
                        and on occasion, force, to engage in sex with other men
                        in order to facilitate Nygard having sex with other
                        women and for his own sexual gratification,” “At these
                        clubs, Nygard frequently forced his victims to engage in
                        group sex activiity to which they had not consented.”
                        [21] It was revealed that Nygard international was
                        funding all of Peter Nygard’s rape parties by
                        transferring cash from the company's bank account in
                        Canada and routing it through New York. The Nygard
                        International IT department created and maintained a
                        decades-old database on company servers of more than
                        7,500 women and children. The database dated back to
                        1987 and included women and children who could be
                        invited and shipped to the ‘pamper parties’ at a
                        moment's notice. When guests checked in, their
                        information and photos would be entered into Nygard’s
                        database and reviewed to select his potential victims
                        for the night. [22]{" "}
                      </p>{" "}
                      <br></br>{" "}
                      <p>
                        Peter Nygard had a scheme to “purchase police protection
                        and political cover in the Bahamas by making regular
                        payments of tens of thousands of dollars to law
                        enforcement, government officials, regulators and even
                        to a former cabinet minister who became the prime
                        minister of the Bahamas." Nygard also paid people, using
                        Nygard company money, to intimidate his former
                        'girlfriends' and victims by slashing their tires,
                        committing arson, paying police to threaten to arrest
                        them and by having them followed." [23] Peter Nygard
                        referred to women's bottoms as "toilets" and was once
                        recorded saying "Do you see those toilets?" while gazing
                        at a group of women and girls. [24] Prosecutors said
                        Peter Nygard would often target victims who came. from
                        economically disadvantaged backgrounds, luring his
                        victims with drugs, money, and false promises of
                        lucrative modeling deals. [25]
                      </p>{" "}
                      <br></br>
                      <p>
                        In August 2020, a lawsuit against Peter Nygard was
                        launched by two of his sons identified as John Doe
                        number one and number two in court documents accusing
                        Nygard of arranging their rape. According to the
                        lawsuit, “the Nygard Companies, in 2018, arranged and
                        paid for John Doe No. 1 — Nygard’s biological son who
                        was just 14 years old at the time —to be transported
                        from California to Nygard’s residence, a property owned
                        and/or paid for by the Nygard Companies, in Winnipeg,
                        Canada where Nygardhad pre-planned and instructed that
                        his long- time “girlfriend,” a known sex worker (“Jane
                        Roe”) would rape Nygard’s 14 -year old son, John Doe No.
                        1”... “Nygard engineered the rape, instructing Jane Roe
                        to “make a man” out of his 14 -year old son. After the
                        rape, Jane Roe told Nygard that she had, indeed,
                        followed his instructionsto rape John Doe No.”...”But
                        this wasn’t the first time Nygard had orchestrated the
                        rape of one of his sons. In 2004, Nygard choreographed a
                        similar scenario — instructing the same Jane Roe to rape
                        JohnDoe No. 2 , Nygard’s son who was then only fifteen
                        years old.” [26]
                      </p>
                      <br></br>
                      <p>
                        The new complaint was also filed against three of Nygard
                        ’s companies, alleging executives not only knew about
                        Peter Nygard’s crimes, but enabled them. “The Nygard
                        companies, through Nygard and a close ring of
                        upper-level executives and employees, know that Nygard
                        is a sexual predator and/or sex trafficker,” the
                        complaint reads, also alleging the companies have acted
                        as a front for Nygard ’s rape trafficking network.
                        Several of the women who recently came forward told
                        lawyers that they had been abused by other men and women
                        at the direction of Nygard. Peter Nygard denied the
                        accusations, claiming that they are the result of a
                        smear campaign paid for and orchestrated by Louis Bacon.
                        He resigned as chairman of Nygard international shortly
                        after the U.S. Federal Beauru of Investigation (FBI)
                        raided company’s New York offices in its investigation
                        into rape trafficking accusations. [27]
                      </p>
                      <br></br>
                      <h2>Co-Conspirators</h2>
                      <p>
                        An October Lawsuit against Nygard also named talent
                        agent Jonathan Baram & Instagram model Suelyn Medeiros
                        as co-conspirators and claims they provided "a steady
                        pool of victims" to Peter Nygard. The lawsuit claims
                        Suelyn Medeiros was paid for her work as a 'recruiter'
                        and she 'lived in Peter Nygard’s Marina Del Ray compound
                        for at least five years, received significant cash,
                        salary, jewelry, plastic surgery, cars, and stem cell
                        injections in exchange for trafficking victims.'
                        Medeiros lured women and girls to be raped. The first
                        lawsuit was filed in Miami by a Florida woman on Oct.
                        22. She claims Nygard raped her in 2010 when she was 18,
                        and that Instagram model Suelyn Medeiros was partially
                        to blame for allegedly luring her to the Nygard Cay in
                        the Bahamas.
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        Another woman filed a similar lawsuit in New York
                        against talent agent Jonathan Baram and his agency
                        Warren & Baram Management LLC. The lawsuit alleges in
                        2007, when she was 17, she was lured to New York from
                        Canada under the guise she would become a model and
                        actress. The lawsuit alleges Baram instructed the 17
                        year old to sending him nude photos that were sent to
                        Nygard for approval "for an upcoming bikini photo shoot
                        in the Bahamas." "Baram sent the nude photographs to
                        Nygard and, once Nygard approved, told Jane Doe that she
                        was 'in luck' because Nygard wanted to 'meet her,'" The
                        teen was then taken to Nygard's penthouse in New York
                        knowing she would be raped. The lawsuit claims Nygard
                        employees gave the teen alcohol and drugged her and soon
                        after she was raped by Peter Nygard, later that night
                        Baram also sexually assaulted her in his apartment.
                        Nygard's New York penthouse apartment was also named in
                        the lawsuit as being above the Nygard company
                        headquarters on the sixth floor. Nygard received a City
                        of New York citation in 2013 for illegally converting
                        that part of the building into a private residence with
                        a jacuzzi and disco room, according to the lawsuit. The
                        lawsuit also alleges a number of upper-level Nygard
                        company executives, officers and directors enabled the
                        sex assaults. [28]
                      </p>
                      <br></br>
                      <h2>Peter Nygard arrest</h2>
                      <p>
                        On December 14th, 2020, Peter Nygard was charged and
                        arrested by the Candian Royal Mounted Police on behalf
                        of the FBI and NYPD for a U.S. criminal investigation.
                        He faces extraditction to the U.S. and was given a
                        nine-count grand jury indictment that included 'charges
                        of racketeering conspiracy, conspiracy to commit sex
                        trafficking, trafficking of minors, forced sex
                        trafficking and transportation of a minor for
                        prostitution'. The crimes he is being charged with were
                        committed between 1995 through 2020 according the US
                        federal prosecutors in New York City. [29] Peter Nygard
                        has applied for bail, two weeks after his arrest in
                        Winnipeg under the Extradition Act. The bail application
                        will be heard in the Manitoba Court of Queen’s Bench at
                        10 a.m. on Jan. 6, 2021. [30]
                      </p>
                      <br></br>
                      <h2>Nygard IT system hacked by an unknown group</h2>
                      <p>
                        According to a court document dated Dec. 30 the
                        court-appointed receiver of Nygard's assets Richter
                        Advisory Group Inc. confirmed that Nygard IT servers
                        were a victim of a ransomware attack. Informanix — a
                        third-party IT consultant hired to preserve digital
                        records and the Nygard IT staff were working to recover
                        records and computer servers impacted by a November 2020
                        power outage in northwestern Winnipeg when they came
                        under ransomware attack by unknown hackers on Dec. 12,
                        2020 two days prior to his arrest.{" "}
                      </p>
                      <br></br>
                      <p>
                        The attack compromised "certain electronic records,
                        programs and IT infrastructure of the Nygard
                        organization, including the debtors," "by reason of the
                        size and complexity of the IT System, and the caution
                        needed in taking steps to assess the ransomware attack,
                        the full scope and impact of the ransomware attack is
                        not yet certain," the document says. A message from the
                        unknown hackers reads, "Our encryption algorithms are
                        very strong and your files are very well protected, the
                        only way to get your files back is to cooperate with us
                        and get the decrypter program," "Do not try to recover
                        your files without a decrypter program, you may damage
                        them and then they will be impossible to recover."{" "}
                      </p>
                      <br></br>
                      <p>
                        The ransomware used is Netwalker initially created by
                        Circus Spider, a criminal group who started selling
                        tools and ransomware over the dark web on March 2019,
                        the court document says. The hackers initially demanded
                        approximately 99 bitcoins, at the time worth $3.6
                        million. When Richter failed to respond to the initial
                        demand, the ransom payment was doubled to 198
                        bitcoins, at the time worth $7.2 million the court
                        document says. [31]
                      </p>{" "}
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Peter Nygard Net Worth. Celebrity Net Worth. (2020).
                          Retrieved 29 December 2020, from
                          https://www.celebritynetworth.com/richest-businessmen/richest-designers/peter-nygard-net-worth/.
                        </li>
                        <br></br>
                        <li>
                          thebahamasweekly.com - In Memory of Mrs. Hilkka
                          Nygard. Thebahamasweekly.com. (2020). Retrieved 29
                          December 2020, from
                          http://www.thebahamasweekly.com/publish/in-memorium/In_Memory_of_Mrs_Hilkka_Nygard9251.shtml.
                        </li>
                        <br></br>
                        <li>
                          North Dakota University System | Q&A: Catching up with
                          UND alum Peter Nygard ’64. Ndus.edu. (2020). Retrieved
                          29 December 2020, from
                          https://ndus.edu/2019/07/30/qa-catching-up-with-und-alum-peter-nygard-64/.
                        </li>
                        <br></br>
                        <li>
                          Falkenberg, K. (2020). Peter Nygard Answers to No One.
                          Forbes. Retrieved 29 December 2020, from
                          https://www.forbes.com/forbes/2010/1206/features-peter-nygard-sexual-harassment-answers-to-no-one.html?sh=52f0cf20bc9b.
                        </li>
                        <br></br>
                        <li>
                          Stacey, K. (2020). Canadian fashion executive Peter
                          Nygard charged with sex trafficking. Ft.com. Retrieved
                          29 December 2020, from
                          https://www.ft.com/content/82f34a56-b968-4049-bcb5-23099569fe7c.
                        </li>
                        <br></br>
                        <li>
                          https://web.archive.org/web/20100612150724/http://corporate.nygard.com/SCF/Headquarters.aspx?ID=33&Folder_id=25
                        </li>
                        <br></br>
                        <li>
                          Canadian fashion mogul Peter Nygard arrested on sex
                          trafficking charges. CJOB. (2020). Retrieved 29
                          December 2020, from
                          https://globalnews.ca/news/7522991/canadian-fashion-mogul-peter-nygard-arrested/.
                        </li>
                        <br></br>
                        <li>
                          How a Neighbors’ Feud in Paradise Launched an
                          International Rape Case. Nytimes.com. (2020).
                          Retrieved 29 December 2020, from
                          https://www.nytimes.com/2020/02/22/world/americas/peter-nygard-louis-bacon.html.
                        </li>
                        <br></br>
                        <li>
                          Donald Trump’s jet in Brunswick for maintenance.
                          (2020). Retrieved 30 December 2020, from
                          https://www.jacksonville.com/article/20120913/NEWS/801247313.
                        </li>
                        <br></br>
                        <li>
                          WARMINGTON: Former employee not surprised by mogul
                          Peter Nygard's arrest | Toronto Sun. Torontosun.com.
                          (2020). Retrieved 30 December 2020, from
                          https://torontosun.com/news/local-news/warmington-former-employee-not-surprised-mogul-peter-nygard-charged.
                        </li>
                        <br></br>
                        <li>
                          How a Neighbors’ Feud in Paradise Launched an
                          International Rape Case. Nytimes.com. (2020).
                          Retrieved 30 December 2020, from
                          https://www.nytimes.com/2020/02/22/world/americas/peter-nygard-louis-bacon.html.
                        </li>
                        <br></br>
                        <li>
                          https://nypost.com/2015/12/06/inside-the-wild-bahama-battle-between-two-wacky-billionaires/
                        </li>
                        <br></br>
                        <li>
                          https://online.fliphtml5.com/rdyfu/jncl/ &
                          https://online.fliphtml5.com/rdyfu/szfj/ &
                          https://www.flickr.com/photos/nygardinternational/albums/72157624212452921
                        </li>
                        <br></br>
                        <li>
                          Fashion executive accused in rape lawsuit reportedly
                          hosted Prince Andrew at Bahamas estate. the Guardian.
                          (2020). Retrieved 30 December 2020, from
                          https://www.theguardian.com/us-news/2020/feb/15/fashion-executive-accused-in-lawsuit-reportedly-hosted-prince-andrew-at-bahamas-estate.
                        </li>
                        <br></br>
                        <li>
                          Nygard’s Golden Jubilee Review-A Beloved Patriot | The
                          Bahama Journal - Jones Communications Network.
                          Jonesbahamas.com. (2020). Retrieved 30 December 2020,
                          from
                          http://jonesbahamas.com/nygards-golden-jubilee-review-a-beloved-patriot/#:~:text=Nyg%C3%A5rd.,to%20Canada%20as%20a%20whole.
                        </li>
                        <br></br>
                        <li>
                          Jones, S., & Holcombe, M. (2020). Class-action lawsuit
                          alleges Canadian businessman Peter Nygard sexually
                          assaulted at least 10 women. CNN. Retrieved 30
                          December 2020, from
                          https://www.cnn.com/2020/02/19/us/peter-nygard-sexual-assault-accusations/index.html.
                        </li>
                        <br></br>
                        <li>
                          Who is Peter Nygard, the Canadian millionaire accused
                          of raping and trafficking nearly a dozen young women?.
                          Insider. (2020). Retrieved 31 December 2020, from
                          https://www.insider.com/who-is-peter-nygard-the-millionaire-accused-of-sex-trafficking-2020-2.
                        </li>
                        <br></br>
                        <li>
                          https://www.dailystar.co.uk/news/latest-news/former-pa-billionaire-rape-accused-21512654
                        </li>
                        <br></br>

                        <li>
                          Canadian fashion mogul Peter Nygard arrested on sex
                          trafficking charges. CJOB. (2020). Retrieved 30
                          December 2020, from
                          https://globalnews.ca/news/7522991/canadian-fashion-mogul-peter-nygard-arrested/.
                        </li>
                        <br></br>
                        <li>
                          A Canadian Multimillionaire, a Caribbean Feud and an
                          International Rape Case. Nytimes.com. (2020).
                          Retrieved 30 December 2020, from
                          https://www.nytimes.com/2020/02/28/world/canada/peter-nygard-rape-case.html.
                        </li>

                        <br></br>
                        <li>
                          Canadian Fashion Executive Peter J. Nygard Charged
                          With Sex Trafficking And Racketeering Offenses.
                          Justice.gov. (2020). Retrieved 31 December 2020, from
                          https://www.justice.gov/usao-sdny/pr/canadian-fashion-executive-peter-j-nygard-charged-sex-trafficking-and-racketeering.
                        </li>
                        <br></br>
                        <li>
                          https://www.winnipegfreepress.com/local/class-action-lawsuit-alleges-nygard-sexually-assaulted-10-women-at-island-estate-567860452.html
                        </li>
                        <br></br>
                        <li>
                          Peter Nygard, Canadian clothing manufacturer, accused
                          of raping 10 women and girls in class-action lawsuit |
                          CBC News. CBC. (2020). Retrieved 31 December 2020,
                          from
                          https://www.cbc.ca/news/world/peter-nygard-class-action-lawsuit-1.5463684.
                        </li>
                        <br></br>
                        <li>
                          How a Neighbors’ Feud in Paradise Launched an
                          International Rape Case. Nytimes.com. (2020).
                          Retrieved 31 December 2020, from
                          https://www.nytimes.com/2020/02/22/world/americas/peter-nygard-louis-bacon.html#:~:text=Only%20those%20who%20were%20young,her%20connection%20with%20Nygard%20Cay.
                        </li>
                        <br></br>
                        <li>
                          DiCello Levitt Gutzler Announces: Fashion Mogul Peter
                          Nygard Accused of Sex Trafficking and Rape Involving
                          Minors in Class Action Filed by 10 of His Victims |
                          Financial Post. Financialpost.com. (2020). Retrieved
                          31 December 2020, from
                          https://financialpost.com/pmn/press-releases-pmn/business-wire-news-releases-pmn/dicello-levitt-gutzler-announces-fashion-mogul-peter-nygard-accused-of-sex-trafficking-and-rape-involving-minors-in-class-action-filed-by-10-of-his-victims.
                        </li>
                        <br></br>
                        <li>
                          Izri, T. (2020). Sons of Peter Nygard file lawsuit,
                          allege he paid a sex worker to rape them as teenagers.
                          Winnipeg. Retrieved 31 December 2020, from
                          https://winnipeg.ctvnews.ca/sons-of-peter-nygard-file-lawsuit-allege-he-paid-a-sex-worker-to-rape-them-as-teenagers-1.5067604?cache=pawumraq%3FclipId%3D104070.
                        </li>
                        <br></br>
                        <li>
                          Peter Nygard’s sons accuse Canadian fashion mogul of
                          arranging for their rape as teens. Global News.
                          (2020). Retrieved 31 December 2020, from
                          https://globalnews.ca/news/7280452/peter-nygards-sons-accuse-canadian-fashion-mogul-of-arranging-for-their-rape-as-teens/.
                        </li>
                        <br></br>
                        <li>
                          Model, talent agency among Peter Nygard
                          co-conspirators named in new lawsuits | CBC News. CBC.
                          (2020). Retrieved 31 December 2020, from
                          https://www.cbc.ca/news/canada/manitoba/peter-nygard-lawsuit-bahamas-wfpcbc-cbc-1.5834324.
                        </li>
                        <br></br>
                        <li>
                          Manitoba RCMP officers arrest Peter Nygard for
                          extradition to the US | Royal Canadian Mounted Police.
                          Rcmp-grc.gc.ca. (2020). Retrieved 31 December 2020,
                          from
                          https://www.rcmp-grc.gc.ca/en/news/2020/manitoba-rcmp-officers-arrest-peter-nygard-extradition-the.
                        </li>
                        <br></br>
                        <li>
                          Peter Nygard applies for bail 2 weeks after arrest on
                          U.S. sex trafficking charges. Global News. (2021).
                          Retrieved 2 January 2021, from
                          https://globalnews.ca/news/7550280/peter-nygard-sex-trafficking-bail/.
                        </li>
                        <br></br>
                        <li>
                          Ransomware attack strikes Nygard IT systems on Dec.
                          12, receiver company assessing impact | CBC News. CBC.
                          (2021). Retrieved 2 January 2021, from
                          https://www.cbc.ca/news/canada/manitoba/peter-nygard-bail-ransomware-attack-1.5859131.
                        </li>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Nygard;
