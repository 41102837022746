import React from "react";
import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/ArasAgalarov.png";


// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";
// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton
} from "react-share";


class Aras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };    
   } 
  render() {
    
    return (
      <>
       
        <div className="wrapper">
          
        <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
              
            <Container className="content">
            <Row>
          <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                      <img
                        alt="..."
                        className="avatar"
                        src={image}
                      />
                      <h4 className="title">Aras Agalarov</h4>
                    {/* </a> */}
                    <p className="description">Billionaire</p>                  
                  <Button  color="primary"id="contents" size="sm">
                    Contents {''}
                    <i className="tim-icons icon-bullet-list-67" />
                  </Button>                 
                  <UncontrolledCollapse toggler="#contents" style={{textAlign:'left', marginBottom: '1rem'}}>
                      <br></br>
                      <ol>
                        <li>Introduction</li>
                      </ol> 
                </UncontrolledCollapse>
                  <Button  color="primary" id="connections" size="sm" >
                    Connections {''}
                    <i className="tim-icons icon-vector" />
                  </Button>
                  <UncontrolledCollapse toggler="#connections" style={{textAlign:'left', marginBottom: '1rem'}}>            
                  <br></br>
                  <ul>
                  <Link to='Donald-Trump'><li>Donald Trump</li></Link>
                  <li>Vladimir Putin</li>
                  </ul>
                <h4>Family Of:</h4>
                <ul>
                <Link to='Emin-Agalarov'><li>Emin Agalarov</li></Link>
                <li>Irina Agalarov</li>
                <li>Sheila Agalarov</li>
                </ul>
                  <h4>Owner / Founder Of:</h4>
                  <ul>
                      <li>Group Crocus Group</li>          
                  </ul>
                </UncontrolledCollapse>
                  <div>
                </div>
                </div>
                
                </CardBody>
                <CardFooter>
                <div className="button-container">
                <h5>Share:</h5>        
                <FacebookShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="facebook">
                  <i className="fab fa-facebook" />
                </Button>
                </FacebookShareButton>
                <TwitterShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="twitter">
                  <i className="fab fa-twitter" />
                </Button>
                </TwitterShareButton>
                <RedditShareButton
                  text={'hello world'}
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="reddit">
                  <i className="fab fa-reddit-alien" />
                </Button>
                </RedditShareButton>                
                </div>  

                </CardFooter>
              </Card> 
              
                <Alert color="danger">
               LATEST UPDATE NOVEMBER 25 Introduction
                </Alert>
                
            
            </Col>
            <Col md="8">
            

              <Card>
                <CardBody>
                  <h1 class="typography-line">Aras Agalarov</h1>
                  
                  <h2> Introduction </h2>
                    <p>
                    <p>Aras Agalarov is a billionaire, real estate developer, public figure, and author of Russia: Reflections on the Way to the Market (1998).  He has extensive connections with transnational organized crime, the Kremlin, and top politicians, including Russian President Vladimir Putin and US President Donald Trump. The United States Senate Intelligence Committee found in 2020 that Agalarov has significant ties to Russian organized crime. [1] As of November 2020, he had an estimated net worth of $1.7 billion, most of which is allegedly derived from his real estate ventures. [2]</p>
                    </p>
                    <br></br>
                  <br></br>     
              </CardBody>
              <CardFooter>
                <h4>Citations</h4>
              <ol>
              <li>Aras Agalarov. Forbes. (2020). Retrieved 26 November 2020, from https://www.forbes.com/profile/aras-agalarov/?sh=7411aa592133.</li>
              <br></br>
              <li>Choma, R. (2020). We asked finance experts to explain Trump’s odd business methods in Scotland. They were mystified.. Mother Jones. Retrieved 26 November 2020, from https://www.motherjones.com/politics/2020/11/we-asked-finance-experts-to-explain-trumps-odd-business-methods-in-scotland-they-were-mystified/.</li> 
              </ol>
              </CardFooter>
                     
              </Card>
            </Col>           
          </Row>
            
            </Container>       
          
            </div>

        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
      </>
    );
  }
}

export default Aras;



