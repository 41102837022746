import React from "react";
// import { Link } from "react-router-dom";

import image from "../../../../../../assets/img/profile/DonaldTrump.png";
import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Trump extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Donald John Trump</h4>
                        {/* </a> */}
                        <p className="description">Former US President</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Donald Trump and Fred Trump</li>
                            <li>
                              Trump, the Genovese, and Gambino Crime Families
                            </li>
                            <li>Trump, Trump air, and cocaine</li>
                            <li>
                              Trump, Trump Tower, the Russian mob, and the
                              Colombo Crime Family
                            </li>
                            <li>
                              Trump, Trump Tower, and Undocumented Immigrant
                              Exploitation{" "}
                            </li>
                            <li>Trump and the Casablancas rape network</li>
                            <li>Trump, KGB, and Ivana Trump</li>
                            <li>Trump Bankrupcy</li>
                            <li>Trump and Robert Maxwell</li>
                            <li>Trump and Epstein rape network</li>
                            <li>Trump and Prince Andrew 'The Duke of York</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul>
                            <li>Epstein's Little Black Book</li>
                            <li>Guccifer 2.0</li>
                            <li>Sheldon Adelson</li>
                            <li>Emin Agalarov</li>
                            <li>Aras Agalarov</li>
                            <li>Rinat Akhmetshin</li>
                            <li>Prince Andrew</li>
                            <li>Boris Alekseyevich Antonov</li>
                            <li>Tevfik Arif</li>
                            <li>Andriy Artemenko</li>
                            <li>Dzheykhun Nasimi Ogly Aslanov</li>
                            <li>Dmitry Sergeyevich Badin</li>
                            <li>Stephen Bannon</li>
                            <li>Elena Baronoff</li>
                            <li>William Barr</li>
                            <li>Tom Barrack</li>
                            <li>Fabien Baussart</li>
                            <li>Ricardo Bellino</li>
                            <li>Avraham Berkowitz</li>
                            <li>Len Blavatnik</li>
                            <li>Anna Vladislavovna Bogacheva</li>
                            <li>Maria Anatolyevna Bovda</li>
                            <li>Robert Sergeyevich Bovda</li>
                            <li>Mikhail Leonidovich Burchik</li>
                            <li>Maria Butina</li>
                            <li>Mikhail Ivanovich Bystrov</li>
                            <li>John Casablancas</li>
                            <li>Paul Castellano</li>
                            <li>Sam Clovis</li>
                            <li>Michael Cohen</li>
                            <li>Roy Cohn</li>
                            <li>James Comey</li>
                            <li>The Commission</li>
                            <li>Jerome Corsi</li>
                            <li>Rick Dearborn</li>
                            <li>Oleg Deripaska</li>
                            <li>Igor Diveykin</li>
                            <li>Kirill Dmitriev</li>
                            <li>Fedor Emilianenko</li>
                            <li>Gambino crime family</li>
                            <li>Philadelphia crime family</li>
                            <li>Genovese crime family</li>
                            <li>Colombo Crime Family</li>
                            <li>UK Royal Family</li>
                            <li>Dmitry Firtash</li>
                            <li>Michael Flynn Jr.</li>
                            <li>Michael Flynn</li>
                            <li>Mikhail Fradkov</li>
                            <li>Mikhail Fridman</li>
                            <li>Rick Gates</li>
                            <li>David Geovanis</li>
                            <li>Rick Gerson</li>
                            <li>Rudy Giuliani</li>
                            <li>Ricardo Samuel Goldstein</li>
                            <li>Rob Goldstone</li>
                            <li>J.D. Gordon</li>
                            <li>Sergei Gorkov</li>
                            <li>Salvatore "Sammy the Bull" Gravano</li>
                            <li>Herman Gref</li>
                            <li>Rudy Guiliani</li>
                            <li>Sean Hannity</li>
                            <li>Hope Hicks</li>
                            <li>Sergei Ivanov</li>
                            <li>Randa Kassis</li>
                            <li>Irakli Kaveladze</li>
                            <li>Irakly (“Ike”) Kaveladze</li>
                            <li>Irina Viktorovna Kaverzina</li>
                            <li>German Khan</li>
                            <li>Viktor Khrapunov</li>
                            <li>Elena Khusyaynova</li>
                            <li>Konstantin Kilimnik</li>
                            <li>Sergey Kislyak</li>
                            <li>Dmitry Klokov</li>
                            <li>Anatoliy Sergeyevich Kovalev</li>
                            <li>Nikolay Yuryevich Kozachek</li>
                            <li>Igor Krutoy</li>
                            <li>Aleksandra Yuryevna Krylova</li>
                            <li>Jared Kushner</li>
                            <li>Phillip “crazy Phil” Leonetti</li>
                            <li>Aleksey Vitorovich Lukashev</li>
                            <li>Ted Malloch</li>
                            <li>Artem Andreyevich Malyshev</li>
                            <li>Paul Manafort</li>
                            <li>Elite Model Management</li>
                            <li>Gerald Marie</li>
                            <li>K.T. McFarland</li>
                            <li>Stephen Miller</li>
                            <li>Sergei Millian</li>
                            <li>Sergey Aleksandrovich Morgachev</li>
                            <li>George Nader</li>
                            <li>Viktor Borisovich Netyshko</li>
                            <li>Devin Nunes</li>
                            <li>Aleksandr Vladimirochich Osadchuk</li>
                            <li>Carter Page</li>
                            <li>George Papadopoulos</li>
                            <li>Brad Parscale</li>
                            <li>Sam Patten</li>
                            <li>Michael Pence</li>
                            <li>Dmitry Peskov</li>
                            <li>Richard Pinedo</li>
                            <li>Vadim Vladimirovich Podkopaev</li>
                            <li>Sergey Pavlovich Polozov</li>
                            <li>Aleksey Aleksandrovich Potemkin</li>
                            <li>Reince Priebus</li>
                            <li>Yevgeniy Viktorovich Prigozhin</li>
                            <li>Erik Prince</li>
                            <li>Vladimir Putin</li>
                            <li>Leonid Reshetnikov</li>
                            <li>Dana Rohrabacher</li>
                            <li>Andrey Rozov</li>
                            <li>Giorgi Rtskhiladze</li>
                            <li>Dmitry Rybolovlev</li>
                            <li>Anthony "Fat Tony" Salerno</li>
                            <li>Alex Sapir</li>
                            <li>Tamir Sapir</li>
                            <li>Felix Sater</li>
                            <li>elix Sater</li>
                            <li>Nicodemo "Little Nicky" Scarfo</li>
                            <li>Igor Sechin</li>
                            <li>Jeff Sessions</li>
                            <li>Kenneth Shapiro</li>
                            <li>Peter Smith</li>
                            <li>John Staluppi</li>
                            <li>Jill Stein</li>
                            <li>Roger Stone</li>
                            <li>Daniel Sullivan</li>
                            <li>Salvatore 'Salvie' Testa</li>
                            <li>Rex Tillerson</li>
                            <li>Alexander Torshin</li>
                            <li>Alex van der Zwaan</li>
                            <li>Gleb Igorevich Vasilchenko</li>
                            <li>Viktor Vekselberg</li>
                            <li>Vladimir Venkov</li>
                            <li>Natalia Veselnitskaya</li>
                            <li>Joseph Weichselbaum</li>
                            <li>Jeffrey Epstein</li>
                            <li>Franklin Weichselbaum</li>
                            <li>Allen Weisselberg</li>
                            <li>Viktor Yanukovych</li>
                            <li>Ivan Sergeyevich Yermakov</li>
                            <li>Pavel Vyacheslavovich Yershov</li>
                            <li>Paolo Zampolli</li>
                          </ul>
                          <h4>Family Of:</h4>
                          <ul>
                            <li>Maryanne Trump Barry</li>
                            <li>Elizabeth Trump Grau</li>
                            <li>Marla Maples</li>
                            <li>Donald Trump Jr</li>
                            <li>Mary Anne MacLeod Trump</li>
                            <li>Donald Trump</li>
                            <li>Eric Trump</li>
                            <li>Melania Trump</li>
                            <li>Blaine Trump</li>
                            <li>Ivanka Trump</li>
                            <li>Ivana Trump</li>
                            <li>Barron Trump</li>
                            <li>Tiffany Trump</li>
                            <li>Frederick Trump</li>
                            <li>Fred Trump</li>
                            <li>Robert Trump</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Mar-a-Lago</li>
                            <li>Trump Air</li>
                            <li>Trump Organization</li>
                            <li>Trump Plaza</li>
                            <li>Trump Models</li>
                            <li>Trump Realty Brazil</li>
                            <li>Trump Tower</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Latest Update: Janurary 21</h5>
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      <br></br>
                      
                  <hr></hr>
                      <a
                        rel="noreferrer"
                        href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
                      >
                        <img
                          alt="Creative Commons License"
                          src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"
                        />
                      </a>
                      <br />
                      <p className="text-center">This work is licensed under a{" "}
                      <a
                        rel="noreferrer"
                        href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
                      >
                        Creative Commons Attribution-NonCommercial-ShareAlike
                        4.0 International License
                      </a>
                      .</p>
                      </div>
                    </CardFooter>
                  </Card>

                </Col>

                <Col md="8">
                  {/* <Alert color="danger">
                    <CardBody>
                      <h5>
                        Like our work and want us to grow? please support us and
                        donate. Your donation to Spooky Connections 👻 is a
                        direct contribution to our research, developers, and
                        servers. With your support, we will continue to publish
                        new profiles, investigations, and uncover organized
                        crime networks.
                      </h5>
                    </CardBody>
                  </Alert> */}

                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Donald Trump</h1>
                      <h2>Introduction</h2>
                      <p>
                        Donald John Trump is the 45th and former president of
                        the United States, a business owner, and a former
                        television personality. He has extensive connections
                        with transnational organized crime, espionage and
                        several mob leaders. Trump and his businesses have been
                        involved in more than 4,000 state and federal legal
                        actions, including six bankruptcies. As of October 2020,
                        Trump is ranked 1,001st on Forbes's list of billionaires
                        with a net worth of $2.5 billion. [1]
                      </p>
                      <br></br>
                      <h2>Donald Trump and Fred Trump</h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Fred Trump</li>
                        <li>Genovese crime family</li>
                      </ul>
                      <p>
                        Trump was born in Queens, New York City. He attended
                        Fordham University for two years and received a
                        bachelor's degree in economics from the Wharton School
                        of the University of Pennsylvania. He became president
                        of his father Fred Trump's real estate business E. Trump
                        & Son in 1971 and renamed it around 1973 to The Trump
                        Organization. He expanded its operations to building or
                        renovating skyscrapers, hotels, casinos, and golf
                        courses. He later handed off its leadership on January
                        11, 2017, to his sons Donald Trump Jr. and Eric Trump
                        along with CFO Allen Weisselberg. [2] Donald Trump’s
                        father Fred Trump had worked closely with construction
                        entities associated and owned by the Genovese crime
                        family since building the Shore Haven development in
                        1947. Every construction partner and union Fred Trump
                        used to build his real estate empire, per public
                        records, was owned and controlled by the Genovese crime
                        family as was the territory in which Fred Trump built.
                        [3]
                      </p>

                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CJ_7Tj_j4Fd/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <h2>Trump, the Genovese, and Gambino Crime Families</h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Roy Cohn</li>
                        <ul>
                          <li>S & A Concrete</li>
                        </ul>
                        <li>Genovese crime family</li>
                        <ul>
                          <li>“Fat Tony” Salerno</li>

                          <li>HRH Construction Corp</li>
                        </ul>
                        <li>Gambino crime family</li>
                        <ul>
                          <li>Paul Castellano</li>
                        </ul>
                        <li>Trump property</li>
                        <ul>
                          <li>Trump Tower</li>
                          <li>Trump Plaza</li>
                        </ul>
                      </ul>

                      <p>
                        In 1973, Donald Trump met Roy Cohn, the lawyer and mob
                        consigliere fixer, at Le Club, a members-only East Side
                        hangout. He befriended Cohn, who had become famous as
                        lead counsel to Senator Joseph McCarthy. Cohn’s client
                        list included clients Anthony “Fat Tony” Salerno, boss
                        of the Genovese crime family, the most powerful Mafia
                        group in New York at the time, and Paul Castellano, head
                        of what was said to be the second-largest family, the
                        Gambinos. [4]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CJ_7nQyjJL4/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <p>Donald Trump ↔️ Roy Cohn</p>
                      <br></br>
                      <p>
                        Donald Trump wanted to expand his business to Manhattan
                        and build Trump Tower and his Trump Plaza apartment. He
                        recalled in a 2016 US election campaign Town Hall, "Even
                        my father he said you don't want to go to Manhattan.
                        That's not our territory." [5] At the time, that area
                        was controlled by the Gambino crime family. [6] Roy Cohn
                        facilitated a solution and created a joint construction
                        company called S & A Concrete between Anthony "Fat Tony"
                        Salerno, Genovese boss, and Paul Castellano, Gambino
                        boss while giving Genovese companies HRH Construction
                        Corp priority where possible. [7] According to a former
                        Cohn employee, Trump and the Genovese boss Anthony "Fat
                        Tony" Salerno met face-to-face at Cohn's townhouse. [8]
                        A US federal investigation concluded that the Trump
                        Plaza apartment building most likely benefited from
                        connections to racketeering. Anthony "Fat Tony" Salerno
                        was later indicted on racketeering charges. An $8
                        million deal for concrete at Trump Plaza was listed as
                        one of the acts establishing that S & A Concrete was
                        part of a racketeering enterprise. Anthony "Fat Tony"
                        Salerno was sent to prison where he died alone from a
                        stroke. [9]
                      </p>
                      <br></br>
                      <h2>Trump, Trump Plaza, the Philadelphia crime family</h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Daniel Sullivan</li>
                        <li>Elliot Goldberg</li>
                        <li>Philadelphia crime family</li>
                        <ul>
                          <li>Kenneth Shapiro</li>
                          <li>Nicodemo "Little Nicky" Scarfo</li>
                          <li>Salvatore "Salvie" Testa</li>
                          <li>Phillip “Crazy Phil” Leonetti</li>
                          <li>Scarf, Inc.</li>
                          <li>Nat Nat</li>
                        </ul>
                        <li>Trump property</li>
                        <ul>
                          <li>Trump Plaza</li>
                          <li>Le Bistro</li>
                        </ul>
                      </ul>
                      <br></br>
                      <p>
                        In the early 1980s Trump went into business with SSG
                        Inc, a drywall manufacturing business, with Daniel
                        Sullivan, Kenneth Shapiro, and Elliot Goldberg [10],
                        which was "among the firms implicated in a racketeering
                        scheme involving the carpenters' union and the Genovese
                        crime family" represented by Roy Cohn. Daniel Sullivan
                        "dealt with labor problems at Trump's construction
                        sites” and introduced Trump into an Atlantic City
                        land-leasing deal with Kenneth Shapiro also an SSG, Inc
                        partner, whom law enforcement authorities had identified
                        as a financier and agent for Philadelphia Crime Family
                        boss Nicodemo "Little Nicky" Scarfo. [11]
                      </p>
                      <br></br>
                      <p>
                        To enlarge Trump Plaza, Donald Trump bought 'Le Bistro'
                        nightclub that was owned by Salvatore 'Salvie' Testa, a
                        chief enforcer and veteran hitman of the Philadelphia
                        crime family, and Frank Narducci Jr, a Capo of the
                        Philadelphia crime family. They were mob hitmen known as
                        the Young Executioners, Testa was murdered in 1984 weeks
                        after a dispute with mob boss Nicky Scarfo and Narducci,
                        who transferred his stake in the club to Testa in 1982,
                        was sentenced to life in prison in 1989 for his role in
                        a mob murder. The two worked for Atlantic City’s mob
                        boss Nicodemo “Little Nicky” Scarfo. Donald Trump bought
                        the 5,000 square-foot nightclub lot that had been bought
                        five years earlier for $195,000 for $1.1 million, more
                        than twice its market value. [12] Nicademo “Little
                        Nicky” Scarfo and his nephew Phillip “Crazy Phil”
                        Leonetti controlled two of the major construction and
                        concrete companies in Atlantic City. Both companies,
                        Scarf, Inc. and Nat Nat, did work on the construction of
                        Harrah’s, according to the State of New Jersey
                        Commission of Investigation’s 1986 report on organized
                        crime. [13] Also, Scarfo controlled the bartenders
                        union, which represented Trump’s workers in Atlantic
                        City. [14]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CJ_8NlLjg2J/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>Donald Trump ↔️ Dan Sullivan</p>
                      <br></br>
                      <h2>
                        Trump, Trump Tower, Undocumented Immigrant Exploitation
                      </h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Daniel Sullivan</li>
                        <li>Genovese crime family</li>
                        <ul>
                          <li>House Wreckers Union Local 95</li>
                        </ul>
                        <li>Trump property</li>
                        <ul>
                          <li>Trump Tower</li>
                        </ul>
                      </ul>
                      <br></br>
                      <p>
                        In 1979, when Trump hired a demolition contractor to
                        take down the Bonwit Teller department store to make way
                        for Trump Tower, he hired non-union men to work
                        alongside the House Wreckers Union Local 95, the union,
                        ran mainly by the Genovese crime family, which hired a
                        large swath of nonunion, undocumented Polish and South
                        Korean demolition workers to work alongside its union
                        members. [15]
                      </p>
                      <br></br>
                      <p>
                        Daniel Sullivan was a labor activist and mafia informant
                        for the FBI and he dealt with labor problems at Trump's
                        construction sites. In June 1980, Trump confided in
                        Daniel Sullivan about a problem he was having at a
                        construction site on Manhattan’s East Side where he was
                        tearing down the Bonwit Teller building to make way for
                        Trump Tower. Trump told Sullivan that his demolition
                        subcontractor was relying on illegal Polish workers and
                        that the workers were unhappy about their nonunion wages
                        and hours. Trump worried that the building was not
                        coming down quickly enough and that he faced heavy real
                        estate taxes if the demolition was not finished soon.
                        [16]
                      </p>

                      <br></br>
                      <p>
                        The Polish workers were paid only $4 per hour, far below
                        union wages, and they were working seven days a week in
                        12-hour shifts, sometimes longer, court documents show.
                        The Polish immigrants were required to use jackhammers
                        and wheelbarrows to take down the building by hand.
                        Sullivan testified against Trump during a civil lawsuit
                        brought forward by the workers, he had warned Trump
                        about the implications of using illegal immigrants and
                        going against the union contract. In the late 1980s
                        Sullivan testified that he had told Trump, “Don’t
                        exploit them like that. . . . Don’t try to f--- these
                        poor souls over.” [17] “They were undocumented and
                        worked ‘off the books,’” Manhattan federal Judge Charles
                        Stewart said of the workers after they became the
                        subject of a 1983 lawsuit. “No records were kept, no
                        Social Security or other taxes were withheld. [18]{" "}
                      </p>
                      <br></br>
                      <h2>Trump, Trump air, and cocaine </h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Maryanne Trump Barry</li>
                        <li>Weichselbaum crime fammily</li>
                        <ul>
                          <li>Joseph Weichselbaum</li>
                          <li>Franklin Weichselbaum</li> <li>Damin Aviation</li>
                          <li>Nimad</li>
                          <li>American Business Aviation</li>
                        </ul>

                        <li>Trump Property</li>
                        <ul>
                          <li>Trump Air</li>
                          <li>Trump Castle</li>
                          <li>Trump Plaza</li>
                        </ul>
                      </ul>
                      <br></br>
                      <p>
                        Donald Trump was close friends with Joseph Weichselbaum,
                        a drug smuggler who in 1979 had been convicted of felony
                        embezzlement and felony car theft. Joseph Weichselbaum
                        was hired to maintain Trump’s private helicopter a black
                        Eurocopter AS332 Super Puma named Ivana and manage a
                        helicopter service Damin Aviation owned by him and his
                        brother Franklin. They were getting paid more than $2
                        million a year for ferrying high rollers to and from
                        Trump's casinos. [19]
                      </p>
                      <br></br>
                      <p>
                        Joseph Weichselbaum lived in an apartment in Trump Plaza
                        when he was indicted for heading a Colombia-Miami-Middle
                        U.S. cocaine ring in 1985 and pleaded guilty to federal
                        charges of cocaine smuggling in 1986. The 1985
                        indictment alleged that he ran a narcotics operation
                        from Miami, where he would buy kilos of cocaine from two
                        Colombian brothers, Francisco and Walter Ramirez, which
                        he would then sell to smaller dealers in the parking lot
                        of a car dealership owned by Franklin Weichselbaum. The
                        drugs would be distributed throughout Ohio, Kentucky,
                        and North Carolina, prosecutors alleged. [20]
                      </p>
                      <br></br>
                      <p>
                        The head of one of Trump’s casinos was notified of the
                        indictment in October 1985, but Donald Trump continued
                        employing Weichselbaum; Just two months later Trump
                        rented an apartment he owned in the Trump Plaza
                        apartment building in Manhattan to Joseph Weichselbaum
                        and his brother Franklin for $7000 a month with half of
                        it paid in bartered helicopter services. [21] Trump also
                        continued paying Weichselbaum’s helicopter firm $180,000
                        a month (Trump Plaza paid $100,000 per month and Trump's
                        Castle paid $80,000 a month) even after it went bankrupt
                        twice renaming itself Nimad (Damin spelled backward) the
                        first time and American Business Aviation the second.
                        The unusual paymements continued was despite Trump
                        having purchased three helicopters of his own, to form
                        Trump Air. [22]
                      </p>
                      <br></br>
                      <p>
                        The Weichselbaum cocaine trafficking case was initially
                        prosecuted in Ohio but moved to New Jersey for unknown
                        reasons and then assigned to federal Judge Maryanne
                        Trump Barry. After three weeks Judge Barry recused
                        herself, explaining to the chief judge that her husband,
                        Trump casino lawyer John Barry, and she had been flown
                        in Weichselbaum’s helicopters.[23] The case was swiftly
                        reassigned to Judge Harold Ackerman. In 1987, Donald
                        Trump sent a letter of reference to Newark federal court
                        Judge Harold Ackerman vouching for Weichselbaum before
                        his sentencing, writing that the drug trafficker is “a
                        credit to the community” who was “conscientious,
                        forthright, and diligent.” [24] Weichselbaum got only
                        three years, serving 18 months before he was released
                        from the urban prison that the Bureau of Prisons
                        maintains in New York City and moved to his new home in
                        Trump Tower. [25] Judge Harold Ackerman later oversaw a
                        separate cocaine smuggling case in 1988 where 20 alleged
                        members of the Lucchese crime family were acquitted.
                        [26]
                      </p>
                      <br></br>
                      <p>
                        Weichselbaum’s girlfriend bought two adjoining
                        apartments at Trump Tower for $2.4 million in cash,
                        while he was in prison and left no public record of the
                        transaction or where the money originated from. [27]
                        When Trump was asked in 1990 by casino officials about
                        his letter to the judge on Joseph Weichselbaum’s behalf,
                        Donald Trump claimed that he could not remember having
                        written it. [28] Trump and Weichselbaum were so close,
                        that Weichselbaum told his parole officer, long before
                        it was publicly revealed, about how he knew Trump was
                        hiding his then mistress, Marla Maples, from his first
                        wife, Ivana Trump, and tried to persuade Trump to end
                        their years-long affair. [29]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CJ_9ALlDFRL/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />

                      <p>Donald Trump ↔️ Joseph Weichselbaum</p>
                      <br></br>
                      <p>
                        One high roller, referring to the Trump Plaza and Trump
                        Castle, told Spy in 1991, “Sure, I was offered coke
                        gambling down there,” and a Trump Castle employee
                        confirmed that VIPs were supplied with cocaine at
                        parties, “It happened all the time.” [30] From 1988
                        until 1992 Donald Trump hosted cocaine-fueled parties
                        featuring trafficked women and children as young as 15.
                        A photographer who attended some of those parties said
                        that the models were groomed with promises “you’ll meet
                        rich guys who will help you.” According to the witness
                        “There were always dramas because the men threw money
                        and drugs at them to keep them enticed. It’s based on
                        power and dominating girls who can’t push back and can
                        be discarded. There’s always someone to pick them back
                        up. Nobody wants to call home and say ‘Help me.’” [31]
                        Trump would allegedly wander through the parties
                        enjoying himself. “Trump was in and out. He’d wander off
                        with a couple of girls. I saw him. He was getting laid
                        like crazy,” Andy Lucchesi, a male model who allegedly
                        organized the cocaine parties, later confessed that
                        there were “a lot of girls, 14, look 24. That’s as juicy
                        as I can get. I never asked how old they were; I just
                        partook.” [32]
                      </p>
                      <br></br>
                      <h2>
                        Trump, Trump Tower, the Russian mob, and the Colombo
                        Crime Family
                      </h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Russian Mob</li>
                        <ul>
                          <li>David Bogatin</li>
                          <li>Jacob Bogatin</li>
                          <li>Simon Mogilevich</li>
                        </ul>
                        <li>Colombo crime family</li>
                        <ul>
                          <li>Michael Franzese</li>
                          <li>Carmine "The Snake" Persico</li>
                          <li> Salvatore " Sammy the Bull" Gravano</li>
                          <li>John Rosatti</li>
                          <li>John Staluppi</li>
                          <li>Dllinger Charter Services</li>
                          <li>Millennium Super Yachts</li>
                        </ul>
                        <li>US Politicans</li>
                        <ul>
                          <li>Tom Cotton</li>
                          <li>Mitch McConnell</li>
                          <li>Cory Gardner</li>
                          <li>Ron DeSantis</li>
                          <li>George W. Bush</li>
                          <li>Republican National Committee</li>
                        </ul>
                        <li>Trump Property</li>
                        <li>Trump Tower</li>

                        <li>Trump Make America Great Again Committee</li>
                      </ul>
                      <p>
                        In 1984, former Soviet Army pilot and a leading member
                        of the New York Russian mob David Bogatin, bought five
                        luxury condos in Trump Tower for $6 million. At the
                        time, Russian mobsters were investing in high end New
                        York real estate for money laundering purposes, and
                        Trump Tower was accepting anonymous buyers. [33]
                        Bogatin's brother Jacob (Yacov) Bogatin had run a $150m
                        stock scam with Russian mob boss Simon Mogilevich. In
                        1987, Bogatin pleaded guilty to “evading millions of
                        dollars in state fuel taxes in what state officials
                        called one of the largest gasoline bootlegging
                        operations in the nation” and claimed was connected to
                        the Colombo crime family. [34] The luxury condos in
                        Trump Towers were seized by the U.S. government,
                        claiming that he had used the purchase to launder money
                        and was part of a Gas cartel operated under the
                        direction of Michael Franzese, a reputed captain of the
                        Colombo crime family. [35]
                      </p>
                      <br></br>
                      <p>
                        In 1988, Donald Trump did his first personal branding
                        name-licensing deal with a luxury limo rental company
                        owned by John Staluppi who was a business partner of
                        John Rosatti. At the time John Staluppi went into
                        business with Trump he had been under investigation by
                        U.S. law enforcement for over a decade and investigators
                        followed him to meetings with Colombo family boss
                        Carmine "The Snake" Persico. [36] Staluppi also operated
                        a company called Dillinger Charter Service alongside
                        Trump’s pilot Joseph Weichselbaum, which supplied
                        helicopters to the Trump Organization.[37] Staluppi and
                        Rosatti started a luxury yacht business called
                        Millennium Super Yachts, buying custom-built high-speed
                        megayachts, renaming them after James Bond films, and
                        selling them after a couple of years for a profit,
                        considered unusual since new yachts are generally more
                        valuable than used ones. [38]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKAI-3uDTp6/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <p>Donald Trump ↔️ John Staluppi ↔️ John Rosatti </p>
                      <br></br>

                      <p>
                        In 2020, according to US Federal Election Commission
                        records, John Staluppi and John Rosatti, formerly of the
                        Colombo crime family donated at least $40,000 to Donald
                        Trump’s re-election campaign. John Staluppi has made a
                        pair of $1000 donations to the Trump Make America Great
                        Again Committee, a joint fundraising operation
                        benefitting Donald J. Trump for President, Inc. and the
                        Republican National Committee. Staluppi has also donated
                        $580 directly to the RNC. John Staluppi’s wife gave
                        $35,000 to Trump Victory, a joint committee whose
                        fundraising principally benefits Trump’s reelection
                        committee, and the RNC. The John Staluppi contribution
                        corresponds with a private Florida fundraiser attended
                        by Trump that costs $35,000 per couple for a photo with
                        the president.[39] US Federal Election Commission
                        records show John Rosatti donated $1000 to the Trump
                        Make America Great Again Committee. FEC records show
                        that the couple has donated to multiple state Republican
                        organizations, as well as officeholders like Tom Cotton,
                        Mitch McConnell, Cory Gardner, Ron DeSantis, and George
                        W. Bush.The bulk of political contributions made by
                        Rosatti and Staluppi came after the pair moved to Palm
                        Beach Gardens, Florida, where they live in waterfront
                        mansions, blocks from each other. [40]
                      </p>
                      <br></br>
                      <p>
                        In 2016, Donald Trump received an endorsement from
                        former Gambino family underboss, originally a soldier of
                        the Colombo crime family, Salvatore " Sammy the Bull"
                        Gravano. He is known as the man who helped bring down
                        John Gotti, the family's boss, by agreeing to become a
                        Federal Bureau of Investigation (FBI) government
                        witness. Gravano played a key role in planning and
                        executing Castellano's murder; other conspirators
                        included John Gotti, Angelo Ruggiero, Frank DeCicco, and
                        Joseph Armone. After Castellano's death, Gotti elevated
                        Gravano to consigliere, a position he held until he
                        became underboss in 1990 around the time he became a
                        government witness. Gravano stated that the United
                        States "doesn't need a bookworm as president, it needs a
                        mob boss." [41] Salvatore Gravano insinuated a
                        relationship with Trump during a 1997 interview with
                        ABC's Diane Sawyer. "I literally marvel at the sight of
                        Manhattan when I see it, because I controlled it,"
                        Gravano said. "I literally controlled Manhattan. When I
                        see it at night — those lights and everything about it —
                        I think of Donald Trump and Tishman and everybody else
                        who couldn't build a building if I didn't want them to
                        build it. That got me off. Plus, I made a lot of money
                        with it." [42]
                      </p>
                      <br></br>

                      <h2>Trump and Casablancas rape network</h2>
                      <h4>Connections:</h4>
                      <p>John Casablancas</p>
                      <p>Roy Cohn</p>
                      <p>Studio 54</p>
                      <p>Steve Rubell</p>
                      <p>Ian Schrager</p>
                      <p>Michael Gross</p>
                      <p>Elite Model Management</p>
                      <p>Trump Model Management</p>
                      <p>Elite Europe</p>
                      <p>Trump Realty Brazil</p>
                      <p>Ivanka Trump</p>
                      <p>Gerald Marie</p>
                      <p></p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKAMaeBj49v/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <p>Donald Trump ↔️ John Casablancas </p>
                      <br></br>
                      <p>
                        Donald Trump and John Casablancas were introduced at
                        some point during the mid ’70s through Roy Cohn. [43]
                        Trump gained access to Manhattan’s mobbed up and drug
                        infested social scene through Roy Cohn who was the legal
                        advisor and attorney to Steve Rubell and Ian Schrager,
                        the owners of Studio 54 where Trump would spend much of
                        the 1970s. They would later be sentenced to 3 ½ years of
                        prison for tax evasion. [44] According to Trump, John
                        Casablancas tutored him in the “modeling agency”
                        business. During this period, Trump engaged in such
                        excess of debauchery that he deemed it “my second
                        business” according to author Michael Gross. According
                        to Donald Trump during an interview with Michael Gross,
                        “You had drugs, women, and booze all over the f--kin’
                        place ... If I hadn’t got married, who knows what would
                        have happened? ... I don’t think anybody had more sex
                        than I did. Sex was all over the fucking place ... I
                        would watch supermodels getting screwed, well-known
                        supermodels getting screwed, on a bench in the middle of
                        the room. There were seven of them and each one was
                        getting screwed by a different guy.” [45]
                      </p>
                      <br></br>
                      <p>
                        John Casablancas, was a prolific child rapist,
                        trafficker, and founder of Elite Model Management. He
                        had a reputation for using his influence to abuse,
                        coerce, and/or rape aspiring models, including children,
                        and was openly embraced by the New York social scene.
                        [46] He openly admitted that he often raped children,
                        “that was the business I was in ... many of us are
                        fascinated by the idea of a woman-child, like Brooke
                        Shields in the movie Pretty Baby.” Shields played a
                        child rape trafficking victim being sold to adult men.
                        [47] Ivanka Trump, age 15, became a model at Elite Model
                        Management directly under the management of John
                        Casablancas. Monica Pillard, the president of Elite, had
                        been eyeing Ivanka since she spotted her on Donald
                        Trump’s lap, when Trump was a judge of a new-talent
                        contest for Elite, the agency often held events at Trump
                        Tower. Pillard served as a judge in the Miss Universe
                        pageant, which was jointly owned by Donald Trump and
                        CBS, as was the Miss Teen USA pageant. [48]
                      </p>
                      <br></br>
                      <p>
                        Donald Trump was often spotted at Elite Model Management
                        parties as far back as 1982. Trump hosted events for
                        Elite’s modeling contests, provided lodging for the
                        contestants, and at times acted as a contest judge
                        alongside John Casablancas, Gerald Marie, and others.
                        According to a witness, Patty Owen, an Elle and
                        Cosmopolitan cover star, “He would always be at the bar.
                        That’s where he would stay and that’s where all the new
                        models would hang out ... Whenever I saw him, I was
                        always like: why does John have to invite him?”.
                        According to another witness Barbara Pilling, an Elite
                        model, Donald Trump asked her out for dinner in the
                        summer of 1989. She recalls Trump asking how old she
                        was. “I said 17 and he said, ‘That’s just great – you’re
                        not too old, not too young.’” She also witnessed seeing
                        girls aged 14, 15 years old from Europe. [49]
                      </p>
                      <br></br>
                      <p>
                        In 1999, Trump started a modeling agency, Trump Model
                        Management later shorten to T-Models, cloned after John
                        Casablanca's own Elite Model Management. [50] In 2000,
                        Casablancas retired from the Elite Model Management,
                        after then-president of Elite Europe, Gerald Marie, was
                        featured in a BBC exposé grooming a young model and was
                        later accused of rape by several models. [51] Marie
                        Anderson Boyd, who was an agent and vice president at
                        the Elite Model Management’s Chicago office between 1985
                        and 1990, said models would regularly report sexual
                        misconduct they experienced on go-sees. She never passed
                        the reports to her managers at Elite, in part because
                        executives like John Casablancas and Gerald Marie also
                        engaged. “They established a culture of compliance with
                        sexually predatory behavior ... That trickled down into
                        everything everybody did.” [52] A 2012 study by Model
                        Alliance, found that 52% of models are rarely or never
                        accompanied by guardians to a shoot, even though the
                        majority of models start working between age 13 - 16,
                        and 50% are exposed to cocaine.[53] Anderson Boyd told
                        New York Magazine that she remembered watching two
                        female executives plead with Gerald Marie and John
                        Casablancas to stop raping children. They responded “We
                        are men. We have our needs.” [54] In 2004, Elite filed
                        for Chapter 11 bankruptcy protection after two lawsuits
                        were brought against it by a group of former models and
                        employees [55], it was bought by Creative World, which
                        is owned by Eddie and Jules Trump. No relation to Donald
                        Trump, the Trumps outbid ID Models boss Paolo Zampolli
                        and child trafficker Jeffrey Epstein.[56]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKASf0sDs16/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        Teenage Elite models in the late 80s and early 90s were
                        required to attend private dinners with Donald Trump,
                        John Casablancas, and other men. Heather Braden, an
                        Elite model, in the late 90s, alleges that she was
                        instructed to go to a party in a mansion on one of the
                        islands near Miami Beach where she witnessed Donald
                        Trump and three famous actors, who she refused to name,
                        prey on dozens of young models isolating many into
                        bedrooms or other parts of the mansion, “Clearly, we
                        were there for one reason. We were just pieces of
                        meat.”. According to witness Shayna Love, an Australian
                        model who was 16, “It was presented as our duty as
                        models at the agency. It wasn’t an invitation. It was
                        like, you have to go and do this.” Love recalls
                        attending a dinner with John Casablancas and Donald
                        Trump “This time it was a private area, a big table and
                        lots of girls – I’d say around 10 to 15 of us, all
                        between the ages of 14 and 18,” she said. “It was just
                        us models, Trump and John. We were all underage, but we
                        were offered drinks,” Love said she went home early, but
                        other children stayed. Eli Nessa, an Elite Model,
                        stated, “We were a bunch of kids, just put there with
                        all these older men.” [57]
                      </p>
                      <br></br>
                      <p>
                        Donald Trump later hired John Casablancas to be on the
                        board at Trump Realty Brazil, a $40 million project
                        aimed at the Brazilian wealthy elite, as a "lifestyle
                        consultant of sorts." [58] The project was pitched by
                        Ricardo Bellino with the help of John Casablancas and
                        they were partners alongside Ricardo Samuel Goldstein
                        the founder of Security Beverages Americas and Gold &
                        Bell. In 2007, Goldstein co-founded with John
                        Casablancas, the StarSystem model scouting project, and
                        previously assisted Ricardo Bellino and John Casablancas
                        in the implementation of Elite Models in Brazil. [59]
                        The Trump Realty Brazil project, dubbed Villa Trump was
                        Donald Trump's first foray into Latin America included
                        an 18-hole golf course, a luxury hotel, and spa, and up
                        to 500 memberships, 350 of which came with plots of land
                        for personal mansions and it never got built. [60]
                      </p>
                      <br></br>
                      <p>
                        To carry out the project, Bellino created the company
                        Trump Realty Brazil, of which he is the CEO, and
                        initially raised about 8 million dollars from partners,
                        mostly two of Brazil's wealthiest families the
                        Meyerfreunds, who made a fortune in the chocolate
                        business, and the Depieris, founders of the Brazilian
                        pharmaceutical giant Aché Laboratórios, among other
                        investors. Bellino when on to explain, “We bought the
                        rights to the Brazilian Golf Open and, at the time, we
                        partnered with Traffic for sports marketing”.[61]
                        Traffic Group is the company of Jose Hawilla, who became
                        one of the biggest sports marketing entrepreneurs in
                        Latin America and was at the center of the FBI
                        investigation that led to the arrest of FIFA officials
                        in 2015. [62] John Casablancas died of cancer in 2013.
                        [63]
                      </p>
                      <br></br>
                      <h2>Trump, KGB, and Ivana Trump</h2>
                      <p>
                        Donald Trump married Ivana Marie Trump, previously Ivana
                        Zelníčková, in 1977 and divorced her in 1992. [64] She
                        held key managerial positions at Trump Organization in
                        the capacities of vice president of interior design, CEO
                        and president of Trump's Castle casino resort, and
                        manager of Plaza Hotel. [65] He has three children with
                        Ivana Trump: Donald Trump Jr. (born December 31, 1977),
                        Ivanka Trump ( born October 30, 1981), and Eric Trump
                        (born January 6, 1984).[66] Ivana Trump's mother worked
                        as a telephone operator and her Czech father was an
                        intelligence informant for the Státní bezpečnost (StB).
                        [67]
                        <br></br>
                      </p>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKAUwFwj40l/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <p>
                        In 1977, the StB initiated an espionage operation
                        against Donald Trump when he married Ivana. [68]
                        Intelligence officers in Czechoslovakia were said to be
                        skilled and nimble-minded speakers of English who were a
                        challenge for their peers in the CIA and MI6. [69] The
                        operation was set up in Ivana's hometown of Zlín in
                        Czechoslovakia and collected information from Ivana's
                        father Miloš Zelníček. He was an informant for the StB
                        and turned over information on Donald Trump's career and
                        social circles, which his daughter provided to him, to
                        the StB. [70] At the time, the StB was formally
                        cooperating with Russia's KGB, Poland's SB, East
                        Germany's Stasi, and other Soviet bloc intelligence
                        agencies. These agencies often exchanged data with each
                        other on request. [71]
                      </p>
                      <br></br>

                      <br></br>
                      <p>
                        The StB spied on Donald Trump throughout the 1980s as
                        part of a mission to gain information about the US
                        government, archive files have revealed. [72] The StB
                        often obtained information through a series of practices
                        that included blackmail, telephone tapping, permanent
                        monitoring of apartments, intercepting private mail,
                        house searches, and surveillance. [73] A former StB
                        official, Vlastimil Daněk confirmed the Trump operation
                        and said, “Trump was of course a very interesting person
                        for us. He was a businessman, he had a lot of contacts,
                        even in US politics ... We were focusing on him, we knew
                        he was influential. We had information that he wanted to
                        be president in future ... It wasn’t only us who paid
                        attention to him. The first department of the StB were
                        interested in him. I don’t know if the first directorate
                        shared information on Trump with the KGB. I can’t verify
                        or deny.” The StB routinely shared intelligence with
                        their Russian counterparts, the KGB. [74]
                      </p>
                      <br></br>
                      <p>
                        In a memo dated February 1, 1984, the KGB wanted wider
                        utilization of confidential and special unofficial
                        contacts. Contacts were chiefly "prominent figures in
                        politics and society and important representatives of
                        business and science.” They also sought contacts to
                        “actively influence” a country’s foreign policy “in a
                        direction of advantage to the USSR.” The KGB distributed
                        a secret personality questionnaire for “prominent
                        figures in the West” intending to draw contacts “into
                        some form of collaboration.” Contacts were targeted by
                        appealing to their ego or obtaining compromising
                        information to use as blackmail. Potential blackmail
                        included, "illegal acts in financial and commercial
                        affairs, intrigues, speculation, bribes, graft … and
                        exploitation of his position to enrich himself." The KGB
                        also sought information on the target's "sexual
                        relationships" or attitude towards women. KGB agents
                        were urged to abandon "stereotyped methods" of
                        recruitment and use more flexible strategies if
                        necessary, such as getting their wives or other family
                        members to help. [75]
                      </p>

                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKAVkkSj284/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        In 1986, Donald was introduced to the new Soviet
                        Ambassador to the UN, Yuri Dubinin. Upon Dubinin's
                        arrival in the U.S. he was picked up by his daughter,
                        Natalia Dubinin, she took him from the airport straight
                        to Trump Tower. [76] Natalia Dubinin was part of the
                        Soviet delegation to the U.N. in New York, the Soviet
                        mission she worked for was widely known to harbor KGB
                        agents. [77] At the time Ambassador Yuri Dubinin was on
                        a mission to create contacts within the U.S. business
                        elite. General Vladimir Alexandrovich Kryuchkov, the KGB
                        spy chief, had ordered KGB staff abroad to recruit more
                        U.S Citizens. [78] Later that year Donald Trump and
                        Ivana Trump met again with Ambassador Yurin Dubinin and
                        his daughter Natalia Dubinin. They attended a private
                        luncheon given by cosmetics magnate Leonard Lauder Son
                        of Estée Lauder and elder brother of Ronald Lauder.
                        According to Donald Trump in his book, Art Of The Deal,
                        the Soviet Ambassador's daughter Natalia Dubinin “had
                        read about Trump Tower and knew all about it ... one
                        thing led to another, and now I ’m talking about
                        building a large luxury hotel, across the street from
                        the Kremlin, in partnership with the Soviet government.”
                        [79]
                      </p>
                      <br></br>
                      <p>
                        In 1987, Donald Trump and Ivana Trump were invited by
                        Soviet Ambassador Yuri Dubinin to the USSR; they visited
                        the cities of Moscow and Leningrad (now Saint
                        Petersburg). [80] The trip was arranged by Intourist, a
                        travel agency that was also an undercover KGB outfit
                        [81], that publicly was interested in a joint venture
                        with Donald Trump.[82] That same year a USSR law went
                        into effect that allows joint ventures between Soviets
                        and foreign investors. [83] Donald Trump, Ivana Trump,
                        along with their assistant Lisa Calandra were treated as
                        VIPs and were lodged in a suite at the National Hotel.
                        [84] According to Viktor Suvorov, an agent for the GRU,
                        Soviet military intelligence, “Everything is free. There
                        are good parties with nice girls. It could be a sauna
                        and girls and who knows what else.” At the time, it was
                        a widespread practice for the KGB to use young women or
                        girls to entrap visiting politicians and businessmen,
                        and to use Intourist to monitor foreigners in the Soviet
                        Union and to facilitate such “honey traps.” [85]
                      </p>
                      <br></br>
                      <p>
                        According to Donald Trump, he toured “a half dozen
                        potential sites for a hotel, including several near Red
                        Square ... I was impressed with the ambition of Soviet
                        officials to make a deal.” [86] Two months after his
                        Moscow visit to the Soviet Union, Donald Trump spent
                        nearly $100,000 on a series of full-page newspaper ads
                        that published a political manifesto critical of U.S
                        foreign policy. “An open letter from Donald J. Trump on
                        why America should stop paying to defend countries that
                        can afford to defend themselves ... Why are these
                        nations not paying the United States for the human lives
                        and billions of dollars we are losing to protect their
                        interests?” [87] Soon after the Moscow trip, Donald
                        Trump changed his political affiliation to Republican
                        and considered running for U.S. president but later
                        backtracked. [88]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKAWJYPjZRu/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        In 1988, during the US election, Donald Trump hosted a
                        New York fundraiser for George H.W. Bush at the Plaza
                        Hotel of which Ivana Trump was the manager and Donald
                        Trump owned. [89] According to StB intelligence records
                        Ivana Trump had "confidently" predicted George HW Bush's
                        victory shortly before the 1988 election. [90] Ivana
                        Trump was infiltrated in “the highest echelons of power
                        in the US” and moved in “very top political circles” all
                        the while passing on information to her hometown. [91]
                      </p>
                      <br></br>

                      <h2>Trump and Robert Maxwell</h2>
                      <p>
                        In 1989 Donald Trump attended a yacht party, aboard the
                        Lady Ghislaine [92], hosted by billionaire, fraudster,
                        member of MEGA Group, and suspected triple spy Ian
                        Robert Maxwell. Whose favorite daughter was child
                        trafficker Jeffrey Epstein’s co-conspirator Ghislaine
                        Maxwell. [93] According to Epstein's former business
                        partner, Steven Hoffenberg, Robert Maxwell introduced
                        Ghislaine Maxwell to Jeffrey Epstein in the late 1980s.
                        [94] And according to Maxwell and Epstein human
                        trafficking collaborator, Jean-Luc Burnel, Robert
                        Maxwell was one of the reasons why Jeffrey Epstein had
                        money. [95]{" "}
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKAYnHoDnSV/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        Robert Maxwell, like Ivana Trump, was born in
                        Czechoslovakia, [96] and he had known links to the
                        British Secret Intelligence Service MI6, to the Soviet
                        KGB, and the Israeli intelligence service Mossad. [97]
                        Robert Maxwell was also part of the Mega Group which was
                        heavily linked to Israel and organized crime, founded by
                        Charles Bronfman and Leslie Wexner, the latter being
                        Epstein's main monetary source. [98] That same year, in
                        1989, Donald Trump and Leslie Wexner were reported to
                        have met in Aspen Colorado, where they spoke among many
                        things about their yachts. [99] The Lady Ghislaine yacht
                        party was attended by Republican Senator John Tower,
                        ex-Navy secretary John Lehman, lawyer Thomas A. Bolan,
                        Literary agent Mort Janklow, UN envoy Thomas Pickering,
                        Peter Kalikow, Helene Atkin, and Ghislaine Maxwell.
                        Ivana Trump was not in attendance and according to
                        reports Donald Trump and Robert Maxwell ‘compared notes’
                        about their yachts, [100] which were both previously
                        owned by arms trafficker, and key Iran-Contra player,
                        Adnan Khashoggi and his nephew Emad Khashoggi. Trump's
                        future AG Bill Barr would later pardon most of the Iran
                        Contra players except Khashoggi. [101]
                      </p>
                      <br></br>
                      <h2>Trump, Epstein, Russian mob, and Deutsche Bank</h2>
                      <p>
                        In 1990, the Trump Organization amassed $3.4 billion in
                        debt; with Donald Trump liable for $832.5 million of
                        debt.In 1991, the New Jersey Casino Control Commission
                        declared Donald Trump could not be "considered
                        financially stable.” He bought a yacht for $29 million,
                        the Plaza Hotel in Manhattan for $407 million and a
                        failing airline for $365 million which all were falling
                        into millions more in debt. [102] In 1989, Donald Trump
                        purchased the Eastern Air Lines Shuttle for $365
                        million, and formed Trump Shuttle which provided flights
                        between New York, Boston, and Washington D.C. In 1992,
                        Trump defaulted on the debt of Trump Shuttle turning it
                        over to U.S. USAir Shuttle, which is presently known as
                        the American Airlines Shuttle. [103] Donald Trump turned
                        to Deutsche Bank (which provided accounts for the
                        Russian mob and Jeffrey Epstein) and Bayrock Group
                        (linked to the the Russian mob) for loans. [104]
                        Deutsche Bank also helped wealthy Russians illegal
                        funnel at least $10 billion offshore through
                        money-laundering. The criminal scheme involved its
                        Moscow, New York and London branches, for which it paid
                        “about $630 million in penalties. During the Nazi era,
                        Deutsche Bank also financed Adolf Hitler’s Nazi regime
                        and purchased stolen Jewish gold obtained through the
                        holocaust. [105]
                      </p>
                      <br></br>
                      <p>
                        Deutsche Bank also maintained its relationship with
                        Jeffrey Epstein long after he was convicted for child
                        trafficking despite multiple “suspicious transactions”
                        and “red flags” according to the New York State’s
                        Department of Financial Services. Epstein used Deutsche
                        Bank to “send over 120 wires totaling $2.65 million to
                        beneficiaries … including some transfers to alleged
                        co-conspirators or women with Eastern European
                        surnames,” Deutsche Bank failed to act even when
                        Epstein’s account activity was flagged by its internal
                        anti-financial crime department. Bank officers
                        “escalated” concerns about Epstein-related transactions
                        to bank executives, who permitted the activity to
                        continue with minimal increased oversight. “Throughout
                        the relationship, very few problematic transactions were
                        ever questioned,” ... “and when they were, they were
                        usually cleared without a satisfactory
                        explanation.”[106] Deutsche Bank later cut ties with
                        Epstein due to state and legal pressure and Donald Trump
                        after a failed coup d'etat on the United States in 2021.
                      </p>
                      <br></br>
                      <br></br>
                      <h2>Trump, Ghislaine Maxwell and Jeffrey Epstein</h2>
                      <br></br>
                      <p>
                        In 1991, After Robert Maxwell’s suspicious death aboard
                        his yacht, Donald Trump seemingly kept in touch with
                        Robert’s daughter, Ghislaine Maxwell, and her partner
                        Jeffrey Epstein. In 1992, Donald Trump hosted an
                        exclusive party with Jeffrey Epstein at his Mar-a-Lago
                        estate, and it was just the 2 of them and 28 girls. The
                        girls were flown in for a "calendar girl" competition
                        that Trump had requested, according to former Trump
                        associate, George Houraney. "At the very first party, I
                        said, 'Who's coming tonight? I have 28 girls coming,'"
                        Houraney said. "It was him and Epstein." He added: "I
                        said: 'Donald, this is supposed to be a party with VIPs.
                        You're telling me it's you and Epstein?'" [107] George
                        Houraney claims to have warned Trump about Epstein but
                        his concerns fell on deaf ears. "Look, Donald, I know
                        Jeff really well, I can't have him going after younger
                        girls," Houraney recalled telling Trump. "He said: 'Look
                        I'm putting my name on this. I wouldn't put my name on
                        it and have a scandal.'" [108] Houraney had a falling
                        out with Trump after his girlfriend accused Trump of
                        making unwanted sexual advances in the early 1990s.
                        [109] Footage from the NBC archives of that year also
                        documented Donald Trump partying with Jeffrey Epstein
                        while surrounded by young models. [110]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKDkbQfjiT7/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        {" "}
                        By 1995, Ghislaine Maxwell and Jeffrey Epstein were
                        running a major human and child trafficking network.
                        Prosecutors allege that between 1994 and 1997 Ghislaine
                        Maxwell helped Epstein lure, rape, and traffic girls as
                        young as 14. She allegedly introduced Jeffrey Epstein to
                        many of her wealthy and powerful friends, including
                        President Bill Clinton, Prince Andrew 'The Duke of
                        York', and former US President Donald Trump. [111]
                        According to Maria Farmer, a Jeffrey Epstein victim, in
                        the early to mid 90’s she met both Ivana Trump and
                        Donald Trump while they hung out with Jeffrey Epstein
                        and Ghislaine Maxwell. Maria Farmer said she witnessed
                        Ivana Trump visit, Ghislaine Maxwell, “Quite often, with
                        people like Ivana – powerful [people] – Ghislaine would
                        say ‘Hop in the car, you can go on a ride with us’ and
                        it was supposed to be my special little treat.” [112] On
                        another occasion she met Donald Trump in Jeffrey
                        Epstein’s office, she reported Donald Trump was eyeing
                        her before Jeffrey Epstein informed him that “she’s not
                        for you.” Farmer’s mother, Janice Swain, collaborated
                        with her daughter’s interaction with Donald Trump.[113]
                        In January 2020, a California woman identified as Jane
                        Doe filed a lawsuit against Epstein’s estate and
                        Ghislaine Maxwell, Doe also claims Epstein introduced
                        her to Donald Trump at Mar-A-Lago when she was only 14
                        years old in 1995. At the meeting Jeffrey Epstein asked
                        Donald Trump, “This is a good one, right?” Donald Trump
                        smiled and nodded before sharing a chuckle with Epstein,
                        according to court documents. [114]{" "}
                      </p>
                      <p>
                        In January 1997, Donald Trump flew on Jeffrey Epstein's
                        private jet from Palm Beach, Florida, to Newark, New
                        Jersey according to unsealed flight logs. Listed on the
                        flight alongside Trump was Ghislaine Maxwell, Jeffrey
                        Epstein, Mark Epstein, billionaire couple Glenn Dubin
                        and Dr. Eva Andersson-Dubin, and someone identified as
                        "Didler" or "Didier." [115] In 2020, A man with a
                        similar name Didier Raoult, a world famous French
                        Physician, led research that President Donald Trump
                        leaned on heavily to endorse hydroxychloroquine as a
                        cure for Covid19. [116] Dr. Eva Andersson-Dubin was a
                        former model for Ford Models in the 1970s and won Miss
                        Sweden in 1980 and is now a doctor and philanthropist.
                        She also had a romantic relationship with Epstein for 11
                        years. [117] Glenn Dubin was later identified as one of
                        the men Epstein rape trafficked his victims to. [118]
                        The document confirms the 2009 testimony of Epstein's
                        brother, Mark Epstein, who said in 2009 that he
                        witnessed Donald Trump fly on Epstein's jets at least
                        once. [119]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKDpSgNjRsK/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        In the Autumn of 1997, Ghislaine Maxwell was spotted
                        chatting with Donald Trump at the Ford Model’s 50th
                        Anniversary Party. [120] The relationship between Donald
                        Trump, Ghislaine Maxwell, and Jeffrey Epstein lasted for
                        more than 10 years, with all dining at Epstein’s mansion
                        and attending many of the same dinner parties. [121]
                        According to Mark Epstein, Donald Trump and Jeffrey
                        Epstein were “good friends.” “I know [Trump] is trying
                        to distance himself, but they were,” Mark said;
                        reportedly Donald Trump used to comp Epstein’s mother
                        and aunt at one of his casino hotels in Atlantic City.
                        An anonymous source told the Post the Jeffrey Epstein
                        and Donald Trump were “tight,” and that they served as
                        “each other’s wingmen.” [122] According to Jeffrey
                        Epstein in 1998 he introduced Donald Trump to Melania
                        Knauss, now Melania Trump, at a Manhattan nightclub
                        during New York Fashion Week. [123] Although, Donald and
                        Melania now claim they were introduced to each other by
                        Paolo Zampolli the former UN ambassador for Dominica and
                        founder of ID models, which he started on the advice of
                        his friend John Casablancas. Paolo Zampolli was a
                        regular in the circles of both Bill Clinton, Hillary
                        Clinton and Donald Trump. He employed Melania Trump in
                        Milan Italy, obtained her US visa, and according to him
                        introduced her to Donald Trump in 1998 at a party at the
                        Kit Kat Club. [124] In 1999, Avenue Magazine listed
                        Jeffrey Epstein (“a rich guy”) and “Mar-a-Lago’s own”
                        Donald Trump among the 157 most eligible bachelors in
                        the United States.{" "}
                      </p>
                      <br></br>
                      <h3>Trump and Prince Andrew</h3>
                      <p>
                        {" "}
                        In February 2000, Donald Trump, then dating Melania
                        Knauss (now Melania Trump), hosted a pro-am tennis
                        tournament at Mar-A-Lago, which Jeffrey Epstein, Prince
                        Andrew 'The Duke of York', and Ghislaine Maxwell
                        attended. [125] According to reports, “Even when Trump
                        announced England’s Prince Andrew, many in the crowd
                        doubted his presence. For one, he stayed in the
                        background, introducing himself as Andrew York, and for
                        two, he’s recently shed 20 pounds, making him a possible
                        double for actor Rupert Everett.” Donald Trump was
                        pictured with Melania and Prince Andrew 'The Duke of
                        York', while Jeffrey Epstein and Ghislaine Maxwell were
                        photographed with Michael Bolton at the same event.
                        [126]{" "}
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKPuOmDD149/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        In the Summer of 2000, Jeffrey Epstein and Ghislaine
                        Maxwell rode on Donald Trump's private plane from New
                        York to Florida for a group trip to Mar-a-Lago. Former
                        journalist Michael Corcoran of now-defunct Maximum Golf
                        magazine was embedded with the group while on assignment
                        to profile Donald Trump. He witnessed Jeffrey Epstein
                        and Ghislaine Maxwell arrive late to flight with an
                        unidentified young female, Donald Trump allegedly yelled
                        at Epstein, “You broke the cardinal rule, Jeffrey! Never
                        be late for someone else’s plane!”. Corcoran: “They did
                        board with a young woman of indeterminate age. I
                        wouldn’t be able to tell if she was 15 or 20. She was
                        quite dressed up and quite made up, so it was hard to
                        tell.” “I honestly couldn’t guess her age, but she was
                        young made up to look a bit older.” That same summer one
                        of the Epstein rape networks, more outspoken victims who
                        was trafficked to Prince Andrew 'The Duke of York', had
                        been recruited at Mar-a-Lago according to court
                        documents. During the group trip, Donald Trump singled
                        out an unidentified "young socialite" at his club at
                        Mar-a-Lago by telling a reporter, “there is nothing in
                        the world like first-rate pussy.”[127][128] In September
                        2000, according to Christopher Mason, a former long-term
                        acquaintance of Maxwell and journalist, Ghislaine
                        Maxwell tried to recruit heiress Paris Hilton while she
                        was a model at Donald Trump's modeling agency Trump
                        Model Management (later shortened to T Management).
                        According to Mason when Ghislaine Maxwell saw Paris
                        Hilton at a party, she stopped, stared at the
                        19-year-old Paris Hilton, and asked, 'Oh my god. Who is
                        that?'; when she was informed it was Paris Hilton,
                        Maxwell responded, 'Ohhhh she'd be perfect for Jeffrey.
                        Can you introduce us?' [129]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKPvXLrD4Z7/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        {" "}
                        In October 2000, Donald Trump, Jeffrey Epstein, Melania
                        Knauss (now Melania Trump), Ghislaine Maxwell, and
                        Prince Andrew 'The Duke Of York' attended a 'Hookers and
                        Pimps' themed Halloween party hosted by former Elite
                        model Heidi Klum at Hudson Bar, New York. Witnesses
                        confirm Donald Trump spoke with Ghislaine Maxwell at the
                        party, "Prince Andrew was with Ghislaine and they were
                        greeted by Heidi Klum,"..." While Andrew was chatting
                        with Heidi, Ghislaine went over to speak with Donald
                        Trump."..." Donald and Ghislaine were chatting for about
                        15 or 20 minutes."..."It seemed clear they already knew
                        each other. " Donald Trump made the comments to People
                        magazine after the party where he claimed Prince Andrew
                        'The Duke of York' was "a lot of fun to be with" and
                        that "He's not pretentious,". [130] One of the Jeffrey
                        Epstein rape network's victims stated that she was rape
                        trafficked to Prince Andrew 'The Duke of York' on at
                        least three occasions between 2001 and 2002. In court
                        documents from a civil suit that were released from a
                        seal in 2019, she named several others that she claims
                        Epstein and Maxwell trafficked her to, including hedge
                        fund manager Glenn Dubin, attorney Alan Dershowitz,
                        politician Bill Richardson, MIT scientist Marvin Minsky,
                        lawyer George J. Mitchell, and MC2 modeling agent
                        Jean-Luc Brunel. According to the victim, she "couldn't
                        comprehend how in the highest level of the government
                        powerful people were allowing this to happen. Not just
                        allowing but participating in it." Lawyers for five
                        women who have accused Epstein of abusing them have
                        called upon Prince Andrew 'The Duke of York' to give
                        evidence about what he knows, they argue he could have
                        important information about the Epstein trafficking
                        network. [131] Prince Andrew 'The Duke of York' has
                        repeatedly denied the allegations, which Buckingham
                        Palace said were “false and without foundation” and
                        described as “lurid and deeply personal”. Even though
                        the UK Royal family claims the allegations are baseless
                        Prince Andrew 'The Duke of York' is being shunned amid
                        ongoing calls for him to speak to the FBI; the FBI
                        claims he's refused to approach them. [132]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKPxp14DnBh/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <p>
                        In 2015 Trump was questioned by media about his links to
                        the Epstein rape network and the crimes that took place
                        on Little St James, Epstein’s private Caribbean island.
                        According to the Telegraph, Trump replied: ‘That island
                        was really a cesspool, there’s no question about it,
                        just ask Prince Andrew – he’ll tell you about it,’
                        adding, ‘The island was an absolute cesspool.’ [133] In
                        2018, then U.S. President Donald Trump and Prince Andrew
                        'The Duke of York attended multiple events together,
                        including a breakfast meeting and a service at
                        Westminster Abbey where they were photographed greeting
                        each other effusively. Others at the event included
                        Melania Trump, Jared Kushner, Ivanka Trump, and various
                        members of the UK Royal family. In 2019, Donald Trump
                        claimed he did not know Prince Andrew, “I don’t know
                        Prince Andrew, but it’s a tough story, it’s a very tough
                        story.” [134]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKPzOzejEmM/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>

                      <h5>To be continued…</h5>
                    </CardBody>

                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Forbes Billionaires List. (2020, August 11). Retrieved
                          November 01, 2020, from
                          https://www.forbes.com/profile/donald-trump/?list=billionaires
                        </li>
                        <br></br>
                        <li>
                          Trump hands over business empire to sons. (2017,
                          January 12).
                          https://www.bbc.com/news/business-38587628.
                        </li>
                        <br></br>
                        <li>
                          Barrett, W. (2016). Roots Of The Empire. In Trump: the
                          greatest show on Earth: the deals, the downfall, the
                          reinvention (pp. 93–94). Regan Arts.
                        </li>
                        <br></br>
                        <li>
                          Jr., R., & Boburg, S. (2016, June 17). The man who
                          showed Donald Trump how to exploit power and instill
                          fear. Retrieved November 16, 2020, from
                          https://www.washingtonpost.com/investigations/former-mccarthy-aide-showed-trump-how-to-exploit-power-and-draw-attention/2016/06/16/e9f44f20-2bf3-11e6-9b37-42985f6a265c_story.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Benac, N. (2016, August 20). Trump family bonds over
                          mortar, bricks and politics. Retrieved November 16,
                          2020, from
                          https://www.ctvnews.ca/world/trump-family-bonds-over-mortar-bricks-and-politics-1.3036684?cache=yes{" "}
                        </li>
                        <br></br>
                        <li>
                          Gold, M. (2019, March 14). Gambino Crime Family: How
                          Control Has Changed Since the 1950s. Retrieved
                          November 16, 2020, from
                          https://www.nytimes.com/2019/03/14/nyregion/gambino-crime-family.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Rowan, R., & Lieblich, J. (1988, June 6). THE MAFIA'S
                          BITE OF THE BIG APPLE. Retrieved November 16, 2020,
                          from
                          https://archive.fortune.com/magazines/fortune/fortune_archive/1988/06/06/70628/index.htm
                          & (Wayne Barrett; Gravano testimony)
                        </li>
                        <br></br>
                        <li>
                          Bruney, G. (2020, August 20). Netflix's 'Fear City'
                          Hints at Trump's Mob Connections. The Real Story Goes
                          Even Deeper. Retrieved November 16, 2020, from
                          https://www.esquire.com/entertainment/tv/a33350476/fear-city-new-york-mafia-donald-trump-tower-mob-ties-explained/
                        </li>
                        <br></br>
                        <li>
                          Johnston, D., White, J., Sitrin, S., & Gerstein, B.
                          (2016, May 22). Just What Were Donald Trump's Ties to
                          the Mob? Retrieved November 16, 2020, from
                          https://www.politico.com/magazine/story/2016/05/donald-trump-2016-mob-organized-crime-213910
                        </li>
                        <br></br>
                        <li>
                          DEPARTMENT OF LAW AND PUBLIC SAFETY DIVISION OF GAMING
                          ENFORCEMENT RICHARD J . HUGHES JUSTICE COMPLEX CN 047
                          TRENTON, NEW JERSEY 08825. (1987, April 7). Retrieved
                          November 25, 2020, from
                          https://www.washingtonpost.com/wp-stat/graphics/politics/trump-archive/docs/dge-report-apr-7-1987-trump-plaza-renewal
                        </li>
                        <br></br>
                        <li>
                          Report: BCCI Linked to Mob, Atlantic City Real Estate
                          Deal With AM-BCCI-Pharaon, Bjt. (1991, August 12).
                          Retrieved November 25, 2020, from
                          https://apnews.com/article/b940cecd93b8b16a10513ea6c2a3aa5b
                        </li>
                        <br></br>
                        <li>
                          Marcus, D. (2015, July 31). How Close Was Donald Trump
                          To The Mob? Retrieved November 25, 2020, from
                          https://thefederalist.com/2015/07/28/how-close-was-donald-trump-to-the-mob/
                        </li>
                        <br></br>
                        <li>
                          state of new jersey commission of investigation 18th
                          annual report and report and recommendations on
                          organized crime-affiliated subcontractors at casino
                          and public construction sites. (2020). Retrieved
                          November 25, 2020. from
                          https://www.state.nj.us/sci/pdf/annual18%20and%20OCCasino.pdf
                        </li>
                        <br></br>
                        <li>
                          Anastasia, G. (2004). Blood and honor. Camino Books.
                        </li>
                        <br></br>
                        <li>
                          Norcia, A. (2020). We Asked Actual Mafia Experts If
                          Trump Is Really Acting Like a 'Mob Boss'. Vice.com.
                          Retrieved November 25, 2020. from
                          https://www.vice.com/en/article/8xymjz/we-asked-actual-mafia-experts-if-trump-is-really-acting-like-a-mob-boss.
                        </li>
                        <br></br>
                        <li>
                          HENNING SANTIAGO, A. (2020). Trump’s mob connections.
                          City & State NY. Retrieved November 25, 2020, from
                          https://www.cityandstateny.com/articles/politics/news-politics/trumps-mob-connections.html.{" "}
                        </li>
                        <br></br>
                        <li>
                          Bagli, C. (2017). Trump Paid Over $1 Million in Labor
                          Settlement, Documents Reveal (Published 2017).
                          Nytimes.com. Retrieved 12 December 2020, from
                          https://www.nytimes.com/2017/11/27/nyregion/trump-tower-illegal-immigrant-workers-union-settlement.html{" "}
                        </li>
                        <br></br>
                        <li>
                          O'Harrlow, R. (2016). Trump’s ties to an informant and
                          FBI agent reveal his mode of operation. Washington
                          Post. Retrieved 12 December 2020, from
                          https://www.washingtonpost.com/investigations/trumps-ties-to-an-informant-and-fbi-agent-reveal-his-modes-of-operation/2016/09/16/6e65522e-6f9f-11e6-9705-23e51a2f424d_story.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Atkin, E. (2016). The Story Behind Donald Trump’s
                          Undocumented Polish Workers.
                          Archive.thinkprogress.org. Retrieved 12 December 2020,
                          from
                          https://archive.thinkprogress.org/the-story-behind-donald-trumps-undocumented-polish-workers-243a00a77fd8/{" "}
                        </li>
                        <br></br>
                        <li>
                          Bastone, W. (2016). Trump Vouched For Cocaine
                          Trafficker. The Smoking Gun. Retrieved 12 December
                          2020, from
                          http://www.thesmokinggun.com/documents/celebrity/the-donald-and-the-dealer-173892
                        </li>
                        <br></br>
                        <li>
                          Bastone, W. (2016). Trump Vouched For Cocaine
                          Trafficker. The Smoking Gun. Retrieved 12 December
                          2020, from
                          http://www.thesmokinggun.com/documents/celebrity/the-donald-and-the-dealer-173892
                        </li>
                        <br></br>
                        <li>
                          JOHNSTON, D. (2016). Just what were Donald Trump’s
                          ties to the mob?. POLITICO. Retrieved 12 December
                          2020, from
                          https://www.politico.eu/article/what-were-donald-trump-ties-to-mob-mafia-casino-atlantic-city-real-estate-investigation/
                        </li>
                        <br></br>
                        <li>
                          O'Connor, B. (2016). Meet the Convicted Cocaine
                          Trafficker Donald Trump Called a 'Credit to the
                          Community'. Jezebel. Retrieved 12 December 2020, from
                          https://theslot.jezebel.com/meet-the-convicted-cocaine-trafficker-donald-trump-call-1787180877
                        </li>
                        <br></br>
                        <li>
                          Johnston, D. (2018). The Drug Trafficker Donald Trump
                          Risked His Casino Empire to Protect. The Daily Beast.
                          Retrieved 12 December 2020, from
                          https://www.thedailybeast.com/the-drug-trafficker-donald-trump-risked-his-casino-empire-to-protect{" "}
                        </li>
                        <br></br>
                        <li>
                          Bastone, W. (2016). Trump Vouched For Cocaine
                          Trafficker. The Smoking Gun. Retrieved 12 December
                          2020, from
                          http://www.thesmokinggun.com/documents/celebrity/the-donald-and-the-dealer-173892
                        </li>
                        <br></br>
                        <li>
                          "Ex-con tied to Aycee firm" | The Marshall Project.
                          The Marshall Project. (2016). Retrieved 12 December
                          2020, from
                          https://www.themarshallproject.org/documents/2815164-Ex-con-tied-to-Aycee-firm{" "}
                        </li>
                        <br></br>
                        <li>
                          Rangel, J. (1988). ALL 20 ACQUITTED IN JERSEY MOB CASE
                          (Published 1988). Nytimes.com. Retrieved 12 December
                          2020, from
                          https://www.nytimes.com/1988/08/27/nyregion/all-20-acquitted-in-jersey-mob-case.html{" "}
                        </li>
                        <br></br>
                        <li>
                          JOHNSTON, D. (2016). Just What Were Donald Trump's
                          Ties to the Mob?. POLITICO Magazine. Retrieved 12
                          December 2020, from
                          https://www.politico.com/magazine/story/2016/05/donald-trump-2016-mob-organized-crime-213910{" "}
                        </li>
                        <br></br>
                        <li>
                          Robbins, T. (2016). Trump and the Mob. The Marshall
                          Project. Retrieved 12 December 2020, from
                          https://www.themarshallproject.org/2016/04/27/trump-and-the-mob{" "}
                        </li>
                        <br></br>
                        <li>Spy, June 1991, ISSN 0890-1759 </li>
                        <br></br>
                        <li>
                          Staff, T. (2016). Report: Trump hosted cocaine-fueled
                          parties with underage girls. Timesofisrael.com.
                          Retrieved 12 December 2020, from
                          https://www.timesofisrael.com/report-trump-hosted-cocaine-fueled-parties-with-underage-girls/{" "}
                        </li>
                        <br></br>
                        <li>
                          Gross, M. (2020). Inside Donald Trump’s One-Stop
                          Parties: Attendees Recall Cocaine and Very Young
                          Models. The Daily Beast. Retrieved 12 December 2020,
                          from
                          https://www.thedailybeast.com/inside-donald-trumps-one-stop-parties-attendees-recall-cocaine-and-very-young-models{" "}
                        </li>
                        <br></br>
                        <li>
                          Unger, C. (2021). Trump’s Russian Laundromat. The New
                          Republic. Retrieved 20 January 2021, from
                          https://newrepublic.com/article/143586/trumps-russian-laundromat-trump-tower-luxury-high-rises-dirty-money-international-crime-syndicate.
                        </li>
                        <br></br>
                        <li>
                          The Russian gangs of New York: Part 1.
                          Crime+Investigation UK. (2021). Retrieved 20 January
                          2021, from
                          https://www.crimeandinvestigation.co.uk/article/the-russian-gangs-of-new-york-part-1.
                        </li>
                        <br></br>
                        <li>
                          Erickson jr, E. (2021). Trump's mob and Russia ties
                          could prove embarrassing for the Donald and the FBI as
                          investigations heats up. Baltimoresun.com. Retrieved
                          20 January 2021, from
                          https://www.baltimoresun.com/citypaper/bcp-062817-mobs-trumprussia-20170627-story.html.
                        </li>
                        <br></br>{" "}
                        <li>
                          Robbins, T. (2016). The truth about Donald Trump and
                          his links to the mob. Newsweek. Retrieved 12 December
                          2020,
                          https://www.newsweek.com/truth-about-trump-mob-454053{" "}
                        </li>
                        <br></br>
                        <li>
                          Howe, C. (2016). How Donald Trump worked with mobsters
                          and shady businessmen. Mail Online. Retrieved 12
                          December 2020, from
                          https://www.dailymail.co.uk/news/article-3718204/Donald-Trump-s-enlisted-son-Donald-Jr-daughter-Ivanka-lure-investors-world-class-Cabo-resort-leave-buyers-high-dry-new-biography-claims.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Yacht Owners Register | SuperYachtFan. SuperYachtFan.
                          (2020). Retrieved 12 December 2020, from
                          https://www.superyachtfan.com/superyacht_owners_register_c_h.html{" "}
                        </li>
                        <br></br>
                        <li>
                          https://www.wesh.com/article/president-trump-seminole-county-florida-fundraiser/31279736{" "}
                        </li>
                        <br></br>
                        <li>
                          Bastone, W. (2020). Trump Donors Include Pair Of Mafia
                          Figures. The Smoking Gun. Retrieved 12 December 2020,
                          from
                          http://www.thesmokinggun.com/documents/crime/trumps-connected-donors-209351{" "}
                        </li>
                        <br></br>
                        <li>
                          Johnson, R. (2018). Sammy ‘The Bull’ thinks the
                          president should be ‘a mob boss’. Page Six. Retrieved
                          12 December 2020, from
                          https://pagesix.com/2018/04/22/sammy-the-bull-thinks-the-president-should-be-a-mob-boss/{" "}
                        </li>
                        <br></br>
                        <li>
                          COLEMAN, O., & SLATTERY, D. (2016). NY Daily News - We
                          are currently unavailable in your region.
                          Nydailynews.com. Retrieved 12 December 2020, from
                          https://www.nydailynews.com/news/politics/sammy-gravano-daughter-dad-pal-trump-president-article-1.2557697{" "}
                        </li>
                        <br></br>
                        <li>
                          Rebbeca (2016) “We All Knew About the Trafficking"-The
                          Untold Story of Trump Model Management (Part 1). Daily
                          Kos. (2016). Retrieved 13 December 2020, from
                          https://www.dailykos.com/stories/2016/10/6/1578544/-The-Untold-Story-of-Trump-Model-Management-A-Daily-Kos-Exclusive-Part-1{" "}
                        </li>
                        <br></br>
                        <li>
                          Saunders, D., & Moritz, O. (2016). NY Daily News - We
                          are currently unavailable in your region.
                          Nydailynews.com. Retrieved 13 December 2020, from
                          https://www.nydailynews.com/new-york/nyc-crime/studio-54-owners-rubell-schrager-sentenced-1980-article-1.2500427{" "}
                        </li>
                        <br></br>
                        <li>
                          Gross, M. (2000). My Generation. Harper Collins. My
                          Generation: Fifty Years of Sex, Drugs, Rock,
                          Revolution, Glamour, Greed, Valor, Faith, and Silicon
                          Chips Paperback – March 1, 2000 by Michael Gross{" "}
                        </li>
                        <br></br>
                        <li>
                          Wilson, E. (2013). John Casablancas, Modeling
                          Visionary, Dies at 70 (Published 2013). Nytimes.com.
                          Retrieved 13 December 2020, from
                          https://www.nytimes.com/2013/07/21/nyregion/john-casablancas-modeling-visionary-dies-at-70.html
                        </li>
                        <br></br>
                        <li>
                          https://www.telegraph.co.uk/news/obituaries/10196149/John-Casablancas.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Steinhauer, J. (1997). Her Cheekbones (High) Or Her
                          Name (Trump)? (Published 1997). Nytimes.com. Retrieved
                          13 December 2020, from
                          https://www.nytimes.com/1997/08/17/style/her-cheekbones-high-or-her-name-trump.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Kirchgaessner, S., Osborne, L., & Davies, H. (2020).
                          Teen models, powerful men and private dinners: when
                          Trump hosted Look of the Year. The Guardian. Retrieved
                          13 December 2020, from
                          https://www.theguardian.com/us-news/2020/mar/14/teen-models-powerful-men-when-donald-trump-hosted-look-of-the-year{" "}
                        </li>
                        <br></br>
                        <li>
                          Jacobs, S. (2017). 2 models who worked for Trump's
                          controversial agency tell what it was like for them.
                          Business Insider. Retrieved 12 December 2020, from
                          https://www.businessinsider.com/former-trump-models-tell-their-story-2017-2{" "}
                        </li>
                        <br></br>
                        <li>
                          Levine, B., El-Faizy, M., & Osborne, L. (2019). Trump
                          the predator. the Guardian. Retrieved 13 December
                          2020, from
                          https://www.theguardian.com/books/2019/oct/19/trump-predator-new-book-fresh-allegations{" "}
                        </li>
                        <br></br>
                        <li>
                          Schetler, J. (1999). Fashion Scandal: Agency `exposed'
                          on TV say BBC film was rigged. The Independent.
                          Retrieved 13 December 2020, from
                          https://www.independent.co.uk/news/fashion-scandal-agency-exposed-on-tv-say-bbc-film-was-rigged-1125517.html{" "}
                        </li>
                        <br></br>
                        <li>
                          PHELAN, H. (2014). The Model Alliance's Industry
                          Survey Finds Nearly 30% of Models Have Been Sexually
                          Harassed and 50% Exposed to Cocaine. Fashionista.
                          Retrieved 13 December 2020, from
                          https://fashionista.com/2012/03/model-alliances-industry-survey-finds-nearly-30-of-models-have-been-inappropriately-touched-on-jobs-and-50-exposed-to-cocaine{" "}
                        </li>
                        <br></br>
                        <li>
                          Chapin, A. (2018). Modeling Agencies Enabled Sexual
                          Predators For Years, Former Agent Says.
                          Huffingtonpost.com. Retrieved 13 December 2020, from
                          https://www.huffingtonpost.com/entry/modeling-agencies-sexual-predators_n_5a395d1ce4b0c65287acc57b?ri18n=true{" "}
                        </li>
                        <br></br>
                        <li>
                          VOGUE. (2004). TRUMP BUYS ELITE. British Vogue.
                          Retrieved 13 December 2020, from
                          https://www.vogue.co.uk/article/trump-buys-elite{" "}
                        </li>
                        <br></br>
                        <li>
                          St. John, W. (2004). Behind the Catwalk, Suspicion and
                          Suits (Published 2004). Nytimes.com. Retrieved 12
                          December 2020, from
                          https://www.nytimes.com/2004/04/18/style/behind-the-catwalk-suspicion-and-suits.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Gross, M. (2000). Girls Interrupted - Nymag. New York
                          Magazine. Retrieved 13 December 2020, from
                          https://nymag.com/nymetro/news/politics/columns/citypolitic/1866/{" "}
                        </li>
                        <br></br>
                        <li>The Power of Ideas by Ricardo Bellino </li>
                        <br></br>
                        <li>
                          https://www.goldbell.com.br/ricardo-samuel-goldstein{" "}
                        </li>
                        <br></br>
                        <li>
                          Benson, T. (2004). Trump Takes a Meeting, Now Backs a
                          Resort in Brazil (Published 2004). Nytimes.com.
                          Retrieved 13 December 2020, from
                          https://www.nytimes.com/2004/05/19/business/trump-takes-a-meeting-now-backs-a-resort-in-brazil.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Sívak, M., Riera, A., Domenici, T., & Crucianelli, S.
                          (2016). O homem que trouxe Trump ao Brasil - Agência
                          Pública. Agência Pública. Retrieved 13 December 2020,
                          from
                          https://apublica.org/2016/08/o-homem-que-trouxe-trump-ao-brasil/{" "}
                        </li>
                        <br></br>
                        <li>
                          https://www.nytimes.com/2018/05/31/obituaries/jose-hawilla-74-central-figure-in-soccer-scandalss-dies.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Woo, E. (2013). John Casablancas dies at 70;
                          transformed modeling business. Los Angeles Times.
                          Retrieved 13 December 2020, from
                          https://www.latimes.com/local/obituaries/la-me-0722-john-casablancas-20130722-story.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Here's What You Need to Know About Ivana Trump,
                          Donald's First Wife. Town & Country. (2020). Retrieved
                          13 December 2020, from
                          https://www.townandcountrymag.com/society/politics/news/g2605/donald-trump-first-wife-ivana-trump/{" "}
                        </li>
                        <br></br>
                        <li>
                          Shnayerson, M. (1988). Inside Ivana’s Role in Donald
                          Trump’s Empire. Vanity Fair. Retrieved 13 December
                          2020, from
                          https://www.vanityfair.com/news/1988/01/ivana-trump-business-empire{" "}
                        </li>
                        <br></br>
                        <li>
                          Van Meter, J. (2004). Donald Trump's Children - The
                          Real Apprentices - Nymag. New York Magazine. Retrieved
                          13 December 2020, from
                          https://nymag.com/nymetro/news/people/features/10610/{" "}
                        </li>
                        <br></br>
                        <li>
                          Burleigh, N. (2017). Donald Trump's first ex-wife,
                          Ivana, once wore homemade clothes. Now she spends
                          holidays at Mar-a-Lago. Newsweek. Retrieved 13
                          December 2020, from
                          https://www.newsweek.com/donald-trump-ivana-donald-ivanka-trump-russia-communism-czech-republic-721669{" "}
                        </li>
                        <br></br>
                        <li>
                          KALMBACHER, C. (2018). Soviet-Linked Spies Targeted
                          Trump After He Married Ivana, Knowing He Wanted to Be
                          President One Day. Law & Crime. Retrieved 13 December
                          2020, from
                          https://lawandcrime.com/high-profile/soviet-linked-spies-targeted-trump-after-he-married-ivana-knowing-he-wanted-to-be-president-one-day/{" "}
                        </li>
                        <br></br>
                        <li>
                          Harding, L. (2018). 'A very different world' - inside
                          the Czech spying operation on Trump. the Guardian.
                          Retrieved 13 December 2020, from
                          https://www.theguardian.com/us-news/2018/oct/29/czechoslovakia-spied-on-trump-to-exploit-ties-to-highest-echelons-of-us-power{" "}
                        </li>
                        <br></br>
                        <li>
                          Harding, T. (2018). Czechoslovakia ramped up spying on
                          Trump in late 1980s, seeking US intel. the Guardian.
                          Retrieved 13 December 2020, from
                          https://www.theguardian.com/us-news/2018/oct/29/trump-czechoslovakia-communism-spying{" "}
                        </li>
                        <br></br>
                        <li>
                          Naegele, J. (1997). Czech Republic: Secret Police
                          Files Unlocked This Weekend.
                          RadioFreeEurope/RadioLiberty. Retrieved 13 December
                          2020, from https://www.rferl.org/a/1084871.html
                        </li>
                        <br></br>
                        <li>
                          Sabur, R. (2018). Czechoslovakia's spy agency targeted
                          Donald Trump in 1980s through wife Ivana's father,
                          archive files reveal. The Telegraph. Retrieved 13
                          December 2020, from
                          https://www.telegraph.co.uk/news/2018/10/29/czechoslovakias-spy-agency-targeted-donald-trump-1980s-wife/
                        </li>
                        <br></br>
                        <li>
                          Day, M. (2018). What is the StB? - the repressive
                          communist secret service linked to Jeremy Corbyn. The
                          Telegraph. Retrieved 13 December 2020, from
                          https://www.telegraph.co.uk/news/2018/02/19/stb-repressive-communist-secret-service-linked-jeremy-corbyn/{" "}
                        </li>
                        <br></br>
                        <li>
                          Committee to Investigate Russia. (2018). Czech Spies
                          Tracked Trump in the 80s. Committee to Investigate
                          Russia. Retrieved 13 December 2020, from
                          https://investigaterussia.org/media/2018-10-29/czech-spies-tracked-trump-80s{" "}
                        </li>
                        <br></br>
                        <li>
                          Harding, L. (2017). The Hidden History of Trump’s
                          First Trip to Moscow. POLITICO Magazine. Retrieved 13
                          December 2020, from
                          https://www.politico.com/magazine/story/2017/11/19/trump-first-moscow-trip-215842{" "}
                        </li>
                        <br></br>
                        <li>
                          Weiss, M. (2018). What Russia Understands about Trump.
                          The New York Review of Books. Retrieved 13 December
                          2020, from
                          https://www.nybooks.com/daily/2018/08/02/what-russia-understands-about-trump/{" "}
                        </li>
                        <br></br>
                        <li>
                          Antonova, T. (2020). «Трамп сразу растаял»: интрига
                          первого визита президента США в СССР. Mk.ru. Retrieved
                          13 December 2020, from
                          https://www.mk.ru/politics/2016/11/09/tramp-srazu-rastayal-intriga-pervogo-vizita-prezidenta-ssha-v-sssr.html{" "}
                        </li>
                        <br></br>
                        <li>
                          LYNCH, C. (2010). The U.N.: Russia's den of spies.
                          Foreign Policy. Retrieved 13 December 2020, from
                          https://foreignpolicy.com/2010/06/29/the-u-n-russias-den-of-spies/{" "}
                        </li>
                        <br></br>
                        <li>
                          Span, P. (1988). From the archives: When Trump hoped
                          to meet Gorbachev in Manhattan. Washington Post.
                          Retrieved 13 December 2020, from
                          https://www.washingtonpost.com/lifestyle/style/from-the-archives-when-trump-hoped-to-meet-gorbachev-in-manhattan/2017/07/10/3f570b42-658c-11e7-a1d7-9a32c91c6f40_story.html?utm_term=.177bf7eb4ffe{" "}
                        </li>
                        <br></br>
                        <li>
                          Chait, J. (2018). What If Trump Has Been a Russian
                          Asset Since 1987?. Intelligencer. Retrieved 13
                          December 2020, from
                          https://nymag.com/intelligencer/2018/07/trump-putin-russia-collusion.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Hazanov, A. (2015). Penn DCC Workshop. Sas.upenn.edu.
                          Retrieved 13 December 2020, from
                          https://www.sas.upenn.edu/andrea-mitchell-center/sites/www.sas.upenn.edu.andrea-mitchell-center/files/uploads/Alex%20Hazanov%20DCC%20Workshop.pdf{" "}
                        </li>
                        <br></br>
                        <li>
                          Farivar, M. (2017). Timeline: Trump's Involvements
                          With Russia. Voice of America. Retrieved 13 December
                          2020, from
                          https://www.voanews.com/europe/timeline-trumps-involvements-russia{" "}
                        </li>
                        <br></br>
                        <li>
                          Heinonline.org. (1987). Retrieved 13 December 2020,
                          from
                          https://heinonline.org/hol-cgi-bin/get_pdf.cgi?handle=hein.journals/intfinr6&section=202{" "}
                        </li>
                        <br></br>
                        <li>
                          Harding, L. (2017). The Hidden History of Trump’s
                          First Trip to Moscow. POLITICO Magazine. Retrieved 13
                          December 2020, from
                          https://www.politico.com/magazine/story/2017/11/19/trump-first-moscow-trip-215842{" "}
                        </li>
                        <br></br>
                        <li>
                          Unger, C. (2019). House of Trump, House of Putin: The
                          untold story of Donald Trump and the Russian mafia.
                          London: Corgi Books.{" "}
                        </li>
                        <br></br>
                        <li>
                          Schwartz, T., Trump, D. (1988). Trump: The art of the
                          deal. Century Pub.: Penguin Random House LLC.
                        </li>
                        <br></br>
                        <li>
                          Ben-Meir, I. (2015). That Time Trump Spent Nearly
                          $100,000 On An Ad Criticizing U.S. Foreign Policy In
                          1987. Buzzfeednews.com. Retrieved 13 December 2020,
                          from
                          https://www.buzzfeednews.com/article/ilanbenmeir/that-time-trump-spent-nearly-100000-on-an-ad-criticizing-us{" "}
                        </li>
                        <br></br>
                        <li>
                          Gillin, J. (2015). PolitiFact - Bush says Trump was a
                          Democrat longer than a Republican 'in the last
                          decade'. Politifact. Retrieved 13 December 2020, from
                          https://www.politifact.com/factchecks/2015/aug/24/jeb-bush/bush-says-trump-was-democrat-longer-republican-las/{" "}
                        </li>
                        <br></br>
                        <li>
                          Barrouquere, B. (2016). How a fundraiser brought
                          George H.W. Bush, Donald Trump and Don King together.
                          Chron. Retrieved 13 December 2020, from
                          https://www.chron.com/politics/us/article/A-Bush-a-Trump-and-a-King-How-a-fundraiser-9227763.php{" "}
                        </li>
                        <br></br>
                        <li>
                          Harding, L. (2018). Czechoslovakia ramped up spying on
                          Trump in late 1980s, seeking US intel. the Guardian.
                          Retrieved 13 December 2020, from
                          https://www.theguardian.com/us-news/2018/oct/29/trump-czechoslovakia-communism-spying{" "}
                        </li>
                        <br></br>
                        <li>
                          Sabur, R. (2018). Czechoslovakia's spy agency targeted
                          Donald Trump in 1980s through wife Ivana's father,
                          archive files reveal. The Telegraph. Retrieved 13
                          December 2020, from
                          https://www.telegraph.co.uk/news/2018/10/29/czechoslovakias-spy-agency-targeted-donald-trump-1980s-wife/{" "}
                        </li>
                        <br></br>
                        <li>
                          Donald Trump and Ghislaine Maxwell on her dad's
                          (Robert) yacht in May 1989 - Newspapers.com.
                          Newspapers.com. (1989). Retrieved 13 December 2020,
                          from
                          https://www.newspapers.com/clip/9383143/donald-trump-and-ghislaine-maxwell-on/{" "}
                        </li>
                        <br></br>
                        <li>
                          Shute, J. (2019). The Maxwell dynasty: what happened
                          to the disgraced mogul's family?. The Telegraph.
                          Retrieved 13 December 2020, from
                          https://www.telegraph.co.uk/family/life/maxwell-dynasty-happened-disgraced-moguls-family/{" "}
                        </li>
                        <br></br>
                        <li>
                          Voytko, L. (2020). Ghislaine Maxwell Reportedly Met
                          Jeffrey Epstein Through Her Disgraced Father. Forbes.
                          Retrieved 13 December 2020, from
                          https://www.forbes.com/sites/lisettevoytko/2020/09/30/ghislaine-maxwell-reportedly-met-jeffrey-epstein-through-her-disgraced-father/?sh=7cd993f770f4{" "}
                        </li>
                        <br></br>
                        <li>
                          Beal, J. (2020). Ghislaine 'introduced to Epstein by
                          father' amid claims he bankrolled paedo. The Sun.
                          Retrieved 13 December 2020, from
                          https://www.thesun.co.uk/news/12813924/ghislaine-maxwell-dad-robert-epstein/.{" "}
                        </li>
                        <br></br>
                        <li>
                          Robert Maxwell | Encyclopedia.com. Encyclopedia.com.
                          (2020). Retrieved 13 December 2020, from
                          https://www.encyclopedia.com/people/literature-and-arts/journalism-and-publishing-biographies/robert-maxwell{" "}
                        </li>
                        <br></br>
                        <li>
                          Bell, L. (2003). FO suspected Maxwell was a Russian
                          agent, papers reveal. The Telegraph. Retrieved 13
                          December 2020, from
                          https://www.telegraph.co.uk/news/uknews/1445707/FO-suspected-Maxwell-was-a-Russian-agent-papers-reveal.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Matsui, J. (2020). La Danse Mossad: Robert Maxwell and
                          Jeffrey Epstein - CounterPunch.org. CounterPunch.org.
                          Retrieved 29 November 2020, from
                          https://www.counterpunch.org/2019/08/16/la-danse-mossad-robert-maxwell-and-jeffrey-epstein/.{" "}
                        </li>
                        <br></br>
                        <li>
                          Rooms for sun's ups and downs. Asbury Park Press.
                          (2020). Retrieved 29 November 2020, from
                          https://www.newspapers.com/clip/35297270/leslie-wexner-donald-trump/.{" "}
                        </li>
                        <br></br>
                        <li>
                          Donald Trump and Ghislaine Maxwell on her dad's
                          (Robert) yacht in May 1989 - Newspapers.com.
                          Newspapers.com. (1989). Retrieved 13 December 2020,
                          from
                          https://www.newspapers.com/clip/9383143/donald-trump-and-ghislaine-maxwell-on/{" "}
                        </li>
                        <br></br>
                        <li>
                          Kilian, M. (1989). THE 200-FOOT FETISH.
                          chicagotribune.com. Retrieved 13 December 2020, from
                          https://www.chicagotribune.com/news/ct-xpm-1989-07-26-8902200576-story.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Donald Trump’s Business Decisions in ’80s Nearly Led
                          Him to Ruin (Published 2016). Nytimes.com. (2021).
                          Retrieved 21 January 2021, from
                          https://www.nytimes.com/2016/10/04/nyregion/donald-trump-taxes-debt.html.
                        </li>
                        <br></br>
                        <li>
                          The rise and fall of Donald Trump's $365 million
                          airline. Business Insider. (2021). Retrieved 21
                          January 2021, from
                          https://www.businessinsider.com/donald-trump-airline-shuttle-transportation-pan-am-eastern-new-york-2019-1.
                        </li>
                        <br></br>
                        <li>
                          Alexander, D. (2021). Trump’s Business Partners
                          Allegedly Involved In Human Trafficking, Mafia
                          Matters, Probable Money Laundering. Forbes. Retrieved
                          21 January 2021, from
                          https://www.forbes.com/sites/danalexander/2020/08/20/trumps-business-partners-allegedly-involved-in-human-trafficking-mafia-matters-probable-money-laundering/.
                        </li>
                        <br></br>
                        <li>
                          Caesar, E. (2021). Deutsche Bank’s $10-Billion
                          Scandal. The New Yorker. Retrieved 21 January 2021,
                          from
                          https://www.newyorker.com/magazine/2016/08/29/deutsche-banks-10-billion-scandal.
                        </li>
                        <br></br>
                        <li>
                          Deutsche Bank maintained accounts for Jeffrey Epstein
                          despite 'red flags': Regulators. ABC News. (2021).
                          Retrieved 21 January 2021, from
                          https://abcnews.go.com/Business/deutsche-bank-agrees-pay-150-million-relationship-jeffrey/story?id=71647740.
                        </li>
                        <br></br>
                        <li>
                          Choi, D. (2020). Trump once hosted an exclusive party
                          with Jeffrey Epstein at his Mar-a-Lago estate, a new
                          report says. It was just the 2 of them and '28
                          girls.'. Business Insider. Retrieved 29 November 2020,
                          from
                          https://www.businessinsider.com/trump-epstein-party-at-mar-a-lago-women-2019-7.{" "}
                        </li>
                        <br></br>
                        <li>
                          Karni, A., & Haberman, M. (2020). Jeffrey Epstein Was
                          a ‘Terrific Guy,’ Donald Trump Once Said. Now He’s
                          ‘Not a Fan.’ (Published 2019). Nytimes.com. Retrieved
                          30 November 2020, from
                          https://www.nytimes.com/2019/07/09/us/politics/trump-epstein.html.{" "}
                        </li>
                        <br></br>
                        <li>
                          Cooney, S. (2020). These Are the Women Who Have
                          Accused President Trump of Sexual Misconduct. Time.
                          Retrieved 30 November 2020, from
                          https://time.com/5058646/donald-trump-accusers/.{" "}
                        </li>
                        <br></br>
                        <li>
                          Bohrer, J. (2020). Tape shows Donald Trump and Jeffrey
                          Epstein discussing women at 1992 party. NBC News.
                          Retrieved 30 November 2020, from
                          https://www.nbcnews.com/news/us-news/tape-shows-donald-trump-jeffrey-epstein-discussing-women-1992-party-n1030686.{" "}
                        </li>
                        <br></br>
                        <li>
                          Jeffrey Epstein ex-girlfriend Ghislaine Maxwell
                          charged in US. BBC News. (2021). Retrieved 14 January
                          2021, from
                          https://www.bbc.com/news/world-us-canada-53268218.
                        </li>
                        <br></br>
                        <li>
                          Saner, E. (2020). ‘She was so dangerous’: where in the
                          world is the notorious Ghislaine Maxwell?. the
                          Guardian. Retrieved 30 November 2020, from
                          https://www.theguardian.com/us-news/2019/dec/12/she-was-so-dangerous-where-in-the-world-is-the-notorious-ghislaine-maxwell.
                        </li>
                        <br></br>
                        <li>
                          Baker, M. (2020). The Sisters Who First Tried to Take
                          Down Jeffrey Epstein (Published 2019). Nytimes.com.
                          Retrieved 30 November 2020, from
                          https://www.nytimes.com/2019/08/26/us/epstein-farmer-sisters-maxwell.html.{" "}
                        </li>
                        <br></br>
                        <li>
                          Epstein Victim Claims He Showed Her Off to Trump When
                          She Was 14. The Daily Beast. (2021). Retrieved 19
                          January 2021, from
                          https://www.thedailybeast.com/epstein-victim-claims-he-showed-her-off-to-trump-at-mar-a-lago-when-she-was-14.
                        </li>
                        <br></br>
                        <li>
                          Mark, M. (2021). Unsealed flight logs show that Trump
                          was on Epstein's private jet in 1997. Insider.
                          Retrieved 12 January 2021, from
                          https://www.insider.com/donald-trump-jeffrey-epstein-flight-logs-unsealed-2019-8.
                        </li>
                        <br></br>
                        <li>
                          Lee, K. (2021). Meet The Renegade Scientist Behind
                          Hydroxychloroquine, Donald Trump's Miracle Coronavirus
                          Cure. Esquire. Retrieved 12 January 2021, from
                          https://www.esquire.com/uk/life/a32284282/hydroxychloroquine-donald-trump-coronavirus-cure-didier-raoult/.
                        </li>
                        <br></br>
                        <li>
                          The High Society That Surrounded Jeffrey Epstein.
                          Intelligencer. (2021). Retrieved 12 January 2021, from
                          https://nymag.com/intelligencer/2019/07/jeffrey-epstein-high-society-contacts.html.
                        </li>
                        <br></br>
                        <li>
                          McEvoy, J. (2021). Virgin Islands To Subpoena Hedge
                          Fund Billionaire Glenn Dubin In Epstein Case. Forbes.
                          Retrieved 12 January 2021, from
                          https://www.forbes.com/sites/jemimamcevoy/2020/09/08/virgin-islands-to-subpoena-hedge-fund-billionaire-glenn-dubin-in-epstein-case/?sh=5c46b81d26ad.
                        </li>
                        <br></br>
                        <li>
                          https://www.washingtonpost.com/politics/donald-trump-and-jeffrey-epstein-partied-together-then-an-oceanfront-palm-beach-mansion-came-between-them/
                        </li>
                        <br></br>
                        <li>
                          Dodson, M. (2001). Jeffrey Epstein’s Brother: Trump
                          and Epstein Were ‘Good Friends’. The Daily Beast.
                          Retrieved 14 January 2021, from
                          https://www.thedailybeast.com/jeffrey-epsteins-brother-trump-and-epstein-were-good-friends.
                        </li>
                        <br></br>
                        <li>
                          Ghislaine Maxwell and Donald Trump 1997 -
                          Newspapers.com. Newspapers.com. (1997). Retrieved 13
                          December 2020, from
                          https://www.newspapers.com/clip/40011947/ghislaine-maxwell-and-donald-trump-1997/{" "}
                        </li>
                        <br></br>
                        <li>
                          Mangan, D. (2020). Ghislaine Maxwell said under oath
                          that she ‘never’ saw Jeffrey Epstein have sex ‘with a
                          minor,’ unsealed deposition reveals. CNBC. Retrieved
                          13 December 2020, from
                          https://www.cnbc.com/2020/10/22/jeffrey-epstein-case-ghislaine-maxwell-deposition-released.html{" "}
                        </li>
                        <br></br>
                        <li>
                          Ross, M. (2019). Jeffrey Epstein says he introduced
                          Donald Trump, Melania: report. Mercurynews.com.
                          Retrieved 13 December 2020, from
                          https://www.mercurynews.com/2019/07/10/jeffrey-epstein-boasted-that-he-played-matchmaker-for-donald-and-melania-trump-report-says/{" "}
                        </li>
                        <br></br>
                        <li>
                          Horowitz, J. (2016). When Donald Met Melania, Paolo
                          Was There (Published 2016). Nytimes.com. Retrieved 13
                          December 2020, from
                          https://www.nytimes.com/2016/09/01/fashion/donald-trump-melania-modeling-agent-paolo-zampolli-daily-mail.html{" "}
                        </li>
                        <br></br>
                        <li>
                          20 Feb 2000, Page 721 - The Palm Beach Post at
                          Newspapers.com. Newspapers.com. (2000). Retrieved 13
                          December 2020, from
                          https://www.newspapers.com/image/130017350/?terms=%22donald%2Btrump%22%2B%22jeffrey%2Bepstein%22{" "}
                        </li>
                        <br></br>
                        <li>
                          20 Feb 2000, Page 721 - The Palm Beach Post at
                          Newspapers.com. Newspapers.com. (2000). Retrieved 13
                          December 2020, from
                          https://www.newspapers.com/image/130017350/?terms=%22donald%2Btrump%22%2B%22jeffrey%2Bepstein%22{" "}
                        </li>
                        <br></br>
                        <li>
                          Walsh, J. (2021). Remembering the Time Jeffrey Epstein
                          Rode on Donald Trump’s Plane. Intelligencer. Retrieved
                          19 January 2021, from
                          https://nymag.com/intelligencer/2019/07/remembering-the-time-jeffrey-epstein-rode-on-trumps-plane.html.
                        </li>
                        <br></br>
                        <li>
                          Trump Bragged: ‘Nothing in the World Like First-Rate
                          P**sy’. The Daily Beast. (2021). Retrieved 19 January
                          2021, from
                          https://www.thedailybeast.com/trump-bragged-nothing-in-the-world-like-first-rate-psy.
                        </li>
                        <br></br>
                        <li>
                          Bates, D. (2021). Ghislaine Maxwell wanted to recruit
                          young Paris Hilton for Epstein. Mail Online. Retrieved
                          19 January 2021, from
                          https://www.dailymail.co.uk/news/article-8604711/Ghislaine-Maxwell-wanted-recruit-young-Paris-Hilton-Epstein.html.
                        </li>
                        <br></br>
                        <li>
                          Coyle, M. (2021). Trump claims he 'doesn't know'
                          Andrew as new pics emerge with 'madam'. The Sun.
                          Retrieved 19 January 2021, from
                          https://www.thesun.co.uk/news/uknews/10491468/donald-trump-prince-andrew-a-lot-of-fun/.
                        </li>
                        <br></br>
                        <li>
                          Photos contradict Trump's claim not to know Prince
                          Andrew. The Guardian. (2021). Retrieved 19 January
                          2021, from
                          https://www.theguardian.com/uk-news/2019/dec/03/photos-contradict-trumps-claim-not-to-know-prince-andrew.
                        </li>
                        <br></br>
                        <li>
                          Pals of Prince Andrew 'shun' his 60th birthday party
                          following Epstein scandal. The Mirror. (2021).
                          Retrieved 19 January 2021, from
                          https://www.mirror.co.uk/news/uk-news/pals-prince-andrew-shun-60th-21522486.
                        </li>
                        <br></br>
                        <li>
                          'Ask Prince Andrew,' Donald Trump said when asked
                          about Epstein's 'cesspool' island in newly unearthed
                          interview. The Telegraph. (2021). Retrieved 19 January
                          2021, from
                          https://www.telegraph.co.uk/news/2020/07/22/ask-prince-andrew-donald-trump-said-asked-epsteins-cesspool/.
                        </li>
                        <br></br>
                        <li>
                          Gold, H. (2021). Trump Can’t Really Pretend Not to
                          Know Prince Andrew. The Cut. Retrieved 19 January
                          2021, from
                          https://www.thecut.com/2019/12/all-the-photographs-of-president-trump-with-prince-andrew.html.
                        </li>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Trump;
