import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/GeraldMarie.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Marie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Gérald Marie De Castellac</h4>
                        {/* </a> */}
                        <p className="description">Millionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>
                              Jean-Luc Marie, Claude Haddadd, Garie Marie, and
                              60 minutes
                            </li>
                            <li>
                              Jean-Luc and Arnaud Marie, Next Management
                              Company, Steven Mnuchin, and Faith Kates
                            </li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul>
                            <li> John Casablancas</li>
                            <li>Naomi Campbell</li>
                            <li>Adnan Khashoggi</li>
                            <li>François Lano</li>
                            <li>Francesca Magugliani</li>
                            <li>Donald Trump</li>
                          </ul>
                          <h4>Family of:</h4>
                          <ul>
                            <li>(ex-wife)Linda Evangelista</li>
                            <li>(current-wife) Russian Model</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Elite Model Management</li>
                            <li>Elite Model Management Europe</li>
                            <li>Paris Planning agency</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE DECEMBER 23rd</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Gérald Marie De Castellac</h1>
                      <h2> Introduction </h2>
                      <p>
                        Gérald Marie De Castellac headed Elite Model Management
                        Europe and was the former business partner of Elite
                        Model's founder John Casablancas. Gérald Marie is accused
                        of sexual assault, rape, and child rape. Gérald Marie is
                        still active as a modeling agent in the Parisian agency 
                        Oui Management.
                      </p>
                      <br></br>
                      <h1>
                        <h2>Gérald Marie and the Paris Planning agency</h2>
                      </h1>
                      <p>
                        {" "}
                        In the 1970s, Gérald Marie made his career as a modeling
                        agent at Paris Planning agency, alongside François Lano.
                        [1] Marie used his position to prey on girls and young
                        women. He would bully them into having sex with him or
                        rape them by drugging them or through force. According
                        to the testimony the fashion photographer Jacques
                        Silberstein, Gérald Marie's philosophy was that "if you
                        want to work, then you have to f*** me". [2] The former
                        model Laurie Marsden is one of the many victims who have
                        come forward to say they were abused by Gérald Marie.[3]
                        In 1982 when she was 19 and working for Paris Planning
                        agency, Marie lured her to a house party in Paris by
                        promising to ‘make her a star’, instead he sexually
                        assaulted and attacked her outside of a bathroom. [4]
                        According to his former business partner, Francesca
                        Magugliani, “[Gérald Marie] is sordid. He hits the
                        girls. He rapes them. He's taking cocaine.” [5]{" "}
                      </p>
                      <br></br>
                      <p>
                        Gérald Marie would groom families by offering them
                        promises of success and wealth; he convinced parents
                        that he would be a very safe guardian of their teenage
                        daughters. The former model Wendy Walsh, who was raped
                        by Gérald Marie while she was living in Paris, stated
                        that Marie would then lure the girls to the south of
                        France and Monaco. She confirmed that one of the reasons
                        Gérald Marie had the ability to exploit the new models
                        was the language barrier. “I didn’t meet one French
                        model while I was there. We were chosen specifically
                        because we didn’t understand the language, would be away
                        from home, and didn’t know what the hell was going on.
                        He had complete control.” Soon after her rape, Wendy
                        Walsh was invited to a ‘glamorous’ trip to Monte Carlo,
                        Monaco. However, a more successful model warned her that
                        Monte Carlo was a world of wealthy men with their yachts
                        and that unsuspecting models could end up being
                        exploited. “Bad things happen there”. [6]
                      </p>
                      <br></br>
                      <p>
                        Other models were not able to receive such a warning.
                        Jill Dodd who was raped by Gérald Marie and then lured to Monte
                        Carlo, Monaco where she was sold by Paris Planning to
                        Saudi weapons trafficker Adnan Khashoggi who paid to
                        ‘meet her’. Dodd says she and her Paris Planning booker
                        were invited to stay the night on Khashoggi yacht, and
                        offered their pick from a closet full of couture gowns,
                        “I was basically one of his harem wives for almost two
                        years,”... “It wasn’t until the end of our relationship
                        that I found out that he had paid to meet me,”... “I was
                        chosen out of a bunch of pictures by Adnan.” One of
                        Khashoggi’s assistants carried a portfolio of pictures
                        of women and would go through the photographs, asking
                        whom Khashoggi would like to meet, and discussed fees
                        between $35,000 and $50,000. Khashoggi later admitted
                        that he had paid Paris Planning in exchange for models.
                        Dodd said,“It was all a front. I had been manipulated
                        and used.” Other models also testified being trafficked
                        by Paris Planning to other men some of which raped or
                        sexually assaulted them. [7]
                      </p>
                      <br></br>
                      <h2>Gérald Marie, John Casablancas, and Elite Models</h2>
                      <p>
                        In the 1970s John Casablancas left for New York to
                        declare war on the US model agencies Ford and
                        Wilhelmina. [8] In the New York disco era nightclub
                        scene, he brushed shoulders with organized crime and
                        befriended future U.S. President Donald Trump. [9] By
                        1985 Casablancas and Gérald Marie had been acquainted
                        and an alliance was made between the two. In 1986 Paris
                        Planning merged with Elite Model Management. Marie
                        became its European president. He ran the agency
                        alongside Elite founder John Casablancas, who was based
                        in New York; together, they helped launch the careers of
                        Naomi Campbell (a Jeffrey Epstein associate in the late
                        90’s), Cindy Crawford, Helena Christensen, and others.
                        In 1991, Elite had offices around the world, from Tokyo
                        to London; he reportedly owned homes in Manhattan,
                        Saint-Tropez, Ibiza, and Paris. [10]
                      </p>
                      <br></br>
                      <p>
                        Gérald Marie was also married to supermodel Linda
                        Evangelista between 1987 and 1993. Evangelista praised
                        Marie's victims and said she believed them, “During my
                        relationship with Gérald Marie, I knew nothing of these
                        sexual allegations against him, so I was unable to help
                        these women,”. [11] The couple were guests at the final
                        of the 1991 Look of the Year event, Elite’s annual
                        international modelling contest, in New York.
                        Evangelista joined Naomi Campbell to present a prize,
                        while Gérald Marie sat and socialized with fellow judges
                        John Casablancas and the future U.S. President Donald
                        Trump in the audience. [12]
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        In 1991, a 15 year old girl Shawna Lee and finalist for
                        Look of the Year was sent to Elite in Paris where she
                        was invited to nightclub Les Bains Douches, a common
                        stalking ground for predators like Gérald Marie,
                        Jean-Luc Brunel, Roman Polanski, and Prince Albert of
                        Monaco. After a night of partying Gérald Marie lured
                        Shawna Lee to his apartment where he proceeded to rape
                        her. When she attempted to come out about the rape,
                        Marie threatened her and Elite staff encouraged her to
                        sweep the situation under the rug. A Paris Elite scout
                        groomed and also raped her and other models. The Paris
                        Elite scout was a friend of Gérald Marie and told her
                        that the two men had been competing over, “who was going
                        to get your virginity”, according to Lee the unnamed
                        Paris Elite scout was “kind of mad that he [Marie] got
                        it first.” According to multiple models Gérald Marie and
                        other Elite agents were raping and trafficking models;
                        making money by sending models on trips where there was
                        “no actual work”, or to parties with wealthy men who had
                        no connection to the industry. [13]{" "}
                      </p>
                      <br></br>
                      <h2>Gérald Marie and the supressed BBC investigation</h2>
                      <p>
                        In 1999, the BBC conducted an investigation into the sexual abuse and
                        rape of models - many of which were children at the time
                        - in certain agencies. The BBC reportedly filmed Gérald
                        Marie confessing that he hoped to seduce the contestants
                        at Elite Model Look (the new name for Look of the Year),
                        as well as offering an undercover journalist money for
                        sex. The former BBC journalist Lisa Brinkworth was
                        sexually assaulted by Gérald Marie in a nightclub in
                        October 1998, when she was posing undercover as a model.
                        Marie was suspended from Elite; in an interview at the
                        time, he said: “I’m destroyed… I’m finished.”{" "}
                      </p>
                      <br></br>
                      <p>
                        Soon after, Elite Model Management. launched a
                        defamation suit against the BBC arguing that the report
                        was “dishonestly edited” and stated that Gérald Marie
                        had been set up. The case was settled in 2001 and the
                        BBC was forced to apologize. The documentary film
                        disappeared and Gérald Marie was reinstated, continuing
                        to run Elite Model Look for many more years. Lisa
                        Brinkworth was traumatized and is prevented from coming
                        forward by an agreement between the BBC and Elite in
                        2001 following the defamation suit launched by Elite
                        Model Management. Her lawyers hope this restriction on
                        her speaking out can be a basis for bypassing the
                        statute of limitations. [14] In 2004, Elite was forced
                        into bankruptcy, splitting into two separate agencies,
                        owned by different corporate entities, which still
                        operate today. Marie continued to work for the New York
                        division of Elite, Creative World Management, until
                        2011. [15]
                      </p>
                      <br></br>
                      <h2>Gérald Marie and Oui Management</h2>
                      <p>
                        In 2012, Marie joined Oui Management, a prestigious
                        Paris agency whose models front Louis Vuitton campaigns
                        and Vogue magazine covers. He remains an investor in
                        Oui, which is registered in the UK. Although Marie has
                        claimed he is retired, industry insiders who work with
                        Oui Management say that until recently he had a
                        “hands-on” role. Marie was accompanying models on
                        castings with photographers as recently as last year.
                        Marie’s LinkedIn page, which he recently deleted, had
                        listed his responsibilities at the “thriving newcomer”
                        agency as scouting for and managing talent. Oui
                        Management claims Marie is not currently an employee.
                        According to documents filed in August 2020 Marie
                        continues to be a “person with significant control” over
                        Oui Management Ltd. [16]
                      </p>
                      <br></br>
                      <h2>Gérald Marie under investigation in France</h2>
                      <p>
                        In 2020, French prosecutors have opened an investigation
                        into Gérald Marie following complaints from at least 4
                        victims. They include former model Carré Sutton
                        (formerly Otis) who was 17 when Gérald Marie repeatedly
                        raped her in 1986. Jill Dodd who was 19 when Gérald
                        Marie raped her in 1980; Ebba Karlsson who was 20 or 21
                        when Gérald Marie raped her in 1990, and the former
                        undercover journalist Lisa Brinkworth, who was sexually
                        assaulted by her when she was working on an
                        investigation on sexual abuse in the modeling industry
                        for the BBC in 1998. [17]
                      </p>
                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Qui est Gérald Marie, l’ancien patron d’Elite Europe
                          accusé de viols ?. (2020). Retrieved 22 December 2020,
                          from
                          https://www.20minutes.fr/justice/2869815-20200925-gerald-marie-ancien-patron-elite-europe-accuse-viols-anciens-mannequins#xtor=RSS-149
                        </li>
                        <br></br>
                        <li>
                          Robertson, L. (2019). Did a Miami-based modeling
                          agency fuel Jeffrey Epstein’s ‘machine of abuse’?.
                          https://www.miamiherald.com. Retrieved 18 December
                          2020, from
                          https://www.miamiherald.com/news/local/article238351108.html.
                        </li>{" "}
                        <br></br>
                        <li>
                          Qui est Gérald Marie, l’ancien patron d’Elite Europe
                          accusé de viols ?. (2020). Retrieved 22 December 2020,
                          from
                          https://www.20minutes.fr/justice/2869815-20200925-gerald-marie-ancien-patron-elite-europe-accuse-viols-anciens-mannequins#xtor=RSS-149
                        </li>
                        <br></br>
                        <li>
                          Pyman, T. (2020). Seven more models accuse Gérald
                          Marie of sexual misconduct. Retrieved 22 December
                          2020, from
                          https://www.dailymail.co.uk/news/article-8972655/Seven-models-accuse-former-agency-boss-G-rald-Marie-sexual-misconduct.html
                        </li>
                        <br></br>
                        <li>
                          Gross, M., 1995. Model: The Ugly Business Of Beautiful
                          Women. New York: Bantam Books.
                        </li>
                        <br></br>
                        <li>
                          ‘He wanted to control me completely’: the models who
                          accuse Gérald Marie of sexual assault. (2020).
                          Retrieved 23 December 2020, from
                          https://www.theguardian.com/fashion/2020/oct/17/he-wanted-to-control-me-completely-the-models-who-accuse-gerald-marie-of-sexual-assault
                        </li>
                        <br></br>
                        <li>
                          ‘He wanted to control me completely’: the models who
                          accuse Gérald Marie of sexual assault. (2020).
                          Retrieved 23 December 2020, from
                          https://www.theguardian.com/fashion/2020/oct/17/he-wanted-to-control-me-completely-the-models-who-accuse-gerald-marie-of-sexual-assault
                        </li>
                        <br></br>
                        <li>
                          Model Wars and the Birth of the Supermodel. (2020).
                          Retrieved 23 December 2020, from
                          https://anyageorgijevic.com/2015/08/model-wars-and-the-birth-of-the-supermodel.html
                        </li>
                        <br></br>
                        <li>
                          Jr., R., & Boburg, S. (2016, June 17). The man who
                          showed Donald Trump how to exploit power and instill
                          fear. Retrieved November 16, 2020, from
                          https://www.washingtonpost.com/investigations/former-mccarthy-aide-showed-trump-how-to-exploit-power-and-draw-attention/2016/06/16/e9f44f20-2bf3-11e6-9b37-42985f6a265c_story.html
                        </li>
                        <br></br>
                        <li>
                          Osborne, L. (2020). 'What he was doing was in plain
                          sight': more ex-models accuse Gérald Marie of sexual
                          assault. Retrieved 23 December 2020, from
                          https://www.theguardian.com/fashion/2020/nov/20/plain-sight-more-models-accuse-gerald-marie-sexual-assault-elite
                        </li>
                        <br></br>
                        <li>
                          Osborne, L. (2020). Linda Evangelista praises women
                          accusing her ex-husband of rape. Retrieved 23 December
                          2020, from
                          https://www.theguardian.com/world/2020/oct/16/linda-evangelista-praises-women-accusing-her-ex-husband-gerald-marie
                        </li>
                        <br></br>
                        <li>
                          Kirchgaessner, S., Osborne, L., & Davies, H. (2020).
                          Teen models, powerful men and private dinners: when
                          Trump hosted Look of the Year. Retrieved 23 December
                          2020, from
                          https://www.theguardian.com/us-news/2020/mar/14/teen-models-powerful-men-when-donald-trump-hosted-look-of-the-year
                        </li>
                        <br></br>
                        <li>
                          ‘He wanted to control me completely’: the models who
                          accuse Gérald Marie of sexual assault. (2020).
                          Retrieved 23 December 2020, from
                          https://www.theguardian.com/fashion/2020/oct/17/he-wanted-to-control-me-completely-the-models-who-accuse-gerald-marie-of-sexual-assault
                        </li>
                        <br></br>
                        <li>
                          Former Elite model agency boss investigated over rape
                          allegations. (2020). Retrieved 23 December 2020, from
                          https://www.theguardian.com/fashion/2020/sep/28/former-elite-model-agency-boss-gerald-marie-investigated-france-over-allegations
                        </li>
                        <br></br>
                        <li>
                          ‘He wanted to control me completely’: the models who
                          accuse Gérald Marie of sexual assault. (2020).
                          Retrieved 23 December 2020, from
                          https://www.theguardian.com/fashion/2020/oct/17/he-wanted-to-control-me-completely-the-models-who-accuse-gerald-marie-of-sexual-assault
                        </li>
                        <br></br>
                        <li>
                          ‘He wanted to control me completely’: the models who
                          accuse Gérald Marie of sexual assault. (2020).
                          Retrieved 23 December 2020, from
                          https://www.theguardian.com/fashion/2020/oct/17/he-wanted-to-control-me-completely-the-models-who-accuse-gerald-marie-of-sexual-assault
                        </li>
                        <br></br>
                        <li>
                          Osborne, L. (2020). Linda Evangelista praises women
                          accusing her ex-husband of rape. Retrieved 23 December
                          2020, from
                          https://www.theguardian.com/world/2020/oct/16/linda-evangelista-praises-women-accusing-her-ex-husband-gerald-marie
                        </li>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Marie;
