import React from "react";
import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/EminAgalarov.png";


// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton
} from "react-share";


class Emin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };    
   } 
  render() {
    
    return (
      <>
       
        <div className="wrapper">
          
        <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
              
            <Container className="content">
            <Row>
          <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                      <img
                        alt="..."
                        className="avatar"
                        src={image}
                      />
                      <h4 className="title">Emin Agalarov</h4>
                    {/* </a> */}
                    <p className="description">Billionaire</p>
                  
                  <Button  color="primary"id="contents" size="sm">
                    Contents {''}
                    <i className="tim-icons icon-bullet-list-67" />
                  </Button>                 
                  <UncontrolledCollapse toggler="#contents" style={{textAlign:'left', marginBottom: '1rem'}}>
                      <ol>
                        <li>Introduction</li>
                        <li>Emin and Donald Trump</li>
                         
                      </ol> 
                </UncontrolledCollapse>
                  <Button  color="primary" id="connections" size="sm" >
                    Connections {''}
                    <i className="tim-icons icon-vector" />
                  </Button>
                  <UncontrolledCollapse toggler="#connections" style={{textAlign:'left', marginBottom: '1rem'}}>            
                  <br></br>
                  <ul>
                    <li>Ilham Aliyev</li>
                    <li>Jared Kushner</li>
                    <li>Paul Manfort</li>
                    <Link to='Donald-Trump'><li>Donald Trump</li></Link>
                    <li>Donald Trump Jr</li>
                    <li>Natalia Veselnitskaya</li>

                  </ul>
                <h4>Family Of:</h4>
                <ul>
                <Link to='Aras-Agalarov'><li>Aras Agalarov</li></Link>
                <li>Leyla Aliyeva</li>
                </ul>
                  <h4>Owner / Founder Of:</h4>
                  <ul>
                     <li>Crocus Group</li>           
                  </ul>
                </UncontrolledCollapse>
                  <div>
                </div>
                </div>
                
                </CardBody>
                <CardFooter>
                <div className="button-container">
                <h5>Share:</h5>        
                <FacebookShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="facebook">
                  <i className="fab fa-facebook" />
                </Button>
                </FacebookShareButton>
                <TwitterShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="twitter">
                  <i className="fab fa-twitter" />
                </Button>
                </TwitterShareButton>
                <RedditShareButton
                  text={'hello world'}
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="reddit">
                  <i className="fab fa-reddit-alien" />
                </Button>
                </RedditShareButton>                
                </div>  

                </CardFooter>
              </Card> 
              
                <Alert color="danger">
               LATEST UPDATE NOVEMBER 26 | Introduction</Alert>
                
            
            </Col>
            <Col md="8">
            

              <Card>
                <CardBody>
                  <h1 class="typography-line">Emin Agalarov</h1>
                  
                  <h2> Introduction </h2>
                  <p>Emin Araz oglu Agalarov, stage name Emin, is an Azerbaijani singer-songwriter and the son of Aras Agalarov, a Russian billionaire linked to the Kremlin and organized crime. Emin is listed as executive vice president of the Crocus Group [1] and was previously married to Leyla Aliyeva, the daughter of the President of Azerbaijan Ilham Aliyev. [2] </p>
                  <br></br>
                  <h2>Emin and Donald Trump</h2>
                  <p>
                  In 2016 Emin Agalarov arranged the meeting between Donald Trump Jr., Jared Kushner, and Paul Manafort and Russian lawyer and spy Natalia Veselnitskaya. Following the meeting, Aras Agalarov wired $1.2 million to a bank in New Jersey controlled by Emin which was subject to a US investigation. Emin said that Donald Trump had sent them a handwritten note after winning the United States presidential election in 2016, saying he does not forget his friends. [3]
                  </p>
                  <br></br>     
              </CardBody>
              <CardFooter>
                <h4>Citations</h4>
              <ol>
                <li>Emin Agalarov, Executive Vice President of Crocus Group, in the F1rst Talk project - Crocus Group. Crocus-city.com. (2020). Retrieved 26 November 2020, from http://www.crocus-city.com/press-center/interview_N3H/51878/.</li>   
         <br></br>
          <li>Emin: a singer with connections. BBC News. (2020). Retrieved 26 November 2020, from https://www.bbc.com/news/entertainment-arts-12481714.</li>
          <br></br>
                <li>Moore, M. (2020). Feds probe ‘suspicious money transfers’ made after Trump Tower meeting. New York Post. Retrieved 26 November 2020, from https://nypost.com/2018/09/12/feds-probe-suspicious-money-transfers-made-after-trump-tower-meeting/.</li>
              </ol>
              </CardFooter>
                     
              </Card>
            </Col>           
          </Row>
            
            </Container>       
          
            </div>

        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
      </>
    );
  }
}

export default Emin;



