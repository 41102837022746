
import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";


// core components
import Navbar from "../components/Navbar/Navbar.js";
import Graph from "../components/Graph/Graph.jsx";
import Footer from "../components/Footer/Footer.js";

// react components

class GraphPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }

  render() {
    return (
      <>
        <div className="wrapper">
       
              <Navbar
              {...this.props}
              // brandText={this.getBrandText(this.props.location.pathname)}
              
            />
            
              <Graph />
                 
          <Footer fluid />
          

        </div>

      </>
    );
  }
}

export default GraphPage;
