import React, { Component } from "react";
import { Link } from "react-router-dom";


class FixedContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "white"
    };
  }



  render() {
    return (
      <div className="fixed-contact">
        <Link to="/contact" style={{color:"#F5F5F5"}}>  
        <div className={this.state.classes}>
               
            <i className="tim-icons icon-email-85 fa-3x mr-2 ml-2"
               style={{
                 fontSize:"20px",
                 height:"40px",
                 paddingTop:"8px",
                 opacity: "0.9"
                   }} />
           
        </div>
        </Link>
      </div>
    );
  }
}

export default FixedContact;
