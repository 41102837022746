
import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";


// core components
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../components/FixedContact/FixedContact.js";

// react components
import {Container, Card, CardBody} from "reactstrap";

//og-image
import image from "../../assets/img/og-img/SpookyConnections.png";


class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }

  render() {
    return (
      <>
        <div className="wrapper">
        <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
              
            <Container className="content">
          
              <Card>
                
               
                
              
                  <CardBody>
                 
                  <div>
                      <h1>About Us</h1>
                    <p>
                   Spooky Connections is an independent international open source
                   investigation to probe transnational organized crime. We operate using open source
                   information from established news outlets and primary sourced documents to graph, map, 
                   and document a clear understanding of organised criminal networks and activities.
                    </p>
                   </div>
                   <br></br>
                   <div>
                    <h4>Contributors</h4>
                    <p>
                   Spooky Connections is an open source collaborative research project. To join us or contribute research please email<bold style={{fontWeight:'600'}}>contact@spookyconnections.com</bold>.</p>
                  </div>
                 
                  </CardBody>
                  
              </Card>
              <Card>
              <img
                   alt="..."
                   src={image}
                      />
              </Card>
            
            </Container>       
          
            </div>

        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
      </>
    );
  }
}

export default About;




