import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/LordMountbatten.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";
// import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class LordMountbatten extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Lord Mountbatten</h4>
                        {/* </a> */}
                        <p className="description">Royal</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>Lord Mountbatten</li>

                        
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul></ul>
                          <h4>Family of:</h4>
                          <ul></ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul></ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE MARCH 3</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Lord Mountbatten</h1>
                      <h2>Introduction</h2>
                      <p>
                        Lord Louis Mountbatten was the last viceroy in India and
                        1st Earl Mountbatten of Burma. Lord Mountbatten was born
                        Prince Louis of Battenberg on 25 June 1900 in Windsor.
                        As the son of Prince Louis of Battenberg and Princess
                        Victoria of Hesse and by Rhine, and as the
                        great-grandson of Queen Victoria, Lord Mountbatten was
                        uncle to Prince Philip and a distant cousin of Queen
                        Elizabeth II. He was an officer in the British Royal
                        Navy and in 1947 was appointed the last Viceroy of
                        India, during the partition of British India into India
                        and Pakistan.[1]
                      </p>
                      <br></br>
                      <h2>Mountbatten and Prince Philip</h2>
                      <p>
                        In 1903, his sister Princess Alice of Battenberg married
                        Prince Andrew of Greece and Denmark. Their son Prince
                        Philip was born in 1921 and was sent to attend the
                        Gordonstoun school in Scotland in 1934. During his time
                        in the UK, Philip was cared for by his grandmother, the
                        Dowager Marchioness of Milford Haven who lived in
                        Kensington Palace and his “Uncle Dickie” , a nickname
                        given to Lord Mountbatten. In 1939, Lord Mountbatten
                        introduced Prince Philip to King George VI, Queen
                        Elizabeth The Queen Mother, and the young Princess
                        Elizabeth during a visit to Dartmouth Naval College.
                        Lord Mountbatten encouraged Prince Philip to pursue
                        Princess Elizabeth with the goal of elevating his own
                        family’s social standing.[2]
                      </p>
                      <br></br>
                      <p>
                        When Prince Philip was required to renounce his Greek
                        citizenship and become a British subject prior to his
                        wedding to Elizabeth, he took Mountbatten as his
                        surname. Lord Mountbatten provided his Broadlands
                        country home to Elizabeth and Philip after their 1947
                        wedding. When Prince Philip was posted to Malta, he and
                        Princess Elizabeth lived in Earl Mountbatten’s Villa
                        Guardamangia. Lord Mountbatten had considerable sway
                        over Prince Philip, His daughter Lady Patricia Brabourne
                        once said, “My father was considered pink — very
                        progressive. The worry was that Prince Philip would
                        bring into court modern ideas and make people
                        uncomfortable.”When King George VI died in 1952, Lord
                        Mountbatten is said to have declared to a room full of
                        guests that “the House of Mountbatten now reigned”. [3]{" "}
                      </p>
                      <br></br>
                      <h2>Mountbatten and Prince Charles</h2>
                      <p>
                        Lord Mountbatten was known to have a close relationship
                        with the Prince of Wales, Charles, becoming a mentor and
                        offering him guidance throughout his life. Lord
                        Mountbatten was the great-uncle to Prince Charles and he
                        advised him in ‘matters of the heart’. Mountbatten is
                        reported to have encouraged Charles to 'sow his wild
                        oats' and embrace his bachelorhood before settling down.
                        They first met when Prince Charles was age 5. [4]
                      </p>
                      <br></br>
                      <p>
                        In 1966, the prolific child rapist Sir James Wilson
                        Vincent Savile, aka Jimmy Savile, became the first
                        civilian to be awarded a Royal Marines’ green beret.
                        Lord Mountbatten was comm­and­ant general at the time.
                        He later introduced Jimmy Savile into royal circles and
                        to Prince Charles. In an interview with Esquire, Savile
                        tells, “The thing about me is I get things done and I
                        work deep cover,” … “I’ve known the Royal Family for a
                        million years.” … “Just because some people are famous
                        or have a lot of money doesn’t mean they don’t have
                        problems.”... ”Coming from Lord Louis, who was the
                        favourite uncle of Prince Philip, that was quite
                        something,” “So obviously I hooked up with the Prince –
                        what was good enough for Lord Louis was good enough for
                        him.” Jimmy Saville was later exposed for having raped
                        and/or sexually assaulted more than 500 people,
                        including hospital patients, children and elders alike.
                        Prince Charles is reported to have identified Jimmy
                        Savile as his ‘best friend’. [5]
                      </p>
                      <br></br>
                      <p>
                        In 2015, Prince Charles visited the site of
                        Mountbatten's death, he said 'I could not imagine how we
                        would come to terms with the anguish of such a deep loss
                        since, for me, Lord Mountbatten represented the
                        grandfather I never had.' Prince Charles's eldest son,
                        Prince William, reportedly named his own son Prince
                        Louis after Lord Mountbatten.[6]
                      </p>
                      <br></br>
                      <h2>FBI Investigation</h2>
                      <p>
                        Lord Mountbatten was reknowned to be wildly promiscuous,
                        bisexual and to abuse young working class boys or
                        peasant indian boys. [4] An FBI dossier on Lord
                        Mountbatten, released in 2019 revealed his predatory
                        behaviour towards children, especially young boys. The
                        FBI began compiling the Mountbatten dossier in February
                        1944, shortly after Mountbatten became Supreme Allied
                        Commander of Southeast Asia. They kept adding to it over
                        the next three decades. The 75-year-old intelligence
                        files describe Louis Mountbatten and his wife Edwina
                        Mountbatten as "persons of extremely low morals" and
                        contain information suggesting that Lord Mountbatten was
                        a pedophile with "a perversion for young boys." [5]
                      </p>
                      <br></br>
                      <p>
                        In a 1944 interview with Elizabeth de la Poer Beresford,
                        Baroness Decies, a friend of the royal family. “She
                        states that in these circles Lord Louis Mountbatten and
                        his wife are considered persons of extremely low morals.
                        She stated that Lord Louis Mountbatten was known to be a
                        homosexual with a perversion for young boys,”
                      </p>
                      <br></br>
                      <p>
                        “In Lady Decies’ opinion he is an unfit man to direct
                        any sort of military operations because of this
                        condition. She stated further that his wife Lady
                        Mountbatten was considered equally erratic.” [6]
                      </p>
                      <br></br>
                      <h2>Mountbatten and Kincora</h2>

                      <p>
                        In 1990, writer Robin Bryans reported that Lord
                        Mountbatten, Anthony Blunt and others were part of an
                        old-boy network that held ‘gay orgies’ in country houses
                        on both sides of the UK / Irish border and at the
                        Kincora boys' home. Decades later a Historical
                        Institutional Abuse (HIA) inquiry concluded that abuse
                        at the home was limited to three staff members and there
                        was no collusion by the state or intelligence services
                        in covering it up.
                      </p>
                      <br></br>
                      <p>
                        In separate allegations, Lord Louis Mountbatten is
                        reported to have raped two teenage boys at the Kincora
                        boys' home in Belfast in the 1970s. Two unnamed men
                        describe being brought from the home in summer 1977 to
                        Mullaghmore where Lord Mountbatten was on holiday. The
                        men, who were both 16 at the time, claim they were
                        abused by Lord Mountbatten. One of them, known as Seán,
                        recalls being driven from Kincora to the Mountbatten’s,
                        Classiebawn Castle, in Sligo where he was raped by a
                        man.He said he did not recognise him as Lord Mountbatten
                        until he saw a report on the TV news two years later
                        that the Lord Mountbatten had been killed.{" "}
                      </p>
                      <br></br>
                      <p>
                        The second interviewee, referred to as Amal, claims to
                        have met Lord Mountbatten four times that summer on day
                        trips from the home in Belfast. He said each encounter
                        lasted about an hour and took place in a suite in an
                        hotel by Mullaghmore harbour. "He was very polite, very
                        nice," Amal is quoted a saying. "I knew he was someone
                        important. He told me he liked dark-skinned people,
                        especially Sri Lankan people as they were very friendly
                        and good-looking." He also claimed that several other
                        boys were brought to Mountbatten on other occasions.{" "}
                      </p>
                      <br></br>
                      <h2>Death on fishing boat at Mullaghmore harbour</h2>

                      <p>
                        The man responsible for trafficking the boys to
                        Classiebawn was Joseph Mains, the Warden of Kincora. By
                        the time of his assassination in August 27, 1979, Lord
                        Mountbatten had been travelling to Ireland, to stay at
                        Classiebawn Castle, for 30 years.[9]
                      </p>
                      <br></br>
                      <p>
                        The bomb on Mountbatten’s boat, Shadow V, killed three
                        other people: his 14-year-old grandson Nicholas
                        Knatchbull, Lady Doreen Brabourne, Nicholas’s
                        83-year-old paternal grandmother, and Paul Maxwell, a
                        15-year-old boat boy. Nicholas’s parents – Mountbatten’s
                        daughter Patricia and her husband, John – and his
                        identical twin, Timothy, were badly injured in the
                        blast. Mountbatten had ignored the advice of his
                        personal security officer not to go to Ireland that year
                        and that his security had been reduced. “Why would he
                        ignore that advice and risk his family? That was an
                        extraordinary thing to do.”{" "}
                      </p>

                      <br></br>
                      <p>
                        It is suggested the IRA may have killed Mountbatten
                        because of the allegations that he was a child rapist
                        rather than because of his position. “There were a lot
                        of IRA people in that area. I am pretty sure they knew
                        [the rumours]. They could have killed him any time in
                        the last 30 years” of his life. Attempts to learn more
                        about Mountbatten’s death have been thwarted by the
                        failure of the UK National Archives to release its files
                        about Kincora Boys’ Home.{" "}
                      </p>
                      <br></br>
                      <p>
                        “There is so much in this story that has not been looked
                        at.”[10]
                      </p>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Louis Mountbatten, 1st Earl Mountbatten | British
                          statesman. (2021). Retrieved 2 March 2021, from
                          https://www.britannica.com/biography/Louis-Mountbatten-1st-Earl-Mountbatten
                        </li>
                        <br></br>
                        <li>
                          Smith, S. (2021). Inside the Dynastic Struggle That
                          Rocked Queen Elizabeth II’s Marriage. Retrieved 2
                          March 2021, from
                          https://www.vanityfair.com/style/society/2012/01/queen-elizabeth-201201
                        </li>
                        <br></br>
                        <li>
                          FBI files allege new royal scandal. (2021). Retrieved
                          2 March 2021, from
                          https://www.news.com.au/entertainment/celebrity-life/royals/fbi-files-claim-lord-louis-mountbatten-had-a-perversion-for-young-boys/news-story/3647da9b3e938ae4aa5d0f3608639479
                        </li>
                        <br></br>
                        <li>
                          Prince Charles and Lord Mountbatten's Treasured
                          Relationship. Biography. (2021). Retrieved 3 March
                          2021, from
                          https://www.biography.com/news/prince-charles-lord-mountbatten-relationship.
                        </li>
                        <br></br>
                        <li>
                          Edge, S. (2021). How Jim really did fix it.
                          Express.co.uk. Retrieved 3 March 2021, from
                          https://www.express.co.uk/expressyourself/43798/How-Jim-really-did-fix-it.
                        </li>
                        <br></br>
                        <li>
                          Cope, R. (2021). What was Lord Mountbatten's
                          relationship with Prince Charles really like?. Tatler.
                          Retrieved 3 March 2021, from
                          https://www.tatler.com/article/lord-mountbatten-relationship-prince-charles-death.
                        </li>
                        <br></br>
                        <li>
                          Lord Mountbatten linked to Kincora child abuse ring -
                          Indymedia Ireland. Indymedia.ie. (2021). Retrieved 3
                          March 2021, from
                          http://www.indymedia.ie/article/20885.
                        </li>
                        <br></br>
                        <li>
                          Lord Mountbatten, murdered by IRA, was a pedophile.
                          IrishCentral.com. (2021). Retrieved 3 March 2021, from
                          https://www.irishcentral.com/roots/history/lord-mountbatten-pedophile-allegations.
                        </li>
                        <br></br>
                        <li>
                          Tucker, G. (2021). Lord Mountbatten’s ‘lust for young
                          men’ revealed. Retrieved 2 March 2021, from
                          https://www.thetimes.co.uk/article/lord-mountbattens-lust-for-young-men-revealed-90swzmgms
                        </li>
                        <br></br>
                        <li>
                          The Crown and the assassination of Lord Mountbatten:
                          fact or fiction?. The Irish Times. (2021). Retrieved 3
                          March 2021, from
                          https://www.irishtimes.com/culture/heritage/the-crown-and-the-assassination-of-lord-mountbatten-fact-or-fiction-1.4413622.
                        </li>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default LordMountbatten;
