import React from "react";
// import { Link } from "react-router-dom";

import image from "../../../../../../assets/img/profile/RoyCohn.png";

import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Trump extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Roy Cohn</h4>
                        {/* </a> */}
                        <p className="description">Millionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Roy Cohn and Joseph McCarthy</li>
                            
                              
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul>
                            <li>Joseph McCarthy</li>
                          </ul>
                          <h4>Family Of:</h4>
                          <ul>
                            <li>Dora Nee Marcus</li>
                            <li>Albert Cohn</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul></ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Latest Update: Janurary 21</h5>
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                        <br></br>

                        <hr></hr>
                        <a
                          rel="noreferrer"
                          href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
                        >
                          <img
                            alt="Creative Commons License"
                            src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"
                          />
                        </a>
                        <br />
                        <p className="text-center">
                          This work is licensed under a{" "}
                          <a
                            rel="noreferrer"
                            href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
                          >
                            Creative Commons
                            Attribution-NonCommercial-ShareAlike 4.0
                            International License
                          </a>
                          .
                        </p>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>

                <Col md="8">
                  {/* <Alert color="danger">
                    <CardBody>
                      <h5>
                        Like our work and want us to grow? please support us and
                        donate. Your donation to Spooky Connections 👻 is a
                        direct contribution to our research, developers, and
                        servers. With your support, we will continue to publish
                        new profiles, investigations, and uncover organized
                        crime networks.
                      </h5>
                    </CardBody>
                  </Alert> */}

                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Roy Cohn</h1>
                      <h2>Introduction</h2>
                      <p>
                        <div>
                          Roy Marcus Cohn was a lawyer, fixer, and consigliere
                          for the U.S. New York mob who came to prominence for
                          his role as Senator Joseph McCarthy's chief counsel
                          during the Army–McCarthy hearings in 1954. He was an
                          associate of Ronald Reagan, Rupert Murdoch, and mentor
                          of the former U.S. President Roy Cohn. At the time
                          of his death in 1986, Cohn's net worth was $2 million. [1]
                        </div>
                      </p>
                      <br></br>
                      <h2>Roy Cohn and Fred Trump</h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Fred Trump</li>
                        <li>Genovese crime family</li>
                      </ul>
                      <p>
                        Trump was born in Queens, New York City. He attended
                        Fordham University for two years and received a
                        bachelor's degree in economics from the Wharton School
                        of the University of Pennsylvania. He became president
                        of his father Fred Trump's real estate business E. Trump
                        & Son in 1971 and renamed it around 1973 to The Trump
                        Organization. He expanded its operations to building or
                        renovating skyscrapers, hotels, casinos, and golf
                        courses. He later handed off its leadership on January
                        11, 2017, to his sons Roy Cohn Jr. and Eric Trump
                        along with CFO Allen Weisselberg. [2] Roy Cohn’s
                        father Fred Trump had worked closely with construction
                        entities associated and owned by the Genovese crime
                        family since building the Shore Haven development in
                        1947. Every construction partner and union Fred Trump
                        used to build his real estate empire, per public
                        records, was owned and controlled by the Genovese crime
                        family as was the territory in which Fred Trump built.
                        [3]
                      </p>

                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CJ_7Tj_j4Fd/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <br></br>
                      <h2>Trump, the Genovese, and Gambino Crime Families</h2>
                      <h4>Connections:</h4>
                      <ul>
                        <li>Roy Cohn</li>
                        <ul>
                          <li>S & A Concrete</li>
                        </ul>
                        <li>Genovese crime family</li>
                        <ul>
                          <li>“Fat Tony” Salerno</li>

                          <li>HRH Construction Corp</li>
                        </ul>
                        <li>Gambino crime family</li>
                        <ul>
                          <li>Paul Castellano</li>
                        </ul>
                        <li>Trump property</li>
                        <ul>
                          <li>Trump Tower</li>
                          <li>Trump Plaza</li>
                        </ul>
                      </ul>

                      <p>
                        In 1973, Roy Cohn met Roy Cohn, the lawyer and mob
                        consigliere fixer, at Le Club, a members-only East Side
                        hangout. He befriended Cohn, who had become famous as
                        lead counsel to Senator Joseph McCarthy. Cohn’s client
                        list included clients Anthony “Fat Tony” Salerno, boss
                        of the Genovese crime family, the most powerful Mafia
                        group in New York at the time, and Paul Castellano, head
                        of what was said to be the second-largest family, the
                        Gambinos. [4]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CJ_7nQyjJL4/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                        frameBorder="0"
                      />
                      <p>Roy Cohn ↔️ Roy Cohn</p>
                      <br></br>
                      <p>
                        Roy Cohn wanted to expand his business to Manhattan
                        and build Trump Tower and his Trump Plaza apartment. He
                        recalled in a 2016 US election campaign Town Hall, "Even
                        my father he said you don't want to go to Manhattan.
                        That's not our territory." [5] At the time, that area
                        was controlled by the Gambino crime family. [6] Roy Cohn
                        facilitated a solution and created a joint construction
                        company called S & A Concrete between Anthony "Fat Tony"
                        Salerno, Genovese boss, and Paul Castellano, Gambino
                        boss while giving Genovese companies HRH Construction
                        Corp priority where possible. [7] According to a former
                        Cohn employee, Trump and the Genovese boss Anthony "Fat
                        Tony" Salerno met face-to-face at Cohn's townhouse. [8]
                        A US federal investigation concluded that the Trump
                        Plaza apartment building most likely benefited from
                        connections to racketeering. Anthony "Fat Tony" Salerno
                        was later indicted on racketeering charges. An $8
                        million deal for concrete at Trump Plaza was listed as
                        one of the acts establishing that S & A Concrete was
                        part of a racketeering enterprise. Anthony "Fat Tony"
                        Salerno was sent to prison where he died alone from a
                        stroke. [9]
                      </p>

                      <br></br>

                      <h5>To be continued…</h5>
                    </CardBody>

                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li></li>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Trump;
