import React, { useState, useEffect } from "react";

import Individuals from "./Individuals";
import Deatheater from "../../components/Deatheater/Deatheater.jsx";
// import Globe from "../../components/Globe/Globe.jsx";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Input,
  InputGroup, 
  InputGroupAddon, 
  InputGroupText,
  Row
} from "reactstrap";


function Persons() {
  const [allPersons, setAllPersons] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setAllPersons([...Individuals]);
  }, []);

  const search = (e) => {
    e.preventDefault();
    console.log('working');

    const results = [...Individuals].filter((item) => {
      return (
        item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        item.state.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    });

    setAllPersons([...results]);

    query === '' && setAllPersons([...Individuals])
  };


  return (
    
<div className="content">
    <Row>
      <Col xs="12">

           <h4 className="title">
           <InputGroup className="all-icons" >
        <InputGroupAddon addonType="prepend">
          <InputGroupText type='text'>
            
          <i className="tim-icons icon-zoom-split" />

          </InputGroupText>
        </InputGroupAddon>
        <Input 
                type='text'
                value={query}
                onChange={e => [setQuery(e.target.value), search(e)]}
                onClick={e => [setQuery(e.target.value), search(e)]}
                placeholder='Search by name... ' />
      </InputGroup>
           </h4>  
      
        <Card className="card-user">
         <CardHeader>
         
                         
         </CardHeader>
         <CardBody className="all-icons">          
         <Row>
         {allPersons.map((item) => (
            <Deatheater
            key={item.id}       
            image={item.image} 
            name={item.name}
            state={item.state}  
            wealth={item.wealth}         
            occupation={item.occupation} 
            page={item.page}
            />
          ))}
         </Row>
         </CardBody>
       </Card>
      </Col>
    </Row>
 </div> 
  

  );
}

export default Persons;

