import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/JeanLucBrunel.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Brunel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Jean-Luc Brunel</h4>
                        {/* </a> */}
                        <p className="description">Millionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>
                              Jean-Luc Brunel, Claude Haddadd, Garie Marie, and
                              60 minutes
                            </li>
                            <li>
                              Jean-Luc and Arnaud Brunel, Next Management
                              Company, Steven Mnuchin, and Faith Kates
                            </li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul>
                            <li>Jeffrey Epstein</li>
                            <li>Ghislaine Maxwell</li>
                            <li>Gerald Marie</li>
                            <li>Claude Haddad</li>
                            <li>Faith Kates</li>
                            <li>Steven Mnuchins</li>
                            <li>Paolo Zampolii</li>
                            <li>Amanda Ungaro</li>
                          </ul>
                          <h4>Family of:</h4>
                          <ul>
                            <li>Arnaud Brunel</li>
                            <li>Candelita Brunel</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Next Management Corporation</li>
                            <li>MC2 Model Management</li>
                            <li>Karin Models</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE DECEMBER 20th</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Jean-Luc Brunel</h1>
                      <h2> Introduction </h2>

                      <p>
                        Jean-Luc Brunel (born Jean-Luc Benchamoul) is a French
                        model scout and former modeling agency manager, and a
                        close associate of Ghislaine Maxwell and Jeffrey
                        Epstein. He led the modelling agency Karin Models, and
                        founded MC2 Model Management, with financing by child
                        trafficker Jeffrey Epstein, and which had offices in New
                        York, Miami, and Tel Aviv. [1] Jean-Luc Brunel was known
                        being the "son of a good family" of the beautiful
                        Parisian districts and father was a high society
                        Parisian real estate executive; their presence and
                        identities have been scrubbed from the internet. Brunel
                        was a “scouting tsunami” according to the MC2 Model
                        Management website for his access to girls from South
                        America and Eastern Europe and has a reputation as a
                        cocaine addict who sexually assaulted child models. [2]{" "}
                      </p>
                      <br></br>
                      <h2>
                        Jean-Luc Brunel, Karin Model Agency, and 60 minutes
                      </h2>
                      <p>
                        In 1977, Jean-Luc Brunel co-founded Karin Model Agency
                        in Paris and rose to prominence during the 1980s as a
                        model scout in Paris. He launched the careers of some of
                        the most successful models of the era, such as Jerry
                        Hall, Angie Everhart, Christy Turlington, Sharon Stone,
                        and Christy Turlington. [3] Jean-Luc Brunel was a
                        regular at prominent Parisian nightclubs such as Les
                        Bains Douches nightclub “where young women who’ve
                        starved themselves parade for Jean-Luc Brunel”,
                        according to Desiree Gruber, a PR executive who was then
                        a budding model. [4]
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        At Les Bains Douches young models often underage were
                        introduced to drugs and alcohol and preyed upon by older
                        and often influential men. It was a hunting ground for
                        other predators such as Gerald Marie, Prince Albert of
                        Monaco, Roman Polanski, and others. [5][6][7] After
                        parties were hosted at Brunel’s home near the Karin
                        headquarters on Avenue Hoche; the apartment housed a
                        succession of younger women and girls, many of them
                        children, who arrived from the US and elsewhere in
                        Europe. The women and girls were expected to share
                        bedrooms and to keep Brunel and his male friends
                        company. Many report being drugged or raped by Brunel.
                        [8] According to former model Courtney Soerensen of
                        Karin Models, "There was a group of French businessmen,
                        in their forties. It was with them that we had to have
                        lunch.” "It had nothing to do with work. We were clearly
                        expected to have sex with them." "a lot of people know
                        this about Jean-Luc". [9]
                      </p>
                      <br></br>
                      <p>
                        In the 1980’s, Jean-Luc Brunel, and modeling agents
                        Claude Haddad and Gérald Marie used to rape models as
                        young as 15 years old. [10] "The photographer Alé de
                        Basseville, who started at that time, says at 20 Minutes
                        that several models told him they had been raped by
                        Claude Haddad" In 1988, Jean-Luc Brunel was the subject
                        of a 60 Minutes investigation where he has faced
                        allegations of sexual assault spanning for over three
                        decades.[11] 60 Minutes interviewed dozens of models who
                        were sexually assaulted by Brunel and Haddad. At least
                        five models said that Brunel and his friends had drugged
                        and raped them.[12]
                      </p>
                      <br></br>
                      <h2>
                        Jean-Luc and Arnaud Brunel, and Next Management
                        Corporation
                      </h2>
                      <p>
                        Jean-Luc Brunel has one brother, Arnaud Brunel, a
                        businessman who is regularly featured in society
                        columns. In 1988, Jean-Luc Brunel and Arnaud Brunel
                        founded the Next Management Corporation. In 1989, the
                        Brunel brothers and Faith Kates formed the global
                        modeling agency Next Management Company. [13] Faith
                        Kates owned a majority stake in the company with the
                        Brunel brother's Next Management Corp. owning a minority
                        stake at 25 percent. [14] In April 1996 the Brunel
                        brothers split off from Next Management Company with The
                        Miami models. [15] Which led to Next Management Company
                        suing the Brunel brothers in 1996. [16]
                      </p>
                      <br></br>
                      <p>
                        A report in 2020, revealed the .U.S. Treasury Secretary
                        Steven Mnuchin, served as the official state point of
                        contact for Next Management Corporation. [17] A Treasury
                        Department official claimed, “Secretary Mnuchin never
                        served in any official capacity in Next Management
                        Corporation, never did any business with nor knows
                        either of the Brunel brothers, and never had any
                        ownership stake in Next Management Corporation or any
                        related entities,”. However, Mnuchin's office did admit
                        Steven Mnuchin was a close friend of Faith Kates, the
                        President and co-founder of Next modelling agency. [18]
                        Faith Kates later claimed that she had no involvement
                        with Next Management Corporation, “Ms. Kates was never
                        involved with Next Management Corp. in any way,”...
                        “Given that, she never designated anyone, including Sec.
                        Mnuchin, to be a DOS process or any officer or director
                        of Next Management Corp.” They went on to claim Faith
                        Kates had broken off contact with the Brunels during the
                        1996 lawsuit. However, it was reported that Faith Kates
                        was in contact with their mutual friend child trafficker
                        Jeffrey Epstein in recent years who regularly visited
                        Kates' New York office and made substantial donations.
                        [19]{" "}
                      </p>
                      <br></br>
                      <h2>Jean-Luc Brunel, Jeffrey Epstein, MC2 Models</h2>
                      <p>
                        In the 1980’s Jean-Luc Brunel met Ghislaine Maxwell. She
                        later introduced him to child trafficker Jeffrey
                        Epstein. [20] Brunel and Epstein became very close
                        friends and business associates. [21] In the mid-90s
                        Jean-Luc Brunel moved to the United States in hopes of
                        relaunching his career after the rape accusations at the
                        end of 1988.[22] When Jeffrey Epstein bought his huge
                        Parisian apartment on Avenue Foch in 2001, he consulted
                        Jean-Luc Brunel. [23]{" "}
                      </p>
                      <br></br>
                      <p>
                        Jean-Luc Brunel, along with numerous young models, was a
                        frequent passenger on Epstein’s private jet the Lolita
                        Express, according to flight manifests Brunel visited
                        Epstein at least 67 times during his 2011 house arrest.
                        [24] Flight logs from Epstein’s private jet show that
                        between 1998 and 2005, “Jean Luc Brunel,” a “Jean Luke”
                        or “JLB” appeared 25 times, headed to destinations
                        Epstein owns homes, including New York, Paris, Palm
                        Beach, and the Virgin Islands. [25] Brunel also appeared
                        on a June 27, 2002, flight log, listed with Amanda
                        Ungaro, the future common-law wife of Paolo Zampolli.
                        Only a handful of people, including Ghislane Maxwell,
                        appear more times.[26]
                      </p>
                      <br></br>
                      <p>
                        In 2005, Jean-Luc Brunel founded the MC2 Model
                        Management agency which Jeffrey Epstein invested $1
                        million, according to a 2010 deposition. [27] Epstein
                        promised girls as young as 13, 14, and 15 a MC2 modeling
                        contract in exchange for being raped by whichever
                        acquaintance Epstein designated.[28] The girls were
                        lured from South America, Europe, and the former Soviet
                        Union, Jean-Luc Brunel's bookkeeper backed the
                        allegations; he testified it wasn’t unusual for MC2 to
                        send girls to an assignment with a wealthy client for
                        $100,000 or more, but the girl wouldn’t be paid the full
                        amount or anything at all if she refused to be raped.
                        [29]
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        According to sources at MC2, Jean-Luc Brunel scours the
                        world for un-agented and vulnerable young girls and
                        operates mostly independently and with little oversight
                        — even relative to the almost totally unregulated
                        modeling industry itself. [30] Jean-Luc Brunel employed
                        200 to 300 “models'' at a time, most of them from 13 to
                        15 years old, and kept them in an apartment building
                        owned by Jeffrey Epstein at East 66th Street in New
                        York. [31] Jeffrey Epstein bragged that he 'procured'
                        Nadia Marcinkova when she was 15, from the former
                        Yugoslavia. [32] According to court documents Jean-Luc
                        Brunel appeared to have an arrangement with the U.S.
                        Government where he could get passports or other travel
                        documents for young girls. Jean-Luc Brunel would lure
                        women and girls ages 12 to 24 to the U.S. for sexual
                        purposes and farm them out to his friends including
                        Jeffrey Epstein. [33] In the mid-1990s Jeffrey Epstein
                        had been posing as a talent agent for the lingerie brand
                        Victoria's Secret which works closely with MC2. When
                        confronted with this information, the mob linked, Les
                        Wexner told the executives he would handle the
                        "situation." Wexner's Victoria's Secret reportedly
                        continued to work with Jean-Luc Brunel's MC2 Management
                        long after it was accused of being used to supplying
                        children to Jeffrey Epstein. [34] According to witnesses
                        Brunel once bragged about paying to have three
                        12-year-old girls, believed to be sisters, flown in from
                        France for one day as a gift to Jeffrey Epstein. [35] At
                        one point Brunel offered Epstein two 8 year old girl.
                        [36]{" "}
                      </p>
                      <br></br>

                      <h1>Arrest</h1>

                      <p>
                        In 2010 the FBI allegedly investigated Jean-Luc Brunel
                        and MC2 Model Management in connection to the Epstein
                        case. The extent of that investigation is undisclosed.
                        [37] A complaint filed (reported in 2010) stated that
                        Epstein and Brunel “engaged in racketeering, luring
                        minor children through MC2, mostly girls under 17,” and
                        to force them into rape slavery [38] In 2019 Epstein was
                        arrested and Burnel disappeared from public view shortly
                        after Epstein’s death in a Manhattan prison. In December
                        2019 he told police he was available to help with their
                        inquiries but made himself difficult to find.{" "}
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        In 2019, the French National Police opened an
                        investigation into Jean-Luc Brunel after he went into
                        hiding after the death of Jeffrey Epstein [39]; On
                        December 17, 2020, Brunel was arrested at
                        Paris-Charles-de-Gaulle airport , and placed in police
                        custody in connection with the Epstein case and on
                        charges of child rape and sexual assault, sexual
                        harassment, and child rape slavery, while he was about
                        to embark for Senegal. [40]{" "}
                      </p>

                      <br></br>
                      
                    </CardBody>
                    <CardFooter>
                      <h4>Citations coming soon...</h4>
                    </CardFooter>
                    {/* <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Sarnoff, C. (2019). Jeffrey Epstein Pedophile
                          Billionaire and His Sex Den. The Daily Beast.
                          Retrieved 18 December 2020, from
                          https://www.thedailybeast.com/jeffrey-epstein-pedophile-billionaire-and-his-sex-den.
                        </li>
                        <br></br>
                        <li>
                          Robertson, L. (2019). Did a Miami-based modeling
                          agency fuel Jeffrey Epstein’s ‘machine of abuse’?.
                          https://www.miamiherald.com. Retrieved 18 December
                          2020, from
                          https://www.miamiherald.com/news/local/article238351108.html.
                        </li>{" "}
                        <br></br>
                        <li>
                          Cobbe, E. (2019). Ex-model accuses Jeffrey Epstein's
                          friend of rape. Cbsnews.com. Retrieved 18 December
                          2020, from
                          https://www.cbsnews.com/news/jeffrey-epstein-news-paris-model-agent-jean-luc-brunel-accused-rape-criminal-complaint-today-2019-10-17/.
                        </li>
                        <br></br>
                        <li>
                          Arnold, A. (2020). What We Know About Jean-Luc Brunel,
                          One of the Men Closest to Epstein. The Cut. Retrieved
                          18 December 2020, from
                          https://www.thecut.com/2020/12/jean-luc-brunel-alleged-epstein-accomplice-found.html.
                        </li>
                        <br></br>
                        <li>
                          Willsher, K. (2020). France detains modelling agent in
                          Jeffrey Epstein inquiry. the Guardian. Retrieved 18
                          December 2020, from
                          https://www.theguardian.com/world/2020/dec/17/france-detains-modelling-agent-jean-luc-brunel-in-jeffrey-epstein-inquiry.
                        </li>
                      </ol>
                    </CardFooter> */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          ++
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Brunel;
