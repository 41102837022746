// eslint-disable-next-line
export default [
  {
    "id": "1",
    "name": "Aras Agalarov",
    "state": "Russia",
    "wealth": "Billioniare",
    "occupation": "Businessman, Property Developer",
    "page": "/Aras-Agalarov",
    "image": "http://spookyconnections.com/static/media/ArasAgalarov.54a1a42d.png",
  },
  {
    "id": "2",
    "name": "Emin Agalarov",
    "state": "Russia",                                                                                                                                                                                   
    "wealth": "Billionaire",
"occupation": "Businessman, Singer, Songwriter",
    "page": "/Emin-Agalarov",
    "image": "http://spookyconnections.com/static/media/EminAgalarov.1d8f301e.png",
  },
  {
    "id": "3",
    "name": "Jean-Luc Brunel",
    "state": "France",
    "wealth": "Millionaire",
    "occupation": "Modeling Scout and Agent",
    "page": "/Jean-Luc-Brunel",
    "image": "http://spookyconnections.com/static/media/JeanLucBrunel.27bd59a4.png",
  },
//   {
//     "id": "3",
//     "name": "Tevfik Arif",
//     "state": "Turkey",
//     "wealth": "Billionaire",
//     "occupation": "Real estate developer, investor, Trafficker",
//     "page": "/Tevfik-Arif",
//     "image": "http://spookyconnections.com/static/media/TevfikArif.67196866.png",
//   },
//   {
//     "id": "4",
//     "name": "Ricardo Bellino",
//     "state": "Brazil",
//     "wealth": "Billionaire",
// "occupation": "Businessman, Investor, Author",
//     "page": "/Ricardo-Bellino",
//     "image": "http://spookyconnections.com/static/media/RicardoBellino.43b2d154.png",
//   },
  {
    "id": "5",
    "name": "John Casablancas",
    "state": "United States",
    "wealth": "Billionaire",
    "occupation": "Businessman, Modeling scout and agent",
    "page": "/John-Casablancas",
    "image": "https://www.spookyconnections.com/static/media/JohnCasablancas.e1afabf0.png",
  },
  {
    "id": "6",
    "name": "Ivan Duque",
    "state": "Colombia",
    "wealth": "Millionaire",
    "occupation": "President",
    "page": "/Ivan-Duque",
    "image": "https://www.spookyconnections.com/static/media/IvanDuque.f9861e34.png",
  },
  {
    "id": "7",
    "name": "King Edward VIII",
    "state": "United Kingdom",
    "wealth": "Royal",
    "occupation": "Royal",
    "page": "/King-EdwardVIII",
    "image": "https://www.spookyconnections.com/static/media/KingEdward.f938daf1.png",
  },
  {
    "id": "8",
    "name": "Lord Mountbatten",
    "state": "United Kingdom",
    "wealth": "Royal",
    "occupation": "Royal",
    "page": "/Lord-Mountbatten",
    "image": "https://www.spookyconnections.com/static/media/LordMountbatten.53be426c.png",
  },
  {
    "id": "9",
    "name": "Prince Philip",
    "state": "United Kingdom",
    "wealth": "Royal",
    "occupation": "Royal",
    "page": "/Prince-Philip",
    "image": "https://www.spookyconnections.com/static/media/PrincePhilip.1c46b87b.png",
  },
  {
    "id": "10",
    "name": "Prince Andrew",
    "state": "United Kingdom",
    "wealth": "Royal",
    "occupation": "Royal",
    "page": "/Prince-Andrew",
    "image": "https://www.spookyconnections.com/static/media/PrinceAndrew.8ddeaeb9.png",
  },
  {
    "id": "11",
    "name": "Prince Charles",
    "state": "United Kingdom",
    "wealth": "Royal",
    "occupation": "Royal",
    "page": "/Prince-Charles",
    "image": "https://www.spookyconnections.com/static/media/PrinceCharles.9efc8ae5.png",
  },
//   {
//     "id": "6",
//     "name": "Paul Castellano",
//     "state": "United States",
//     "wealth": "Millionaire",
//     "occupation": "Crime Boss, Gambino, Butcher, Dead",
//     "page": "/Paul-Castellano",
//     "image": "http://spookyconnections.com/static/media/PaulCastellano.30e23815.png",
//   },
//   {
//     "id": "7",
//     "name": "The Commission",
//     "state": "United States",
//     "wealth": "Undetermined",
//     "occupation": "Governing body of the U.S. Mafia",
//     "page": "/The-Commission",
//     "image": "http://spookyconnections.com/static/media/Commission.b63b6a1f.png",
//   },
//   {
//     "id": "8",
//     "name": "Jeffrey Epstein",
//     "state": "United States",
//     "wealth": "Billionaire",
// "occupation": "Financier, Broker, Educator, Spy, Trafficker",
//     "page": "/Jeffrey-Epstein",
//     "image": "http://spookyconnections.com/static/media/JeffreyEpstein.f05af1e3.png",
//   },
//   {
//     "id": "9",
//     "name": "Ricardo Goldstein",
//     "state": "Brazil",
//     "wealth": "Billionaire",
// "occupation": "Businessman, Investor, Consultant",
//     "page": "/Ricardo-Goldstein",
//     "image": "http://spookyconnections.com/static/media/RicardoGoldstein.5156b439.png",
//   },
//   {
//     "id": "10",
//     "name": "Salvatore Gravano",
//     "state": "United States",
//     "wealth": "Millionaire",
//     "occupation": "Mobster, Gambino, Drug trafficker",
//     "page": "/Salvatore-Gravano",
//     "image": "http://spookyconnections.com/static/media/SammyGravano.b306d639.png",
//   },
//   {
//     "id": "11",
//     "name": "Rudy Giuliani",
//     "state": "United States",
//     "wealth": "Millionaire",
//     "occupation": "Politician, Lawyer, Former Mayor, Honorary Knight",
//     "page": "/Rudy-Giuliani",
//     "image": "http://spookyconnections.com/static/media/RudyGiuliani.db47c6bd.png",
//   },
//   {
//     "id": "12",
//     "name": "Irakly Kaveladze",
//     "state": "Russia",
//     "wealth": "Millionaire",
//     "occupation": "Senior Vice President, Crocus Group",
//     "page": "/Irakly-Kaveladze",
//     "image": "http://spookyconnections.com/static/media/IrakliKaveladze.bb37a41d.png",
//   },
//   {
//     "id": "13",
//     "name": "Charles Kushner",
//     "state": "United States",
//     "wealth": "Billionaire",
//     "occupation": "Real estate developer, Former Lawyer, Criminal",
//     "page": "/Charles-Kushner",
//     "image": "http://spookyconnections.com/static/media/CharlesKushner.9fb88ad8.png",
//   },
//   {
//     "id": "14",
//     "name": "Jared Kushner",
//     "state": "United States",
//     "wealth": "Millionaire",
//     "occupation": "Investor, Real estate developer, WH senior advisor",
//     "page": "/Jared-Kushner",
//     "image": "http://spookyconnections.com/static/media/JaredKushner.f724398b.png",
//   },
//   {
//     "id": "15",
//     "name": "Phil Leonetti",
//     "state": "United States",
//     "wealth": "Unknown",
//     "occupation": "Underboss, Philadelphia crime family, Author",
//     "page": "/Phil-Leonetti",
//     "image": "http://spookyconnections.com/static/media/PhilLeonetti.6ab4350b.png",
//   },
  // {
  //   "id": "17",
  //   "name": "Elite Model Management",
  //   "state": "International",
  //   "wealth": "Unknown",
  //   "occupation": "Modeling Agency",
  //   "page": "/Elite-Models",
  //   "image": "",
  // },
  {
    "id": "19",
    "name": "Gerald Marie",
    "state": "France",
    "wealth": "Millionaire",
    "occupation": "Modeling Scout and Agent, Actor",
    "page": "/Gerald-Marie",
    "image": "http://spookyconnections.com/static/media/GeraldMarie.f538d18c.png",
  },
  {
    "id": "20",
    "name": "Robert Maxwell",
    "state": "UK",
    "wealth": "Billionaire",
    "occupation": "Spy, Media Mogul, Politician",
    "page": "/Robert-Maxwell", 
    "image": "http://spookyconnections.com/static/media/RobertMaxwell.72c35358.png",
  },
  {
    "id": "21",
    "name": "Alexei Navalny",
    "state": "Russia",
    "wealth": "Middle Class",
    "occupation": "Investigator, Author, Politician",
    "page": "/Alexei-Navalny", 
    "image": "http://spookyconnections.com/static/media/AlexeiNavalny.4bcf6318.png",
  }, 
  {
    "id": "22",
    "name": "Peter Nygard",
    "state": "Canada",
    "wealth": "Millionaire",
    "occupation": "Businessman, Fashion Executive",
    "page": "/Peter-Nygard",
    "image": "http://spookyconnections.com/static/media/PeterNygard.f5435e37.png",
  },
  // {
  //   "id": "20",
  //   "name": "Ghislaine Maxwell",
  //   "state": "France",
  //   "wealth": "",
  //   "occupation": "Socialite",
  //   "page": "/Ghislaine-Maxwell",
  //   "image": "",
  // },
  // {
  //   "id": "19",
  //   "name": "Robert Maxwell",
  //   "state": "United Kingdom",
  //   "occupation": "Spy",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "20",
  //   "name": "Andrey Rozov",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "21",
  //   "name": "Giorgi Rtskhiladze",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
 
   {
    "id": "23",
    "name": "Dmitry Rybolovlev",
    "state": "Russia",
    "wealth": "Billionaire",
    "occupation": "Businessman, Investor, Art Collector",
    "page": "/Dmitry-Rybolovlev",
    "image": "https://www.spookyconnections.com/static/media/DmitryRybolovlev.94dfcfb7.png",
  },
  // {
  //   "id": "23",
  //   "name": "Anthony Salerno",
  //   "state": "United States",
  //   "occupation": "Mob Boss",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "24",
  //   "name": "Felix Sater",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "25",
  //   "name": "Nicodemo Scarfo",
  //   "state": "United States",
  //   "occupation": "Mobster",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "26",
  //   "name": "Kenneth Shapiro",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "27",
  //   "name": "John Staluppi",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "28",
  //   "name": "Daniel Sullivan",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "29",
  //   "name": "Salvatore Testa",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  {
    "id": "32",
    "name": "Donald Trump",
    "state": "United States",
    "wealth": "Billionaire",
    "occupation": "US President, Real Estate Developer",
    "page": "/Donald-Trump",
    "image": "http://spookyconnections.com/static/media/DonaldTrump.4031cec8.png",
  },
  // {
  //   "id": "32",
  //   "name": "Maryanne Trump",
  //   "state": "United States",
  //   "occupation": "Judge",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // {
  //   "id": "33",
  //   "name": "Elizabeth Trump Grau",
  //   "state": "United States",
  //   "occupation": "Banker",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // // {
  // //   "id": "34",
  // //   "name": "Barron Trump",
  // //   "state": "United States",
  // //   "occupation": "Student",
  // //   "page": "/Donald-Trump",
  // //   "image": "",
  // // },
  // {
  //   "id": "35",
  //   "name": "Donald Trump jr",
  //   "state": "United States",
  //   "wealth": "Millionaire",
  //   "occupation": "Businessman, Investor, Real Estate developer",
  //   "page": "/Donald-Trump-Jr",
  //   "image": "http://spookyconnections.com/static/media/DonaldTrumpJr.19f9eaa5.png",
  // },
  // {
  //   "id": "36",
  //   "name": "Elizabeth C. Trump",
  //   "state": "United States",
  //   "occupation": "Grandmother",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // {
  //   "id": "37",
  //   "name": "Eric Trump",
  //   "state": "United States",
  //   "occupation": "US President",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  {
    "id": "38",
    "name": "Fred Trump",
    "state": "United States",
    "wealth": "Billionaire",
  "occupation": "Businessman, Investor, Real Estate developer",
    "page": "/Fred-Trump", 
    "image": "http://spookyconnections.com/static/media/FredTrump.01d495d4.png",
  },
  {
    "id": "39",
    "name": "Fredrich Trump",
    "state": "United States",
    "wealth": "Millionaire",
    "occupation": "Businessman, Brothel Owner",
    "page": "/Fredrich-Trump", 
    "image": "http://spookyconnections.com/static/media/FredrichTrump.11c9d47b.png",
  },
  // {
  //   "id": "39",
  //   "name": "Ivana Trump",
  //   "state": "United States",
  //   "occupation": "US President",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // {
  //   "id": "40",
  //   "name": "Ivanka Trump",
  //   "state": "United States",
  //   "occupation": "US President",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // {
  //   "id": "41",
  //   "name": "Melania Trump",
  //   "state": "United States",
  //   "occupation": "US President",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // {
  //   "id": "42",
  //   "name": "Robert Trump",
  //   "state": "United States",
  //   "occupation": "US President",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // {
  //   "id": "43",
  //   "name": "Tiffany Trump",
  //   "state": "United States",
  //   "occupation": "US President",
  //   "page": "/Donald-Trump",
  //   "image": "",
  // },
  // {
  //   "id": "44",
  //   "name": "F. Weichselbaum",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "45",
  //   "name": "J. Weichselbaum",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
  // {
  //   "id": "46",
  //   "name": "Paolo Zampolli",
  //   "state": "????",
  //   "occupation": "????",
  //   "page": "/Jeffrey-Epstein",
  //   "image": "",
  // },
   {
    "id": "6",
    "name": "Alvaro Uribe",
    "state": "Colombia",
    "wealth": "Billionaire",
    "occupation": "President",
    "page": "/Ivan-Duque",
    "image": "https://www.spookyconnections.com/static/media/IvanDuque.f9861e34.png",
  } 
  ]



