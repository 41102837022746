import React from "react";


// core components
import FixedPlugin from "../FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../FixedGlobe/FixedGlobe.js";
import FixedContact from "../FixedContact/FixedContact.js";

// react components
import {Container} from "reactstrap";
import Iframe from "react-iframe";

class Graph extends React.Component {
  constructor(props) {
    super(props);                                                                                                                                                                                                                                                                                          
    this.state = {
      backgroundColor: "default",
    };
  }

  render() {
    return (
      <>
             <div className="wrapper">
          
          <div
              className="main-panel"
              ref="mainPanel"
              data={this.state.backgroundColor}
            >
            
            <Container width="100%" className="content">
              
                
              <Iframe 
                    src="https://embed.kumu.io/22998445e934f2ee7c63981fa6dece26"
                     width="100%" height="100%"   frameBorder="0"
                     
                     top= "0"
                     left= "0"
                     bottom= "0"
                     right= "0"
                     
                     ></Iframe>               
              
                  
                  
              
              

           
            </Container>
            
            </div>
            </div>

            
    
            <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 



        
      </>
    );
  }
}

export default Graph;


