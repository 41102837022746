import React from "react";

import {
Col,
Container,
Button,
CardBody,
CardTitle,
FormGroup,
Input,
Label,
Row,
CardText
} from "reactstrap";


export default class MailingList extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     backgroundColor: "default",
    //   };
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
        <>
             <div >
          
          <div>
                <Container className="content">
            <Row>
          <Col md="12">
        {/* <Card> */}
       
      <CardBody className="px-lg-5 py-lg-5 text-muted" >
          <CardTitle tag="h2" className="text-center">
          Uh oh, this profile is not available yet.
          
          </CardTitle >
          <CardText tag="h4">
          Be the first to know when Spooky Connections adds a new profile. Sign up and consider supporting us to speed things up.
          </CardText>
          
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/mqkyryoz"
        method="POST"
      >
        {/* <!-- add your custom form HTML here --> */}
        <Row form>
        <Col md={12}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="email" name="email" id="email" placeholder=""/>  
          </FormGroup>
        </Col>
       
      </Row>
      <small>
      Along with our published content, we will update our readers on events that our staff and contributors are involved with, such as noteworthy interviews, articles, or new investigations.</small>
        <div className="text-center">
        {status === "SUCCESS" ? <p 
        style={{
          color:"#2dce89",
        }}>Thanks, for signing up! you have been added to the Spooky Connections mailing list!</p> : <Button className="my-4" color="default">Sign Up</Button>}
        {status === "ERROR" && <p 
        style={{
          color:"red",
        }}>Ooops! There was an error.</p>}

        {" "}

<a target="_blank" href="https://donorbox.org/donate-to-spooky-connections" rel="noreferrer">
              <Button
                color="danger"
                className="my-4"
              >
                 Support Us
              </Button>
              </a>  
        </div>
        </form>
        
      </CardBody>
      {/* </Card> */}
      
      </Col>
      </Row>
      </Container>
     
  
      </div>
            </div>
        </>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}