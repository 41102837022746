import React from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Card
} from "reactstrap";

const Deatheater = ({ image, name, state, wealth, occupation, page }) => {
  
 return (
      <Col
       className="col-xs-6 col-xs-6"
        lg="3"
        md="6"
        sm="9">
          <Link to={page}>
    <Card className="font-icon-detail"
    style={{
      width:'min-25em',
        }}>
            <div>
              <img    
                alt="..."                     
                src={image}
                className="avatar"
                style={{
                    height:'12em',
                    width:'12em',
                      }}
              />
            </div>
            <ul style={{
              listStyleType:'none',
              textAlign:'left',
            }}>
              <li><h1 style={{
                fontSize:'1rem',
                fontWeight:'900',
                marginBottom:'4',
                      }}>
                        <i 
                        className="tim-icons icon-single-02" 
                      style={{
                        fontSize:'1rem',
                        fontWeight:'100',
                        paddingRight:'.5rem',
                              }}/>{name}</h1></li>
              <li><h4 style={{
                fontSize:'1rem',
                fontWeight:'400',         
                marginBottom:'2',
                      }}>
                        <i 
                        className="tim-icons icon-square-pin" 
                      style={{
                        fontSize:'1rem',
                        fontWeight:'100',
                        paddingRight:'.5rem',
                              }}/>{state}</h4></li>
              <li><h4 style={{
                fontSize:'1rem',
                fontWeight:'400',
                marginBottom:'10',
                      }}>
                      <i 
                      className="tim-icons icon-money-coins" 
                      style={{
                      fontSize:'1rem',
                      fontWeight:'100',
                      paddingRight:'.5rem',
                            }}/>{wealth}</h4></li>  
              <li><h4 className="text-mute" style={{
                fontSize:'.8rem',
                fontWeight:'300',
                marginRight:'2',
                marginBottom:'0',
                paddingBottom:'0',
                      }}>
                        <i 
                        className="tim-icons icon-tag" 
                      style={{
                        fontSize:'1rem',
                        fontWeight:'100',
                        paddingRight:'.5rem',
                              }}/>{occupation}</h4></li>
                             
            </ul>         
               
      </Card>
      
      </Link>
      </Col> 
   
  );
};

export default Deatheater;


