import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/JohnCasablancas.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";
import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class Casablancas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">John Casablancas</h4>
                        {/* </a> */}
                        <p className="description">Billionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>John Casablancas and Institut Le Rosey</li>
                            <li>Casablancas and Elyss Models</li>
                            <li>John Casablancas and Elite Model Management</li>
                            <li>John Casablancas and two dead models</li>
                            <li>John Casablancas and Gérald Marie</li>
                            <li>
                              Casablancas, Trump, Elite Model Look (formerly
                              Look of the Year)
                            </li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul>
                            <li>Alain Kittler</li>
                            <li>Gérald Marie</li>
                            <li>Donald Trump</li>
                            <li>Ivanka Trump</li>
                            <li>Malenia Trump</li>
                            <li>Paolo Zampolli</li>
                            <li>Jeffrey Epstein</li>
                            <li>Naomi Campbell</li>
                            <li>David Copperfield</li>
                            <li>Stephanie Seymour</li>
                            <li>Institut Le Rosey</li>
                            <li>Adnan Khashoggi</li>
                            <li>Coca-Cola</li>
                            <li>Gunnar Larsson</li>
                            <li>Paula Brenken</li>
                            <li>Ingmari Lamy</li>
                            <li>Ann Schaufuss</li>
                            <li>Bebe Buel</li>
                            <li>Marie Anderson Boyd</li>

                            <li>Cindy Crawford</li>

                            <li>Lynn Kohlman</li>
                            <li>Paris Planning</li>
                            <li>Ricardo Samuel Goldstein</li>
                            <li>Ricardo Bellino</li>
                          </ul>
                          <h4>Family of:</h4>
                          <ul>
                            <li>Antònia Casablancas</li>
                            <li>Ferran Casablancas</li>
                            <li>Ferran Casablancas Planell</li>
                            <li> Silvia Casablancas</li>
                            <li>Marie Christine</li>
                            <li>Jeanette Christiansen</li>
                            <li>Aline Wermelinger</li>
                            <li>Julian Casablancas</li>
                            <li>John Casablancas Jr</li>
                            <li>Fernando Augusto</li>
                            <li> Nina Casablancas</li>
                            <li>Cécile Casablancas</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li>Elite Model Management</li>
                            <li>Elyss Models</li>
                            <li>Elite Look The Year</li>
                            <li>StarSystem model scouting</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">
                    LATEST UPDATE JANUARY 15 Introduction
                  </Alert>
                </Col>
                <Col md="8">
                <Alert
                    color="danger"
                  >
                    <CardBody>
                    
                      <h3>Like our work
                      and want us to grow? please support us and donate. Your donation
                      to Spooky Connections 👻 is a direct contribution to our
                      research, developers, and servers. With your support, we
                      will continue to publish new profiles, investigations, and
                      uncover organized crime networks.</h3>
                    <br></br>
                    <a target="_blank" rel="noreferrer" 
                    href="https://donorbox.org/donate-to-spooky-connections">
                    <img src="https://d1iczxrky3cnb2.cloudfront.net/button-medium-blue.png" alt="Donate Here"/></a>
                    </CardBody>
                  </Alert>
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">John Casablancas</h1>
                      <h2>Introduction</h2>
                      <p>
                        John Casablancas was a US modeling agent, scout, founder
                        of Elite Model Management, and a predator that preyed on
                        young models. John Casablancas was born in New York in
                        1942 to Fernando Casablancas, a banker, and Antònia
                        Casablancas, a former model, and is the grandson of
                        Catalan textile machinery inventor Fernando Casablancas
                        Planell. His family fled to the United States in 1930
                        after the outbreak of the Spanish Civil War. John
                        Casablancas was the youngest of three siblings, Fernando
                        Casablancas and Silvia Casablancas. John Casablancas
                        died on July 20, 2013, in Rio de Janeiro, of cancer. [1]
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKJsOa9D0Yq/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                      />
                      <br></br>
                      <h2>John Casablancas and Institut Le Rosey</h2>
                      <p>
                        In the 1950s, John Casablancas attended the Institut Le
                        Rosey. A Swiss boarding school for children of the
                        international elite in business and royalty. It included
                        the children of the royal families of Egypt, Saudi
                        Arabia, Greece, Yugoslavia. Some of the better-known
                        alumni at Le Rosey are the Shah of Iran, Prince Rainier,
                        the Duke of Kent, King Fouad II of Egypt, King Juan
                        Carlos of Spain, King Albert II of Belgium, King
                        Baudouin of Belgium, Nicholas Negroponte, Prince Albert
                        of Monaco, and the Aga Khan IV. [2] In 1957, John's
                        sister Silvia Casablancas was the 'official' girlfriend
                        of Prince Karim, Aga Khan IV. [3] Le Rosey also included
                        sheiks and the children of the Saudi Arabian arms dealer
                        Adnan Khashoggi, including his nephew Dodi Al-Fayed who
                        was killed with Princess Diana.[4] In the 1990's John's
                        son Julian Casablancas also attended.[5]
                      </p>
                      <br></br>
                      <p>
                        Casablancas was raped at the age of 15 by a 28-year-old
                        woman and then passed around to others. In the
                        documentary "Casablancas: The Man Who Loved Women," John
                        Casablancas claims: "I lost my virginity at the age of
                        15 on a summer night in 1958, in Cannes, on the French
                        Riviera." Casablancas claims he met a 28-year-old Danish
                        woman named Désirée through a decadent playboy from
                        Argentina named Albert. Days later, Casablancas and
                        Désirée met in town and went for a swim at the Croisette
                        where she raped him afterward. John goes on to claim he
                        was passed around to her friends, "It was so exciting.
                        It was my first lesson in love, and a fantastic lesson
                        it was! I started meeting her friends. One was a
                        beautiful German brunette called Margaret. We also
                        started having an affair. She was very naughty and
                        introduced me to more of her friends. I discovered a
                        group of young, beautiful women looking for a rich lover
                        or even a husband." John Casablancas also claimed other
                        Le Rosey alumni lost their virginities to prostitutes:
                        "Most of my friends had awful first experiences with
                        hookers, or ugly girls, in awful places."[6] Another
                        alumnus, the 14-year-old son of a Saudi Sheik, was raped
                        weekly by a prostitute while attending Le Rosey. After
                        the family instructed his bodyguard, a retired cop with
                        the Lausanne vice squad, to teach him 'the facts of
                        life.'[7]
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        Upon returning to the Institut Le Rosey, Casablancas
                        claims to have fallen in love "very passionately" and
                        "very regularly." When the school hired a young maid
                        named Priska, age unknown, Casablancas had sexual
                        relations with her, the circumstances unknown. The
                        school sent a letter to John's father rescinding John's
                        letters of recommendation. The letter allegedly read,
                        "Dear Sir, Your son slept with the maid." "Your son has
                        betrayed our trust. He has betrayed everyone here. We
                        don't want to know him anymore. All letters of
                        recommendation are immediately suspended." According to
                        John Casablancas, his father told him, "You are
                        disgusting. You're a horrible human being. You will
                        never be able to talk about your time at Le Rosey. Your
                        name doesn't exist there anymore." [8]
                      </p>
                      <br></br>
                      <p>
                        John Casablancas planned to attend Harvard or Yale,
                        however, having his letters of recommendation destroyed
                        left him without options. He attempted to join the US
                        Marines. He went to the U.S. embassy in France took an
                        admissions test. According to the man representing the
                        military and the embassy, John Casablanca's results were
                        so bad that he wouldn't even register him. He told
                        Casablancas, "These results show you to be stupid, but
                        in the medical sense of the word."{" "}
                      </p>
                      <br></br>
                      <h2>Casablancas and Elyss Models</h2>
                      <p>
                        In the early 1960s, John Casablancas attended several
                        European universities but never graduated. At age 22,
                        through a school friend from Le Rosey, John Casablancas
                        obtained a job offer. He was hired as the promotion and
                        sales manager for Coca-Cola in Brazil, despite having no
                        background or experience. In 1965 John Casablancas
                        married his long time girlfriend Marie-Christine, and
                        they moved to Brazil from France. They had one child
                        named Cécile Casablancas, who was born in 1969 in
                        France. The marriage lasted 2 years and ended in 1970 on
                        a trip from Brazil to France.{" "}
                      </p>
                      <br></br>
                      <p>
                        John Casablancas lived in a Paris hotel on rue d'Argout
                        until 1967 with his roommate Gunnar Larsson, a
                        photographer who is always surrounded by models. Through
                        Gunnar Larsson. John Casablancas met Jeanette
                        Christiansen, 19, a Danish model, and miss Denmark in
                        1965. In 1969 John and Jeanette opened a modeling agency
                        named Elysées 3 in Paris and John's father loaned them a
                        hundred thousand dollars. The money was spent finding
                        offices, hiring three bookers and fifteen Scandinavian
                        models, all friends Jeanette. The idea was to scout in
                        Scandinavia, the source then of most models for the
                        mainland European market. Elysées 3 failed to take off
                        financially until John Casablancas handed it over to his
                        brother Fernando Casablancas.{" "}
                      </p>
                      <br></br>
                      <h2>John Casablancas and Elite Model Management</h2>
                      <h3>John Casablancas and two dead models</h3>
                      <p>
                        In 1972 John Casablancas, Alain Kittler, founded Elite
                        Model Management in Paris France. Elite Model opened
                        with several high-profile models including Paula
                        Brenken; Ingmari Lamy; Ann Schaufuss, Clive Arrowsmith's
                        girlfriend, Bebe Buell; Barry Lategna's wife, Lynn
                        Kohlman; and Paris Planning's Emanuelle Dano. Within a
                        year at least two of John Casablanca's top Elite models
                        Paula Brenken and Emmanuel Dano were found dead under
                        suspicious circumstances.{" "}
                      </p>
                      <br></br>
                      <p>
                        Paula Brenken died after she allegedly dove out a window
                        in a drunken state the night after she told friends she
                        had been raped by a photographer. Soon after Emmanuel
                        Dano "died" after "falling out" of a moving car after
                        attempting to fend off a gang rape. "She was out for a
                        late-night drive with several male friends when they
                        attempted to [gang] rape her", according to Casablancas.
                        He claimed she "died instantly" and that he also
                        discovered her body lying in her own bed, raising
                        suspicions about his testimony. In both cases, there was
                        an insufficient police investigation. The only
                        explanation of their deaths came from John Casablancas.
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        In 1977, after the deaths of Emmanuel Dano and Paula
                        Brenken, Elite Model Management opened offices in New
                        York, and used their tragedies for publicity and
                        self-promotion. John Casablancas declared war on the US
                        model agencies Ford and Wilhelmina. Shortly after
                        opening the New York office, Elite was sued for $10
                        million by Ford and Wilhelmina, but the cases were
                        unsuccessful and resulted only in more publicity for
                        Casablancas. Due to the death of Wilhelmina Cooper in
                        1980, models were in a panic to find a stable agency.
                        Ford was affiliated with Elite until Casablancas began
                        stealing Ford's models. Throughout the 1980s this was
                        known as the 'model wars'.
                      </p>
                      <br></br>
                      <h3>John Casablancas and Stephanie Seymour</h3>
                      <p>
                        In 1978, John Casablancas and Jeanette Christiansen had
                        a child named Julian Casablancas. Julian was the lead
                        vocalist of U.S. bands The Strokes and The Voidz. John
                        Casablancas and Christiansen married a year later in
                        1979 and soon after divorced in 1983 because John
                        Casablancas was publicly grooming and raping child model
                        Stephanie Seymour, 16 years old. In 1984, John
                        Casablancas met Stephanie Seymour when she was
                        15-years-old via a contest at a shopping center in San
                        Diego he was 41-years-old. Over the next year, John
                        Casablancas took Seymour to Acapulco for the final round
                        of The Look of the Year contest, then to New York. Among
                        the five finalists that year, were Cindy Crawford and
                        Stephanie Seymour. John Casablancas' predatory behaviour
                        towards very young girls was not a secret in fact it was
                        widely known within the fashion industry and the social
                        scene in New York City long before it was reported to
                        the public.
                      </p>
                      <br></br>
                      <p>
                        On Stephanie Seymour's 16th birthday, he threw her a
                        party at a cocaine-fueled nightclub in Milan, and a week
                        later she moved in with him. According to Casablancas
                        when referring to Seymour, “the way she developed —
                        there’s a quality that developed about her that is this
                        incredible sensuality that a woman-child has, a true
                        woman-child … her voice is a child’s, her attitudes, the
                        way she holds her feet and her hands are those of a
                        child, at the same time with an incredible sensuality to
                        it. And that mixture was and is so explosive … This was
                        something like a forbidden fruit for both of us.” After
                        two years, Seymour's parents intervened and broke off
                        the 'relationship' between the young teen and the
                        42-year-old man. John Casablanca's openly admitted that
                        he often raped children and lured young women, “that was
                        the business I was in ... many of us are fascinated by
                        the idea of a woman-child, like Brooke Shields in the
                        movie Pretty Baby.” In Pretty Baby, Shields played a
                        child rape trafficking victim being sold to adult men.
                        Casablancas's reputation for raping child models was
                        established and openly embraced by the New York social
                        scene.{" "}
                      </p>
                      <br></br>
                      <h3>John Casablancas and Gérald Marie</h3>
                      <p>
                        By 1985 John Casablancas and Gérald Marie had been
                        acquainted and an alliance was made between the two. In
                        1986 Paris Planning merged with Elite Model Management.
                        Gérald Marie became its European president. He ran the
                        agency alongside John Casablancas and together, they
                        helped launch the careers of Naomi Campbell, Cindy
                        Crawford, Helena Christensen, and others.
                      </p>
                      <br></br>
                      <p>
                        Marie Anderson Boyd, who was an agent and vice president
                        at the Elite Model Management’s Chicago office between
                        1985 and 1990, said models would regularly report sexual
                        misconduct they experienced on go-sees. She never passed
                        the reports to her managers at Elite, in part because
                        executives like John Casablancas and Gerald Marie also
                        engaged. “They established a culture of compliance with
                        sexually predatory behavior ... That trickled down into
                        everything everybody did.” A 2012 study by Model
                        Alliance, found that 52% of models are rarely or never
                        accompanied by guardians to a shoot, even though the
                        majority of models start working between age 13 - 16,
                        and 50% are exposed to cocaine. Anderson Boyd told New
                        York Magazine that she remembered watching two female
                        executives plead with Gerald Marie and John Casablancas
                        to stop raping children. They responded “We are men. We
                        have our needs.”{" "}
                      </p>
                      <br></br>
                      <p>
                        In 1991, Elite had offices around the world, from Tokyo
                        to London. John Casablancas' first supermodels were
                        Christie Brinkley, Cindy Crawford, Naomi Campbell,
                        Claudia Schiffer, Gisele Bündchen, Heidi Klum, Andie
                        MacDowell, Iman, and Paulina Porizkova, and Linda
                        Evangelista. Many of these models were 'discovered'
                        through Elite's annual modeling contest the Look of the
                        Year which was launched in 1983. The name was later
                        changed in 1995 to Elite Model Look and features models
                        age 14 - 25 from over 55 countries. These young girls
                        and women were paraded in front of wealthy men who
                        participated as judges.
                      </p>
                      <br></br>
                      <h2>
                        Casablancas, Trump, Elite Model Look (formerly Look of
                        the Year)
                      </h2>
                      <br></br>
                      <p>
                        One of the most famous Elite Model Look (formerly Look
                        of the Year) contest judges is U.S. President Donald
                        Trump. In the New York disco era nightclub scene, John
                        Casablancas brushed shoulders with organized crime and
                        befriended future U.S. President Donald Trump. They were
                        introduced at some point during the mid-’70s through Roy
                        Cohn. According to Trump, John Casablancas tutored him
                        in the “modeling agency” business. During this period,
                        Trump engaged in such excess of debauchery that he
                        deemed it “my second business” according to author
                        Michael Gross. According to Donald Trump during an
                        interview with Michael Gross, “You had drugs, women, and
                        booze all over the f--kin’ place ... If I hadn’t got
                        married, who knows what would have happened? ... I don’t
                        think anybody had more sex than I did. Sex was all over
                        the fucking place ... I would watch supermodels getting
                        screwed, well-known supermodels getting screwed, on a
                        bench in the middle of the room. There were seven of
                        them and each one was getting screwed by a different
                        guy.”{" "}
                      </p>
                      <br></br>
                      <p>
                        Donald Trump was often spotted at Elite Model Management
                        parties as far back as 1982. Trump hosted events for
                        Elite’s modeling contests, provided lodging for the
                        contestants, and at times acted as a contest judge
                        alongside John Casablancas, Gerald Marie, and others.
                        According to a witness, Patty Owen, an Elle, and
                        Cosmopolitan cover star, “He would always be at the bar.
                        That’s where he would stay and that’s where all the new
                        models would hang out ... Whenever I saw him, I was
                        always like: why does John have to invite him?”.
                        According to another witness Barbara Pilling, an Elite
                        model, Donald Trump asked her out for dinner in the
                        summer of 1989. She recalls Trump asking how old she
                        was. “I said 17 and he said, ‘That’s just great – you’re
                        not too old, not too young.’”
                      </p>
                      <br></br>
                      <p>
                        Teenage Elite models in the late 80s and early 90s were
                        required to attend private dinners with Donald Trump,
                        John Casablancas, and other men. Heather Braden, an
                        Elite model, in the late 90s, alleges that she was
                        instructed to go to a party in a mansion on one of the
                        islands near Miami Beach where she witnessed Donald
                        Trump and three famous actors, who she refused to name,
                        prey on dozens of young models isolating many into
                        bedrooms or other parts of the mansion, “Clearly, we
                        were there for one reason. We were just pieces of
                        meat.”. According to witness Shayna Love, an Australian
                        model who was 16, “It was presented as our duty as
                        models at the agency. It wasn’t an invitation. It was
                        like, you have to go and do this.” Love recalls
                        attending a dinner with John Casablancas and Donald
                        Trump “This time it was a private area, a big table and
                        lots of girls – I’d say around 10 to 15 of us, all
                        between the ages of 14 and 18,” she said. “It was just
                        us models, Trump and John. We were all underage, but we
                        were offered drinks,” Love said she went home early, but
                        other children stayed. Eli Nessa, an Elite Model,
                        stated, “We were a bunch of kids, just put there with
                        all these older men.”
                      </p>
                      <br></br>
                      <p>
                        Donald Trump's daughter Ivanka Trump, age 15, became a
                        model at Elite Model Management directly under the
                        management of John Casablancas. Monica Pillard, the
                        president of Elite, had been eyeing Ivanka since she
                        spotted her on Donald Trump’s lap when Trump was a judge
                        of Elite's Look Of The Year contest, which was hosted at
                        Trump Tower. Pillard also served as a judge in the Miss
                        Universe pageant, which was jointly owned by Donald
                        Trump and CBS, as was the Miss Teen USA pageant. In
                        1999, Trump started a modeling agency, Trump Model
                        Management, cloned after John Casablanca's own Elite
                        Model Management.
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        In 1993, John Casablancas married the child model, 17
                        year old Aline Mendonça de Carvalho Wermelinger, winner
                        of Elite Model Look 1992 in Brazil. She also
                        participated in the Elite Model look 1992 in the
                        Slovenia finals which included, Ruffa Gutierrez, Diane
                        Heidkrüger, Marta Cecchetto, Oxana Zubakova, Eugenia
                        Silva, Nieves Álvarez, Amit Machtinger, Lee So-ra,
                        Melania Knauss (Donald Trump's future wife). had three
                        children, John Jr, Fernando Augusto, and Nina.
                      </p>
                      <br></br>
                      <p>
                        John Casablancas retired from the agency in 2000, his
                        decision to leave came after then-president of Elite
                        Europe, Gerald Marie, was featured in a 1991 BBC exposé
                        into the sexual abuse and rape of models - many of which
                        were children at the time - in certain agencies. The BBC
                        reportedly filmed Gérald Marie confessing that he hoped
                        to seduce the contestants at Elite Model Look (the new
                        name for Look of the Year), as well as offering an
                        undercover journalist money for sex. The former BBC
                        journalist Lisa Brinkworth was sexually assaulted by
                        Gérald Marie in a nightclub in October 1998, when she
                        was posing undercover as a model. Marie was suspended
                        from Elite; in an interview at the time, he said: “I’m
                        destroyed… I’m finished.”
                      </p>
                      <br></br>
                      <p>
                        In 2004, Elite filed for Chapter 11 bankruptcy
                        protection after two lawsuits were brought against it by
                        a group of former models and employees, it was bought by
                        Creative World, which is owned by Eddie and Jules Trump.
                        No relation to Donald Trump, the Trumps outbid ID Models
                        boss Paolo Zampolli and child trafficker Jeffrey
                        Epstein.
                      </p>
                      <br></br>
                      <p>
                        Donald Trump later hired John Casablancas to be on the
                        board at Trump Realty Brazil, a $40 million project
                        aimed at the Brazilian wealthy elite, as a "lifestyle
                        consultant of sorts." The project was pitched by Ricardo
                        Bellino with the help of John Casablancas and they were
                        partners alongside Ricardo Samuel Goldstein the founder
                        of Security Beverages Americas and Gold & Bell. In 2007,
                        Goldstein co-founded with John Casablancas, the
                        StarSystem model scouting project, and previously
                        assisted Ricardo Bellino and John Casablancas in the
                        implementation of Elite Models in Brazil. John
                        Casablancas died of cancer in 2013.{" "}
                      </p>
                      <br></br>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          The Man Who Invented Supermodels Also Took Advantage
                          of Them. W Magazine | Women's Fashion & Celebrity
                          News. (2021). Retrieved 17 January 2021, from
                          https://www.wmagazine.com/story/john-casablancas-models-documentary-netflix/.
                        </li>
                        <br></br>
                        <li>
                          Where you learn to be a billionaire. Forbes. (2021).
                          Retrieved 17 January 2021, from
                          https://www.forbes.com/forbes/1999/0705/6401214a.html.
                        </li>
                        <br></br>
                        <li>
                          John Casablancas, l’uomo che amò le donne. GQ Italia.
                          (2021). Retrieved 17 January 2021, from
                          https://www.gqitalia.it/magazine/2016/06/23/john-casablancas-l-uomo-che-amo-le-donne.
                        </li>
                        <br></br>
                        <li>
                          Forbes.com. (2021). Retrieved 17 January 2021, from
                          https://www.forbes.com/global/1999/0705/0213126a.html.
                        </li>
                        <br></br>
                        <li>
                          Inside Le Rosey, the World's Most Expensive Boarding
                          School. Town & Country. (2021). Retrieved 17 January
                          2021, from
                          https://www.townandcountrymag.com/society/tradition/a2696/le-rosey-the-worlds-most-expensive-boarding-school/.
                        </li>
                        <br></br>
                        <li>
                          Casablancas: The Man Who Loved Women (2016) Movie
                          Script | Subs like Script. Subslikescript.com. (2021).
                          Retrieved 17 January 2021, from
                          https://subslikescript.com/movie/Casablancas_The_Man_Who_Loved_Women-5078372.
                        </li>
                        <br></br>
                        <li>
                          Where you learn to be a billionaire. Forbes. (2021).
                          Retrieved 17 January 2021, from
                          https://www.forbes.com/forbes/1999/0705/6401214a.html.
                        </li>
                        <br></br>
                        <li>
                          Casablancas: The Man Who Loved Women (2016) Movie
                          Script | Subs like Script. Subslikescript.com. (2021).
                          Retrieved 17 January 2021, from
                          https://subslikescript.com/movie/Casablancas_The_Man_Who_Loved_Women-5078372.
                        </li>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Casablancas;
