import React, { Component } from 'react';
import Navbar from "../../components/Navbar/Navbar.js";
import Content from "../views/ComingSoon/Content.js";
import Footer from "../../components/Footer/Footer.js";

export class ComingSoon extends Component {
    render() {
        return (
            <div>
            <Navbar />
            <Content />
            <Footer />
            </div>
        )
    }
}

export default ComingSoon;
