import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/KingEdward.png";
// import ComingSoon from "../../../../ComingSoon/Content.js";
// import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class KingEdwardVIII extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">King Edward VIII</h4>
                        {/* </a> */}
                        <p className="description">Royal</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>King Edward VIII</li>
                            <li>King Edward VIII</li>
                            <li>King Edward VIII</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul></ul>
                          <h4>Family of:</h4>
                          <ul>Uk Royalty</ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul></ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">LATEST UPDATE April 3</Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">King Edward VIII</h1>
                      <h2>Introduction</h2>

<p>Edward Vlll was the King of the British Empire in 1936 until he abdicated
after less than a year on the throne. He was connected to the Nazi party and
committed treason during World War II that went unpunished and largely unpublished.
Edward served in the British army during World War I, while he was the Prince of Wales.
He was prohibited from fighting on the front line by Lord Kitchener, the Secretary of 
State for War.</p>
<br></br>
<p>Edward was told that the concern was not for himself but that the Germans 
might capture him, which would endanger troops around him and create a wartime
crisis. Edward went to the front lines despite the prohibition and got his 
driver killed by doing so. He witnessed the carnage of the frontlines and wrote
that it was a “pathetic and gruesome sight”. Soldiers wrote home reports of seeing 
Edward in the frontlines which boosted his popularity at home and drove much of the
sympathy towards him in later years. According to historians, his frontline service
shaped his political goal of peace at any cost, even if that meant befriending the enemy
and surrendering his country. </p>
<br></br>
<p>During World War I, Edward also developed an abhorrence for communism and Jewish
people after the Bolsheviks murdered his cousins, the Russian Tsar Nicholas II and 
his family. The Russian Tsar was both his cousin and his godfather. The murder of 
the Russian royals allegedly helped shape his right-wing views and embrace of fascism
as well as the German Nazi party and Adolf Hitler.</p>
<br></br>
<p>In 1917, Edward met a high-class French courtesan, a “Poule de Luxe”, Marguerite 
Alibert, also known as Maggie Meller, in Paris, France. Edward was introduced to 
Alibert by his military friends while on leave. They had an affair that lasted for
about 18 months, during the latter part of the First World War. In 1923, Alibert murdered
her husband, Ali Kamel Fahmy Bey, an Egyptian diplomat, at the Savoy Hotel in London. 
She used a .32 calibre semi-automatic Browning pistol to shoot him in the back and head
multiple times after an alleged argument.</p>
<br></br>
<p>Alibert blackmailed the Royal Family, threatening to reveal Edward’s love letters to her. 
The trial judge disallowed any mention of her past as a courtesan, to ensure that the name of 
Prince Edward was never mentioned as part of the evidence during the trial. At the same time, 
Fahmy was portrayed as "a monster of Eastern depravity and decadence, whose sexual tastes were
indicative of an amoral sadism towards his helpless European wife". Despite the evidence in the
murder being stacked against Alibert, she was quickly acquitted and let go. Some historians say
it was thanks to a conspiracy between Buckingham palace and the Establishment, who feared Edward
would be exposed as her former lover.</p>
<br></br>
<h2> Edward Teaches Elizabeth How To Seig Heil</h2>
<p>In 1933 or 1934, Edward and Queen Elizabeth’s mother were filmed teaching a young Princess 
Elizabeth and her sister, Princess Margaret, how to do a Nazi salute. This was filmed shortly
after Hitler’s Nazi party came to power in 1933.</p> 
<br></br>
<h2>Hitler Plays Matchmaker For Edward</h2>
<p>In 1933, Hitler devised a plan to link the English and German royal houses by having Princess
Friederike, the 17-year-old granddaughter of the Kaiser, marry Edward.Hitler hoped for a return 
to the days of Queen Victoria when English royalty married German royalty. Friederike’s parents,
both ardent Nazis, declined the plan and stated that the 17-year-old princess was too young to 
marry Edward who was 23 years her senior. Rebuffed, Hitler then sent multiple German aristocrats,
including many blood relatives of the Windsors, to London to collect intelligence on the prince 
and the rest of the royal family.</p>
<br></br>
<h2>The Nazi King Falls In Love With Wallis Simpson</h2>
<p>Edward became King Edward VIII of England after the death of his father, George V in January 1936.
During the year that he was king, Edward was having an affair with US citizen, Wallis Simpson. 
Hitler ordered Germany’s UK Ambassador, Joachim von Ribbentrop, to court the new King Edward and Wallis Simpson.
Von Ribbentrop was Hitler's right-hand man and later the Minister of Foreign Affairs of Nazi Germany. 
He was hung at the end of World War II.</p>
<br></br>
<p>Communications between Berlin, Washington, and London diplomats confirmed that Joachim von Ribbentrop and 
Wallis Simpson began having an affair. Von Ribbentrop is said to have sent 17 carnations, one for every time 
they had sex, to Wallis Simpson’s apartment at Bryanston Court in central London. British intelligence was aware
at the time that Wallis Simpson was passing classified information from the King to Joachim von Ribbentrop.</p>
<br></br>
<p>MI5 were concerned that Bryanston Court might be harbouring Nazi spies, one of which was Wallis Simpson. 
Simpson’s neighbour, Austrian Princess Stephanie von Hohenlohe was also a Nazi spy linked to Hitler. 
A frequent visitor of Wallis Simpson and Edward at the apartment of Bryanston Court was Sir Oswald Mosley,
leader of the UK fascist movement which supported Nazi Germany. Mosley was very influential in UK politics.
In 1924, he had come within 100 votes of beating the future Prime Minister, Neville Chamberlain for his seat in parliament.
In the 1930’s, the US FBI identified Wallis Simpson as a Nazi sympathizer. Duke Carl Alexander of Württemberg confirmed with 
the FBI that she and Hitler's right hand man Joachim Von Ribbentrop had been lovers in London.</p>
<br></br>
<h2>Edward Helps Hitler Invade Rhineland</h2>
<p>When Adolf Hitler occupied the Rhineland in March 1936, he relied on the new king to support him. Edward urged 
Prime Minister Stanley Baldwin to take no action against Germany. Edward threatened to abdicate if Hitler’s advance
was stopped and phoned the German ambassador Joachim von Ribbentrop to tell him he had done so. His frequent direct
communications with the German ambassador were unconstitutional. Edward ruled for less than a year, during which time
he alarmed palace officials by installing several  advisers with Nazi sympathies. He abdicated on December 11, 1936, 
allegedly to marry Wallis Simpson over palace objections. UK palace officials stated that their objection to Edward's
marriage stemmed from the fact that Simpson was divorced, a very weak excuse considering Henry the VIII and the 
proliferation of divorced royals since, including the wives of both Prince Charles and Prince Harry.</p>
<h2>Edward Marries Wallis And Helps Nazis Invade France</h2>
<p>Edward and Wallis Simpson married in France in June, 1937. The wealthy French industrialist and Nazi collaborator, 
Charles Bedaux, hosted the couple’s wedding at the Château de Candé, near the city of Tours. The new couple were known 
as the Duke and Duchess of Windsor after the wedding. They lived in Paris and continued contact with members of the Nazi
high command.</p>
<br></br>
<p>Charles Bedaux and Edward would meet at the Paris Ritz and then pass on details of their conversations to Count
Julius von Zech-Burkersroda, the German ambassador in The Hague. Edward informed Charles Bedaux that the British had
found Hitler’s secret plans for the invasion of France. Charles Bedaux informed the Nazi high command of the development.
The Nazi high command subsequently changed its strategy using the information from Edward. They crushed the French army, 
killing thousands, and leaving British and allied forces exposed and forced to retreat at Dunkirk. Charles Bedaux was later
appointed an economic adviser to the Reich and was given responsibility for the liquidation of Jewish businesses in Nazi-occupied France.</p>
<br></br>
                      <p>
                        According to witnesses Wallis and Edward were openly
                        anti-Semitic and continued sympathy for Hitler during
                        the war. ‘My parents were horrified by their
                        dinner-table talk, where they made it perfectly clear
                        that the world would have been a better place if Jews
                        were exterminated,’ recalled Dr Leinhardt. At one dinner
                        party, Edward told an unnamed English friend: ‘I have
                        never thought Hitler was such a bad chap.’ At another
                        party, he took hold of the hands of a guest,
                        intertwining his fingers in hers to illustrate his view
                        that the Jews had their tentacles around German society,
                        and declared ‘All Hitler tried to do was free the
                        tentacles’.[6]
                      </p>
                      <br></br>
                      <p>
                        Edward died at age 78 of throat cancer, in his last two
                        weeks of life he was alone. When he called for Wallis
                        who was in a room nearby she never visited even once
                        according to a nurse.
                      </p>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li>
                          Edward VII, George V and Edward VIII: The Complicated
                          Relationship Between the British Kings and Their
                          Heirs. Biography. (2021). Retrieved 3 March 2021, from
                          https://www.biography.com/news/edward-viii-george-v-edward-viii-difficult-relationship.
                        </li>
                        <br></br>
                        <li>
                          London Jury Acquits Mme. Fahmy of Murder Of Egyptian
                          Husband in the Savoy Hotel (Published 1923).
                          Nytimes.com. (2021). Retrieved 3 March 2021, from
                          https://www.nytimes.com/1923/09/16/archives/london-jury-acquits-mme-fahmy-of-murder-of-egyptian-husband-in-the.html
                        </li>
                        <br></br>
                        <li>
                          Goldsmith, B. (2021). Sex, murder and conspiracy sheds
                          new light on Edward VIII - Book. U.S. Retrieved 3
                          March 2021, from
                          https://www.reuters.com/article/uk-books-edwardviii-conspiracy-idUKBRE9320GL20130403.
                        </li>
                        <br></br>
                        <li>
                          Edward VIII's Murderous Mistress: Was there a cover-up
                          of Edward VIII&rsquo;s fling with a murderess?. The
                          Telegraph. (2021). Retrieved 3 March 2021, from
                          https://www.telegraph.co.uk/culture/tvandradio/9983938/Edward-VIIIs-Murderous-Mistress-Was-there-a-cover-up-of-Edward-VIIIs-fling-with-a-murderess.html.
                        </li>
                        <br></br>
                        <li>
                          Wallis Simpson: How an American Stole the Heart of a
                          British King | BBC America. BBC America. (2021).
                          Retrieved 3 March 2021, from
                          https://www.bbcamerica.com/anglophenia/2011/02/wallis-simpson-how-an-american-stole-the-heart-of-a-british-king.
                        </li>
                        <br></br>
                        <li>
                          The documents that revealed Duke of Windsor's Nazi
                          ties - NZ Herald. NZ Herald. (2021). Retrieved 3 March
                          2021, from
                          https://www.nzherald.co.nz/lifestyle/the-documents-that-revealed-duke-of-windsors-nazi-ties/7RTNIFJA55SS6FLQ5B6CSFBPCM/.
                        </li>
                        <br></br>
                        <li>
                          Gye, H. (2021). Letter from Edward VIII ponders
                          whether he could have stopped WWII. Mail Online.
                          Retrieved 3 March 2021, from
                          https://www.dailymail.co.uk/news/article-2522559/Unseen-letter-Edward-VIII-ponders-stopped-war-king.html.
                        </li>
                        <br></br>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default KingEdwardVIII;
