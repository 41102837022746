import React from "react";

// core components
import Navbar from "../components/Navbar/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Content from "./Content.js";

// SEO
import { Helmet } from "react-helmet";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }

  render() {
    return (
      <>
        <Helmet>
            <title>Spooky Connections - End Impunity</title>
            <meta name="description" content="An independent international open source investigation to probe transnational 
            organized crime"/>
            <meta itemprop="name" content="Spooky Connections - Ending Impunity"/>
            <meta itemprop="description" content="An independent international open source investigation to probe transnational 
            organized crime"/>
            <meta itemprop="image" content="https://www.spookyconnections.com/static/media/SpookyConnections.6e1b905f.png"/>

            <meta property="og:url" content="https://www.spookyconnections.com"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Spooky Connections - Ending Impunity"/>
            <meta property="og:description" content="An independent international open source investigation to probe transnational 
            organized crime"/>
            <meta property="og:image" content="https://www.spookyconnections.com/static/media/SpookyConnections.6e1b905f.png"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="630"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="Spooky Connections - Ending Impunity"/>
            <meta name="twitter:description" content="An independent international open source investigation to probe transnational 
            organized crime"/>
            <meta name="twitter:image" content="https://www.spookyconnections.com/static/media/SpookyConnections.6e1b905f.png"/>
       </Helmet>
        <Navbar />
        <Content />
        <Footer fluid />
        
        
      </>
    );
  }
}

export default Main;
