import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/CharlesKushner.png";
import ComingSoon from "../../../../ComingSoon/Content.js";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton
} from "react-share";


class  CKushner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };    
   } 
  render() {
    
    return (
      <>
       
        <div className="wrapper">
          
        <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
              
            <Container className="content">
            <Row>
          <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                      <img
                        alt="..."
                        className="avatar"
                        src={image}
                      />
                      <h4 className="title">Charles Kushner</h4>
                    {/* </a> */}
                    <p className="description">Billionaire</p>
                  
                  <Button  color="primary"id="contents" size="sm">
                    Contents {''}
                    <i className="tim-icons icon-bullet-list-67" />
                  </Button>                 
                  <UncontrolledCollapse toggler="#contents" style={{textAlign:'left', marginBottom: '1rem'}}>
                      <ol>
                        <li>Introduction</li>
                         
                      </ol> 
                </UncontrolledCollapse>
                  <Button  color="primary" id="connections" size="sm" >
                    Connections {''}
                    <i className="tim-icons icon-vector" />
                  </Button>
                  <UncontrolledCollapse toggler="#connections" style={{textAlign:'left', marginBottom: '1rem'}}>            
                  <ul>
                    
                  </ul>
                <h4>Family of:</h4>
                <ul>
              
              </ul>
                  <h4>Owner / Founder Of:</h4>
                  <ul>
                                
                  </ul>
                </UncontrolledCollapse>
                  <div>
                </div>
                </div>
                
                </CardBody>
                <CardFooter>
                <div className="button-container">
                <h5>Share:</h5>        
                <FacebookShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="facebook">
                  <i className="fab fa-facebook" />
                </Button>
                </FacebookShareButton>
                <TwitterShareButton
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="twitter">
                  <i className="fab fa-twitter" />
                </Button>
                </TwitterShareButton>
                <RedditShareButton
                  text={'hello world'}
                  url={String(window.location)}
                  media={`${String(window.location)}/${image}`}
                >
                <Button className="btn-icon btn-round" color="reddit">
                  <i className="fab fa-reddit-alien" />
                </Button>
                </RedditShareButton>                
                </div>  

                </CardFooter>
              </Card> 
              
                <Alert color="danger">
               LATEST UPDATE NOVEMBER 25 Introduction
                </Alert>
                
            
            </Col>
            <Col md="8">
            

              <Card>
                <CardBody>
                  <ComingSoon />
                  {/* <h1 class="typography-line">Charles Kushner</h1>
                  
                  <h2> Introduction </h2>
                    <p>
                  
                    </p> */}
                    <br></br>
                  <br></br>     
              </CardBody>
              {/* <CardFooter>
                <h4>Citations</h4>
              <ol>
                <li></li>   
              </ol>
              </CardFooter> */}
                     
              </Card>
            </Col>           
          </Row>
            
            </Container>       
          
            </div>

        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        /> 
      </>
    );
  }
}

export default  CKushner;



