import React, { Component } from 'react';
import Navbar from "../components/Navbar/Navbar.js";
import Content from "../views/Contact/Content.js";
import Footer from "../components/Footer/Footer.js";



export class Contact extends Component {
    render() {
        return (
            <>
            <Navbar fluid />
            <Content />
            <Footer fluid/>
            </>
        )
    }
}

export default Contact;
