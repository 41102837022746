import React, { Component } from 'react';
import Navbar from "../components/Navbar/Navbar.js";
import Header from "../views/Legal/Header.js";
import Footer from "../components/Footer/Footer.js";



export class About extends Component {
    render() {
        return (
            <>
            <Navbar />
            <Header />
            <Footer />
            </>
        )
    }
}

export default About;
