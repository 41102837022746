import React from "react";
// import { Link } from "react-router-dom";
import image from "../../../../../../assets/img/profile/RobertMaxwell.png";
import InstagramEmbed from "react-instagram-embed";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

// core components
import FixedPlugin from "../../../../../../components/FixedPlugin/FixedPlugin.js";
import FixedGlobe from "../../../../../../components/FixedGlobe/FixedGlobe.js";
import FixedContact from "../../../../../../components/FixedContact/FixedContact.js";

// social share
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

class RMaxwell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "default",
    };
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <Container className="content">
              <Row>
                <Col md="4">
                  <Card className="card-user">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        {/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
                        <img alt="..." className="avatar" src={image} />
                        <h4 className="title">Robert Maxwell</h4>
                        {/* </a> */}
                        <p className="description">Billionaire</p>

                        <Button color="primary" id="contents" size="sm">
                          Contents {""}
                          <i className="tim-icons icon-bullet-list-67" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#contents"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ol>
                            <li>Introduction</li>
                            <li>Robert Maxwell and WW2</li>
                          </ol>
                        </UncontrolledCollapse>
                        <Button color="primary" id="connections" size="sm">
                          Connections {""}
                          <i className="tim-icons icon-vector" />
                        </Button>
                        <UncontrolledCollapse
                          toggler="#connections"
                          style={{ textAlign: "left", marginBottom: "1rem" }}
                        >
                          <ul></ul>
                          <h4>Family of:</h4>
                          <ul>
                            <li>Elisabeth Maxwell</li>
                            <li>Ghislaine Maxwell</li>
                            <li>Philip Maxwell</li>
                            <li>Ann Maxwell</li>
                            <li>Christine Maxwell</li>
                            <li>Isabel Maxwell</li>
                            <li>Ian Maxwell</li>
                            <li>Kevin Maxwell</li>
                            <li>Karine Maxwell</li>
                            <li>Michael Maxwell</li>
                          </ul>
                          <h4>Owner / Founder Of:</h4>
                          <ul>
                            <li> Mirror group of newspapers </li>
                            <li>Maxwell Communications</li>
                            <li>Nimbus Records</li>
                            <li>P.F. Collier</li>
                            <li>Official Airline Guide</li>
                            <li>Prentice Hall Information Services</li>
                            <li>Macmillan publishing</li>
                            <li>Berlitz language schools </li>
                            <li>Pergamon Press</li>
                          </ul>
                        </UncontrolledCollapse>
                        <div></div>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="button-container">
                        <h5>Share:</h5>
                        <FacebookShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="facebook"
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button
                            className="btn-icon btn-round"
                            color="twitter"
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                        </TwitterShareButton>
                        <RedditShareButton
                          text={"hello world"}
                          url={String(window.location)}
                          media={`${String(window.location)}/${image}`}
                        >
                          <Button className="btn-icon btn-round" color="reddit">
                            <i className="fab fa-reddit-alien" />
                          </Button>
                        </RedditShareButton>
                      </div>
                    </CardFooter>
                  </Card>

                  <Alert color="danger">
                    LATEST UPDATE JANUARY 14 Introduction
                  </Alert>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <h1 class="typography-line">Robert Maxwell</h1>

                      <h2> Introduction </h2>
                      <p>
                        Robert Maxwell was born in the easternmost province (now
                        Ukraine) of Czechoslovakia on June 10th, 1923, his
                        original name is Ján Ludvík Hyman Binyamin Hoch. Robert
                        Maxwell's parents were Mechel Hoch and Hannah Slomowitz.
                        He had six siblings. He was married to and had 9
                        children, Ghislaine Maxwell, Philip Maxwell, Ann
                        Maxwell, Christine Maxwell, Isabel Maxwell, Karine
                        Maxwell, Ian Maxwell, Kevin Maxwell, and Michael
                        Maxwell. Karine Maxwell died of leukemia at age three
                        and Michael Maxwell died at age 15 after a car accident.
                      </p>
                      <br></br>
                      <p>
                        At the peak of his business empire, his net worth was
                        $1.9 billion. After his death, his business empire was
                        nearly $4 billion in debt. His companies included the
                        Mirror group of newspapers, Maxwell Communications,
                        Nimbus Records, P.F. Collier, Official Airline Guide,
                        Prentice Hall Information Services, Macmillan
                        publishing, the Berlitz language schools, and Pergamon
                        Press, a technical publishing company. Maxwell led a
                        flamboyant lifestyle living in Headington Hill Hall in
                        Oxford, where he frequently flew in his helicopter and
                        sailed his £15M luxury yacht, the Lady Ghislaine. Named
                        after his favorite daughter Ghislaine Maxwell who was
                        arrested in 2020 for her role in the Epstein child
                        trafficking network. Robert Maxwell had known links to
                        the British Secret Intelligence Service MI6, to the
                        Soviet KGB, and the Israeli intelligence service Mossad.
                        He was also part of the Mega Group which was heavily
                        linked to Israel and organized crime, founded by Charles
                        Bronfman and Leslie Wexner, the latter being Jeffrey
                        Epstein's main monetary source.{" "}
                      </p>
                      <br></br>
                      <p>
                        Robert Maxwell was notably litigious and often embroiled
                        in controversy, including his support for Israel at the
                        time of the 1948 Arab-Israeli War, his ties to Iran
                        Contra, and his involvement in the PROMIS affair. In
                        1989, he had to sell businesses, including Pergamon
                        Press, to cover some of his debts. In 1991, his body was
                        discovered having fallen overboard from his yacht. He
                        was given a full state funeral and buried in Jerusalem.
                        Major discrepancies in his companies' finances were
                        revealed soon after his death, including his fraudulent
                        misappropriation of the Mirror Group pension fund at the
                        tune of £460m.{" "}
                      </p>
                      <br></br>
                      <h2>Robert Maxwell and WW2 </h2>
                      <p>
                        In 1939, the area Maxwell was living in was taken over
                        by Hungary until 1944 when it was occupied by Nazi
                        Germany. Maxwell fled to France while most members of
                        his family died in Auschwitz. In May 1940, he joined the
                        Czechoslovak Army in exile in Marseille France. After
                        the fall of France and the British retreat to Britain,
                        Robert Maxwell using the name "Ivan du Maurier", or
                        "Leslie du Maurier", the surname taken from the name of
                        a popular cigarette brand took part in a protest against
                        the leadership of the Czechoslovak Army, and with 500
                        other soldiers, he was transferred to the British Army
                        under the Pioneer Corps and later to the North
                        Staffordshire Regiment in 1943. He was then involved in
                        action across Europe, from the Normandy beaches to
                        Berlin, and achieved the rank of sergeant. Robert
                        Maxwell gained a commission in 1945 and was promoted to
                        the rank of captain. In January 1945, he received the
                        Military Cross from Field Marshal Montgomery. Robert
                        Maxwell naturalized as a British subject on June 19,
                        1946, and legally changed his name by deed poll from
                        Ludvick Hoch to Ian Robert Maxwell on June 30, 1948.{" "}
                      </p>
                      <br></br>
                      <InstagramEmbed
                        clientAccessToken="727084844885115|041efd28728cfc345a9392b4307e5ea4"
                        url="https://www.instagram.com/p/CKEIQPhjenw/"
                        hideCaption={true}
                        containerTagName="div"
                        injectScript
                        protocol=""
                        onLoading={() => {}}
                        onSuccess={() => {}}
                        onAfterRender={() => {}}
                        onFailure={() => {}}
                      />
                      <br></br>
                      <p>
                        In 1991, the U.K. Metropolitan Police Force began a war
                        crimes investigation into Robert Maxwell after it was
                        revealed he murdered an unarmed German civilian in 1945.
                        It was also claimed Maxwell ordered the indiscriminate
                        shelling of civilians and towns while serving in the
                        British army. The German victim was the mayor of a small
                        town in Germany, the murder was revealed in a letter
                        penned by Robert Maxwell to his wife, where he admits
                        how he summoned the town's mayor, who he instructed to
                        tell German troops to surrender, "... so I sent one of
                        the Germans to fetch the mayor of the town," "In half an
                        hour's time, he turned up and I told him that he had to
                        go to tell the Germans to surrender and hang the white
                        flag otherwise the town will be destroyed. One hour
                        later, he came back saying that the soldiers will
                        surrender and the white flag was put up so we marched
                        off, but as soon as we marched off a German tank opened
                        fire on us. Luckily he missed so I shot the mayor and
                        withdrew." Maxwell when on to admit how he was
                        destroying civilian towns and homes on his way through
                        Germany, "As you can well imagine, I am not taking any
                        prisoners, and, whatever home my men occupy, before I
                        leave I order it to be destroyed." The name of the
                        affected towns are not specified and the Metropolitan
                        Police Force closed the investigation after being
                        allegedly unable to find evidence or witnesses while
                        disregarding Maxwell’s own admission to the crime.
                      </p>
                      <br></br>
                      <h2>Maxwell, MI6, and Pergamon Press</h2>
                      
                      <p>
                        In 1945, the British were looking to acquire technical
                        information and documents. First, they set up the
                        British enemy publications committee to filter German
                        classified scientific information extracted from
                        captured documents or scientists. When it failed to
                        produce satisfactory results, MI6 set up its own
                        networks and fronts to develop British scientific
                        publishing.{" "}
                      </p>
                      <br></br>
                      <p>
                        In November 1945, Robert Maxwell was employed as an
                        interrogation officer in Bad Salzuflen, Germany, which
                        was an Intelligence Corps facility. There he
                        interrogated German scientists and extracted information
                        from them. Robert Maxwell's work sometimes took him into
                        Soviet territory where he made Soviet contacts, which
                        exposed him to rumors and suspicions about his loyalty.
                        Correspondence between various members of the
                        Information Research Department (IRD), a covert unit of
                        the Foreign Office, reveals that Maxwell had been
                        considered to be a suspicious figure directly after the
                        war. In 1946, Robert Maxwell served in the Press and
                        Publicity Branch of the British Information Service in
                        Berlin, for the Foreign Office for two years as a
                        newspaper censor at the British Control Commission.
                        There Robert Maxwell accumulated contacts such as Hugh
                        Quennell, Dr. Paul Rosebaud, and Ferdinand Springer,
                        which he used to go into business. British intelligence
                        officers, in reports submitted to the Information
                        Research Department (IRD), suspected that Robert Maxwell
                        was "a thoroughly bad character and almost certainly
                        financed by Russia".{" "}
                      </p>
                      <br></br>
                      <p>
                        {" "}
                        In November of that year, the MI6 heavy Cabinet Office
                        of the Scientific Advisory Board held its first meeting,
                        the objective of the meeting was to develop scientific
                        publishing through a new company, Butterworth Scientific
                        Publications, using the expertise of German exile Dr.
                        Paul Rosebaud, who attended the meeting. The meeting
                        chaired by Sir John Anderson, the secretary was
                        Professor Robert Hutton and other scientific advisers
                        included Sir Wallace Akers, Sir Charles Darwin, Sir
                        Alfred Egerton, Sir Richard Gregory, Sir Edward
                        Salisbury, Sir Alexander Fleming, and Sir Edward
                        Appleton. The meeting also included Special Operations
                        Executive (SOE) officer Charles Hambro, the son of Sir
                        Eric Hambro a partner in C. J. Hambro & Son (later to
                        become Hambros Bank), who acted as Lieutenant Commander
                        Eric Welsh's Representative.{" "} A notable outcome of the meeting was the forging of a
                        relationship with the old publishing firm, Butterworth.
                        The representative at the meeting for Butterworth was
                        Count Frederick Vanden Heuvel, a Dutch-born MI6 officer,
                        known as 'Fanny the fixer', his primary role was to
                        recruit 'Z' agents - journalists, publishers, and
                        businessmen. One of his agents was Major John Whitlock,
                        a former SOE officer and 'intelligence veteran who
                        worked as Butterworth's joint managing director. He had
                        been at the board meeting with fellow director and SOE
                        officer Hugh Quennell, who had acted for Hambros when
                        the bank purchased a quarter of a million Butterworth
                        shares during the war.{" "}
                      </p>
                      <br></br>
                      <p>
                        In 1946, Robert Maxwell met Dr Ferdinand Springer, owner
                        of the German Springer Verlag, Europe's leading pre-war
                        scientific publisher which was under financial burden
                        due to the war. While still working at the Control
                        Commission, Robert Maxwell became the director of a firm
                        that offered distribution for Springers journals across
                        Europe and the United States. In 1948, Butterworth
                        Scientific Publications failed to take off and a joint
                        company was created with its partner Springer Verlag,
                        the new company was called Butterworth-Springer. In
                        1951, Robert Maxwell bought three-quarters of
                        Butterworth-Springer and the remaining quarter was held
                        by the former Springer Verlag scientific editor Paul
                        Rosbaud. They changed the name of the company to
                        Pergamon Press and rapidly built it into a major
                        publishing house.
                      </p>

                      <p>To be Continued</p>
                    </CardBody>
                    <CardFooter>
                      <h4>Citations</h4>
                      <ol>
                        <li></li>
                        <p>Coming Soon</p>
                      </ol>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedGlobe
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
        <FixedContact
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default RMaxwell;
